import { AccountService, AffiliateService } from 'src/app/core/services';
import { TranslateService } from '@ngx-translate/core';

import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { B2BService, CoursesService, SystemService } from './../../../core/services';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';




@Component({
  selector: 'sm-joinastrainer',
  templateUrl: './joinastrainer.component.html',
  styleUrls: ['./joinastrainer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JoinastrainerComponent implements OnInit {

  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  hasError = false;
  errorMsg = '';
  button = 'ارسال الطلب';
  titles = [];
  separateDialCode = false;
  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];
  phoneForm = new UntypedFormGroup({
    mobile: new UntypedFormControl(undefined, [Validators.required])
  });
  phonex: any;
  studies = [];

  selectedMajors;
  majors: any[] = [];
  majorNames = [];
  expfields = [];
  langs = [];
  experiences = [
    { id: 0, name: '0 - 1' },
    { id: 1, name: '2 - 3' },
    { id: 2, name: '4 - 7' },
    { id: 3, name: '8 - 12' },
    { id: 4, name: '+12' },
  ];


  constructor(private _b2bService: B2BService, private _router: Router, private _translate: TranslateService,
    private _account: AccountService, private cd: ChangeDetectorRef, private _affiliate: AffiliateService,
    private _system: SystemService, private formBuilder: UntypedFormBuilder) {
    for (let i = 0; i < 6; i++) {
      this.titles.push(
        { name: this._translate.instant('TITLES.TITLE' + (i + 1)) }
      )
    }
    for (let i = 0; i < 5; i++) {
      this.studies.push(
        { name: this._translate.instant('STUDIES.STUDY' + (i + 1)) }
      )
    }
    for (let i = 0; i < 5; i++) {
      this.expfields.push(
        { name: this._translate.instant('EXPFIELDS.STUDY' + (i + 1)) }
      )
    }
    for (let i = 0; i < 3; i++) {
      this.langs.push(
        { name: this._translate.instant('LANGUAGES.STUDY' + (i + 1)) }
      )
    }
    for (let i = 0; i < 20; i++) {
      this.majorNames.push(
        { name: this._translate.instant('MAJORS.STUDY' + (i + 1)) }
      )
    }
  }

  addTagFn(name) {
    return { name: name, tag: true };
  }

  ngOnInit() {
    this._affiliate.getTrainersPolicies().subscribe((data: any[]) => {
      this.policies = data;
      this.cd.markForCheck();
    })
    this.majorNames.forEach((c) => {
      this.majors.push({ name: c });
    });
    this.newCourse = this.formBuilder.group({
      id: null,
      title: [{ value: '', disabled: this.loading }, Validators.required],
      name: [{ value: '', disabled: this.loading }, Validators.required],
      email: [{ value: '', disabled: this.loading }, Validators.email],
      nationality: [{ value: '', disabled: this.loading }, Validators.required],
      country: [{ value: '', disabled: this.loading }, Validators.required],
      city: [{ value: '', disabled: this.loading }, Validators.required],
      mobile: [{ value: '', disabled: this.loading }, Validators.required],
      birthdate: [{ value: '', disabled: this.loading }, Validators.required],
      educationalLevel: [{ value: '', disabled: this.loading }, Validators.required],
      major: [{ value: '', disabled: this.loading }],
      specialized: [{ value: '', disabled: this.loading }],
      programs: [{ value: '', disabled: this.loading }],
      totalYearsOfExperience: [{ value: '', disabled: this.loading }, Validators.required],
      scopedExperience: [{ value: '', disabled: this.loading }, Validators.required],
      languages: [{ value: '', disabled: this.loading }, Validators.required],
      additionalServices: [{ value: '', disabled: this.loading }],
      consultancy: [{ value: '', disabled: this.loading }],
      cvUrl: [{ value: '', disabled: this.loading }],
      personalProgram: [{ value: '', disabled: this.loading }],
      recorded: [{ value: '', disabled: this.loading }],
      youtube: [{ value: '', disabled: this.loading }],
      youtubechannel: [{ value: '', disabled: this.loading }],
      instagram: [{ value: '', disabled: this.loading }],
      instagramprofile: [{ value: '', disabled: this.loading }],
      facebook: [{ value: '', disabled: this.loading }],
      facebookpage: [{ value: '', disabled: this.loading }],


    });
  }

  get xf() { return this.newCourse.controls; }

  isYoutube
  setYoutube(e) {
    this.isYoutube = e;
  }
  isInstagram
  setInstagram(e) {
    this.isInstagram = e;
  }
  isFacebook
  setFacebook(e) {
    this.isFacebook = e;
  }
  policies: any[] = [];

  onSubmit() {
    this.submitted = true;

    this.newCourse.patchValue({
      mobile: this.phonex.e164Number
    })

    console.log(this.newCourse.controls);

    if (this.newCourse.invalid) {
      this.submitted = false;
      return;
    }




    this.loading = true;
    var major = '';
    this.selectedMajors.forEach(element => {
      major += element.name + " "
    });
    this.newCourse.patchValue({
      major: major
    })
    console.log(this.newCourse.value);
    this._account.applyForTrainerWithus(this.newCourse.value).subscribe((data) => {
      this.isapplied = true;
    })
    // this._b2bService.AddOrModifyB2BSubscription(this.newCourse.value).subscribe((data) => {
    //   // console.log(data);
    //   this.newCourse.reset();
    //   this.submitted = false;
    //   // this.button = 'إضافة الدورة'
    // });
  }

  isapplied = false;

}
