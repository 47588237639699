import { AccountService } from 'src/app/core/services/account.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'sm-certchecker',
  templateUrl: './certchecker.component.html',
  styleUrls: ['./certchecker.component.scss']
})
export class CertcheckerComponent implements OnInit {

  code
  result : any[] ;

  constructor(private _account: AccountService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }


  checkCode() {
    console.log(this.code);
    if(this.code && this.code.length > 5 ) {
      this._account.checkYourCertificate(this.code).subscribe((data) => {
        this.result = data ;
        this.cd.markForCheck();
      })
    }
  }

}
