import { Subscription, Observable } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, NgZone, HostListener } from '@angular/core';
// import { AuthService } from './core/auth/auth.service';
import { TranslationService } from './core/services/translation.service';
import {
  NavigationStart,
  NavigationEnd,
  RouterOutlet,
  Event,
  Router
} from "@angular/router";
import { LoadingBarService } from '@ngx-loading-bar/core';

import { locale as enLang } from './core/i18n/en';
import { locale as arLang } from './core/i18n/ar';
import { locale as esLang } from './core/i18n/es';
import { locale as jpLang } from './core/i18n/jp';
import { locale as deLang } from './core/i18n/de';
import { locale as frLang } from './core/i18n/fr';


import * as signalR from '@microsoft/signalr';
import { Store, select } from '@ngrx/store';
import * as profileReducer from './core/state/_profile/profile.reducer';
import { loadHeaderStyle } from './core/services';
import { environment } from '../environments/environment';
import { BlockTypes, ButtonBlock, BlockFactory } from './core/_models/newstuff';
import { BroadcastService } from './core/services/channel.service';
import { MatDialog } from '@angular/material/dialog';
import { AnnouncementComponent } from './views/course/announcement/announcement.component';
import { AlertMessageComponent, AlertType } from './views/dialogs/alertmessage/alertmessage.component';


@Component({
  selector: 'body[mas-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {




  @HostListener("window:onbeforeunload", ["$event"])
  clearLocalStorage(event) {
    localStorage.clear();
    this._store.dispatch({ type: '[Profile] Clear Out Everything' })
  }

  isAuthenticated: boolean;
  userData: Observable<any>;
  loading$: Observable<boolean>;
  sub$ = new Subscription();
  announcement = new Subscription();
  rtl = true;
  block: ButtonBlock;
  blockReady = {
    buttonAction: 'buttonAction',
    buttonUrl: 'ButtonURL',
    buttonText: 'Button Text',
    blockId: 1,
    id: 23,
    design: {
      backgroundColor: 'backgroundColor',
      borderRadius: 'borderRaduis',
      padding: 'padding'
    }
  }

  constructor(
    private _dialogRef: MatDialog,
    private _channel: BroadcastService, private _store: Store<any>, private loadingBar: LoadingBarService,
    private _router: Router, private gtmService: GoogleTagManagerService, private _ngZone: NgZone,
    private translate: TranslateService, private translationService: TranslationService,) {

    this._channel.restartConnection();
    this.translationService.loadTranslations(enLang, arLang, esLang, jpLang, deLang, frLang);
    // document.documentElement.style.setProperty('--primary-color', environment.MAINColor);
    let bProfile = JSON.parse(localStorage.getItem('__template'));
    if (bProfile) {
      document.documentElement.style.setProperty('--primary-color', bProfile.primary);
    }

    // _dialogRef.open(AnnouncementComponent, {
    //   data: { header: "HELLO ", body: "BODY" }
    // })

    const browserLang = this.translate.getBrowserLang();
    // console.log(browserLang);
    if (browserLang && browserLang === 'en') {
      this.rtl = false;
    }


    // this.block = BlockFactory.createBlock(BlockTypes.Button);
    // this.block = this.blockReady;
    // console.log(this.block);

    // this._router.events.subscribe((event: Event) => {
    //   this.loadingBarInterceptor(event);
    // });

  }

  private loadingBarInterceptor(event: Event) {
    var loader = this.loadingBar.useRef('router');
    if (event instanceof NavigationStart) {
      loader.start();
    }
    if (event instanceof NavigationEnd) {
      loader.complete();
    }
  }

  ngOnInit() {

    console.log('%cWelcome!', 'background-color:#f00;color:#ff0;font-size:16pt');
    console.log('-------------------------------------');
    console.log('This tool is intended for use by developers and generally people with technical knowledge.');
    console.log('DO NOT copy/paste any text that someone has given to you, because you risk the security of your account.');
    console.log('');
    console.log('Current Version: %s', environment.LMSVersion);
    console.log('%cELT', 'background-color:#91191c;color:#fff;font-size:16pt');
    console.log('%cE Learning Technology', 'background-color:#4E2B79;color:#fff;font-size:16pt');
    console.log('');

    loadHeaderStyle('ar');

    this.loading$ = this._store.pipe(select(profileReducer.isLoading));
    this.announcement = this._channel.announcement$.subscribe((data) => {
      if (data) {
        // console.log(data);

        var alertData = { title: data.header, body: data.body, type: AlertType.Information }
        var dialog = this._dialogRef.open(AlertMessageComponent, {
          data: alertData,
          height: '200px',
          width: '400px'
        })
        dialog.afterClosed().subscribe((data) => {
          this._channel.announcement$.next(null);
        })
      }
    })
    // this._store.dispatch({ type: '[Categories] Load All Categories' });
    // this._store.dispatch({ type: '[Counsultancies] Load Available Consultancies' });
    // this._store.dispatch({ type: '[Courses] Load Available Courses' });
    // this._store.dispatch({ type: '[Packages] Load Available Packages' });
    // this._store.dispatch({ type: '[Profile] Stop Loading' });
    // this._store.dispatch({ type: '[Cart] Get Cart' });


    // this._router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };
    //     this.gtmService.pushTag(gtmTag);
    //   }
    // });

    // this.oidcSecurityService.getIsAuthorized().subscribe(auth => {
    //   this.isAuthenticated = auth;
    //   this.loading = false;
    //   if (!auth) {
    //     this._router.navigateByUrl('/unauthorized');
    //   }
    // });

    // this.oidcSecurityService.getUserData().subscribe(userData => {
    //   this.userData = userData;
    // });



  }


  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }


  ngOnDestroy(): void {
    this.sub$.unsubscribe();
    this.announcement.unsubscribe();

  }

}
