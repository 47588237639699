import { consultancyCard } from './../../_models/consultancies';
import { createAction, props } from '@ngrx/store';




export const AvaliableConsultancies = createAction('[Counsultancies] Load Available Consultancies');
export const LoadConsultanciesFail = createAction('[Counsultancies] Load Available Consultancies Fail', props<{ error: any }>());
export const LoadConsultanciesSuccess = createAction('[Counsultancies] Load Available Consultancies Success', props<{ consultancies: consultancyCard[] }>());
export const GetSelectedConsultancy = createAction('[Counsultancies] Load Current Consultancy', props<{ consultancies: consultancyCard }>());
export const SelectConsultancy = createAction('[Counsultancies] Select And Update Consultancy', props<{ consultancyId: number }>());
export const SelectedConsultancyError = createAction('[Counsultancies] Failed To Get Selected Consultancy', props<{ error: any }>());
