<div *ngIf="!loaded">

</div>
<div *ngIf="loaded" class="sm-slider-container sm-slider-container-{{id}}" id="sm-slider-container-{{id}}">

  <div class="sm-slider-header">
    {{header}}
  </div>
  <div class="sm-slider-pages">
    <ul>
      <ng-container *ngFor="let item of pager.numberOfPages; let i = index ;">
        <li *ngIf="pager.currentPage != i + 1 "></li>
        <li *ngIf="pager.currentPage == i + 1 " class='active'></li>
      </ng-container>
    </ul>

  </div>
  <div class="sm-slider-body" id="sm-slider-body-{{id}}">
    <span class="sm-slider-next sm-slider-next-{{id}}" *ngIf="pager.currentPage != pager.endPage"
      (mouseenter)="hoverNext()" (mouseleave)="releaseNext()" (click)="scrollTo(pager.currentPage + 1 )">

      <svg id='next-{{id}}' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
        id="Layer_1" x="0px" y="0px" viewBox="0 0 511.991 511.991" style="enable-background:new 0 0 511.991 511.991;"
        style="stroke: #fff; fill: #fff;" xml:space="preserve">
        <path id='nextMen-{{id}}'
          d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" />
      </svg>
    </span>
    <div class="sm-slider" (swiperight)="scrollTo(pager.currentPage + 1 )"
      (swipeLeft)="scrollTo(pager.currentPage - 1 )">

      <div class="sm-slider-items">
        <ng-container *ngFor="let item of sliders; let i = index;">
          <div routerLinkActive="active" class="sm-slider-item sm-slider-item-{{id}} sm-slider-item-{{i}}-{{id}}"
            id="sm-slider-item-{{i}}-{{id}}" (click)="onClick(i,item)" (mouseenter)="onMouseEnter(i)"
            (mouseleave)="onMouseLeave(i)">

            <div class="sm-item-body">
              <div *ngIf="item.discount" style="position: absolute; top: 0; left: 0; z-index: 2;">
                <div class="discount">
                  <span style="display: flex;">

                    <p style="font-size: 10px; margin-top: 10px;">
                      %
                    </p>
                    <p [ngStyle]="{ 'position':'absolute','top':'10px', 'right':'10px'  }">
                      {{item.percent}}
                    </p>
                  </span>
                </div>

              </div>
              <!-- <img class="sm-item-image" [useSrcset]="true" [defaultImage]="defaultImage" [lazyLoad]="item.image" -->
              <img class="sm-item-image" [alt]='item.header' />
              <!-- <div class="sm-item-image" [style.background-image]="'url(' +item.image+')'"></div> -->
              <span class="sm-item-title" id="sm-item-content-body-{{i}}-{{id}}">
                {{item.header}}
              </span>
              <div class="sm-item-content-body">

                <span class="sm-item-content sm-item-content-{{i}}-{{id}}">
                  {{item.body.trainer}}
                </span>
                <span class="sm-item-price sm-item-price-{{i}}-{{id}}">
                  <span *ngIf="item.discount" class="sm-price sm-price-{{i}}-{{id}}">

                    <p class="sm-original-price sm-original-price-{{i}}-{{id}}">
                      <span class="sm-original-price-line sm-original-price-line-{{i}}-{{id}}"></span>
                      {{item.body.price}} <span>SAR</span>
                    </p>
                    <p class="sm-new-price sm-new-price-{{i}}-{{id}}">
                      {{item.body.newPrice}} <span>SAR</span> </p>
                  </span>
                  <span *ngIf="!item.discount" class="sm-price sm-price-{{i}}-{{id}}">
                    <p class="sm-new-price sm-new-price-{{i}}-{{id}}">{{item.body.price}}</p>
                  </span>
                </span>
                <svg version="1.1" id="moreinfo-{{i}}-{{id}}" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.998 511.998"
                  style="enable-background:new 0 0 511.998 511.998;" xml:space="preserve">
                  <g>
                    <path
                      d="M509.04,131.296c-4.063-4.24-10.802-4.406-15.083-0.333L255.999,358.577L18.04,130.962
        c-4.271-4.094-11.021-3.927-15.083,0.333c-4.073,4.26-3.917,11.01,0.333,15.083l245.333,234.667
        c2.063,1.969,4.719,2.958,7.375,2.958s5.313-0.99,7.375-2.958l245.333-234.667C512.957,142.306,513.113,135.556,509.04,131.296z" />
                  </g>
                </svg>
              </div>
            </div>

            <div class="sm-slider-item-active sm-slider-item-active-{{i}}-{{id}}"
              id="sm-slider-item-active-{{i}}-{{id}}">

            </div>
          </div>
        </ng-container>

      </div>

    </div>
    <span class="sm-slider-prev sm-slider-prev-{{id}}" *ngIf="pager.currentPage != pager.startPage"
      (mouseenter)="hoverPrev()" (mouseleave)="releasePrev()" (click)="scrollTo(pager.currentPage - 1 )">


      <svg id='prev-{{id}}' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
        id="Layer_1" x="0px" y="0px" viewBox="0 0 511.995 511.995"
        style="enable-background:new 0 0 511.995 511.995; stroke: #fff; fill: #fff;" xml:space="preserve">
        <path
          d="M381.039,248.62L146.373,3.287c-4.083-4.229-10.833-4.417-15.083-0.333c-4.25,4.073-4.396,10.823-0.333,15.083    L358.56,255.995L130.956,493.954c-4.063,4.26-3.917,11.01,0.333,15.083c2.063,1.979,4.729,2.958,7.375,2.958    c2.813,0,5.604-1.104,7.708-3.292L381.039,263.37C384.977,259.245,384.977,252.745,381.039,248.62z" />
      </svg>

      <!-- <svg  height="512pt" viewBox="0 -77 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <path d="m292.246094 357.222656v-81.195312h-163.1875v-194.832032h163.1875v-81.195312l219.753906 178.613281zm0 0"
          fill="#42a5f5" />
        <g id="prevMen-{{id}}" fill="#1e88e5">
          <path
            d="m16.234375 276.027344c-8.960937 0-16.234375-7.273438-16.234375-16.234375v-162.359375c0-8.964844 7.273438-16.238282 16.234375-16.238282 8.964844 0 16.238281 7.273438 16.238281 16.238282v162.359375c0 8.960937-7.273437 16.234375-16.238281 16.234375zm0 0" />
          <path
            d="m81.179688 276.027344c-8.960938 0-16.234376-7.273438-16.234376-16.234375v-162.359375c0-8.964844 7.273438-16.238282 16.234376-16.238282 8.960937 0 16.234374 7.273438 16.234374 16.238282v162.359375c0 8.960937-7.273437 16.234375-16.234374 16.234375zm0 0" />
          <path d="m292.246094 357.222656v-81.195312h-163.1875v-97.414063h382.941406zm0 0" />
        </g>
      </svg> -->
    </span>
  </div>
</div>
