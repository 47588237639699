import { MaterialTypes } from ".";



export class Materials {
  id?: number;
  Id?: number;
  unitId?: number;
  length: number;
  link: string;
  // isFree: boolean;
  notes: string;
  addedBy: number;
  addedDate?: Date;
  updateAt?: Date;
  name: string;
  file: any;
  // type: MaterialTypes;

}


export class questionOnVideo {
  id?: number;
  Id?: number;
  contentId: number;
  fallBack: number;
  question: string;
  isAnswered?: boolean;
  maximumRepeat: number;
  atTime: number;
  isMandatory: boolean;
  addedBy: number;
  addedDate?: Date;
  updatedAt?: Date;
}

export class answersForQuestionOnVideo {
  id?: number;
  Id?: number;
  questionId?: number;
  isCorrect: boolean;
  answer: string;
  originalWord: string;
  order: number;
  mark: number;
}

export class questionWithAnswers {
  question: questionOnVideo;
  isAnswered: boolean;
  answers: answersForQuestionOnVideo[];
}


export class materialFiles {
  id?: number;
  Id?: number;
  materialId: number;
  fileId: number;
  link: string;
}

export class materialContent {

  id?: number;
  Id?: number;
  materialId: number;
  type: MaterialTypes;
  fileId?: number;
  isFree: boolean;
  body: string;
}


export class FileBankDTO {
  Id?: number;
  id?: number;
  fileName: string;
  type: FileTypes;
  length: number;
  fileSize: number;
  uploadDate: Date;
  uploadedBy: number;

}


export enum FileTypes {
  Video = 1,
  Image = 2,
  PDF = 3,
  Text = 4,
  Audio = 5,
  Zip = 6,
  Other = 7,
  Unfomratted = 8
}
