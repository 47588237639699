import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
// import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: 'unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private location: Location) {

  }

  progress = new BehaviorSubject<number>(0);
  timeout = 3000;

  ngOnInit() {
    // setTimeout(() => {
    //   this.oidcSecurityService.authorize();
    // }, this.timeout);
  }

  login() {
    // this.oidcSecurityService.authorize();
  }


}
