
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { AffiliateService, B2BService, CoursesService, SystemService } from './../../../core/services';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { B2BStatus } from 'src/app/core/_models';
import { SearchCountryField,  CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'sm-joinasb2b',
  templateUrl: './joinasb2b.component.html',
  styleUrls: ['./joinasb2b.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Joinasb2bComponent implements OnInit {

  // public profile: any = {};
  submitted = false;
  newCourse: UntypedFormGroup;
  loading = false;
  hasError = false;
  errorMsg = '';
  button = 'ارسال الطلب';
  companySize = [
    {
      id: 1,
      name: '1-9'
    },
    {
      id: 2,
      name: '10-50'
    },
    {
      id: 3,
      name: '51-100'
    },
    {
      id: 4,
      name: '101-400'
    },
    {
      id: 5,
      name: '400+'
    }
  ]
  phonex
  SearchCountryField = SearchCountryField;
 
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];
  phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(undefined, [Validators.required])
  });

  constructor(private _b2bService: B2BService, private _router: Router, private _affiliate: AffiliateService, private cd: ChangeDetectorRef,
    private _system: SystemService, private formBuilder: UntypedFormBuilder) {
  }


  policies: any[] = [];

  ngOnInit() {
    // this._system.loadData();
    this._affiliate.getB2BPolicies().subscribe((data: any[]) => {
      this.policies = data;
      this.cd.markForCheck();
    })

    this.newCourse = this.formBuilder.group({
      id: null,
      groupId: 4,
      companyName: [{ value: '', disabled: this.loading }, Validators.required],
      companyEmail: [{ value: '', disabled: this.loading }, Validators.required],
      contactName: [{ value: '', disabled: this.loading }, Validators.required],
      contactPhone: [{ value: '', disabled: this.loading }, Validators.required],
      appliedOn: moment.utc(new Date()),
      type: [''],
      jobTitle: [{ value: '', disabled: this.loading }, Validators.required],
      companySize: [{ value: '', disabled: this.loading }],
      country: [{ value: '', disabled: this.loading }, Validators.required],
      city: [{ value: '', disabled: this.loading }, Validators.required],
      status: B2BStatus.Pending,
    });
  }

  get xf() { return this.newCourse.controls; }
  isapplied = false;

  onSubmit() {
    this.submitted = true;
    // console.log(this.newCourse.value);

    this.newCourse.patchValue({
      contactPhone: this.phonex.e164Number
    })
    console.log(this.newCourse.invalid);

    if (this.newCourse.invalid) {
      this.submitted = false;
      return;
    }
    var size = this.newCourse.get('type').value;
    this.newCourse.patchValue({
      companySize: this.companySize.find(x => x.name == size).id
    })
    this.loading = true;

    this._b2bService.AddOrModifyB2BSubscription(this.newCourse.value).subscribe((data) => {
      // console.log(data);
      this.isapplied = true;
      this.cd.markForCheck();
      // this.button = 'إضافة الدورة'
    });
  }

}
