<div class="mas-bookmark-container" [ngClass]="{'hidden': hidden, 'shown': !hidden}">
  <span style="display: flex;">
    <h4 style="color: #fff;">
      {{'SYSTEM.NEWBOOKMARK' | translate}}
    </h4>

    <i (click)="closeBookmark()" class="fa fa-close"
      style="flex : 2 ;cursor: pointer; font-size: 20px; color: #960000;"></i>
  </span>
  <div class="form-group form-group-bookmark" style="text-align: right;">

    <textarea class="form-control" style="text-align: right; font-family: 'JF-Flat';margin-bottom: 10px;"
      [(ngModel)]="bookmark" type="text"> </textarea>
    <button (click)="submitBookmark()" class="btn btn-primary btn-embossed"
      style="margin-right: 0; font-family: 'JF-Flat';">{{'SYSTEM.SUBMITBOOKMARK' | translate }}</button>
  </div>


</div>
