// China
export const locale = {
  lang: 'ar',
  data: {
    STATUS: {
      PENDING: 'تم إرسال الطلب',
      PROCESSING: 'قيد المعالجة',
      PAID: 'مدفوع',
      SUSPENDED: 'معلق',
    },
    DIALOGS: {
      ERROR: 'خطأ',
      REQUESTHASBEENSENT: 'تم ارسال الطلب',
      SENT: 'تم الارسال',
      DELETENOTE: 'حذف الملاحظة',
      AREYOUSURERESET: 'هل انت متأكد من إعادة المحاولة',
      YES: 'نعم',
      NO: 'لا',
      CONFIRMRESET: 'سيتم حذف النتيجة الحالية',
      EDITNOTE: 'تعديل الملاحظة',

      SEARCHFORFILE: 'البحث عن ملف',
      UPDATESUCCESSED: 'تم تحديث البيانات',
      CONSULTANCYADDEDSUCCESSFULLY: 'تم إضافة الاستشارة بنجاح',
      ONLYONEMAINPRICE: 'يمكن اضافة سعر اصلي واحد فقط',
      PRICEDATESNEEDED: 'يجب تحديد تواريخ السعر',
      CODEISUSED: 'هذا الكود مستخدم',
      SELECTEDFILE: 'الملف المحدد',
      CURRICULAADDED: 'تم إضافة الباقة بنجاح',
      COURSESUPDATEDSUCCESSFULLY: 'تم تحديث بيانات الدورة بنجاح',
      THISLECTUREALREADYHASVIDEOTITLE: 'هذه المحاضرة تحتوي على فيديو',
      THISLECTUREALREADYHASLVIEMEETINGTITLE: 'هذه المحاضرة تحتوي على رابط بث مباشر',
      THISLECTUREALREADYHASVIDEO: 'يمكن لمحاضرة ان تحتوي على فيديو واحد فقط ! ',
      THISLECTUREALREADYHASLIVEMEETING: 'يمكن لمحاضرة ان تحتوي على رابط بث مباشر واحد فقط ! ',
      QUESTIONADDED: 'تمت الاضافة بنجاح',
      QUESTIONADDEDBODY: 'تمت إضافة سؤال للفيديو ',
      SORTINGELEMENTSSUCCESSED: 'تمت إعادة ترتيب العناصر بنجاح',
      ELEMENTDELTED: 'تم حذف العنصر بنجاح',
      AREYOUSURE: 'تأكيد عملية الحذف',
      DELETEPERMENTALY: 'عملية الحذف نهائية لا يمكن العودة عنها ',
      CONFIRM: 'تأكيد الحذف',
      CLOSE: 'إغلاق',
      CANCEL: 'إلغاء الامر',
      SAVE: 'حفظ',
      DONE: 'نجاح العملية',
      MODIFY: 'تعديل',
      CONFIGURE: 'إعداد',
      DELETE: 'حذف',
      NEWLECTURE: 'محاضرة جديدة',
      DEPARTMENTSIMPORTEDSUCCESSFULLY: 'تم استيراد الأقسام بنجاح',

      ERRORHAPPENED: 'حصل خطأ اثناء تنفيذ العملية',
      ORDERSUCCSSED: 'تمت إعادة الترتيب بنجاح',
      PASSWORDCHANGED: 'تم تغيير كلمة المرور بنجاح',
      CONSULTANTADDEDSUCCESSFULLY: 'تم إضافة مستشار بنجاح',
      SESSIONSTARTED: 'بدأت الجلسة',
      ANNOUNCEMENTADDEDSUCCESSFULLY: 'تم بث الإعلان بنجاح',
      MEETINGADDEDSUCCESSFULLY: 'تمت إضافة الجلسة بنجاح',
      CASESTUDYADDEDSUCCESSFULLY: 'تمت إضافة الحالة الدراسية بنجاح',
      ACTIVITYADDEDSUCCESSFULLY: 'تمت إضافة النشاط بنجاح',
      COURSEINFOADDEDSUCCESSFULLY: 'تمت إضافة وصف للدورة بنجاح',
      GLOSSARYADDEDSUCCESSFULLY: 'تم بناء قائمةالمصطلحات بنجاح',
      CONCEPTADDEDSUCCESSFULLY: 'تم إضافة مصطلح جديد بنجاح',
      CATEGORYADDEDSUCCESSFULLY: 'تمت إضافة تصنيف جديد بنجاح',
      COUPONDATESUPDATEDSUCCESSFULLY: 'تم تعديل تاريخ الكوبون بنجاح',
      WIKIADDED: 'تمت إضافة الموسوعة الحرة بنجاح',
      WIKISECTIONADDED: 'تمت إضافة قسم إلى الموسوعة بنجاح',
      MEETINGACCEPTED: 'تم قبول الجلسة',
      COURSEADDEDSUCCESSFULLY: 'تمت إضافة الدورة التدريبية بنجاح',
      NOTADDEDCOMMENT: 'لم تكتب التعليق',
    },
    AFFILIATES: {
      AFFILIATES: 'نظام التسويق',
      TOTALNUMBERORDERS: 'عدد الطلبات',
      PERCENTAGE: 'نسبة الخصم',
      POLICY: 'السياسات والاتفاقية',
      COMMISIONSLOG: 'سجل العمولات',
      COURSESINCLUDED: 'المنتجات المتضمنة',
      POLICIES: 'برنامج التسويق بالعمولة',
      POLICIESPOLICY: 'السياسات والاتفاقية',
      MYPAYMENTS: 'سجل المقبوضات',
      REQUESTPAYMENT: 'إرسال طلب صرف',
      YOUARENOTREGISTERED: 'لست مسجلا في نظام التسويق بالعمولة',
      YOUHAVEREGISTEREDBEFORE: 'لقد قمت بالتسجيل  بنظام التسويق بالعمولة',
      STATUSOFYOURAPPLICATION: 'حالة الطلب',
      SUBSCRIBE: 'طلب اشتراك',
      NAME: 'الاسم الثلاثي',
      EMAIL: 'البريد الالكتروني',
      PHONE: 'رقم الموبايل',
      IDNUMBER: 'الرقم الوطني',
      REQUIRED: 'حقل ضروري',
      COUNTRY: 'الدولة',
      CITY: 'المدينة',
      POSTALCODE: 'الرمز البريدي',
      POSTOFFICE: 'صندوق البريد',
      AREYOUFAMOUS: 'هل انت مؤثر (تمتلك قاعدة جماهير و متابعين)',
      YES: 'نعم',
      NO: 'لا',
      FAMOUSPLATFORM: ' ماهي المنصة او الوسيلة التي تخاطب من خلالها متابعيك؟',
      DOYOUHAVESOCIALMEDIA: 'هل يوجد لديك متابعين على وسائل التواصل الاجتماعي ',
      FACEBOOK: 'فيسبوك',
      INSTAGRAM: 'انستجرام',
      TWITTER: 'تويتر',
      SNAPCHAT: 'سناب شات',
      YOUTUBE: 'يوتيوب',
      OTHERPLATFORM: 'اخرى',
      AREYOUBLOGGER: 'هل أنت مدون / كاتب',
      BLOGGERSITE: 'رابط المدونة ',
      DOYOUHAVEMAILINGLIST: ' هل يوجد لديك قائمة عملاء بريدية ',
      NUMBEROFSUBSCRIPERS: 'عدد المضافين لديك في القائمة البريدية',
      SUBSCRIBERSINTERESTS: 'ماهي اهم اهتمامات المضافين لديك ',
      DOYOUHAVEWHATSAPPLIST: 'هل يوجد لديك قائمة عملاء من خلال الواتساب',
      NUMBEROFWHTSAPPSUBSCRIBERS: 'عدد المضافين لديك في مجموعات او برودكاست الواتساب',
      EXPLAINYOURPLAN: 'إشرح لنا باختصار ماهي خطتك المختصرة للتسويق لدورات ماستري أكاديمي',
      WELCOME: 'أهلا بك',
      MYCOMMISSIONS: 'العمولات المستحقة',
      MYACCTOUNT: 'بيانات حساب الدفع',
      LINKS: 'روابط المنتجات',
      COPYLINK: 'نسخ الرابط',
      ADDRESS: 'العنوان',
      BANKINFO: 'معلومات البنك',
      BANKNAME: 'اسم البنك',
      ACCOUNTNAME: 'اسم الحساب',
      ACCOUNTNUMBER: 'رقم الحساب',
      BANKSWIFT: 'السويفت',
      BANKADDRESS: 'عنوان البنك',
      ACCOUNTIBAN: 'ايبان الحساب',
      ACCOUNTADDRESS: 'عنوان صاحب الحساب',
      COURSE: 'الدورة التدريبية',
      COMMISSION: 'العمولة',
      FORACOUPON: 'لطلب كوبون التواصل على البريد الالكتروني التالي',
      MYCOUPONS: 'كوبوناتي',
      COUPONCODE: 'كود الكوبون',
      TODATE: 'صالح لغاية',
      TOTALORDERS: 'طلب',
    },
    GLOSSARY: {
      CONCEPTNAME: 'عنوان المصطلح',
      REQUIRED: 'حقل ضروري',
      KEYWORDS: 'كلمات مفتاحية',
      DEFINITION: 'شرح وتعريف المصطلح',
      REPLY: 'المشاركة في النقاش'
    },

    INFO: {
      INSERTTEXTHERE: 'يمكنك كتابة النص هنا',
      INSERTQUESTION: 'اضف سؤالك هنا',
      INSERTREPLY: 'اضف اجابتك هنا',
    },
    CONSULTANCIES: {
      REQUESTID: 'رقم الطلب',
      NOTSTARTEDYET: 'لم تبدأ الجلسة بعد',
      USEREMAIL: 'بريد العميل',
      DATE: 'التاريخ',
      ENTERSESSION: 'الدخول إلى الجلسة',
      EARLYSESSION: 'هل تريد بدء الجلسة مبكراً',
      SENDREMINDER: 'ارسال تذكير للعميل',
      ENDSESSION: 'انهاء الجلسة',
      DONE: 'انتهت الجلسة',
      DAY: 'اليوم',
      TIMELEFT: 'ستبدأ الجلسة بعد',
      EMAIL: 'بريد العميل',
      HOUR: 'الساعة',
      PENDING: 'بانتظار الموافقة',
      HOURS: 'ساعة',
      DAYS: 'يوم',
      TIMEZONE: 'المنطقة الزمنية',
      STARTNOW: 'ابدأ الجلسة الآن',
      MINUTES: 'دقيقة',
      SECONDS: 'ثانية',
      CONFIGURE: 'إعداد',
      ACCEPT: 'موافقة',
      DELAY: 'تأجيل',
      TOTALINFO: 'مربع وصف',
      TOTALSESSIONS: 'جلسة',
      INFO: 'وصف الاستشارة',
      SESSIONS: 'جلسة استشارية',

    },
    DASHBOARD: {
      REQUESTS: 'طلبات الاختبار ',
      WAIT: 'الرجاء الانتظار جاري التحقق ',
      DAYSLEFT: 'يوم متبقي ',
      REQUESTEDON: 'تاريخ الطلب ',
      SENDREQUEST: 'ارسال طلب لتقديم الامتحان ',
      NOTPASSEDYET: 'لم تقم باجتياز شروط اتمام الدورة بعد',
      COURSECONDITION: 'للحصول على شهادة حضور الدورة يجب عليك ان:',
      DEPARTMENTS: 'الاقسام / المجموعات',
      RATETHECOURSE: 'تقييم الدورة',
      MORENOTES: 'لديك ملاحظات اضافية ؟',
      HOWYOURATETHECOURSE: 'ماهو تقييمك للدورة؟',
      YOUROPINION: 'رأيك يهمنا',
      TOTALASSIGNEDCOURSES: 'دورة مسندة',
      DATETOEND: 'التاريخ الأقصى للإتمام',
      UNASSIGN: 'الغاء الاسناد',
      ENDBEFORE: 'اتمام قبل',
      FULLNAME: 'اسم المتدرب',
      MYASSIGNEDCOURSES: 'الدورات  المسندة',
      ASSIGNEDCOURSES: 'المسارات والدورات المسندة',
      NOTASSIGNEDCOURSES: 'قيد الدراسة',
      REPORTS: 'التقارير',
      COURSENAME: 'اسم الدورة التدريبية',
      USERS: 'المستخدمين',
      CHOOSEDATE: 'اختر الفترة',
      COURSES: 'استعراض الدورات',
      ADDEDBY: 'بواسطة',
      HOUR: 'الساعة',
      B2BASSIGNMENTS: 'تخصيص مسارات التعلم',
      DAY: 'اليوم',
      VIDEOQUESTIONS: 'اختر المدرب او قم بطرح سؤال عام',
      MYCONSULTANCIES: 'الاستشارات',
      CONSULTANCIES: 'الاستشارات',
      AFFILIATEPROGRAM: 'برنامج التسويق بالعمولة',
      AVILABLEDATES: 'الأيام المتاحة',
      AVILABLEHOURS: 'الساعات المتاحة',
      LECTURENAME: 'المحاضرة',
      TIMEZONE: 'المنطقة الزمنية',
      ATTIME: 'الوقت في الفيديو',
      REQUESTMEETING: 'طلب حجز جلسة',
      MYSESSIONS: 'جلسات الاستشارة',
      NOTE: 'الملاحظة',
      MYBOOKMARKSTITLE: 'ملاحظاتي من الدورات',
      PUBLICQUESTION: 'سؤال عام',
      HOMEPAGE: 'موقع الاكاديمية',
      COMMISSION: 'العمولة المستحقة',
      ADDBOOKMARK: 'اضف ملاحظة',
      NOCOMMENTS: 'لا يوجد ردود بعد',
      REQUESTDATE: 'تاريخ الطلب',
      TOTALAMOUNT: 'اجمالي المبلغ',
      COURSEWIKI: 'الموسوعة الحرة',
      REQUESTSTATUS: 'حالة الطلب',
      TOTALPAIDCOMMISSIONS: 'اجمالي العمولات المقبوضة',
      TOTALUNPAIDCOMMISSIONS: 'اجمالي العمولات الغير مقبوضة',
      SELECTPERIOD: 'اختر فترة زمنية',
      TOTALCOMMISSIONS: 'اجمالي العمولات المستحقة',
      ADDARTICLE: 'إضافة مقال',
      CURRENTLEARNINGPLAN: 'خطة الدراسة الحالية',
      FROMDATE: 'من تاريخ',
      TODATE: 'إلى تاريخ',
      LEARNPROGRESS: 'مستوى التقدم',
      SELECTTWODATES: 'اختر فترة زمنية ',
      CHANGEPASSWORD: 'تغيير كلمة المرور',
      MYFINANCE: 'سجل المدفوعات',
      ORDERID: 'رقم الطلب',
      PRODUCTNAME: 'اسم المنتج',
      PURCHASEDDATE: 'تاريخ الشراء',
      PRICE: 'سعر الشراء',
      EDITINFO: 'تحديث البيانات',
      ANNOUNCEMENTS: 'إشعارات الدورات',
      NONOTIFICATIONS: 'لا يوجد إشعارات حالية',
      MYLEARNINGPLAN: 'خطة التعلم',
      MENU: 'القائمة الرئيسية',
      ACADEMY: 'Emastery Academy',
      PROFILEVERSION: 'V.1.052113',
      HEADER: 'العنوان',
      SENDANSWER: 'إرسال الجواب',
      ANSWER: 'الجواب',
      LOGOUT: 'تسجيل الخروج',
      CASESTUDIES: 'حالات دراسية',
      DOWNLOADCERTIFICATE: 'تنزيل الشهادة',
      SETREMINDER: 'تذكير يومي',
      SELECTPLANTYPE: 'هدف خطة التعلم',
      TIMEGOAL: 'اجمالي ساعات',
      COURSEGOAL: 'اتمام دورات',
      TARGETTIME: 'اتمام اجمالي ساعات تعلم',
      SETTIMEPERHOUR: 'عدد الساعات',
      SUBMITPLAN: 'حفظ خطة التعلم',
      BODY: 'نص المقال',
      ADDEDAT: 'تاريخ الإضافة',
      ADDREPLY: 'اضافة مشاركة في النقاش',
      MYCOURSES: 'الدورات',
      MYCERTIFICATES: 'الشهادات',
      TOPICSLIST: 'قائمة المواضيع',
      GOTOTOPICS: 'العودة لقائمة المواضيع',
      TOTALTOPICS: 'اجمالي المشاركات',
      ADDCONCEPT: 'إضافة المصطلح',
      MYQUIZZES: 'الاختبارات',
      ISASSIGNED: 'دورة مسندة',
      YES: ' نعم',
      NO: ' لا ',
      SAVE: ' حفظ ',
      SELECTEMPLOYEE: ' اختر الموظف المطلوب ',
      SELECTDEPARTMENT: ' اختر القسم المطلوب ',
      ALLCOMPANY: ' جميع الموظفين ',
      DEPARTMENT: ' قسم محدد ',
      ANEMPLOYEE: ' موظف محدد ',
      RESETASSIGNEE: ' إعادة الاختيار ',
      SETTINGS: ' إدارة ',
      SELECTASSIGNEES: ' لمن تريد الاسناد؟ ',
      SELECTEDCOURSES: ' الدورات المختارة ',
      LEARNINGPATH: 'ادارة قوائم التعلم ',
      ASSUIGNCOURSE: ' اسناد دورة تدريبية ',
      SELECTCOURSEORMORE: ' اختر دورة تدريبية ',
      QUIZZNAME: ' الاختبار',
      STATUS: 'الحالة',
      MYASSIGNMENTS: 'الواجبات',
      MYBOOKMARKS: 'المحفوظات',
      COMPLETE: '٪ مكتمل',
      COMPLETED: 'نسبة الاكتمال',
      DATE: 'تاريخ الاضافة',
      FILE: 'الملف',
      FILEBACK: 'ملف المدرب',
      COMMENT: 'تقييم المدرب',
      DONE: 'اتممت الدرس بنجاح',
      ATTACHFILE: 'إرفاق ملف',
      TOTAL: 'الاجمالي',
      NOTPASSED: 'غير المقبولة',
      PASSED: 'المقبولة',
      PENDING: 'بانتظار التقييم',
      CONTINUE: 'متابعة',
      CONTENTS: 'المحتويات',
      GOBACK: 'العودة للملف الشخصي',
      PERVIOUSLESSON: 'الدرس السابق',
      NEXTLESSON: 'الدرس التالي',
      GENERALINFO: 'معلومات عامة عن الاختبار',
      TOTALATTEMPTS: 'عدد المحاولات المسموحة',
      TIMELIMIT: 'الوقت المسموح للمحاولة',
      NUMBEROFTRIES: 'عدد المحاولات التي قمتم بها ',
      ONCE: ' مرة ',
      MINUTES: ' دقيقة ',
      TIME: ' مرة ',
      YOUDIDNTTRY: 'لم تقم بمحاولة تقديم الاختبار من قبل',
      DONTCLICK: 'إن محاولة العودة إلى الخلف تعني الغاء محاولة الاختبار ويتم احتساب ما تم الاجابة عليه ',
      TRYATTEMPQUIZZ: 'قم بمحاولة اجتياز الاختبار الآن',
      NEXTQUESTION: 'السؤال التالي',
      TIMELEFT: 'الوقت المتبقي',
      ATTEMPTINFO: 'معلومات المحاولة الأخيرة',
      TOTALANSWERED: 'عدد الاسئلة التي تم الإجابة عليها',
      TOTALUNANASWERED: 'عدد الاسئلة التي لم يتم الإجابة عليها',
      ATTENDEDAT: 'تاريخ التقديم',
      SUBMITYOURQUIZZ: 'نتيجة الإختبار',
      QUIZZESSECTION: 'الاختبارات',
      ATTEMPTRESULT: 'نتيجة الاختبار',
      YOURSCORE: 'الدرجة المستحقة',
      RESULT: 'النتيجة النهائية',
      DIDPASS: 'ممتاز لقد قمتم باجتياز الاختبار بنجاح',
      DIDNTPASS: 'لم يتم اجتياز الاختبار بنجاح',
      GOHOME: 'متابعة',
      CONTINUEQUIZZ: 'متابعة المحاولة الأخيرة',
      TIMEISUP: 'انتهى الوقت المخصص للمحاولة',
      ASSIGNMENTSECTION: 'الواجبات',
      WHICH: 'القسم',
      WHO: 'المتدرب',
      WHEN: 'التاريخ',
      ACCEPT: 'قبول',
      REFUSE: 'رفض',
      APPROVEDWAITING: 'موافق بانتظار الادارة',
      DECLINED: 'مرفوض',
      APPROVED: 'مقبول',
      NOSESSIONSLEFT: 'انتهى رصيد جلسات القسم',
      NOTES: 'الرد / رابط الدخول',
      LINK: 'رابط الدخول',
      TOTALGRADUATES: 'خريج',
      TOTALCOURSES: 'دورة تدريبية',
      TOTALEMPLOYEES: 'موظف',
      TOTALDEPARTMENTS: 'قسم',
      HOME: 'Dashboard',
      TOTALQUIZZES: 'الاختبارات',
      TOTALASSIGNMETNS: 'الواجبات',
      TOTALCONSULTANCIES: ' الاستشارات',
      TOTALTAKENCONSULTANCIES: ' الاستشارات المنفذة',
      MOSTVIEWEDCOURSE: 'الدورة الأكثر متابعة',
      MOSTGRADUATEDCOURSE: 'الدورة الأكثر اتمام',
      BESTEMPLOYEE: 'الموظف الأكثر نشاطاً',
      BESTDEPARTMENT: 'القسم الأكثر نشاطاً',
      DEPARTMENTNAME: 'القسم',
      DETAILS: 'تفاصيل',
      TOTALDUEVIEW: 'اجمالي وقت التعلم',
      TOTALVIEWED: 'اجمالي الوقت المنجز',
      SELECTCOURSE: 'اختر دورة للاطلاع على تفاصيل التقدم',
      BACK: 'العودة',
      COURSEDONE: 'تم الدورة',
      NOTDONE: 'لم يتم الدورة',
      COURSECERTIFIED: 'حصل على شهادة',
      NOTCOURSECERTIFIED: 'لم يحصل على شهادة',

      SUPERVISION: 'لوحة الاشراف',
      INCORRECTANSWER: 'إجابة خاطئة',
      OUTOF: 'من اصل',
      RATETHISCOMMENT: 'تقييم الاجابة',
      SUBMITANSWER: 'حفظ الجواب',
      ATTENDACTIVITY: 'المشاركة في النشاط',
      REATTENDACTIVITY: 'إعادة المحاولة',
      QUESTIONNO: 'السؤال رقم',
      YOURANSWER: 'اجابتك',
      CORRECTANSWER: 'الجواب الصحيح',
      CORRECTANSWERFROM: 'اجابة صحيحة من اصل',
      TEXT: 'نص',
      RETURNTOPROFILE: 'العودة إلى حسابي',
      LINKS: 'رابط خارجي',
      MYNOTES: 'الملاحظات',
      ACCEPTED: 'قبول الحل',
      REFUSED: 'رفض الحل',
      TRAINEENAME: 'اسم المتدرب',
      REPLY: 'تقييم المدرب',
      ADDCOMMENT: 'إضافة تعليق',
      EXPORTCERTIFICATE: 'تنزيل الشهادة',
      AVAILABLE: 'متاح',
      ASSIGNMENTAPPLICATIONS: 'تقديم',
      NOTAVAILABLE: 'غير متاح',
      PASSEDTHEQUIZ: 'قمتم باجتياز الاختبار',
      FAILEDTHEQUIZZ: 'لم يتم اجتياز الاختبار',
      QUIZRESULT: 'نتيجة الإختبار',
      HELLO: 'اهلاً',
      EMAIL: 'البريد الإلكتروني',
      GOING: 'جارية',
      UPDATEINFORMATION: 'البيانات الشخصية',
      EXPIRED: 'منتهية',
      PROGRESS: 'التقدم',
      LEARNINGPLANS: 'الخطة التعليمية',
      NUMBEROFHOURS: 'عدد الساعات',
      TOTALLEARNINGTIME: 'اجمالي الوقت المستهدف',
      NOTIFYME: 'قم بتذكيري',
      NEWLEARNINGPLAN: 'خطة تدريبية جديدة',
      DATES: 'الفترة الزمنية',
      SAVENOTE: 'حفظ',
      MYNOTEBOOK: 'الملاحظات',
      ACTIVITY: 'نشاط تدريبي',
      ASSIGNMENTS: 'الواجبات',
      NOFILEBACK: 'بانتظار التقييم',
      YOUCANTATTENDASSIGNMENT: 'المشاركة في الواجب غير متاحة',
      WAITINGREPLY: 'بانتظار تقييم المدرب',
      NUMBEROFARTICLES: 'عدد المقالات',
      NUMBEROFASSIGNMENTS: 'عدد الواجبات ',
      NUMBEROFTOPICS: 'عدد المواضيع',
      NUMBEROFCASES: 'عدد الحالات الدراسية',
      FINALQUIZZ: 'الاختبار النهائي',
      MINUTE: 'دقيقة',
      QUIZATTEMPT: 'محاولة',
      NUMBEROFCONCEPTS: 'عدد المصطلحات : ',
      GLOSSARY: 'قائمة المصطلحات',
      NEWCONCEPT: 'إضافة مصطلح جديد',
      DATEOFATTEMPT: 'تاريخ المحاولة : ',
      TOTALDEGREE: 'الدرجةالمستحقة : ',
      PERVIOUSATTEMPTSRESULTS: 'سجل المحاولات السابقة',
      VIDEOQUESTION: 'املئ الفراغات بالكلمات المناسبة',
      ADDCOMMENTREPLY: 'اضف رداً على التعليق',
      COMMENTREPLY: 'الرد على التعليق',
      REQUIRED: 'لا يمكن أن يكون النص فارغاً',
      YOUPASSEDTHEQUIZZ: 'ممتاز لقد قمت باجتياز الاختبار',
      QUIZSCORE: 'الدرجة المستحقة',
      YOUDIDNOTPASSEDTHEQUIZZ: 'لم تقم باجتياز الاختبار',
      COMPLETETHETEST: 'انهاء المحاولة',
      CASESTUDY: 'حالة دراسية',
      QUIZZATTEMPTINFO: 'معلومات المحاولة الأخيرة',
      NUMBEROFQUESTIONSANSWERED: 'عدد الاسئلة التي تم الإجابة عليها :',
      NUMBEROFQUESTIONSNOTANSWERED: 'عدد الاسئلة التي لم يتم الإجابة عليها :',
      ATTEMPTDATE: 'معلومات المحاولة الأخيرة',
      VIEWRESULT: 'نتيجة الأختبار',
      QUESTIONNUMBER: 'السؤال رقم :',

      DOWNLOADPDF: 'تنزيل الملف',
      MEETINGURL: 'رابط الدخول إلى جلسة البث المباشر',
      QUIZINFO: 'معلومات عامة عن الاختبار',
      ACTIVITYQUIZINFO: 'معلومات عامة عن النشاط',
      NUMBEROFATTEPMTS: 'عدد المحاولات المسموحة : ',
      TIMEALLOWED: 'الوقت المسموح للمحاولة : ',
      QUIZNOTE: '* إن محاولة العودة إلى الخلف تعني الغاء محاولة الاختبار ويتم احتساب ما تم الاجابة عليه',
      PERVIOUSATTEMPTS: 'محاولة التقديم للاختبار من قبل : ',

      UNDEFINIED: 'غير محدد',
      ATTEMPTQUIZ: 'قم بمحاولة الإختبار',

      SENDFILE: 'ارسال الملف',

      DASHBOARD: 'ملف المتدرب',
      LEARNINGPLAN: 'الخطة التدريبية',
      NEWLEARNINGPLANE: 'خطة تدريبية جديدة',
      MYCURRENTLEARNINGPLAN: 'الخطة التدريبية الحالية',
      PERSONALINFO: 'المعلومات الشخصية',

      CONSULTANCYDATE: 'تاريخ الشراء',
      CONSULTANCYNAME: 'اسم الاستشارة',
      CONSULTANTNAME: 'اسم المدرب',
      NUMBEROFSESSIONS: 'عدد الجلسات',
      REQUESTSESSION: 'اطلب جلسة استشارة',

      YESTERDAY: 'البارحة',
      TODAY: 'اليوم',
      LATEST7DAYS: 'آخر ٧ أيام',
      LATEST28DAYS: 'آخر ٢٨ يوم',
      LATEST90DAYS: 'آخر ٩٠ يوم',
      LATEST365DAYS: 'آخر ٣٦٥ يوم',
      LIFETIME: 'كامل المدة',
      CURRENTYEAR: 'العام الحالي',
      LASTYEAR: 'العام الماضي',
      CURRENTMONTH: 'الشهر الحالي',
      LASTMONTH: 'الشهر الماضي',
      MONTHBEFORE: 'الشهر السابق',
      CUSTOMDATES: 'مدة مخصصة',
      DUEDATE: 'الانجاز قبل تاريخ'



    },
    B2B: {
      categorieslibrary: 'تصنيفات الدورات',
      ourcategories: 'قوائم التعلم',
      lastseen:'آخر تسجيل دخول',
      courses: 'الدورات ',
      unkown: 'غير معروف',
      delete: 'حذف',
      assignedToMe: 'المسارات المسندة',
      activate: 'تفعيل',
      continueWatching: 'تابع التعلم',
      courseName: 'الدورة التدريبية',
      certificateCode: 'كود الشهادة',
      certificateDate: 'تاريخ الاصدار',
      progress: 'مستوى التقدم',
      totalWatched: 'اجمالي وقت المشاهدة',
      consultancyReason: 'شرح الاستشارة المطلوبة',
      consultancyDate: 'تاريخ طلب الاستشارة',
      employeename: 'اسم الموظف',
      deactivate: 'تعطيل',
      showinlisting: 'نشر القائمة للجميع',
      userreport: 'تقرير المتدرب',
      userstatus: 'الحالة',
      groupname: 'اختر المجموعة المطلوبة',
      addtogroup: 'إضافة إلى مجموعة',
      B2B: 'B2B',
      WAITINGAPPROVAL: 'بانتظار الموافقة',
      SENDREQUEST: 'ارسال الطلب',
      SELECTDATEANDTIME: 'اختر الزمن والوقت',
      CONSULTANCYDESCRIPTION: 'شرح الحالة',
      SUBSCRIBE: 'الاشتراك',
      COMPANYNAME: 'اسم الشركة Company Name',
      COMPANYPHONE: 'رقم الهاتف/الموبايل Phone Number',
      COMPANYEMAIL: 'حجم الشركة Company Size ',
      CONTACTPHONE: 'الموقع الوظيفي Job Title',
      CONTACTEMAIL: 'البريد الالكتروني Business Email',
      CONTACTNAME: 'الاسم Name',
      ADDRESS: 'العنوان',
      NAME: 'الاسم Name',
      EMAIL: 'البريد الالكتروني Business Email',
      PHONE: 'رقم الهاتف/الموبايل Phone Number',
      JOBTITLE: 'الموقع الوظيفي Job Title',
      COMPANYSIZE: 'حجم الشركة Company Size ',
      COUNTRY: 'الدولة Country',
      CITY: 'المدينة State',
      APPLICATIONS: 'الطلبات',
      LEADS: 'طلب',
      UPDATEINFO: 'تحديث البيانات',
      DELETEAPPLICATION: 'حذف الطلب',
      MANAGESUBSCRIPTIONS: 'إدارة الاشتراكات',
      NUMBEROFENROLLMENTS: 'عدد المستخدمين',
      STARTINGAT: 'يبدأ بتاريخ',
      ENDINGAT: 'ينتهي بتاريخ',
      ISACTIVE: 'مفعل',
      NUMBEROFUSERS: 'عدد الموظفين المرشحين',
      IMPORTUSERS: 'استيراد المستخدمين',
      MANAGEUSERS: 'ادارة المستخدمين',
      IMPORTDEPARTMENTS: 'إستيراد الاقسام / المجموعات',
      DEPARTMENTS: 'الاقسام / المجموعات والادارات',
      COURSES: 'الدورات المتاحة',
      MANAGECOURSES: 'ادارة الدورات المتاحة للاشتراك',
      SELECTCATEGORY: 'اختر التصنيف',
      INCLUSIVECOURSES: 'اختر الدورات المستثناة',
      departmentName: 'اسم المجموعة / الإدارة',
      noOfEmployees: 'عدد المتدربين',
      totalViews: 'اجمالي وقت المشاهدة',
      totalDue: 'اجمالي الوقت المطلوب',
      employeesBrowse: 'استعراض المتدربين',
      addUser: 'اضافة متدرب جديد',
      addDepartment: 'اضافة مجموعة / ادارة جديدة',
      departmentEmail: 'بريد الاشعارات',
      people: 'متدربي المجموعة / الإدارة',
      email: 'البريد الإلكتروني',
      name: 'اسم المتدرب',
      learningpathName: 'الاسم',
      description: 'وصف القائمة',
      courseslibrary: 'مكتبة الدورات',
      addnewlearningpath: 'إضافة قائمة تعلم جديدة',
      assignmentName: 'اسم المسار التدريبي المخصص',
      newAssignment: 'مسار تدريبي جديد',
      isrequired: 'مسار واجب انجازه',
      pathslibrary: 'المسارات التدريبية',
      duedate: 'اقصى تاريخ للإنجاز',
      reminderdate: ' إرسال تذكير بتاريخ ',
      listofusers: 'قائمة الموظفين',
      listofgroups: 'قائمة المجموعات / الإدارات',
      modify: 'تعديل',
      users: 'المستخدمين / المتدربين',
      groups: 'الأقسام / المجموعات',
      edit: 'تعديل',
      totalsupervisors: 'عدد المشرفين',
      supervisors: 'المشرفين',

    },
    TABLE: {
      NORESULTSFOUND: 'لا يوجد نتائج مطابقة'
    },
    COURSE: {
      CLOSEMENU: 'إغلاق القائمة',
      COURSECONTENT: 'محتويات الدورة',
    },
    WIKI: {
      ARTICLES: 'المقالات'
    },
    MENU: {
      ABOUT: 'ماستري اكاديمي',
      CATEGORIES: 'الدبلومات والباقات',
      COURSES: 'الدورات التدريبية',
      CERTIFICATEVALIDATE: 'التحقق من الشهادة',
      JOINUSASAFFILIATE: 'التسويق بالعمولة',
      COURSEPAACKGES: 'باقات الدورات',
      CONSULTANTS: 'الاستشارات الشخصية',
      VIDEOS: 'فيديوهات',
      COMPANIESREQUESTS: 'طلبات الشركات',
      JOINUSASTRAINER: 'انضم لنا كمدرب',
      CONTACTUS: 'تواصل معنا',
    },
    COURSES: {
      ALLCOURSES: 'الدورات التدريبية',
      COURSE: 'دورة تدريبية',
      ALL: 'الكل',

    },
    CONSULTANCY: {
      ALLCONSULTANCIES: 'الاستشارات الشخصية',
      ALL: 'الكل'
    },
    CART: {
      TOTALORIGINALPRICE: 'السعر الاصلي',
      TOTALPRICE: 'السعر بعد الخصم',
      APPLIEDCOUPON: 'كود الخصم المستخدم',
      ENTERCOUPON: 'هل لديك كود / كوبون خصم',
      COUPON: 'ادخل كود / كوبون الخصم',
      SUBMITCOUPON: 'اضف',
      PROCEED: 'قم بالدفع الان',
      YOURCART: 'سلة مشترياتك'
    },
    CHECKOUT: {
      PRODUCTPRICE: 'السعر',
      PRODUCTNAME: 'الاسم',
      ORDERSUMMERY: 'تفاصيل الطلب',
      TOTALDUEPAY: 'اجمالي المبلغ',
      ACCOUNTDETAILS: 'بيانات الحساب',
      FIRSTNAME: 'الاسم',
      EMAIL: 'البريد الالكتروني',
      LASTNAME: 'الشهرة',
      PAYMENTMETHOD: 'آلية الدفع',
      PAYBYCREDIT: 'الدفع ببطاقة ائتمانية',
      WEACCEPTCARDS: 'يمكنكم الدفع ببطاقة ائتمانية او مشحونة مسبقا',
      PAYNOW: 'قم بالدفع الآن',
      CHECKOUTREVIEW: 'إتمام العملية'
    },
    PACKAGE: {
      BUYNOW: 'قم بالشراء الآن',
      INFO: 'الدورات المتضمنة في هذه الباقة',
      ALLPACKAGES: 'الدبلومات التدريبية والباقات',
      ALL: 'الكل',
    },
    SLIDER: {
      DISCOUNT: 'خصم',
      DIPLOMA: 'دبلوم',
      COURSES: 'دورات',
    },
    ACCOUNT: {
      MYINFORMATION: 'معلوماتي الشخصية',
      MYCOURSESLIST: 'قائمة الدورات المتبعة',
      CHANGEMYPASSWORD: 'تغيير كلمة المرور',
      MYFINANCIALRECORD: 'سجل المدفوعات',
      LOGOUT: 'تسجيل الخروج'
    },
    MATERIALS: {
      REQUIRED: 'حقل مطلوب'
    },
    SEX: {
      MALE: "ذكر",
      FEMALE: 'أنثى'
    },
    HOME: {
      WELCOMETOAFFILIATE: 'أهلا بكم في نظام التسويق بالعمولة',
      APPLICATIONFORB2BSUBSCRIPTION: 'طلب الاشتراك الخاص بالشركات',
      APPLICATIONFORTRAINERSUBSCRIPTION: 'الاشتراك ك مدرب في ماستري',
      PRIVACY: 'الخصوصية والأمان',
    },
    TRAINER: {
      FIRSNAME: 'الاسم',
      LASTNAME: 'اللقب',
      EMAIL: 'البريد الالكتروني',
      NATIONALITY: 'الجنسية',
      COUNTRY: 'الدولة التي تقيم فيها',
      CITY: 'المدينة التي تقيم فيها',
      MOBILE: 'رقم الموبايل',
      BIRTHDATE: 'تاريخ ميلادك',
      EDUCATIONALLEVEL: 'آخر درجة تعليمية حصلت عليها',
      MAJOR: 'التخصص العام',
      SPECIALIZED: 'التخصص الدقيق',
      PROGRAMS: 'مجالات التدريب الرئيسية التي تحترف التدريب فيها',
      EXPERIENCE: 'سنوات الخبرة كمدرب محترف',
      SCOPEDEXPERIENCE: 'معظم خبرتك في التدريب كانت في تدريب القطاع',
      LANGUAGES: 'اختر اللغة التي تحترف التدريب فيها بطلاقة',
      ADDITIONALSERVICES: 'ماهي الخدمات الإضافية التي تقدمها إضافة لخدمة التدريب داخل القاعة ولديك خبرة لا تقل عن سنتين فيها',
      PERSONALPROGRAM: 'اسم البرنامج الذي ترغب تدريبه مع ماستري',
      REQUIRED: 'حقل مطلوب',
      TITLE: 'اللقب',
      FULLNAME: 'الاسم الكامل',
      PHONE: 'رقم الهاتف',
      CONSULTANCY: 'هل ترغب في تقديم خدمة الاستشارات عن بعد',
      YES: 'نعم',
      NO: 'كلا',
      DOYOUTUBE: 'هل لديك قناة يوتيوب',
      DOINSTAGRAM: 'هل لديك صفحة انستغرام',
      DOFACEBOOK: 'هل لديك صفحة فيس بوك',
      RECORDEDCOURSES: 'هل قدمت دورات الكترونية مسجلة سابقا؟  (Recorded Courses)',
      YOUTUBECHANNEL: 'ادخل رابط القناة',
      INSTAGRAMPROFILE: 'ادخل رابط الحساب',
      FACEBOOKPAGE: 'ادخل رابط الصفحة',
      YOURAPPLICATIONHASBEENSENT: 'تم ارسال الطلب  سيتم التواصل معكم في أقرب وقت ممكن  شكراً لاختياركم اكاديميتنا ',
    },
    TITLES: {
      TITLE1: 'السيد/ة',
      TITLE2: 'أخصائي/ة',
      TITLE3: 'استاذ/ة',
      TITLE4: 'مهندس/ة',
      TITLE5: 'دكتور/ة',
      TITLE6: 'استاذ/ة دكتور/ة',
      TITLE7: '',
    },
    STUDIES: {
      STUDY1: 'ثانوية',
      STUDY2: 'بكالوريوس',
      STUDY3: 'ماجستير',
      STUDY4: 'دكتوراه',
      STUDY5: 'بروفسور',
      STUDY6: '',
      STUDY7: '',
      STUDY8: '',
      STUDY9: '',
      STUDY10: '',
    },
    EXPFIELDS: {
      STUDY1: 'القطاع الحكومي',
      STUDY2: 'شركات كبيرة',
      STUDY3: 'شركات صغيرة ومتوسطة',
      STUDY4: 'افراد وجمهور عام',
      STUDY5: 'منظمات غير ربحية',
      STUDY6: '',
      STUDY7: '',
      STUDY8: '',
      STUDY9: '',
      STUDY10: '',
    },
    LANGUAGES: {
      STUDY1: 'اللغة العربية',
      STUDY2: 'اللغة الانجليزية',
      STUDY3: 'لغات أخرى',
      STUDY4: 'افراد وجمهور عام',
      STUDY5: 'منظمات غير ربحية',
      STUDY6: '',
      STUDY7: '',
      STUDY8: '',
      STUDY9: '',
      STUDY10: '',
    },
    MAJORS: {
      STUDY1: 'الإدارة والقيادة',
      STUDY2: 'التسويق والمبيعات',
      STUDY3: 'الإدارة المالية',
      STUDY4: 'المحاسبة',
      STUDY5: 'إدارة الموارد البشرية',
      STUDY6: 'الجودة والتمييز المؤسسي',
      STUDY7: 'الأمن والسلامة',
      STUDY8: 'التصوير الاحترافي',
      STUDY9: 'الميديا وانتاج الفيديو',
      STUDY10: 'التصميم الفوتوجرافي',
      STUDY11: 'تطوير الويب والتطبيقات',
      STUDY12: 'التقنية والمعلوماتية',
      STUDY13: 'الإعلام والعلاقات العامة',
      STUDY14: 'القانون والعقود',
      STUDY15: 'الهندسة والطاقة',
      STUDY16: 'التعليم والتدريب',
      STUDY17: 'التنمية الذاتية',
      STUDY18: 'نمط الحياة',
      STUDY19: 'الصحة والرياضة',
      STUDY20: 'يمكنك كتابة اي نشاط آخر',
    },
    PROFILE: {
      WELCOMEYA: 'أهلاً بك',
      ATTACHMENTS: 'مرفقات',
      HOMEPAGE: 'الصفحة الرئيسية',
      MYACCOUNT: 'ملفي الشخصي',
      MYCOURSES: 'الدورات ',
      SUBMITREPLY: 'الرد على التعليق',
      CERTIFICATES: 'الشهادات',
      MYCOURSE: 'الدورات ',
      NOTIFICATIONS: 'اشعاراتي',
      NOTES: 'ملاحظاتي',
      ADDBOOKMARK: 'اضف ملاحظة على الفيديو',
      NOTESONVIDEO: 'الملاحظات',
      DISCUSSION: 'التعليقات',
      NODISCUSSION: 'لا يوجد تعليقات حول هذه المحاضرة',
      SUBMITCOMMENT: 'أضف تعليق',
      REPLY: 'الرد على التعليق',
      QUIZZNAME: 'اسم الامتحان',
      STARTTHEQUIZZ: 'ابدأ الامتحان',
      NEXTQUESTION: 'السؤال التالي',
      SUBMITYOURANSWERS: 'اتمام الامتحان',
      NAME: 'الاسم',
      DATEOFBIRTH: 'تاريخ الميلاد',
      GENDER: 'الجنس',
      UPDATEPASSWORD: 'تحديث كلمة المرور',
      PHONENUMBER: 'رقم الهاتف',
      UPDATEINFO: 'تحديث البيانات',
      DRAGYOURANSWERHERE: 'قم بإدراج الجواب الصحيح هنا',
      SUBMITYOURANSWERSMESSAGE: 'لقد اتممت الاجابة على الاسئلة اضغط على اتمام الامتحان لاعتماد الاجوبة',
      CURRENTPASSWORD: 'كلمة المرور الحالية',
      NEWPASSWORD: 'كلمة المرور الجديدة',
      REPEATPASSWORD: 'إعادة إدخال كلمة المرور الجديدة',
      NONOTIFICATIONS: 'لايوجد إشعارات جديدة'
    },
    QUIZZ: {
      WELCOMEMESSAGE: 'في هذا الاختبار',
      NUMBEROFQUESTIONS: 'سؤالا',
      NUMBEROFREPETION: 'عدد مرات الاعادة',
      TIMELIMIT: 'وقت الاختبار',
      GOODLUCK: 'بالتوفيق',
      DIDNTATTEND: 'لم تقم بالمشاركة',
      WEAK: 'ضعيف',
      GOOD: 'جيد',
      VERYGOOD: 'جيد جداً',
      EXCELLENT: 'ممتاز',
      PERFECT: 'امتياز',
    },
    SYSTEM: {
      SUCCESS: 'تمت بنجاح',
      YOUWILLBETRANSFERED: 'سيتم تحويلك إلى بوابة الدخول ',
      NEWBOOKMARK: 'ملاحظة جديدة',
      ACADEMYNAME: 'أكاديمية ماستري للتدريب الإلكتروني',
      FAILED: 'فشلت العملية',
      RESULT: 'النتيجة',
      ENTERPHONE: 'ادخل رقم هاتفك بدون رمز النداء',
      NEXT: 'التالي',
      PERVIOUS: 'السابق',
      NoCertificate: 'لا توجد شهادة أو الكود الذي قمتم بإدخاله خاطئ',
      SUBMITBOOKMARK: 'حفظ الملاحظة',
      CHECKYOURCERTIFICATE: 'التحقق من الشهادات',
      ENTERYOURCODE: 'يمكنكم هنا ادخال كود الشهادة للتحقق من البيانات الخاصة بها, ستجد الكود في اسفل الشهادة الالكترونية',
      IFYOUDONTHAVECODE: 'إذا كانت شهادتكم قديمة او لا تحتوي على كود تحقق يمكنكم إعادة تنزيل الشهادة من ملف المتدرب - الشهادات او يمكنكم التواصل مع الدعم الفني للحصول على شهادتكم',
      ENTERCODEHERE: 'الرجاء كتابة كود الشهادة هنا',
      CHECK: 'التحقق من الشهادة',
      NAME: 'الاسم كما هو في الشهادة',
      CERTIFICATENAME: 'الشهادة',
      CERTIFICATEDATE: 'تاريخ المنح',

    },
    ACADEMY: {
      NAME: 'اكاديمية ماستري',
      ADDRESS: 'ماليزيا- السعودية وجميع انحاء العالم',
      PHONE: 'رقم هاتف الاكاديمية',
      FAX: 'رقم فاكس الاكاديمية',
      IMPORTANTLINKS: 'روابط مهمة',
      OURSERVICE: 'خدماتنا',
      PRIVECY: 'الخصوصية',
      HELPCENTER: 'الدعم الفني',
      TERMSOFUSE: 'شروط الاستخدام',
      POLICY: 'سياسة الاكاديمية',
      PAYEMENTS: 'نظام الدفع',
      FAQ: 'اسئلة شائعة',
      LEGALNOTICE: 'معلومات قانونية',
      BUSINESS: 'ماستري اعمال',
      B2B: 'خدمة كبار العملاء',
      AFFILIATES: 'نظام التسويق بالعمولة',
      TRAINING: 'نظام التدريب',
      PARTNERSHIP: 'الشراكة',
      COPYRIGHTS: 'أكاديمية ماستري ٢٠٢٠ ',
    },
    ACTIVITIES: {
      PREVQUESTION: 'السؤال السابق',
      NEXTQUESTION: 'السؤال التالي',
      SKIPQUESTION: 'تخطي',
      FINISHTEST: 'متابعة',
      EXPLANATION: 'التفسير',
      NUMBEROFQUESTIONS: 'عدد الاسئلة',
      RETRY: 'إعادة المحاولة',
      ATTENDEDAT: 'تاريخ المحاولة',
      YOURANSWER: 'اجابتك',
      REPLIESANDCOMMENTS: 'الردود والتعليقات',
      NOREPLIESYET: 'لا يوجد ردود او تعليقات حتى الآن',
    },
    FORM: {
      'SEARCHANDFILTER': 'قم بالبحث عن اي شيء'
    }
  },
  cookie: {
    header: "هذا الموقع يستخدم كوكيز",
    message: "هذا الموقع يستخدم كوكيز للحصول على أداء افضل وامان اعلى",
    dismiss: "حسناً",
    allow: "السماح",
    deny: "عدم السماح",
    link: "معرفةالمزيد",
    policy: "سياسة الخصوصية"
  },



};
