export class Rules {
    Id?: number;
    id?: number;
    courseId?: number;
    relation: ConditionRelation;
    condition: Conditions;
    fraction?: number;
    unitId?: number;
    lessonId?: number;
    time?: number;
}


export enum ConditionRelation {
    AND = 1,
    OR = 2
}

export enum Conditions {
    AttendedTheExam = 1,
    WatchedAllVideos = 2,
    ExamVideosAssignements = 3,
    ExamVideosAssignementsApprove = 4,
    SpcificVideo = 5
}
