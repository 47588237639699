import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sm-basesupervision',
  templateUrl: './basesupervision.component.html',
  styleUrls: ['./basesupervision.component.scss']
})
export class BasesupervisionComponent implements OnInit {


  myNotifications: any[] = [];
  currentDate = moment().get('year');
  currentVersion = environment.LMSVersion;
  profile
  bProfile;
  template

  constructor() {
    this.profile = JSON.parse(localStorage.getItem('profile'));
    this.bProfile = JSON.parse(localStorage.getItem('__bb__prf'));
    this.template = JSON.parse(localStorage.getItem('__template'));
  }

  ngOnInit(): void {
  }


  toggleMenu() {
    let menut = document.getElementById('mobile-menu');
    menut.classList.toggle("active");
  }


}
