import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from '../../../core/services';

@Component({
  selector: 'sm-embed-video',
  templateUrl: './embed-video.component.html',
  styleUrls: ['./embed-video.component.scss'],

})
export class EmbedVideoComponent implements OnInit, OnDestroy {


  sub = new Subscription();


  constructor(private _route: ActivatedRoute, private _sanit: DomSanitizer, private _account: AccountService, private cd: ChangeDetectorRef) { }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }



  videoId: any

  ngOnInit(): void {
    this.sub = this._route.queryParams.subscribe((data) => {
      let videoId = data['videoId']
      let courseId = data['courseId']
      let userId = data['userId']
      this._account.getSigned(videoId, courseId, userId).subscribe((data: any) => {
        // console.log(data);
        this.videoId = this._sanit.bypassSecurityTrustResourceUrl(data);
        this.cd.markForCheck();
      })
    })
  }


}
