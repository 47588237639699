import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/auth/authentication.service';

@Component({
  selector: 'sm-b2b-mobilemnue',
  templateUrl: './b2b-mobilemnue.component.html',
  styleUrls: ['./b2b-mobilemnue.component.scss']
})
export class B2bMobilemnueComponent implements OnInit {

  profile;
  bProfile;
  clientId;
  template 

  constructor(@Inject('HOME_URL') private originUrl: string, private route: ActivatedRoute,
    private _router: Router, private _auth: AuthenticationService, private _route: ActivatedRoute) {
    if (typeof window !== 'undefined') {

      this.profile = JSON.parse(localStorage.getItem('profile'));
      this.bProfile = JSON.parse(localStorage.getItem('__bb__prf'));
      this.template = JSON.parse(localStorage.getItem('__template'));
    }
    if (this.bProfile)
      this.clientId = this.bProfile.companyId;
    // console.log(this.clientId);



  }


  goToHome() {
    window.location.href = this.originUrl;

  }


  goHome() {
    window.location.href = this.originUrl;
  }

  ngOnInit(): void {
  }


  logout() {
    let self = this;
    var id = this._route.snapshot.queryParamMap.get('clientId')
    let returnUrl = (id) ? 'login?clientId/' + id : '/login';
    console.log('WE RHW ');

    this._auth.logout()
  }


}
