<div class="sm-footer-container">

  <div class="sm-footer-logo">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 353.3 532.3" style="enable-background:new 0 0 353.3 532.3;" xml:space="preserve">
      <style type="text/css">
        .st0 {
          fill: #fff;
        }

        .rightwingx {
          fill: #fff;
        }

        .leftwingx {
          fill: #fff;
        }
      </style>
      <g>
        <path id="sx" class="rightwingx" d="M177.4,407.8c0,0,35.7,10.3,49.1-39.4C226.5,368.4,183.5,363.9,177.4,407.8" />
        <path class="rightwingx" d="M210.2,402.7c0,0,11.4,31.6,47.7,16.4C257.9,419.2,240.5,385.3,210.2,402.7" />
        <path class="rightwingx" d="M228.3,398.8c0,0-6.7-31.4,28.5-44.4C256.8,354.5,259.2,389.7,228.3,398.8" />
        <path class="rightwingx" d="M249.6,384.8c0,0,13.9,21.8,38.4-1.1C288,383.6,270.2,364.9,249.6,384.8" />
        <path class="rightwingx" d="M262.6,378.9c0,0-7.8-25.4,14.2-42.9C276.8,336.1,283.4,363.9,262.6,378.9" />
        <path class="rightwingx" d="M276,362.3c0,0,7-18.6,28.5-12.2C304.5,350.1,295.5,371.6,276,362.3" />
        <path class="rightwingx" d="M285.2,354.5c0,0-10.7-14.9,3.6-36C288.8,318.5,296,338.8,285.2,354.5" />
        <path class="rightwingx" d="M290.3,338.5c0,0,1.8-16.2,19.9-20C310.1,318.5,309,338.3,290.3,338.5" />
        <path class="rightwingx" d="M296,329.4c0,0-11-12.7-3.5-30C292.6,299.4,302,311.9,296,329.4" />
        <path class="rightwingx" d="M299,315.2c0,0-2.4-13.7,11.1-20.6C310.1,294.6,312.7,311.7,299,315.2" />
        <path class="rightwingx" d="M301.3,307.1c0,0,2.8-13.8-8.4-20.4C292.9,286.7,291.3,301,301.3,307.1" />
        <path class="rightwingx" d="M300.2,294.6c0,0-2.9-12.5,4.4-18.4C304.6,276.2,309.2,289.1,300.2,294.6" />
        <path class="rightwingx" d="M301,287.2c0,0-1.4-9.6-10.7-12.3C290.3,274.9,291.5,287.8,301,287.2" />
        <path class="rightwingx" d="M298,282.2c0,0,5.8-10.1,0.8-17.7C298.8,264.5,292.2,270.8,298,282.2" />
      </g>

      <g>
        <path class="leftwingx" d="M171.5,407.8c0,0-35.7,10.3-49.1-39.4C122.5,368.4,165.4,363.9,171.5,407.8" />
        <path class="leftwingx" d="M138.8,402.7c0,0-11.4,31.6-47.7,16.4C91.1,419.2,108.4,385.3,138.8,402.7" />
        <path class="leftwingx" d="M120.6,398.8c0,0,6.7-31.4-28.5-44.4C92.1,354.5,89.7,389.7,120.6,398.8" />
        <path class="leftwingx" d="M99.4,384.8c0,0-13.9,21.8-38.4-1.1C61,383.6,78.8,364.9,99.4,384.8" />
        <path class="leftwingx" d="M86.4,378.9c0,0,7.8-25.4-14.2-42.9C72.1,336.1,65.6,363.9,86.4,378.9" />
        <path class="leftwingx" d="M72.9,362.3c0,0-7-18.6-28.5-12.2C44.4,350.1,53.4,371.6,72.9,362.3" />
        <path class="leftwingx" d="M63.8,354.5c0,0,10.7-14.9-3.6-36C60.2,318.5,52.9,338.8,63.8,354.5" />
        <path class="leftwingx" d="M58.7,338.5c0,0-1.8-16.2-19.9-20C38.8,318.5,40,338.3,58.7,338.5" />
        <path class="leftwingx" d="M52.9,329.4c0,0,11-12.7,3.4-30C56.4,299.4,46.9,311.9,52.9,329.4" />
        <path class="leftwingx" d="M50,315.2c0,0,2.4-13.7-11.1-20.6C38.8,294.6,36.3,311.7,50,315.2" />
        <path class="leftwingx" d="M47.6,307.1c0,0-2.8-13.8,8.4-20.4C56,286.7,57.6,301,47.6,307.1" />
        <path class="leftwingx" d="M48.7,294.6c0,0,2.9-12.5-4.4-18.4C44.4,276.2,39.7,289.1,48.7,294.6" />
        <path class="leftwingx" d="M48,287.2c0,0,1.4-9.6,10.7-12.3C58.7,274.9,57.4,287.8,48,287.2" />
        <path class="leftwingx" d="M50.9,282.2c0,0-5.8-10.1-0.8-17.7C50.1,264.5,56.8,270.8,50.9,282.2" />
      </g>

      <g>
        <path class="st0" d="M120.6,33.5c0,6.1-5,11.1-11.1,11.1c-6.1,0-11.1-5-11.1-11.1s5-11.1,11.1-11.1
              C115.6,22.4,120.6,27.4,120.6,33.5" />
        <path class="st0" d="M114.2,27.2c1.5,1.5,2.4,3.6,2.4,5.9c0,4.7-3.8,8.5-8.6,8.5c-0.6,0-1.2-0.1-1.7-0.2c1.2,0.6,2.5,1,3.9,1
                     c4.5,0,8.2-3.7,8.2-8.2C118.4,31.2,116.7,28.6,114.2,27.2" />
        <path class="st0"
          d="M153,33.5c0,6.1-5,11.1-11.1,11.1c-6.1,0-11.1-5-11.1-11.1s5-11.1,11.1-11.1C148.1,22.4,153,27.4,153,33.5" />
        <path class="st0" d="M146.7,27.2c1.5,1.5,2.4,3.6,2.4,5.9c0,4.7-3.8,8.5-8.6,8.5c-0.6,0-1.2-0.1-1.8-0.2c1.2,0.6,2.5,1,3.9,1
                  c4.5,0,8.2-3.7,8.2-8.2C150.9,31.2,149.2,28.6,146.7,27.2" />
        <path class="st0" d="M185.5,33.5c0,6.1-5,11.1-11.1,11.1c-6.1,0-11.1-5-11.1-11.1s5-11.1,11.1-11.1
                     C180.6,22.4,185.5,27.4,185.5,33.5" />
        <path class="st0" d="M179.2,27.2c1.5,1.5,2.4,3.6,2.4,5.9c0,4.7-3.8,8.5-8.6,8.5c-0.6,0-1.2-0.1-1.8-0.2c1.2,0.6,2.5,1,3.9,1
                     c4.5,0,8.2-3.7,8.2-8.2C183.4,31.2,181.7,28.6,179.2,27.2" />
        <path class="st0"
          d="M218,33.5c0,6.1-5,11.1-11.1,11.1c-6.2,0-11.1-5-11.1-11.1s5-11.1,11.1-11.1C213,22.4,218,27.4,218,33.5" />
        <path class="st0" d="M211.7,27.2c1.5,1.5,2.4,3.6,2.4,5.9c0,4.7-3.8,8.5-8.5,8.5c-0.6,0-1.2-0.1-1.7-0.2c1.2,0.6,2.5,1,3.9,1
                 c4.5,0,8.2-3.7,8.2-8.2C215.9,31.2,214.2,28.6,211.7,27.2" />
        <g>
          <path class="st0" d="M250.5,33.5c0,6.1-5,11.1-11.1,11.1c-6.1,0-11.1-5-11.1-11.1s5-11.1,11.1-11.1
             C245.5,22.4,250.5,27.4,250.5,33.5" />
        </g>
        <g>
          <path class="st0" d="M244.2,27.2c1.5,1.5,2.4,3.6,2.4,5.9c0,4.7-3.8,8.5-8.5,8.5c-0.6,0-1.2-0.1-1.8-0.2c1.2,0.6,2.5,1,3.9,1
             c4.5,0,8.2-3.7,8.2-8.2C248.3,31.2,246.7,28.6,244.2,27.2" />
        </g>
        <g>
          <path class="st0"
            d="M220.3,126c0,4-3.3,7.2-7.3,7.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.3,7.2-7.3C217,118.7,220.3,122,220.3,126" />
        </g>
        <g>
          <path class="st0" d="M216.2,121.9c1,1,1.6,2.3,1.6,3.8c0,3.1-2.5,5.6-5.6,5.6c-0.4,0-0.8,0-1.1-0.1c0.8,0.4,1.6,0.7,2.5,0.7
             c3,0,5.4-2.4,5.4-5.4C218.9,124.5,217.8,122.8,216.2,121.9" />
        </g>
        <g>
          <path class="st0"
            d="M201,126c0,4-3.2,7.2-7.2,7.2c-4,0-7.3-3.2-7.3-7.2c0-4,3.3-7.3,7.3-7.3C197.8,118.7,201,122,201,126" />
        </g>
        <g>
          <path class="st0" d="M196.9,121.9c1,1,1.6,2.3,1.6,3.8c0,3.1-2.5,5.6-5.6,5.6c-0.4,0-0.8,0-1.1-0.1c0.8,0.4,1.6,0.7,2.5,0.7
             c3,0,5.3-2.4,5.3-5.4C199.6,124.5,198.5,122.8,196.9,121.9" />
        </g>
        <g>
          <path class="st0" d="M181.7,126c0,4-3.2,7.2-7.2,7.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.3,7.2-7.3C178.4,118.7,181.7,122,181.7,126
             " />
        </g>
        <g>
          <path class="st0" d="M177.6,121.9c0.9,1,1.5,2.3,1.5,3.8c0,3.1-2.5,5.6-5.6,5.6c-0.4,0-0.8,0-1.2-0.1c0.8,0.4,1.6,0.7,2.5,0.7
             c2.9,0,5.3-2.4,5.3-5.4C180.3,124.5,179.2,122.8,177.6,121.9" />
        </g>
        <g>
          <path class="st0" d="M162.4,126c0,4-3.2,7.2-7.2,7.2c-4,0-7.3-3.2-7.3-7.2c0-4,3.2-7.3,7.3-7.3C159.1,118.7,162.4,122,162.4,126
             " />
        </g>
        <g>
          <path class="st0" d="M158.3,121.9c1,1,1.6,2.3,1.6,3.8c0,3.1-2.5,5.6-5.6,5.6c-0.4,0-0.8,0-1.1-0.1c0.8,0.4,1.6,0.7,2.5,0.7
             c3,0,5.3-2.4,5.3-5.4C161,124.5,159.9,122.8,158.3,121.9" />
        </g>
        <g>
          <path class="st0" d="M143.1,126c0,4-3.2,7.2-7.2,7.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.3,7.2-7.3C139.8,118.7,143.1,122,143.1,126
             " />
        </g>
        <g>
          <path class="st0" d="M139,121.9c1,1,1.6,2.3,1.6,3.8c0,3.1-2.5,5.6-5.6,5.6c-0.4,0-0.8,0-1.1-0.1c0.8,0.4,1.6,0.7,2.5,0.7
             c3,0,5.3-2.4,5.3-5.4C141.7,124.5,140.6,122.8,139,121.9" />
        </g>
        <g>
          <polygon class="st0" points="201.9,104.1 214.8,104.1 234.3,47.1 			" />
        </g>
        <g>
          <polygon class="st0" points="185,104.1 197.2,104.1 203.2,47.1 			" />
        </g>
        <g>
          <polygon class="st0" points="146.6,104.1 133.7,104.1 114.2,47.1 			" />
        </g>
        <g>
          <polygon class="st0" points="163.5,104.1 151.3,104.1 145.3,47.1 			" />
        </g>
        <g>
          <polygon class="st0" points="167.7,104.1 179.7,104.1 173.7,47.3 			" />
        </g>

        <g>
          <rect x="128.8" y="108.7" class="st0" width="90.9" height="5.7" />
        </g>
        <g>
          <rect x="128.8" y="137.2" class="st0" width="90.9" height="5.8" />
        </g>


      </g>
      <g>
        <path class="st0" d="M65.1,261c0-58.5,49.1-105.8,109.6-105.9l0,0c60.5,0.1,109.6,47.4,109.6,105.9l0,0
         c0,58.5-49.1,105.8-109.6,105.9l0,0C114.2,366.8,65.1,319.5,65.1,261L65.1,261z M67,261c0.1,57.4,48.2,104,107.7,104l0,0
         c59.5,0,107.6-46.6,107.7-104l0,0c-0.1-57.4-48.2-104-107.7-104l0,0C115.2,157,67.1,203.6,67,261L67,261z" />
        <path class="st0" d="M214.8,319.3c-1.4-1-2.3-1.2-3.2-1.2l0,0c-1.2,0-2,0.6-3.3,0.6l0,0c-0.2,0-0.5,0-0.8-0.1l0,0
         c-1.4-0.7-3.2-3.1-5.4-6.4l0,0c-2.1-3.2-4.1-6.8-4.5-9.5l0,0c0-0.3-0.1-0.7-0.1-1l0,0c0-5.1,4.4-11.2,4.9-12.7l0,0
         c0-0.1,0-0.1,0-0.2l0,0c0.1-0.6-1-1.7-1.1-3.3l0,0c0-1.1,0.9-2,2.2-2.4l0,0c2.7-0.9,3.2-2.8,3.3-3.2l0,0c0-0.2-0.1-0.5-0.1-1l0,0
         c0-1.9,0.6-5.9,4.6-10.6l0,0c2.1-2.5,2.8-4.7,2.8-6.2l0,0c-0.1-1.8-0.8-2.5-2.1-2.6l0,0c-0.3,0-0.6,0-0.9,0.2l0,0
         c-0.5,0.1-1,0.2-1.4,0.2l0,0c-2.6,0-4.5-2.2-4.5-5l0,0c0-1.9,0.8-4.1,2.5-6.2l0,0c3.5-4.3,4.3-8.7,4.3-10.5l0,0c0-0.4,0-0.6,0-0.6
         l0,0c-0.3-0.8-0.5-2.3-0.5-3.8l0,0c0.2-0.9-0.3-2.1,1.5-2.6l0,0c0.9,0.1,1.2,0.6,1.6,1.1l0,0c0.9,1.2,2.6,1.8,3.8,1.8l0,0
         c0.3,0,0.6,0,0.8-0.1l0,0c0,0,0,0,0.1,0l0,0c0-0.1,0-0.2,0-0.2l0,0c0-1.8,1.3-3,1.3-3.1l0,0l0.2-0.2l0.2-0.1c2.4-0.9,2.9-2,2.9-3.6
         l0,0c0.1-3.5-4-8.6-4.8-9.5l0,0c-1.8-0.7-3-1-3.6-1l0,0c-0.9,0.2-0.5,0-0.7,0.5l0,0c0,0.8,0.7,2.2,0.9,2.6l0,0
         c0.6,0.6,3.5,3.3,3.6,6.5l0,0c0,1.6-0.9,3.3-3,4.3l0,0c-1.4,0.7-2.7,1-3.8,1l0,0c-4.5-0.1-6.4-4.1-6.5-4.3l0,0
         c-2.2-6.3-3.2-11.1-3.2-15l0,0c-0.1-10.6,7.4-13.1,11.8-14.5l0,0c2.9-0.9,5.8-5.5,5.7-9l0,0c-0.1-2.5-1-4.1-4.7-4.2l0,0
         c-0.2,0-0.4,0-0.7,0l0,0c-0.5,0-1,0-1.5,0l0,0c-9.7,0.1-10.4-7-12.4-10l0,0c-2.3-3.4-3.2-4.5-19.6-7.2l0,0c-7.2-1.1-9.9-3.3-10-6.2
         l0,0c0.1-3.3,3.6-5.5,3.6-5.6l0,0v0l1.1,1.5c0,0-2.9,2.3-2.8,4l0,0c-0.1,1.1,1.2,3.1,8.4,4.3l0,0c16.2,2.5,18.3,4.1,20.9,8l0,0
         c2.6,4,2.3,9,10.8,9.1l0,0c0.4,0,0.9,0,1.4,0l0,0c0.3,0,0.5,0,0.8,0l0,0c2.2,0,3.9,0.7,5,1.8l0,0c1.1,1.1,1.6,2.7,1.6,4.3l0,0
         c-0.1,4.3-3,9.3-7,10.8l0,0c-4.6,1.6-10.5,3.2-10.5,12.7l0,0c0,3.5,0.9,8.2,3,14.2l0,0c0,0,0.4,0.8,1.2,1.6l0,0
         c0.8,0.8,2,1.6,3.5,1.6l0,0c0.8,0,1.8-0.2,2.9-0.8l0,0c1.6-0.9,1.9-1.7,1.9-2.6l0,0c0.1-2.2-3-5.2-3-5.2l0,0l-0.1-0.1l-0.1-0.1
         c0-0.1-1.2-1.9-1.3-3.7l0,0c0-0.5,0.1-1.2,0.6-1.7l0,0c0.5-0.5,1.2-0.7,2-0.7l0,0c1.1,0,2.5,0.4,4.6,1.2l0,0l0.2,0.1l0.1,0.2
         c0,0.1,5.4,5.8,5.5,11l0,0c0,2.1-1.2,4.3-4,5.3l0,0c-0.2,0.3-0.7,1.1-0.7,1.6l0,0c0,0.1,0,0.1,0,0.2l0,0c0,0.1,0,0.1,0,0.2l0,0
         c-0.2,1.6-1.6,1.8-2.8,1.8l0,0c-1.6,0-3.5-0.6-4.9-2.1l0,0c0,1.1,0.3,2.9,0.4,2.9l0,0c0.2,0.4,0.2,0.8,0.2,1.4l0,0
         c0,2.3-1,7-4.8,11.7l0,0c-1.5,1.9-2.1,3.6-2.1,5l0,0c0,2,1.1,3.1,2.6,3.1l0,0c0.3,0,0.6,0,0.9-0.1l0,0c0.5-0.2,1-0.2,1.5-0.2l0,0
         c2.4,0,4,2,4,4.5l0,0c0,2.1-1,4.7-3.3,7.4l0,0c-3.7,4.4-4.2,7.9-4.2,9.4l0,0c0,0.6,0.1,0.8,0.1,0.8l0,0l0,0.2l0,0.2
         c0,0.2-0.5,3.6-4.6,4.9l0,0c-1.1,0.4-0.9,0.5-0.9,0.6l0,0c-0.1,0.4,1.1,1.7,1.2,3.3l0,0c0,0.3-0.1,0.5-0.2,0.8l0,0
         c-0.8,2-4.8,7.9-4.8,12.1l0,0c0,0.3,0,0.5,0.1,0.7l0,0c0.4,3.6,6,11.9,8.2,14l0,0c0.3,0.3,0.4,0.4,0.4,0.4l0,0c0,0,0,0,0.1,0l0,0
         c0.3,0.1,1.6-0.6,3.3-0.6l0,0c1.2,0,2.7,0.4,4.3,1.5l0,0c1.4,1.1,6.7,2.2,12.1,2.2l0,0c6.3,0,12.9-1.4,15.3-4.9l0,0
         c-0.1-0.5-0.2-1.3-0.2-2.3l0,0c0-2.2,1-5.8,5.2-5.8l0,0c0.5,0,0.9,0,1.3,0l0,0c1.6,0,2.9,0.1,4,0.1l0,0c2.7-0.1,3.8-0.2,4.9-2.5
         l0,0c1.2-2.4,3.8-5.8,7.2-5.9l0,0c3.1,0.1,5.5,3,6.7,10.1l0,0l-1.9,0.3c-1.3-7-3.4-8.6-4.8-8.6l0,0c-1.9-0.1-4.6,2.8-5.4,4.8l0,0
         c-1.3,3-3.9,3.7-6.6,3.6l0,0c-1.2,0-2.5-0.1-4-0.1l0,0c-0.4,0-0.8,0-1.2,0l0,0c-2.9,0.1-3.2,1.9-3.3,3.9l0,0c0,1.2,0.3,2.2,0.3,2.2
         l0,0l0.1,0.4l-0.2,0.4c-3.1,4.8-10.4,6.1-17.1,6.1l0,0C222.3,321.7,217,320.8,214.8,319.3L214.8,319.3z" />
        <path class="st0"
          d="M275.2,258.5c-3.9-2.6-7.7-4.7-8.1-7.2l0,0c0.1-1.4,1.5-1.7,2.6-1.7l0,0c2.3,0,6.4,0.9,13.7,3l0,0l-0.5,1.8
         c-7.1-2.1-11.3-2.9-13.1-2.9l0,0c-0.2,0-0.4,0-0.5,0l0,0c0.1,0.1,0.2,0.2,0.3,0.3l0,0c0.4,0.4,0.9,0.9,1.6,1.5l0,0
         c1.4,1.1,3.3,2.4,5.2,3.6l0,0c3.8,2.4,7.6,4.5,7.6,4.5h0l-0.9,1.7C282.9,263,279,260.9,275.2,258.5L275.2,258.5z" />
        <path class="st0" d="M219.2,263c-0.9-0.4-1.5-1.2-1.5-2.1l0,0c0.2-2.4,2.9-4,5.4-5.6l0,0c1.9-1.1,3.8-2,4.6-2.4l0,0l0.9-4.5
         c1.1-4,5.6-3.3,7.6-4l0,0c1-0.3,1.6-0.9,2.9-0.9l0,0c1.3,0.1,2.2,1,3.4,2.9l0,0c0.7,1.2,1.4,1.5,1.9,1.5l0,0c0.7,0,1.9-1,1.9-2.9
         l0,0c0-0.1,0-0.2,0-0.2l0,0c0-0.3,0-0.6,0-0.9l0,0c0-2.2,0.3-4.5,2.5-4.7l0,0c0.8,0,1.6,0.4,2.3,1.1l0,0c0.8,0.7,1.3,1,1.6,1.1l0,0
         c0.9-2,1.8-3,3.3-3.1l0,0c2.8,0.4,3.6,4.1,4.3,4.5l0,0c1.3,1.2,2.6,3.8,2.6,5.9l0,0c0,0.7-0.2,1.7-1.1,2.1l0,0
         c-1.5,0.6-8.1,4-9.5,8.1l0,0c-1.1,2.8-4.2,3.4-7,3.4l0,0c-3.5,0-6.9-0.9-7.8-1.2l0,0c-0.3-0.1-2.3-0.4-4.2-0.3l0,0
         c-2.1,0-4.6,0.2-5.5,0.7l0,0c-1.2,0.4-2.3,2.9-5.2,3.1l0,0C221.6,264.1,220.5,263.7,219.2,263L219.2,263z M229.5,254.3l-0.5,0.2
         c0,0-2.4,1.1-4.8,2.5l0,0c-2.5,1.3-4.7,3.5-4.5,3.9l0,0c0,0.1,0,0.2,0.5,0.5l0,0c1.1,0.6,1.8,0.8,2.4,0.8l0,0
         c1.5,0.1,2.3-1.8,4.4-2.9l0,0c1.5-0.7,3.9-0.8,6.2-0.8l0,0c2.1,0,3.9,0.1,4.8,0.5l0,0c0.6,0.2,4.1,1.1,7.2,1.1l0,0
         c2.6,0,4.8-0.7,5.2-2.1l0,0c2-5.2,8.8-8.3,10.5-9.1l0,0c0,0,0.1-0.2,0.1-0.4l0,0c0.1-1.2-1.3-3.9-2-4.4l0,0c-1.2-1.3-2.7-4.3-3.1-4
         l0,0c0,0-0.8,0.2-1.6,2.1l0,0c0,0.2-0.5,1-1.5,1l0,0c-0.9,0-1.7-0.5-3-1.6l0,0c-0.6-0.5-1-0.7-1.1-0.6l0,0c0,0-0.1-0.1-0.3,0.5l0,0
         c-0.2,0.5-0.3,1.3-0.3,2.4l0,0c0,0.3,0,0.5,0,0.8l0,0c0,0.1,0,0.2,0,0.3l0,0c0,2.5-1.4,4.8-3.8,4.8l0,0c-1.3,0-2.6-0.8-3.5-2.4l0,0
         c-1.2-1.9-1.7-2-1.8-2l0,0c-0.2-0.1-1,0.4-2.3,0.9l0,0c-2.9,0.7-6,0.6-6.2,2.5l0,0L229.5,254.3L229.5,254.3z" />
        <path class="st0" d="M187.9,319.7c-0.8-0.8-1.2-1.8-1.2-2.6l0,0c0-1.7,1.2-2.6,1.3-2.7l0,0c1.2-0.9,2.2-1.3,3.2-1.3l0,0
         c3.6,0.3,3.4,4.6,3.5,4.6l0,0l-1,0.1l1-0.1l0,0.5l-0.3,0.3c-1.2,0.9-2.1,2.4-4,2.5l0,0C189.5,320.9,188.7,320.5,187.9,319.7
         L187.9,319.7z M189.3,315.9c0,0-0.6,0.6-0.6,1.2l0,0c0,0.3,0.1,0.7,0.6,1.2l0,0c0.6,0.6,0.9,0.6,1.1,0.6l0,0
         c0.4,0.1,1.3-0.7,2.4-1.7l0,0c0-0.3-0.1-0.7-0.3-1.1l0,0c-0.3-0.8-0.7-1.2-1.2-1.2l0,0h0l0,0C190.9,315,190.2,315.2,189.3,315.9
         L189.3,315.9z" />
        <path class="st0" d="M195.4,324.7c-1.5-0.3-3.3-1.8-3.5-4.2l0,0c0-0.9,0.4-1.9,1.2-2.7l0,0l0.1-0.1l0.2-0.1c0.1,0,1.2-0.6,2.7-0.6
         l0,0c1.6-0.1,3.7,0.9,4.3,3.6l0,0c0.1,0.6,0.2,1.1,0.2,1.6l0,0c0,0.9-0.3,1.8-0.9,2.3l0,0c-0.7,0.5-1.3,0.6-1.9,0.6l0,0
         C196.7,325,195.8,324.7,195.4,324.7L195.4,324.7z M193.8,320.5c-0.2,1,1.5,2.3,1.8,2.3l0,0c0.6,0.1,1.5,0.3,2.1,0.3l0,0
         c0.8-0.2,0.8,0.1,0.9-0.9l0,0c0-0.3,0-0.7-0.1-1.1l0,0c-0.5-1.9-1.3-2-2.4-2.1l0,0c-0.8,0-1.5,0.2-1.8,0.3l0,0
         C193.9,319.8,193.8,320.1,193.8,320.5L193.8,320.5z" />
        <path class="st0"
          d="M175.4,215.3c-1.3-6.6-2.4-8.2-3-8.4l0,0c-0.5-0.4-2.3,0.3-4.6-1.3l0,0c-2.5-1.8-3.7-5.7-3.7-9.2l0,0
         c0-3.1,1-6.3,4.2-6.7l0,0c2.4-0.3,2.4-0.5,3-1l0,0c0.7-0.6,1.8-1.3,5-1.8l0,0c0.8-0.1,1.5-0.2,2.2-0.2l0,0c4.2,0,5.6,2.7,5.8,3.5
         l0,0c3.1,4.4,3.3,10.7,3.3,12.2l0,0c0,0.2,0,0.3,0,0.3l0,0v0.2l-0.1,0.2c0,0-3.9,10.5-4,12.1l0,0c-0.1,0.9-0.5,2.4-1.1,4l0,0
         c-0.7,1.4-1.2,3.1-3.1,3.3l0,0C177.1,222.2,176.5,220.1,175.4,215.3L175.4,215.3z M176.5,188.8c-3,0.5-3.5,1-4.1,1.4l0,0
         c-0.6,0.5-1.6,1.2-4,1.4l0,0c-1.5,0.1-2.4,2-2.4,4.8l0,0c0,3,1.2,6.5,2.9,7.6l0,0c1.6,1.2,2.5,0.4,4.4,1.1l0,0
         c1.8,1,2.7,3.1,4.1,9.8l0,0c0.7,3.5,1.5,5.2,1.9,5.5l0,0c0.1,0.1,0.1,0.1,0.1,0.1l0,0c0,0,0.1,0,0.4-0.4l0,0c0.3-0.4,0.6-1,0.9-1.7
         l0,0c0.5-1.3,0.9-2.9,1-3.4l0,0c0.2-2.3,3.6-11.2,4.1-12.5l0,0c0,0,0-0.1,0-0.1l0,0c0-1.4-0.2-7.5-3-11.2l0,0l0.8-0.6l0.9-0.2
         l-0.9,0.2l-0.8,0.6l-0.1-0.2l0-0.2c-0.1,0-0.2-2.1-4-2.2l0,0C177.8,188.7,177.2,188.7,176.5,188.8L176.5,188.8z" />
        <path class="st0"
          d="M154.2,246.8c-0.4-0.8-0.6-1.6-0.6-2.3l0,0c0.1-3.2,3.3-4.5,3.3-4.5l0,0l0.2-0.1l0.3,0
         c3.4,0.4,4.4,3.5,4.3,5.4l0,0c0,0.6-0.1,1.1-0.3,1.6l0,0c-0.5,0.9-1.9,2.3-3.9,2.4l0,0h0h0C156.2,249.1,155,248.4,154.2,246.8
         L154.2,246.8z M155.5,244.5c0,0.4,0.1,0.8,0.4,1.4l0,0c0.7,1.2,1.2,1.3,1.6,1.3l0,0c0.8,0.1,2.1-1,2.2-1.3l0,0c0,0,0.1-0.3,0.1-0.7
         l0,0c0-1.3-0.6-3.1-2.3-3.4l0,0C156.9,242.2,155.4,243.2,155.5,244.5L155.5,244.5z M157.2,241.8l0.1-1L157.2,241.8L157.2,241.8z" />
        <path class="st0" d="M162.9,364.5c9.5-4.2,10.6-12.1,14-14.5l0,0c1.8-1,6-5.1,5.9-7.4l0,0c0-0.9-0.4-1.7-2.3-2.5l0,0
         c-6.5-2.5-10.4-6.3-11.2-7.1l0,0c-1-0.4-1.6-1.4-1.5-2.2l0,0c0.1-1.5,1-2.9,0.9-3.5l0,0c0-0.3,0.2-0.7-2.7-0.9l0,0
         c-3.9-0.1-5.5-0.6-6.7-1.6l0,0c-1.1-1-1.3-1.8-3.2-3l0,0c-4.6-2.8-3.9-5.3-12.4-6.9l0,0c-8.9-1.5-8.2-3.6-10.3-4.1l0,0
         c-0.3-0.1-0.5-0.1-0.9-0.1l0,0c-2.1,0-4.6,1.8-5.4,2.2l0,0c-0.2,0-7.4,4.2-7.5,4.3l0,0c-4.6,1.9-6.9,2.7-8.4,2.8l0,0
         c-0.6,0-1.2-0.1-1.6-0.6l0,0c-0.4-0.5-0.5-1-0.5-1.4l0,0c0-0.9,0.2-1.7,0.2-2.5l0,0c0-1.1-0.2-1.9-1.6-2.8l0,0
         c-2.3-1.3-3.3-3.2-3.3-4.9l0,0c0-2.8,2.1-5.1,2.7-5.7l0,0c0.8-2.2,1.1-3.7,1.1-4.6l0,0c-0.2-1.3-0.1-1-0.5-1.1l0,0
         c-1.6-0.2-6.3,3.6-8.3,5.6l0,0c-2.1,2-4.4,4.1-6.7,4.2l0,0c-2.1,0-3.2-2.1-3.2-5.4l0,0c0-0.1,0-0.3,0-0.4l0,0c0-0.5,0-1,0-1.5l0,0
         c-0.3-6.2-0.2-3.5-3.4-6l0,0c-1.9-1.4-3.5-2.4-3.6-4.2l0,0c0-1.2,1-1.9,2.1-2.4l0,0c1.3-0.5,1.7-1.3,1.8-2.2l0,0
         c0-0.7-0.4-1.6-1.1-2.4l0,0c-0.1-1-7.3,1.3-6.9-8.4l0,0c0-1.2,0.1-2.5,0.2-3.9l0,0c0.5-5.4,2.2-7.9,4.8-8l0,0
         c3.8,0.2,6.8,4.9,7.6,6.8l0,0c0.1,1.1,4.4,3.1,7.4,3l0,0c1.1,0,2-0.2,2.5-0.5l0,0c1.5-1,3.5-1.2,5.7-1.2l0,0c3.2,0,6.4,0.7,8.3,1.7
         l0,0c1.8,1.1,2.6,2.1,3.5,2l0,0c0.6,0,1.8-0.4,3.6-1.9l0,0c3-2.5,5.5-3.2,7.6-3.2l0,0c1.4,0,2.6,0.3,3.5,0.4l0,0c0.1,0,0.1,0,0.1,0
         l0,0c0.1,0,0.2,0,0.4-0.4l0,0c0.2-0.4,0.4-1.1,0.6-1.9l0,0c0.5-1.5,0.7-3.5,2.7-4.1l0,0c2.4-0.2,13.1-2.7,12.6-6l0,0
         c0-0.2,0-0.3,0-0.5l0,0c-0.4-1.8-0.5-3.5-0.5-4.9l0,0c0-2.1,0.3-3.5,0.4-4.2l0,0c-1-2.3-2-3.7-2.1-5.2l0,0c0.1-1.8,1.5-2.7,3.9-3.8
         l0,0c3.1-1.3,6.1-4.8,6-5.9l0,0c-0.1-0.3,0.1-0.4-1.3-0.6l0,0c-0.1,0-0.2,0-0.4,0l0,0c-0.1,0-0.2,0-0.4,0l0,0
         c-1.2,0-2.2-0.4-2.8-1.2l0,0c-0.6-0.8-0.7-1.7-0.7-2.6l0,0c0-1.6,0.5-3.5,0.5-4.8l0,0c0-1-0.2-1.5-0.6-1.8l0,0
         c-0.4-0.3-0.7-0.3-0.9-0.3l0,0c-0.6-0.1-0.9,0.7-2.4,1l0,0c-1.6-0.3-1.6-1.5-2-3.6l0,0c-0.3-2.3-2.7-3.5-5.2-3.5l0,0
         c-2.5,0-4.5,1.2-4.5,3.2l0,0c-0.1,5.9-4.2,13.5-10.4,14.2l0,0c-0.4,0-0.7,0.1-0.9,0.1l0,0c-0.8,0-1.5-0.1-2-0.6l0,0
         c-0.6-0.5-0.7-1.2-0.7-1.6l0,0c0-0.8,0.2-1.5,0.2-2.1l0,0c0-0.7-0.1-1.3-1-2l0,0c-1.8-1.4-2.5-3.7-2.5-5.9l0,0
         c0-2.7,1.1-5.6,2.7-7.6l0,0c2.7-2.9,12.9-6.2,19.8-6.3l0,0c2.3,0,4.2,0.3,5.5,1.6l0,0c1.4,1.6,4.5,3.1,7.1,3.1l0,0
         c2.9-0.1,5.3-1.4,5.5-7l0,0c0-0.1,0-0.2,0-0.3l0,0c0-10.1-10.6-8.8-13.7-10.3l0,0c-0.6-0.3-1.1-1-1-1.7l0,0c0.1-1.5,1.1-2.4,1-3.1
         l0,0c0-0.3,0-0.6-1-1.2l0,0c-3.7-2.2-4.8-6.2-5-7.4l0,0c-2.5-2.4-5.3-3.2-8.5-3.2l0,0c-7.2,0-15.7,4.9-22.1,7.9l0,0
         c-4.4,2-6.2,5.3-7.2,8.4l0,0c-1.2,3.1-0.7,5.6-2.3,7l0,0c-1,0.5-3.1,5.5-3.4,7.6l0,0c-0.7,3.3-8.7,10.2-11.8,12.5l0,0
         c-2.6,1.7-8.5,11.4-9.3,16.6l0,0c-0.7,4.7-4.6,5.6-6.4,5.5l0,0c-0.7,0-1.1-0.1-1.2-0.1l0,0h0l0.3-1.9c0,0,0.3,0,0.8,0l0,0
         c1.5-0.1,3.9-0.3,4.5-3.9l0,0c1-6.1,6.7-15.4,10.1-17.9l0,0c2.8-1.8,11-9.8,11-11.3l0,0c0.5-2.7,2.1-7.3,4.2-8.9l0,0l0,0
         c0,0,0.1-0.2,0.1-0.3l0,0c0.1-0.3,0.2-0.8,0.3-1.4l0,0c0.2-1.1,0.5-2.7,1.1-4.4l0,0c1.1-3.3,3.3-7.3,8.2-9.6l0,0
         c6.4-2.9,15-8,22.9-8.1l0,0c3.6,0,7.1,1.1,10,4l0,0l0.2,0.2l0,0.3c0,0,0.9,4.5,4.2,6.4l0,0c1.3,0.7,2,1.8,2,2.9l0,0
         c-0.1,1.7-1.2,2.9-1,3.1l0,0l0.1,0c1.1,0.9,14.6-0.1,14.6,11.9l0,0c0,0.1,0,0.2,0,0.3l0,0c0,6.4-3.7,9.1-7.5,9l0,0
         c-3.3,0-6.7-1.7-8.5-3.7l0,0c-0.5-0.6-2-1-4-1l0,0c-6.2-0.1-16.8,3.6-18.3,5.6l0,0c-1.4,1.6-2.3,4.1-2.3,6.3l0,0
         c0,1.8,0.6,3.4,1.8,4.4l0,0c1.3,1,1.7,2.4,1.7,3.5l0,0c0,1-0.2,1.7-0.2,2.1l0,0c0,0.1,0,0.1,0,0.2l0,0c0,0,0.1,0,0.2,0l0,0
         c0.1,0,0.3,0.1,0.6,0.1l0,0c0.2,0,0.5,0,0.8,0l0,0c4.5-0.3,8.7-7.2,8.6-12.3l0,0c0-3.5,3.4-5.1,6.4-5.1l0,0c3,0,6.5,1.6,7,5.2l0,0
         c0.1,0.8,0.2,1.3,0.3,1.7l0,0c0,0,0,0,0,0l0,0c0.1,0,0.1-0.1,0.2-0.1l0,0c0.4-0.3,1.1-0.6,1.9-0.6l0,0c0.6,0,1.3,0.2,1.9,0.6l0,0
         c1.2,0.8,1.5,2.2,1.4,3.4l0,0c0,1.7-0.4,3.5-0.4,4.8l0,0c0.2,1.5,0,1.7,1.6,1.8l0,0c0.1,0,0.2,0,0.3,0l0,0c0.2,0,0.3,0,0.5,0l0,0
         c1.8,0,3.2,0.9,3.2,2.5l0,0c-0.2,2.8-3.5,6-7.1,7.7l0,0c-2.5,1.1-2.8,1.7-2.7,2l0,0c-0.1,0.6,0.8,2.2,2,4.8l0,0l0.2,0.3l-0.1,0.3
         c0,0-0.4,1.4-0.4,4l0,0c0,1.3,0.1,2.8,0.5,4.5l0,0c0.1,0.3,0.1,0.5,0.1,0.8l0,0c-0.6,6.3-11.9,7.3-14.1,7.9l0,0
         c-0.3-0.1-0.9,1.1-1.2,2.7l0,0c-0.4,1.4-0.7,3.5-2.9,3.7l0,0c-0.1,0-0.3,0-0.4,0l0,0c-1.1-0.2-2.1-0.4-3.2-0.4l0,0
         c-1.6,0-3.6,0.5-6.3,2.7l0,0c-2,1.6-3.4,2.4-4.8,2.4l0,0c-2.1-0.1-3.1-1.6-4.4-2.3l0,0c-1.3-0.8-4.5-1.5-7.4-1.5l0,0
         c-1.9,0-3.7,0.3-4.6,0.9l0,0c-1,0.6-2.3,0.9-3.6,0.9l0,0c-3.7-0.1-8.1-1.5-9.2-4.3l0,0c-0.3-1.3-3.9-5.8-5.8-5.5l0,0
         c-0.9-0.1-2.3,0.9-2.9,6.2l0,0c-0.1,1.4-0.2,2.7-0.2,3.7l0,0c0.3,8.7,3.2,4.3,6.5,7.1l0,0c1,1.1,1.5,2.4,1.5,3.7l0,0
         c0,1.7-1,3.3-3,4l0,0c-1,0.4-0.9,0.6-0.9,0.6l0,0c-0.1,0.1,1,1.5,2.8,2.7l0,0c2.2,1.4,4.5,1.3,4.2,7.6l0,0c0,0.5,0,1,0,1.5l0,0
         c0,0.1,0,0.3,0,0.4l0,0c0.1,3.2,0.8,3.4,1.3,3.4l0,0c1,0.1,3.5-1.7,5.4-3.7l0,0c2.3-2.2,6.5-6,9.7-6.2l0,0c0.6,0,1.4,0.2,1.8,0.8
         l0,0c0.5,0.6,0.6,1.3,0.6,2.1l0,0c0,1.3-0.4,3-1.2,5.4l0,0l-0.1,0.2l-0.2,0.2c0,0-2.4,2.4-2.3,4.5l0,0c0,1.1,0.4,2.1,2.3,3.3l0,0
         c2.1,1.1,2.7,3,2.6,4.4l0,0c0,1.1-0.3,2.1-0.2,2.5l0,0c0,0,0,0.1,0,0.1l0,0c0,0,0.1,0,0.1,0l0,0c0.8,0,3.1-0.7,7.6-2.6l0,0
         c0.1,0,6.9-4.1,7.8-4.4l0,0c0,0.1,3.1-2.3,6.1-2.3l0,0c0.5,0,1,0.1,1.4,0.2l0,0c3.5,1.2,1.3,2.5,10,4.1l0,0c9,1.6,9.4,4.9,13.1,7.1
         l0,0c2.2,1.3,2.8,2.7,3.5,3.2l0,0c0.6,0.5,1.6,1,5.5,1.1l0,0c2.9,0,4.6,1.1,4.6,2.8l0,0c-0.1,1.5-1,2.9-0.9,3.5l0,0
         c0.1,0.3-0.1,0.2,0.4,0.5l0,0l0.2,0.1l0.2,0.2c0,0,3.8,4.1,10.7,6.8l0,0c2.3,0.9,3.5,2.5,3.5,4.3l0,0c-0.2,4-4.7,7.6-6.8,9l0,0
         c-2.1,1-4.1,10.1-14.2,14.7l0,0L162.9,364.5L162.9,364.5z" />
        <path class="st0" d="M105.9,338.5c0-3.7,1.2-8.8,6.4-11.7l0,0c0,0,0.1-0.1,0.2-0.2l0,0c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0l0,0
         c-0.4-0.2-1.3-0.3-2.8-0.3l0,0c-0.5,0-1.2,0-1.8,0l0,0c-0.1,0-0.2,0.1-0.5,0.1l0,0c-1,0-3.1-0.7-4.1-4l0,0
         c-0.6-2.9-10.7-7.1-12.2-6.9l0,0c-2-0.1-2.5-1.9-3.2-3.5l0,0c-0.6-1.7-1.2-3.7-2-5.1l0,0c-1.5-2.9-13.9-15.2-14.1-22.5l0,0
         c0-0.2,0-0.3,0-0.5l0,0c0-3.1,0.5-8.3,0.5-12.5l0,0c0-2.8-0.3-5.2-0.7-5.8l0,0c-0.1-0.2-0.1-0.1-0.1-0.1l0,0c0,0-0.8,0.3-1.9,1.8
         l0,0l-2,6.5l-1.8-0.5l2.1-6.8l0.1-0.1c1.3-1.6,2.2-2.6,3.5-2.7l0,0c1.6,0.1,2.1,1.5,2.4,2.8l0,0c0.3,1.4,0.4,3.2,0.4,5.1l0,0
         c0,4.3-0.5,9.6-0.5,12.5l0,0c0,0.2,0,0.3,0,0.4l0,0c0,5.8,12,18.3,13.9,21.6l0,0c0.9,1.7,1.5,3.8,2.1,5.4l0,0
         c0.6,1.7,1.2,2.3,1.4,2.3l0,0c2.7,0.2,12.2,3.6,14,8.3l0,0c0.9,2.7,2,2.6,2.2,2.6l0,0c0,0,0.1,0,0.2,0l0,0c0.8-0.1,1.4-0.1,2-0.1
         l0,0c3.1,0,4.6,0.5,4.9,2.1l0,0c-0.1,1.3-1,1.8-1.2,2l0,0c-4.6,2.6-5.5,6.7-5.6,10.1l0,0c0,2.7,0.7,4.8,0.7,4.8l0,0l-1.8,0.6
         C106.7,343.8,105.9,341.5,105.9,338.5L105.9,338.5z" />
      </g>

      <g>
        <path id="m" class="st0" d="M21.2,467.5c0.1-0.6,0.3-0.8,0.6-0.8c0.2,0,0.4,0.2,0.9,1l8.9,18.3l8.9-18.5c0.3-0.5,0.4-0.8,0.7-0.8
         c0.3,0,0.5,0.3,0.6,1l3,20.1c0.3,2,0.6,3,1.6,3.3c0.9,0.3,1.6,0.3,2,0.3c0.3,0,0.5,0,0.5,0.2c0,0.3-0.4,0.4-0.9,0.4
         c-0.8,0-5.3-0.1-6.6-0.2c-0.7-0.1-0.9-0.2-0.9-0.3c0-0.2,0.1-0.3,0.4-0.4c0.2-0.1,0.3-0.5,0.2-1.4l-2-14.3h-0.1l-7.1,14.7
         c-0.7,1.5-0.9,1.8-1.2,1.8c-0.3,0-0.6-0.7-1.1-1.6c-0.8-1.5-3.4-6.5-3.7-7.4c-0.3-0.7-2.2-4.8-3.4-7.2h-0.1l-1.7,12.9
         c-0.1,0.6-0.1,1-0.1,1.5c0,0.6,0.4,0.9,1,1.1c0.6,0.1,1.1,0.2,1.4,0.2c0.3,0,0.5,0,0.5,0.2c0,0.3-0.3,0.4-0.8,0.4
         c-1.4,0-2.8-0.1-3.4-0.1c-0.6,0-2.1,0.1-3.2,0.1c-0.3,0-0.6-0.1-0.6-0.4c0-0.2,0.2-0.2,0.5-0.2c0.3,0,0.5,0,1-0.1
         c0.9-0.2,1.2-1.5,1.3-2.6L21.2,467.5z" />
        <path class="st0"
          d="M53.1,483.5c-0.2,0-0.2,0.1-0.3,0.3l-1.7,4.6c-0.3,0.8-0.5,1.6-0.5,2c0,0.6,0.3,1,1.3,1h0.5
         c0.4,0,0.5,0,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1,0-2.4-0.1-3.4-0.1c-0.3,0-2.1,0.1-3.8,0.1c-0.4,0-0.6-0.1-0.6-0.3
         c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.8,0,1-0.1c1.5-0.2,2.1-1.3,2.8-2.9l8.2-20.4c0.4-0.9,0.6-1.3,0.9-1.3c0.3,0,0.5,0.3,0.8,1
         c0.8,1.8,6,15,8.1,20c1.2,2.9,2.2,3.4,2.8,3.5c0.5,0.1,1,0.1,1.4,0.1c0.3,0,0.4,0,0.4,0.3c0,0.3-0.3,0.3-1.5,0.3
         c-1.2,0-3.5,0-6.1-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.4-0.4,0.3-0.8l-2.6-6.9c-0.1-0.2-0.1-0.2-0.3-0.2H53.1z
          M59.7,481.9c0.2,0,0.2-0.1,0.2-0.2l-2.9-8c0-0.1-0.1-0.3-0.1-0.3c-0.1,0-0.2,0.2-0.2,0.3l-3,8c0,0.1,0,0.2,0.1,0.2H59.7z" />
        <path class="st0" d="M70,491.6c-0.5-0.2-0.6-0.4-0.6-1.1c0-1.8,0.1-3.7,0.2-4.3c0-0.5,0.1-0.8,0.4-0.8c0.3,0,0.3,0.3,0.3,0.6
         c0,0.4,0.1,1.1,0.3,1.7c0.7,2.4,2.6,3.3,4.6,3.3c2.9,0,4.4-2,4.4-3.7c0-1.6-0.5-3.1-3.2-5.2l-1.5-1.2c-3.6-2.8-4.8-5.1-4.8-7.7
         c0-3.6,3-6.1,7.5-6.1c2.1,0,3.5,0.3,4.3,0.5c0.3,0.1,0.5,0.2,0.5,0.4c0,0.4-0.1,1.4-0.1,3.9c0,0.7-0.1,1-0.4,1
         c-0.2,0-0.3-0.2-0.3-0.6c0-0.3-0.2-1.3-0.8-2.1c-0.5-0.6-1.4-1.6-3.6-1.6c-2.4,0-3.9,1.4-3.9,3.4c0,1.5,0.8,2.6,3.4,4.7l0.9,0.7
         c3.9,3,5.3,5.2,5.3,8.3c0,1.9-0.7,4.1-3,5.7c-1.6,1-3.4,1.3-5.2,1.3C72.8,492.4,71.4,492.2,70,491.6" />
        <path class="st0"
          d="M92.6,469.2l-4.8,0.1c-1.9,0.1-2.7,0.2-3.2,0.9c-0.3,0.5-0.5,0.9-0.5,1.1c-0.1,0.3-0.2,0.4-0.4,0.4
         c-0.2,0-0.3-0.2-0.3-0.5c0-0.5,0.6-3.5,0.7-3.7c0.1-0.4,0.2-0.6,0.4-0.6c0.2,0,0.6,0.3,1.4,0.4c1,0.1,2.2,0.2,3.2,0.2h12.9
         c1.1,0,1.8-0.1,2.2-0.2c0.5-0.1,0.8-0.2,0.9-0.2c0.2,0,0.3,0.2,0.3,0.7c0,0.7-0.1,2.9-0.1,3.8c0,0.3-0.1,0.5-0.3,0.5
         c-0.3,0-0.3-0.2-0.3-0.6l0-0.4c-0.1-0.8-1-1.8-3.9-1.8l-4.1-0.1v13.3c0,3,0,5.6,0.2,7c0.1,0.9,0.3,1.6,1.3,1.7
         c0.5,0.1,1.2,0.1,1.7,0.1c0.4,0,0.5,0.1,0.5,0.3c0,0.2-0.2,0.3-0.6,0.3c-2.2,0-4.2-0.1-5.1-0.1c-0.7,0-2.8,0.1-4.1,0.1
         c-0.4,0-0.6-0.1-0.6-0.3c0-0.2,0.1-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,0.8-0.8,0.9-1.8c0.2-1.4,0.2-3.9,0.2-6.9V469.2z" />
        <path class="st0" d="M108.9,476.8c0-4.9,0-5.8-0.1-6.9c-0.1-1.1-0.3-1.6-1.4-1.8c-0.3-0.1-0.8-0.1-1.3-0.1c-0.4,0-0.5-0.1-0.5-0.3
         c0-0.2,0.2-0.3,0.7-0.3c0.9,0,1.8,0,2.6,0c0.8,0,1.6,0.1,2.1,0.1c1,0,7.5,0,8.1,0c0.6-0.1,1.1-0.1,1.4-0.2c0.2,0,0.4-0.1,0.5-0.1
         c0.2,0,0.2,0.1,0.2,0.3c0,0.2-0.2,0.6-0.3,2.1c0,0.3-0.1,1.8-0.2,2.2c0,0.2-0.1,0.4-0.3,0.4c-0.2,0-0.3-0.2-0.3-0.5
         c0-0.2,0-0.8-0.2-1.2c-0.2-0.6-0.5-1-2.2-1.2c-0.5-0.1-4-0.1-4.6-0.1c-0.2,0-0.2,0.1-0.2,0.3v8.2c0,0.2,0,0.4,0.2,0.4
         c0.7,0,4.5,0,5.2-0.1c0.8-0.1,1.3-0.2,1.6-0.5c0.2-0.3,0.4-0.4,0.5-0.4c0.2,0,0.2,0.1,0.2,0.3c0,0.2-0.1,0.7-0.3,2.4
         c0,0.6-0.1,2-0.1,2.2c0,0.3,0,0.6-0.3,0.6c-0.2,0-0.3-0.1-0.3-0.3c0-0.3,0-0.8-0.1-1.2c-0.2-0.6-0.6-1.1-1.8-1.3
         c-0.6-0.1-3.8-0.1-4.6-0.1c-0.2,0-0.2,0.1-0.2,0.3v2.6c0,1.1,0,4.1,0,5c0.1,2.2,0.6,2.7,3.9,2.7c0.8,0,2.2,0,3-0.4
         c0.9-0.4,1.2-1.1,1.5-2.4c0.1-0.4,0.1-0.5,0.3-0.5c0.3,0,0.3,0.3,0.3,0.6c0,0.7-0.3,3-0.4,3.6c-0.2,0.8-0.5,0.8-1.8,0.8
         c-2.4,0-4.2-0.1-5.6-0.1c-1.4-0.1-2.4-0.1-3.4-0.1c-0.4,0-1.1,0-1.9,0c-0.7,0-1.6,0.1-2.2,0.1c-0.4,0-0.7-0.1-0.7-0.3
         c0-0.2,0.1-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.6-0.1,0.8-0.8,0.9-1.8c0.2-1.4,0.2-3.9,0.2-6.9V476.8z" />
        <path class="st0" d="M127,476.8c0-4.9,0-5.8-0.1-6.9c-0.1-1.1-0.3-1.6-1.4-1.8c-0.2-0.1-0.8-0.1-1.3-0.1c-0.4,0-0.6-0.1-0.6-0.3
         c0-0.2,0.2-0.3,0.7-0.3c1.7,0,3.8,0.1,4.4,0.1c1.1,0,3.6-0.1,4.6-0.1c2,0,4.2,0.2,6,1.4c0.9,0.6,2.2,2.3,2.2,4.5
         c0,2.4-1,4.6-4.3,7.3c2.9,3.6,5.1,6.5,7.1,8.5c1.8,1.9,3.2,2.1,3.6,2.2c0.4,0.1,0.7,0.1,0.9,0.1c0.2,0,0.4,0.1,0.4,0.3
         c0,0.3-0.2,0.3-0.6,0.3h-3.1c-1.8,0-2.6-0.2-3.5-0.6c-1.4-0.8-2.6-2.3-4.5-4.8c-1.3-1.8-2.8-4-3.2-4.5c-0.2-0.2-0.3-0.2-0.6-0.2
         l-2.8-0.1c-0.2,0-0.3,0.1-0.3,0.3v0.4c0,3,0,5.6,0.2,7c0.1,0.9,0.3,1.7,1.3,1.8c0.5,0.1,1.2,0.1,1.6,0.1c0.2,0,0.4,0.1,0.4,0.3
         c0,0.2-0.2,0.3-0.6,0.3c-1.9,0-4.3-0.1-4.8-0.1c-0.6,0-2.7,0.1-4,0.1c-0.4,0-0.6-0.1-0.6-0.3c0-0.2,0.1-0.3,0.5-0.3
         c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,0.8-0.8,0.9-1.8c0.2-1.4,0.2-3.9,0.2-6.9V476.8z M130.8,479.4c0,0.3,0.1,0.5,0.3,0.6
         c0.7,0.2,1.7,0.3,2.5,0.3c1.3,0,1.7-0.1,2.3-0.5c1-0.7,1.9-2.2,1.9-4.9c0-4.6-3.1-5.9-5-5.9c-0.8,0-1.4,0-1.7,0.1
         c-0.2,0.1-0.3,0.2-0.3,0.5V479.4z" />
        <path class="st0" d="M152.1,483.5c0-1.8-0.3-2.5-0.8-3.4c-0.2-0.5-4.6-7.7-5.7-9.4c-0.8-1.2-1.6-2-2.2-2.4c-0.4-0.2-1-0.4-1.4-0.4
         c-0.3,0-0.5-0.1-0.5-0.3c0-0.2,0.2-0.3,0.6-0.3c0.6,0,3,0.1,4.1,0.1c0.7,0,2-0.1,3.5-0.1c0.3,0,0.5,0.1,0.5,0.3
         c0,0.2-0.2,0.2-0.7,0.4c-0.3,0.1-0.5,0.3-0.5,0.6c0,0.3,0.2,0.6,0.4,1.1c0.5,1,5,8.8,5.5,9.7c0.3-0.8,4.4-7.9,4.9-8.9
         c0.3-0.7,0.5-1.3,0.5-1.7c0-0.3-0.1-0.7-0.7-0.8c-0.3-0.1-0.6-0.1-0.6-0.3c0-0.2,0.2-0.3,0.5-0.3c1.1,0,2.1,0.1,3.1,0.1
         c0.7,0,2.9-0.1,3.5-0.1c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.3,0.3-0.6,0.3c-0.3,0-0.9,0.1-1.4,0.4c-0.7,0.3-1,0.7-1.8,1.6
         c-1.1,1.3-5.6,8.9-6.2,10.3c-0.5,1.2-0.5,2.2-0.5,3.3v2.7c0,0.5,0,1.9,0.1,3.2c0,0.9,0.3,1.7,1.3,1.8c0.5,0.1,1.2,0.1,1.6,0.1
         c0.4,0,0.5,0.1,0.5,0.3c0,0.2-0.3,0.3-0.7,0.3c-2,0-4.1-0.1-5-0.1c-0.8,0-2.8,0.1-4.1,0.1c-0.5,0-0.7-0.1-0.7-0.3
         c0-0.2,0.2-0.3,0.5-0.3c0.4,0,0.8-0.1,1.1-0.1c0.7-0.1,0.9-0.8,1-1.8c0.1-1.4,0.1-2.7,0.1-3.2V483.5z" />
        <path id="a" class="st0"
          d="M176.3,483.5c-0.2,0-0.2,0.1-0.3,0.3l-1.8,4.6c-0.3,0.8-0.5,1.6-0.5,2c0,0.6,0.3,1,1.3,1h0.5
         c0.4,0,0.5,0,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1,0-2.4-0.1-3.4-0.1c-0.4,0-2.1,0.1-3.8,0.1c-0.4,0-0.6-0.1-0.6-0.3
         c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.7,0,1-0.1c1.5-0.2,2.1-1.3,2.8-2.9L180,468c0.4-0.9,0.6-1.3,0.9-1.3c0.3,0,0.5,0.3,0.8,1
         c0.8,1.8,6,15,8.1,20c1.2,2.9,2.1,3.4,2.9,3.5c0.5,0.1,1,0.1,1.4,0.1c0.3,0,0.4,0,0.4,0.3c0,0.3-0.3,0.3-1.5,0.3
         c-1.2,0-3.6,0-6.1-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.4-0.4,0.3-0.8l-2.6-6.9c-0.1-0.2-0.2-0.2-0.3-0.2H176.3z
          M183,481.9c0.2,0,0.2-0.1,0.2-0.2l-2.9-8c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0.2-0.2,0.3l-3,8c0,0.1,0,0.2,0.1,0.2H183z" />
        <path class="st0"
          d="M195.8,488.9c-3.2-2.8-4-6.4-4-9.6c0-2.2,0.7-6.1,3.8-8.9c2.3-2.1,5.4-3.5,10.3-3.5c2,0,3.2,0.1,4.7,0.3
         c1.2,0.2,2.3,0.5,3.2,0.6c0.4,0,0.5,0.2,0.5,0.4c0,0.3-0.1,0.6-0.2,1.8c-0.1,1.1,0,2.9-0.1,3.5c0,0.5-0.1,0.7-0.4,0.7
         c-0.3,0-0.3-0.3-0.3-0.7c0-1-0.4-2.2-1.3-3c-1.1-1.1-3.6-2-6.6-2c-2.8,0-4.7,0.7-6.1,2c-2.4,2.2-3,5.3-3,8.4
         c0,7.7,5.9,11.6,10.2,11.6c2.9,0,4.6-0.3,5.9-1.8c0.5-0.6,1-1.5,1.1-2.1c0.1-0.5,0.1-0.7,0.4-0.7c0.2,0,0.3,0.2,0.3,0.5
         c0,0.4-0.4,3.2-0.7,4.3c-0.2,0.6-0.3,0.7-0.8,0.9c-1.3,0.5-3.8,0.7-5.9,0.7C202.5,492.4,198.8,491.4,195.8,488.9" />
        <path class="st0"
          d="M221.2,483.5c-0.2,0-0.2,0.1-0.3,0.3l-1.8,4.6c-0.3,0.8-0.5,1.6-0.5,2c0,0.6,0.3,1,1.3,1h0.5
         c0.4,0,0.5,0,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1,0-2.4-0.1-3.4-0.1c-0.4,0-2.1,0.1-3.8,0.1c-0.4,0-0.6-0.1-0.6-0.3
         c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.7,0,1-0.1c1.5-0.2,2.1-1.3,2.8-2.9l8.2-20.4c0.4-0.9,0.6-1.3,0.9-1.3c0.3,0,0.5,0.3,0.8,1
         c0.8,1.8,6,15,8,20c1.3,2.9,2.2,3.4,2.9,3.5c0.5,0.1,1,0.1,1.4,0.1c0.2,0,0.4,0,0.4,0.3c0,0.3-0.3,0.3-1.5,0.3
         c-1.2,0-3.6,0-6.2-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.5-0.4,0.3-0.8l-2.6-6.9c-0.1-0.2-0.2-0.2-0.3-0.2H221.2z
          M227.8,481.9c0.2,0,0.2-0.1,0.2-0.2l-2.9-8c0-0.1,0-0.3-0.1-0.3c-0.1,0-0.2,0.2-0.2,0.3l-3,8c0,0.1,0,0.2,0.1,0.2H227.8z" />
        <path class="st0" d="M240.2,476.8c0-4.9,0-5.8-0.1-6.9c-0.1-1.1-0.3-1.6-1.4-1.8c-0.3-0.1-0.8-0.1-1.3-0.1c-0.4,0-0.6-0.1-0.6-0.3
         c0-0.2,0.2-0.3,0.7-0.3c1.7,0,3.8,0.1,4.7,0.1c1,0,3.1-0.1,5-0.1c4.1,0,9.5,0,13,3.7c1.6,1.7,3.1,4.4,3.1,8.3
         c0,4.1-1.7,7.2-3.5,9.1c-1.5,1.5-4.9,3.8-10.9,3.8c-1.2,0-2.5-0.1-3.7-0.2c-1.2-0.1-2.4-0.2-3.2-0.2c-0.4,0-1.1,0-1.9,0
         c-0.7,0-1.6,0.1-2.2,0.1c-0.4,0-0.7-0.1-0.7-0.3c0-0.2,0.1-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,0.8-0.8,0.9-1.8
         c0.2-1.4,0.2-3.9,0.2-6.9V476.8z M244.3,480.4c0,3.4,0,5.9,0.1,6.6c0,0.8,0.1,2.1,0.4,2.4c0.4,0.6,1.7,1.3,4.3,1.3
         c3.3,0,5.5-0.6,7.5-2.3c2.1-1.8,2.7-4.7,2.7-8.1c0-4.1-1.7-6.8-3.1-8.1c-3-2.9-6.7-3.2-9.2-3.2c-0.7,0-1.9,0.1-2.1,0.2
         c-0.3,0.1-0.4,0.3-0.4,0.7c0,1.1,0,3.9,0,6.5V480.4z" />
        <path class="st0" d="M267.8,476.8c0-4.9,0-5.8-0.1-6.9c-0.1-1.1-0.3-1.6-1.4-1.8c-0.3-0.1-0.8-0.1-1.3-0.1c-0.4,0-0.6-0.1-0.6-0.3
         c0-0.2,0.2-0.3,0.7-0.3c0.9,0,1.8,0,2.6,0c0.9,0,1.6,0.1,2.1,0.1c1.1,0,7.5,0,8.1,0c0.6-0.1,1.1-0.1,1.4-0.2c0.2,0,0.4-0.1,0.5-0.1
         c0.2,0,0.2,0.1,0.2,0.3c0,0.2-0.2,0.6-0.3,2.1c0,0.3-0.1,1.8-0.2,2.2c0,0.2-0.1,0.4-0.3,0.4c-0.2,0-0.3-0.2-0.3-0.5
         c0-0.2,0-0.8-0.2-1.2c-0.2-0.6-0.5-1-2.1-1.2c-0.6-0.1-4-0.1-4.6-0.1c-0.2,0-0.2,0.1-0.2,0.3v8.2c0,0.2,0,0.4,0.2,0.4
         c0.7,0,4.4,0,5.2-0.1c0.8-0.1,1.3-0.2,1.6-0.5c0.2-0.3,0.4-0.4,0.5-0.4c0.1,0,0.2,0.1,0.2,0.3c0,0.2-0.1,0.7-0.3,2.4
         c0,0.6-0.1,2-0.1,2.2c0,0.3,0,0.6-0.3,0.6c-0.2,0-0.3-0.1-0.3-0.3c0-0.3,0-0.8-0.1-1.2c-0.2-0.6-0.6-1.1-1.8-1.3
         c-0.6-0.1-3.8-0.1-4.6-0.1c-0.2,0-0.2,0.1-0.2,0.3v2.6c0,1.1,0,4.1,0,5c0.1,2.2,0.6,2.7,3.9,2.7c0.8,0,2.2,0,3.1-0.4
         c0.8-0.4,1.2-1.1,1.5-2.4c0.1-0.4,0.1-0.5,0.4-0.5c0.3,0,0.3,0.3,0.3,0.6c0,0.7-0.3,3-0.4,3.6c-0.2,0.8-0.5,0.8-1.8,0.8
         c-2.4,0-4.2-0.1-5.6-0.1c-1.4-0.1-2.4-0.1-3.4-0.1c-0.4,0-1.1,0-1.9,0c-0.7,0-1.6,0.1-2.2,0.1c-0.4,0-0.6-0.1-0.6-0.3
         c0-0.2,0.1-0.3,0.5-0.3c0.5,0,0.9-0.1,1.2-0.1c0.7-0.1,0.8-0.8,1-1.8c0.2-1.4,0.2-3.9,0.2-6.9V476.8z" />
        <path class="st0" d="M287.1,467.5c0.1-0.6,0.3-0.8,0.6-0.8c0.3,0,0.5,0.2,0.9,1l8.9,18.3l8.8-18.5c0.3-0.5,0.4-0.8,0.7-0.8
         c0.3,0,0.5,0.3,0.6,1l3,20.1c0.3,2,0.6,3,1.6,3.3c1,0.3,1.6,0.3,2,0.3c0.3,0,0.5,0,0.5,0.2c0,0.3-0.4,0.4-0.8,0.4
         c-0.8,0-5.3-0.1-6.6-0.2c-0.7-0.1-0.9-0.2-0.9-0.3c0-0.2,0.1-0.3,0.4-0.4c0.2-0.1,0.3-0.5,0.2-1.4l-1.9-14.3h-0.1l-7.1,14.7
         c-0.8,1.5-0.9,1.8-1.2,1.8c-0.3,0-0.6-0.7-1.1-1.6c-0.8-1.5-3.3-6.5-3.7-7.4c-0.3-0.7-2.2-4.8-3.4-7.2h-0.1l-1.7,12.9
         c-0.1,0.6-0.1,1-0.1,1.5c0,0.6,0.4,0.9,1,1.1c0.6,0.1,1,0.2,1.4,0.2c0.2,0,0.5,0,0.5,0.2c0,0.3-0.3,0.4-0.8,0.4
         c-1.4,0-2.8-0.1-3.4-0.1c-0.6,0-2.2,0.1-3.2,0.1c-0.3,0-0.6-0.1-0.6-0.4c0-0.2,0.2-0.2,0.5-0.2c0.3,0,0.5,0,1-0.1
         c0.9-0.2,1.2-1.5,1.3-2.6L287.1,467.5z" />
        <path class="st0" d="M320.1,483.5c0-1.8-0.3-2.5-0.7-3.4c-0.2-0.5-4.6-7.7-5.7-9.4c-0.8-1.2-1.6-2-2.2-2.4c-0.4-0.2-1-0.4-1.4-0.4
         c-0.3,0-0.5-0.1-0.5-0.3c0-0.2,0.2-0.3,0.6-0.3c0.6,0,3,0.1,4.1,0.1c0.7,0,2-0.1,3.5-0.1c0.3,0,0.5,0.1,0.5,0.3
         c0,0.2-0.2,0.2-0.7,0.4c-0.3,0.1-0.5,0.3-0.5,0.6c0,0.3,0.2,0.6,0.4,1.1c0.4,1,5,8.8,5.5,9.7c0.4-0.8,4.4-7.9,4.9-8.9
         c0.3-0.7,0.5-1.3,0.5-1.7c0-0.3-0.1-0.7-0.7-0.8c-0.3-0.1-0.6-0.1-0.6-0.3c0-0.2,0.2-0.3,0.5-0.3c1.1,0,2.1,0.1,3,0.1
         c0.7,0,2.9-0.1,3.5-0.1c0.4,0,0.6,0.1,0.6,0.3c0,0.2-0.3,0.3-0.6,0.3c-0.3,0-0.9,0.1-1.4,0.4c-0.7,0.3-1.1,0.7-1.8,1.6
         c-1.1,1.3-5.6,8.9-6.2,10.3c-0.5,1.2-0.5,2.2-0.5,3.3v2.7c0,0.5,0,1.9,0.1,3.2c0.1,0.9,0.4,1.7,1.3,1.8c0.4,0.1,1.2,0.1,1.6,0.1
         c0.4,0,0.5,0.1,0.5,0.3c0,0.2-0.3,0.3-0.7,0.3c-2,0-4.1-0.1-5-0.1c-0.8,0-2.9,0.1-4.1,0.1c-0.4,0-0.7-0.1-0.7-0.3
         c0-0.2,0.2-0.3,0.5-0.3c0.5,0,0.9-0.1,1.1-0.1c0.6-0.1,0.9-0.8,1-1.8c0.1-1.4,0.1-2.7,0.1-3.2V483.5z" />
      </g>
      <g>
        <path class="st0"
          d="M23.7,513.2c0,2.1,0,3.9,0.1,4.8c0.1,0.6,0.2,1.1,0.9,1.2c0.3,0.1,0.8,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.2
         c0,0.1-0.1,0.2-0.4,0.2c-1.2,0-2.7-0.1-2.9-0.1c-0.1,0-1.7,0.1-2.4,0.1c-0.3,0-0.4,0-0.4-0.2c0-0.1,0-0.2,0.2-0.2
         c0.2,0,0.5,0,0.7-0.1c0.5-0.1,0.6-0.5,0.7-1.2c0.1-0.9,0.1-2.7,0.1-4.8v-9.3l-3.3,0.1c-1.4,0-2,0.2-2.3,0.7
         c-0.3,0.4-0.3,0.5-0.4,0.7c-0.1,0.2-0.1,0.2-0.2,0.2c0,0-0.1-0.1-0.1-0.2c0-0.2,0.5-2.2,0.5-2.4c0.1-0.1,0.2-0.4,0.3-0.4
         c0.2,0,0.4,0.2,1,0.3c0.6,0.1,1.5,0.1,1.8,0.1h8.7c0.7,0,1.3-0.1,1.6-0.1c0.3-0.1,0.5-0.1,0.7-0.1c0.1,0,0.1,0.1,0.1,0.3
         c0,0.7-0.1,2.3-0.1,2.5c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.1-0.1-0.2-0.4l0-0.2c-0.1-0.7-0.6-1.2-2.8-1.2l-2.9-0.1V513.2z" />
        <path class="st0" d="M32.6,509.2c0-3.4,0-4.1,0-4.8c-0.1-0.8-0.2-1.1-1-1.2c-0.2,0-0.5-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.2-0.2
         c0-0.1,0.1-0.2,0.4-0.2c1.1,0,2.6,0.1,2.7,0.1c0.2,0,2.2-0.1,2.9-0.1c1.5,0,3.1,0.1,4.3,1c0.6,0.4,1.6,1.5,1.6,3
         c0,1.7-0.7,3.2-2.8,5.1c1.9,2.4,3.4,4.4,4.8,5.8c1.2,1.3,2,1.5,2.6,1.5c0.4,0.1,0.7,0.1,0.8,0.1c0.1,0,0.2,0.1,0.2,0.2
         c0,0.1-0.1,0.2-0.5,0.2h-1.4c-1.3,0-1.9-0.1-2.5-0.4c-1-0.5-1.8-1.6-3.1-3.3c-1-1.2-2-2.6-2.5-3.2c-0.1-0.1-0.2-0.1-0.3-0.1
         l-2.9-0.1c-0.1,0-0.2,0.1-0.2,0.2v0.5c0,2.2,0,3.9,0.1,4.8c0.1,0.6,0.2,1.1,0.9,1.2c0.3,0.1,0.8,0.1,1,0.1c0.1,0,0.2,0.1,0.2,0.2
         c0,0.1-0.1,0.2-0.4,0.2c-1.3,0-2.7-0.1-2.8-0.1c0,0-1.6,0.1-2.3,0.1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2
         c0.2,0,0.5-0.1,0.7-0.1c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8V509.2z M34.6,511.1c0,0.1,0.1,0.2,0.2,0.3
         c0.4,0.2,1.5,0.3,2.6,0.3c0.5,0,1.2,0,1.7-0.4c0.8-0.5,1.4-1.7,1.4-3.4c0-2.8-1.5-4.4-4-4.4c-0.7,0-1.4,0.1-1.7,0.1
         c-0.1,0-0.2,0.1-0.2,0.3V511.1z" />
        <path class="st0" d="M51.9,503.2c0.3-0.7,0.4-0.8,0.5-0.8c0.2,0,0.3,0.3,0.5,0.8c0.4,0.9,4.1,10.1,5.5,13.5c0.8,2,1.5,2.3,2,2.4
         c0.3,0.1,0.7,0.1,0.9,0.1c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.3,0.2-0.5,0.2c-0.3,0-2,0-3.6,0c-0.5,0-0.8,0-0.8-0.1c0-0.1,0-0.1,0.2-0.2
         c0.1,0,0.4-0.2,0.2-0.6l-2.1-5.1c0-0.1-0.1-0.1-0.2-0.1h-5.3c-0.1,0-0.2,0.1-0.3,0.2l-1.3,3.6c-0.2,0.5-0.3,1.1-0.3,1.5
         c0,0.5,0.4,0.6,0.8,0.6h0.2c0.2,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.5,0-1.8-0.1-2.1-0.1c-0.3,0-1.4,0.1-2.3,0.1
         c-0.3,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5,0,0.7,0c1-0.1,1.5-0.9,1.9-2.1L51.9,503.2z M54.4,512.5
         c0.1,0,0.1-0.1,0.1-0.1l-2.3-6.1c-0.1-0.3-0.2-0.3-0.4,0l-2.1,6.1c0,0.1,0,0.1,0.1,0.1H54.4z" />
        <path class="st0"
          d="M64.8,513.2c0,2.1,0,3.9,0.1,4.8c0.1,0.6,0.2,1.1,0.8,1.2c0.3,0.1,0.9,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.2
         c0,0.1-0.1,0.2-0.4,0.2c-1.3,0-2.8-0.1-2.9-0.1c-0.1,0-1.6,0.1-2.3,0.1c-0.3,0-0.4,0-0.4-0.2c0-0.1,0-0.2,0.2-0.2
         c0.2,0,0.5,0,0.7-0.1c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8v-3.9c0-3.5,0-4.1,0-4.8c0-0.8-0.3-1.1-0.7-1.2
         c-0.3,0-0.6-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.4-0.2c0.8,0,2.2,0.1,2.3,0.1c0.1,0,1.6-0.1,2.3-0.1
         c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3,0-0.6,0.1c-0.6,0.1-0.8,0.4-0.8,1.3c0,0.7,0,1.3,0,4.8V513.2z" />
        <path class="st0" d="M71.4,516.6c0,1.8,0.3,2.4,0.9,2.5c0.4,0.1,1,0.1,1.2,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.4,0.2
         c-1.3,0-2.2-0.1-2.5-0.1c-0.2,0-1.2,0.1-2.2,0.1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.6,0,0.9-0.1
         c0.6-0.2,0.7-0.8,0.7-2.8v-12.7c0-0.9,0.1-1.1,0.3-1.1c0.3,0,0.7,0.5,0.9,0.7c0.4,0.4,3.7,3.9,7.3,7.6c2.3,2.3,4.7,5,5.4,5.7
         l-0.2-11.1c0-1.5-0.2-1.9-0.9-2.1c-0.4-0.1-0.9-0.1-1.1-0.1s-0.2-0.1-0.2-0.2c0-0.1,0.2-0.2,0.4-0.2c1.1,0,2.2,0.1,2.4,0.1
         c0.3,0,1-0.1,2-0.1c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.3,0-0.6,0.1c-0.8,0.2-0.8,0.6-0.8,1.9l0,13
         c0,1.5,0,1.6-0.2,1.6c-0.2,0-0.4-0.2-1.7-1.3c-0.2-0.2-3.6-3.5-6-6c-2.7-2.8-5.3-5.5-6-6.3L71.4,516.6z" />
        <path class="st0"
          d="M91.8,513.2c0,2.1,0,3.9,0.1,4.8c0.1,0.6,0.2,1.1,0.8,1.2c0.3,0.1,0.8,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.2
         c0,0.1-0.1,0.2-0.4,0.2c-1.3,0-2.7-0.1-2.9-0.1c-0.1,0-1.6,0.1-2.3,0.1c-0.2,0-0.3,0-0.3-0.2c0-0.1,0-0.2,0.1-0.2
         c0.2,0,0.5,0,0.7-0.1c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8v-3.9c0-3.5,0-4.1-0.1-4.8c0-0.8-0.2-1.1-0.7-1.2
         c-0.3,0-0.6-0.1-0.7-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.4-0.2c0.8,0,2.2,0.1,2.3,0.1c0.1,0,1.6-0.1,2.3-0.1
         c0.3,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.3,0-0.6,0.1c-0.6,0.1-0.8,0.4-0.8,1.3c0,0.7,0,1.3,0,4.8V513.2z" />
        <path class="st0" d="M98.4,516.6c0.1,1.8,0.3,2.4,0.9,2.5c0.4,0.1,0.9,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.4,0.2
         c-1.3,0-2.2-0.1-2.4-0.1c-0.2,0-1.2,0.1-2.2,0.1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.6,0,1-0.1
         c0.6-0.2,0.7-0.8,0.7-2.8v-12.7c0-0.9,0.1-1.1,0.3-1.1c0.3,0,0.7,0.5,0.9,0.7c0.4,0.4,3.7,3.9,7.3,7.6c2.3,2.3,4.7,5,5.4,5.7
         l-0.2-11.1c0-1.5-0.2-1.9-0.9-2.1c-0.4-0.1-0.9-0.1-1.1-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.2-0.2,0.4-0.2c1.1,0,2.2,0.1,2.4,0.1
         c0.3,0,1-0.1,2-0.1c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.3,0-0.5,0.1c-0.8,0.2-0.8,0.6-0.8,1.9l0,13
         c0,1.5,0,1.6-0.2,1.6c-0.2,0-0.5-0.2-1.7-1.3c-0.3-0.2-3.5-3.5-6-6c-2.7-2.8-5.2-5.5-6-6.3L98.4,516.6z" />
        <path class="st0" d="M127.7,513.9c0-1.8,0-2.1-1-2.2c-0.2,0-0.6-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.2,0.4-0.2
         c1,0,2.6,0,2.7,0c0.1,0,1.6,0,2.3,0c0.3,0,0.4,0,0.4,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.3,0-0.6,0.1c-0.6,0.1-0.8,0.4-0.9,1.2
         c0,0.7,0,1.4,0,2.3v2.5c0,1,0,1.1-0.3,1.2c-1.5,0.8-3.6,1-4.9,1c-1.8,0-5-0.2-7.6-2.4c-1.4-1.2-2.8-3.5-2.8-6.3
         c0-3.6,1.8-6.1,3.8-7.3c2.1-1.2,4.4-1.4,6.1-1.4c1.5,0,3.1,0.3,3.5,0.4c0.5,0.1,1.3,0.2,1.9,0.2c0.2,0,0.3,0.1,0.3,0.2
         c0,0.3-0.1,0.9-0.1,3.1c0,0.4-0.1,0.5-0.3,0.5c-0.1,0-0.1-0.1-0.1-0.4c0-0.3-0.2-0.9-0.5-1.5c-0.5-0.8-2.3-1.7-5.3-1.7
         c-1.4,0-3.1,0.1-4.8,1.4c-1.3,1-2.3,3-2.3,5.6c0,3.1,1.6,5.4,2.4,6.1c1.8,1.7,3.8,2.3,5.9,2.3c0.8,0,1.9-0.1,2.5-0.4
         c0.3-0.2,0.4-0.3,0.4-0.7V513.9z" />
        <path class="st0" d="M138.6,514.2c0-3.1,2-5.1,4-6.3c-1-1.4-1.3-2.4-1.3-3.6c0-2.1,2.1-4.8,5.6-4.8c1.3,0,2.5,0.3,3.2,0.7
         c0.3,0.2,0.3,0.3,0.3,0.7c0,0.8-0.1,2.6-0.2,3c-0.1,0.4-0.2,0.4-0.3,0.4c-0.1,0-0.1-0.1-0.1-0.5c0-0.7-0.2-1.5-0.8-2.2
         c-0.6-0.6-1.5-1.1-2.6-1.1c-1.6,0-3.3,0.9-3.3,3.4c0,2,1.4,3.7,4.1,6.7c0.9,1,4.1,4,4.9,4.7c0.7-1,1.3-2.7,1.3-4.3
         c0-0.4-0.1-0.9-0.5-1.3c-0.4-0.3-0.9-0.4-1.3-0.4c-0.1,0-0.3-0.1-0.3-0.2c0-0.1,0.2-0.2,0.4-0.2c0.6,0,1.7,0,3.2,0.2
         c0.4,0.1,0.5,0.3,0.5,0.5c0,2.1-1.5,4.8-2.6,6.2c1.7,1.5,2.9,2.2,3.6,2.6c0.9,0.5,2.2,1,3.1,1c0.2,0,0.4,0,0.4,0.2
         c0,0.1-0.2,0.2-0.4,0.2h-2.6c-1.9,0-2.5-0.2-5.4-2.4c-0.6,0.6-2.7,2.8-6.6,2.8C140.3,520,138.6,516.5,138.6,514.2 M150.8,516.7
         c-1.5-1.2-4.1-3.7-4.7-4.4c-0.7-0.7-2.4-2.6-3.1-3.7c-1.4,0.9-2.4,2.3-2.4,4.4c0,3.3,2.3,5.6,5.2,5.6
         C148.3,518.6,149.9,517.6,150.8,516.7" />
        <path class="st0" d="M166.2,509.2c0-3.5,0-4.1-0.1-4.8c0-0.8-0.2-1.1-0.9-1.2c-0.2,0-0.6-0.1-0.8-0.1c0,0-0.2-0.1-0.2-0.2
         c0-0.1,0.1-0.2,0.4-0.2c1,0,2.6,0.1,2.6,0.1c0.3,0,1.7-0.1,3.1-0.1c2.3,0,6.5-0.2,9.2,2.5c1.2,1.2,2.3,3,2.3,5.7
         c0,2.8-1.2,5-2.5,6.2c-1,1-3,2.6-7,2.6c-1,0-2.2-0.1-3.2-0.1c-1-0.1-1.8-0.1-1.9-0.1c0,0-0.5,0-0.9,0c-0.5,0-1,0-1.4,0
         c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5,0,0.7-0.1c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8V509.2z
          M168.3,511.7c0,2.3,0,4.4,0,4.8c0,0.6,0.1,1.4,0.3,1.6c0.3,0.4,1.1,0.9,3.8,0.9c2.1,0,4-0.7,5.4-2c1.2-1.1,1.8-3.2,1.8-5.3
         c0-2.8-1.2-4.6-2.2-5.6c-2.2-2.2-4.7-2.5-7.4-2.5c-0.4,0-1.2,0.1-1.4,0.1c-0.2,0.1-0.3,0.2-0.3,0.5V511.7z" />
        <path class="st0" d="M185.2,509.2c0-3.5,0-4.1-0.1-4.8c0-0.8-0.2-1.1-0.9-1.3c-0.2,0-0.6-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.2-0.2
         c0-0.1,0.1-0.2,0.4-0.2c1.1,0,2.5,0.1,2.7,0.1c0.2,0,5,0,5.5,0c0.4,0,0.9-0.1,1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.1,0.2
         c0,0.1-0.1,0.4-0.2,1c0,0.2-0.1,1.2-0.1,1.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.5-0.1-0.8
         c-0.1-0.4-0.4-0.5-1.6-0.7c-0.4-0.1-3.1-0.1-3.3-0.1c-0.1,0-0.1,0.1-0.1,0.2v6c0,0.2,0,0.2,0.1,0.2c0.3,0,3.3,0,3.8-0.1
         c0.5-0.1,0.9-0.1,1.1-0.3c0.2-0.1,0.3-0.3,0.3-0.3c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.5-0.2,1.2c-0.1,0.4-0.1,1.3-0.1,1.4
         c0,0.2-0.1,0.4-0.2,0.4c-0.1,0-0.1-0.1-0.1-0.2c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.3-0.6-1.2-0.7c-0.6-0.1-3.1-0.1-3.5-0.1
         c-0.1,0-0.2,0.1-0.2,0.2v1.9c0,0.8,0,3.3,0,3.8c0.1,1.5,0.5,1.8,2.8,1.8c0.6,0,1.7,0,2.3-0.2c0.6-0.3,0.9-0.7,1.1-1.5
         c0.1-0.3,0.1-0.3,0.2-0.3c0.1,0,0.1,0.2,0.1,0.3c0,0.1-0.2,1.7-0.3,2.1c-0.2,0.6-0.4,0.6-1.3,0.6c-1.8,0-3.1-0.1-4-0.1
         c-1-0.1-1.6-0.1-1.9-0.1c0,0-0.5,0-1,0c-0.5,0-1,0-1.3,0c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5-0.1,0.7-0.1
         c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8V509.2z" />
        <path class="st0"
          d="M203.2,516.7c1.3-3,4.3-10.6,4.8-12.2c0.1-0.3,0.2-0.7,0.2-0.8c0-0.2-0.1-0.4-0.3-0.5
         c-0.3-0.1-0.5-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.5-0.2c0.9,0,2,0.1,2.3,0.1c0.1,0,1.1-0.1,1.8-0.1
         c0.2,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.7,0-1.1,0.3c-0.3,0.2-0.7,0.5-1.3,2c-0.2,0.6-1.4,3.3-2.5,6
         c-1.4,3.2-2.4,5.7-3,6.8c-0.6,1.3-0.8,1.7-1,1.7c-0.3,0-0.4-0.3-0.8-1.3l-5.6-13.6c-0.5-1.3-0.8-1.5-1.6-1.7
         c-0.4-0.1-0.8-0.1-0.9-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.3-0.2,0.6-0.2c1.1,0,2.4,0.1,2.7,0.1c0.2,0,1.3-0.1,2.1-0.1
         c0.4,0,0.7,0.1,0.7,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.3,0.2-0.3,0.4c0,0.3,0.3,1,0.6,1.9L203.2,516.7z" />
        <path class="st0" d="M213.3,509.2c0-3.5,0-4.1-0.1-4.8c-0.1-0.8-0.2-1.1-0.9-1.3c-0.2,0-0.6-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.2-0.2
         c0-0.1,0.1-0.2,0.4-0.2c1,0,2.5,0.1,2.7,0.1c0.2,0,5,0,5.5,0c0.4,0,0.8-0.1,1.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.1,0.2
         c0,0.1-0.1,0.4-0.2,1c0,0.2-0.1,1.2-0.1,1.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.5-0.1-0.8
         c-0.1-0.4-0.4-0.5-1.7-0.7c-0.4-0.1-3-0.1-3.3-0.1c-0.1,0-0.1,0.1-0.1,0.2v6c0,0.2,0,0.2,0.1,0.2c0.3,0,3.3,0,3.8-0.1
         c0.5-0.1,0.9-0.1,1.1-0.3c0.2-0.1,0.3-0.3,0.3-0.3c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.5-0.2,1.2c0,0.4-0.1,1.3-0.1,1.4
         c0,0.2-0.1,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.3-0.6-1.2-0.7c-0.6-0.1-3.1-0.1-3.5-0.1
         c-0.1,0-0.1,0.1-0.1,0.2v1.9c0,0.8,0,3.3,0,3.8c0.1,1.5,0.5,1.8,2.8,1.8c0.6,0,1.7,0,2.3-0.2c0.6-0.3,0.9-0.7,1-1.5
         c0.1-0.3,0.1-0.3,0.2-0.3c0.1,0,0.2,0.2,0.2,0.3c0,0.1-0.2,1.7-0.3,2.1c-0.2,0.6-0.4,0.6-1.3,0.6c-1.7,0-3-0.1-4-0.1
         c-1-0.1-1.6-0.1-1.9-0.1c0,0-0.5,0-1,0c-0.4,0-1,0-1.3,0c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5-0.1,0.7-0.1
         c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8V509.2z" />
        <path class="st0" d="M227.8,513.2c0,3.3,0.1,4.6,0.5,5c0.4,0.4,1.3,0.5,3.1,0.5c1.2,0,2.2,0,2.8-0.7c0.3-0.3,0.5-0.8,0.5-1.3
         c0-0.2,0.1-0.3,0.2-0.3c0.1,0,0.2,0.1,0.2,0.4c0,0.2-0.2,1.6-0.3,2.2c-0.2,0.5-0.2,0.6-1.5,0.6c-1.7,0-2.9,0-4-0.1
         c-1.1,0-1.8-0.1-2.5-0.1c-0.1,0-0.5,0-0.9,0c-0.5,0-1,0-1.4,0c-0.2,0-0.3,0-0.3-0.2c0-0.1,0-0.2,0.2-0.2c0.2,0,0.5,0,0.7-0.1
         c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8v-3.9c0-3.5,0-4.1,0-4.8c0-0.8-0.2-1.1-1-1.2c-0.2,0-0.6-0.1-0.8-0.1
         c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.4-0.2c1,0,2.5,0.1,2.6,0.1c0.1,0,1.8-0.1,2.5-0.1c0.2,0,0.4,0.1,0.4,0.2
         c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.5,0-0.8,0c-0.7,0.1-0.8,0.5-0.8,1.3c-0.1,0.7-0.1,1.3-0.1,4.8V513.2z" />
        <path class="st0" d="M243.8,502.5c5.2,0,9.1,3.2,9.1,8.4c0,5-3.7,9.1-9.2,9.1c-6.3,0-9-4.7-9-8.7
         C234.6,507.6,237.4,502.5,243.8,502.5 M244.3,519c2.1,0,6.2-1.1,6.2-7.5c0-5.3-3.3-8.1-6.9-8.1c-3.9,0-6.6,2.4-6.6,7.2
         C237,515.8,240.1,519,244.3,519" />
        <path class="st0" d="M255.7,509.2c0-3.5,0-4.1-0.1-4.8c-0.1-0.8-0.2-1.1-0.9-1.3c-0.2,0-0.6-0.1-0.8-0.1c-0.1,0-0.1-0.1-0.1-0.2
         c0-0.1,0.1-0.2,0.4-0.2c1,0,2.5,0.1,2.7,0.1c0.5,0,2.2-0.1,3.3-0.1c3,0,4,0.9,4.4,1.3c0.5,0.5,1.1,1.4,1.1,2.7
         c0,3.2-2.4,5.3-5.9,5.3c-0.1,0-0.5,0-0.6,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.1-0.2,0.6-0.2c2.5,0,4.3-1.6,4.3-3.6
         c0-0.7-0.1-2.2-1.3-3.4c-1.2-1.2-2.6-1.1-3.1-1.1c-0.4,0-1,0.1-1.3,0.1c-0.1,0-0.1,0.2-0.1,0.4v9c0,2.1,0,3.9,0.1,4.8
         c0.1,0.6,0.2,1.1,0.9,1.2c0.3,0.1,0.8,0.1,1,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.3,0.2c-1.3,0-2.8-0.1-2.9-0.1
         c-0.1,0-1.6,0.1-2.3,0.1c-0.3,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5,0,0.7-0.1c0.4-0.1,0.5-0.5,0.6-1.2
         c0.1-0.9,0.1-2.7,0.1-4.8V509.2z" />
        <path class="st0"
          d="M286.3,516.8c0.1,0.8,0.2,2,1.2,2.3c0.6,0.2,1.2,0.2,1.5,0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.2,0.2-0.5,0.2
         c-0.5,0-3.1,0-3.9-0.1c-0.6,0-0.7-0.1-0.7-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2-0.4,0.1-0.8l-1.1-11.2h-0.1l-5.5,11.3
         c-0.5,1-0.6,1.2-0.8,1.2c-0.2,0-0.3-0.2-0.8-1c-0.6-1.1-2.1-4-2.7-5.2c-0.7-1.4-2.5-5.2-2.9-6.1h-0.1l-0.9,10c0,0.3,0,0.8,0,1.1
         c0,0.3,0.2,0.6,0.6,0.6c0.4,0.1,0.7,0.1,0.9,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.2,0.2-0.4,0.2c-0.9,0-2-0.1-2.1-0.1
         c-0.2,0-1.3,0.1-1.9,0.1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.3,0,0.7-0.1c0.8-0.1,0.8-0.9,0.9-1.8l1.7-14.5
         c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.3,0.1,0.5,0.4l6.9,13.8l6.7-13.8c0.1-0.2,0.2-0.4,0.4-0.4c0.1,0,0.3,0.2,0.3,0.6L286.3,516.8z" />
        <path class="st0" d="M291.3,509.2c0-3.5,0-4.1-0.1-4.8c-0.1-0.8-0.2-1.1-0.9-1.3c-0.2,0-0.6-0.1-0.8-0.1c-0.1,0-0.2-0.1-0.2-0.2
         c0-0.1,0.1-0.2,0.4-0.2c1.1,0,2.5,0.1,2.7,0.1c0.2,0,5,0,5.5,0c0.5,0,0.9-0.1,1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.1,0.2
         c0,0.1-0.1,0.4-0.2,1c0,0.2-0.1,1.2-0.1,1.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.3c0-0.2,0-0.5-0.1-0.8
         c-0.1-0.4-0.4-0.5-1.6-0.7c-0.4-0.1-3-0.1-3.3-0.1c-0.1,0-0.1,0.1-0.1,0.2v6c0,0.2,0,0.2,0.1,0.2c0.3,0,3.3,0,3.9-0.1
         c0.5-0.1,0.9-0.1,1.1-0.3c0.2-0.1,0.3-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.5-0.2,1.2c0,0.4-0.1,1.3-0.1,1.4
         c0,0.2,0,0.4-0.2,0.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.3-0.6-1.2-0.7c-0.6-0.1-3.1-0.1-3.5-0.1
         c-0.1,0-0.2,0.1-0.2,0.2v1.9c0,0.8,0,3.3,0,3.8c0.1,1.5,0.5,1.8,2.8,1.8c0.6,0,1.7,0,2.3-0.2c0.6-0.3,0.9-0.7,1-1.5
         c0.1-0.3,0.1-0.3,0.2-0.3c0.1,0,0.2,0.2,0.2,0.3c0,0.1-0.2,1.7-0.3,2.1c-0.2,0.6-0.4,0.6-1.3,0.6c-1.7,0-3.1-0.1-4-0.1
         c-1-0.1-1.6-0.1-1.9-0.1c-0.1,0-0.5,0-1,0c-0.5,0-1,0-1.3,0c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5-0.1,0.7-0.1
         c0.4-0.1,0.5-0.5,0.6-1.2c0.1-0.9,0.1-2.7,0.1-4.8V509.2z" />
        <path class="st0" d="M304.7,516.6c0.1,1.8,0.3,2.4,0.9,2.5c0.4,0.1,0.9,0.1,1.1,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.4,0.2
         c-1.3,0-2.2-0.1-2.4-0.1c-0.2,0-1.2,0.1-2.2,0.1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.6,0,0.9-0.1
         c0.6-0.2,0.6-0.8,0.6-2.8v-12.7c0-0.9,0.1-1.1,0.3-1.1c0.3,0,0.6,0.5,0.9,0.7c0.4,0.4,3.7,3.9,7.3,7.6c2.3,2.3,4.7,5,5.4,5.7
         l-0.2-11.1c0-1.5-0.2-1.9-0.9-2.1c-0.4-0.1-0.9-0.1-1.1-0.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.2-0.2,0.5-0.2c1,0,2.1,0.1,2.4,0.1
         c0.3,0,1-0.1,2-0.1c0.2,0,0.4,0.1,0.4,0.2c0,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.3,0-0.5,0.1c-0.8,0.2-0.8,0.6-0.8,1.9l0,13
         c0,1.5,0,1.6-0.2,1.6c-0.2,0-0.4-0.2-1.7-1.3c-0.2-0.2-3.5-3.5-6-6c-2.7-2.8-5.2-5.5-6-6.3L304.7,516.6z" />
        <path class="st0"
          d="M328.5,513.2c0,2.1,0,3.9,0.1,4.8c0.1,0.6,0.2,1.1,0.9,1.2c0.3,0.1,0.8,0.1,1,0.1c0.2,0,0.2,0.1,0.2,0.2
         c0,0.1-0.1,0.2-0.4,0.2c-1.3,0-2.7-0.1-2.8-0.1c-0.1,0-1.7,0.1-2.4,0.1c-0.3,0-0.3,0-0.3-0.2c0-0.1,0-0.2,0.2-0.2
         c0.2,0,0.4,0,0.7-0.1c0.5-0.1,0.6-0.5,0.7-1.2c0.1-0.9,0.1-2.7,0.1-4.8v-9.3l-3.3,0.1c-1.4,0-2,0.2-2.3,0.7
         c-0.2,0.4-0.3,0.5-0.4,0.7c-0.1,0.2-0.1,0.2-0.2,0.2c0,0-0.1-0.1-0.1-0.2c0-0.2,0.5-2.2,0.5-2.4c0-0.1,0.1-0.4,0.2-0.4
         c0.2,0,0.4,0.2,1,0.3c0.6,0.1,1.5,0.1,1.8,0.1h8.6c0.8,0,1.3-0.1,1.6-0.1c0.4-0.1,0.5-0.1,0.7-0.1c0.1,0,0.1,0.1,0.1,0.3
         c0,0.7-0.1,2.3-0.1,2.5c0,0.2-0.1,0.3-0.2,0.3c-0.1,0-0.1-0.1-0.2-0.4l0-0.2c-0.1-0.7-0.6-1.2-2.8-1.2l-2.9-0.1V513.2z" />
      </g>
    </svg>
  </div>

  <div class="sm-footer-menu">
    <ul>
      <li><a [routerLink]="['/home/mastery']" routerLinkActive="active"><strong>{{'MENU.ABOUT' | translate
            }}</strong></a>
      </li>
      <li><a [routerLink]="['/home/privacy']" routerLinkActive="active"><strong>{{'ACADEMY.PRIVECY' | translate
            }}</strong></a></li>
      <!-- <li><a><strong>{{'ACADEMY.HELPCENTER' | translate }}</strong></a></li> -->
      <!-- <li><a><strong>{{'MENU.CONTACTUS' | translate }}</strong></a></li> -->
      <li><a [routerLink]="['/home/terms']" routerLinkActive="active"><strong>{{'ACADEMY.TERMSOFUSE' | translate
            }}</strong></a></li>

      <li><a (click)="certificateRequest()"><strong>{{'MENU.CERTIFICATEVALIDATE' | translate }}</strong></a></li>
      <!-- <li><a><strong>{{'MENU.COMPANIESREQUESTS' | translate }}</strong></a></li> -->
      <!-- <li><a><strong>{{'ACADEMY.PAYEMENTS' | translate }}</strong></a></li> -->
      <!-- <li><a><strong>{{'ACADEMY.AFFILIATES' | translate }}</strong></a></li> -->
    </ul>
  </div>

  <div class="sm-footer-payments">
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 473.96 473.96" style="enable-background:new 0 0 473.96 473.96;" xml:space="preserve">
      <circle style="fill:#F3F2F2;" cx="236.98" cy="236.99" r="236.97" />
      <g>
        <polygon style="fill:#293688;" points="175.483,282.447 193.616,175.373 222.973,175.373 204.841,282.447 	" />
        <path style="fill:#293688;" d="M309.352,178.141c-5.818-2.17-14.933-4.494-26.316-4.494c-29.014,0-49.451,14.526-49.627,35.337
c-0.161,15.382,14.589,23.962,25.732,29.088c11.427,5.238,15.27,8.599,15.214,13.28c-0.071,7.177-9.13,10.458-17.571,10.458
c-11.749-0.004-17.994-1.624-27.637-5.62l-3.783-1.706l-4.123,23.97c6.859,2.99,19.543,5.583,32.71,5.714
c30.858-0.007,50.899-14.353,51.124-36.583c0.112-12.179-7.712-21.448-24.651-29.092c-10.264-4.947-16.55-8.251-16.482-13.272
c0-4.449,5.324-9.208,16.815-9.208c9.601-0.15,16.557,1.931,21.979,4.101l2.627,1.235L309.352,178.141L309.352,178.141z" />
        <path style="fill:#293688;" d="M359.405,175.373c-7.034,0-12.116,2.148-15.207,9.119l-43.509,97.959h31.083l6.043-16.408h37.137
l3.45,16.408h27.633L381.86,175.376h-22.454L359.405,175.373L359.405,175.373z M346.062,244.618
c2.425-6.166,11.693-29.927,11.693-29.927c-0.168,0.281,2.413-6.196,3.895-10.215l1.987,9.227c0,0,5.616,25.56,6.795,30.918h-24.37
V244.618z" />
        <path style="fill:#293688;" d="M121.946,248.771l-2.586-14.679c-5.358-17.111-21.987-35.625-40.621-44.901l25.938,93.256h31.09
l46.626-107.074H151.31L121.946,248.771z" />
      </g>
      <g>
        <path style="fill:#F7981D;" d="M46.823,175.373v1.729c36.838,8.86,62.413,31.259,72.538,56.991l-10.645-49.582
c-1.777-6.776-7.162-8.902-13.534-9.137L46.823,175.373L46.823,175.373z" />
        <path style="fill:#F7981D;" d="M236.964,473.958c91.464,0,170.77-51.846,210.272-127.725H26.696
C66.201,422.112,145.504,473.958,236.964,473.958z" />
      </g>
      <path style="fill:#293688;"
        d="M236.964,0C146.952,0,68.663,50.184,28.548,124.103h416.84C405.268,50.188,326.976,0,236.964,0z" />
      <g>
      </g>
    </svg>
    <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path style="fill:#F0EFEB;" d="M512,256c0,54.293-16.896,104.636-45.735,146.066c-6.457,9.279-13.521,18.119-21.128,26.446
C398.326,479.817,330.919,512,256,512c-81.147,0-153.475-37.752-200.38-96.664c-3.438-4.315-6.74-8.746-9.895-13.281
C16.896,360.636,0,310.282,0,256C0,114.615,114.615,0,256,0S512,114.615,512,256z" />
      <circle style="fill:#EE0005;" cx="174.02" cy="211.05" r="131.96" />
      <circle style="fill:#F9A000;" cx="337.97" cy="211.05" r="131.96" />
      <path style="fill:#FF6300;" d="M206.012,211.048c0,41.9,19.532,79.235,49.984,103.407c30.451-24.173,49.984-61.507,49.984-103.407
s-19.532-79.235-49.984-103.407C225.545,131.815,206.012,169.149,206.012,211.048z" />
      <g>
        <path style="fill:#171614;" d="M94.156,382.6c-5.465,0-10.93,3.009-14.221,9.415c-2.173-5.559-6.698-9.415-12.351-9.415
 c-6.311,0-10.459,3.479-12.528,7.158v-3.396c0-1.881-2.069-3.197-4.43-3.197c-2.915,0-4.901,1.317-4.901,3.197v15.694
 c3.156,4.535,6.457,8.965,9.895,13.281v-14.179c0-6.405,4.336-9.801,8.767-9.801c4.618,0,8.756,3.866,8.756,9.707v23.562
 c0,2.257,2.644,3.197,4.901,3.197c2.55,0,4.995-0.94,4.995-3.197V400.97c0-6.123,4.43-9.707,8.861-9.707
 c4.336,0,8.767,3.396,8.767,9.895v23.374c0,1.599,2.351,3.291,4.901,3.291c2.727,0,4.984-1.693,4.984-3.291v-23.374
 C110.55,389.287,102.64,382.6,94.156,382.6z" />
        <path style="fill:#171614;" d="M134.771,382.6c-6.405,0-14.232,2.633-14.232,6.029c0,2.257,1.233,4.995,3.584,4.995
 c1.881,0,3.584-2.832,10.459-2.832c6.875,0,9.331,5.371,9.331,10.177v1.693h-4.148c-13.093,0-22.047,2.832-22.047,13.479
 c0,8.485,5.653,12.434,12.528,12.434c6.405,0,10.93-3.479,14.232-7.346v3.302c0,1.787,1.975,3.291,4.524,3.291
 c2.727,0,4.807-1.505,4.807-3.291v-23.656C153.809,391.45,148.626,382.6,134.771,382.6z M143.914,411.617
 c0,4.524-5.371,9.425-10.083,9.425c-3.49,0-6.123-1.881-6.123-6.123c0-6.029,6.687-6.792,13.845-6.792h2.361V411.617z" />
        <path style="fill:#171614;" d="M171.144,395.692c0-2.351,2.069-5.465,7.628-5.465c5.287,0,8.202,2.738,10.177,2.738
 c2.361,0,3.772-3.396,3.772-4.807c0-3.02-7.064-5.559-14.137-5.559c-11.87,0-16.112,7.064-16.112,13.563
 c0,15.642,22.716,9.801,22.716,19.696c0,2.539-1.797,5.089-8.014,5.089c-8.098,0-10.083-4.336-12.528-4.336
 c-1.985,0-3.396,2.738-3.396,4.43c0,3.584,6.886,7.534,16.206,7.534c10.648,0,16.583-5.089,16.583-13.093
 C194.038,398.524,171.144,403.989,171.144,395.692z" />
        <path style="fill:#171614;" d="M221.654,419.349h-3.302c-4.901,0-6.593-1.703-6.593-6.228V390.51h11.494
 c1.693,0,2.738-1.787,2.738-3.678c0-1.881-1.045-3.668-2.738-3.668h-11.494v-17.251c0-1.881-2.55-3.197-4.995-3.197
 c-2.361,0-4.901,1.317-4.901,3.197v47.208c0,10.271,5.747,14.702,16.488,14.702h3.302c2.821,0,4.232-2.069,4.232-4.242
 C225.886,421.418,224.475,419.349,221.654,419.349z" />
        <path style="fill:#171614;" d="M264.903,416.987c-2.55,0-4.807,3.584-11.494,3.584c-7.262,0-12.257-3.96-12.257-10.271v-2.173
 h19.884c4.242,0,8.579-0.282,8.579-8.098c0-10.271-8.673-17.429-18.756-17.429c-10.836,0-19.602,8.38-19.602,18.275v8.955
 c0,11.212,9.049,18.745,21.87,18.745c9.509,0,15.642-4.336,15.642-7.158C268.769,419.725,267.254,416.987,264.903,416.987z
  M241.152,398.994c0-5.183,4.43-8.861,9.613-8.861c5.371,0,9.519,4.054,9.519,9.049c0,2.257-0.752,2.821-3.49,2.821h-15.642
 L241.152,398.994L241.152,398.994z" />
        <path style="fill:#171614;" d="M301.934,382.6h-3.02c-5.559,0-9.979,3.95-11.964,7.91v-4.148c0-1.881-2.069-3.197-4.524-3.197
 c-2.821,0-4.807,1.317-4.807,3.197v38.17c0,1.599,1.985,3.291,4.807,3.291c2.832,0,5.089-1.693,5.089-3.291v-19.32
 c0-8.579,5.653-13.855,11.4-13.855h3.02c1.975,0,3.772-1.975,3.772-4.43C305.706,384.575,303.909,382.6,301.934,382.6z" />
        <path style="fill:#171614;" d="M340.281,416.705c-2.163,0-3.668,3.114-9.895,3.114c-7.252,0-10.459-3.772-10.459-9.989v-8.485
 c0-6.217,3.302-9.989,10.365-9.989c6.04,0,7.732,2.926,9.707,2.926c2.361,0,3.96-2.926,3.96-4.807c0-3.302-5.841-6.875-14.42-6.875
 c-13.469,0-19.498,8.192-19.498,18.745v8.485c0,10.742,6.029,18.745,19.592,18.745c8.673,0,14.89-3.856,14.89-7.158
 C344.524,419.443,342.925,416.705,340.281,416.705z" />
        <path style="fill:#171614;" d="M365.913,382.6c-6.405,0-14.232,2.633-14.232,6.029c0,2.257,1.233,4.995,3.584,4.995
 c1.891,0,3.584-2.832,10.459-2.832s9.331,5.371,9.331,10.177v1.693h-4.148c-13.093,0-22.047,2.832-22.047,13.479
 c0,8.485,5.653,12.434,12.528,12.434c6.416,0,10.93-3.479,14.232-7.346v3.302c0,1.787,1.975,3.291,4.524,3.291
 c2.727,0,4.807-1.505,4.807-3.291v-23.656C384.951,391.45,379.768,382.6,365.913,382.6z M375.056,411.617
 c0,4.524-5.371,9.425-10.083,9.425c-3.49,0-6.123-1.881-6.123-6.123c0-6.029,6.687-6.792,13.845-6.792h2.361V411.617
 L375.056,411.617z" />
        <path style="fill:#171614;"
          d="M419.338,382.6h-3.009c-5.559,0-9.989,3.95-11.964,7.91v-4.148c0-1.881-2.079-3.197-4.524-3.197
 c-2.832,0-4.807,1.317-4.807,3.197v38.17c0,1.599,1.975,3.291,4.807,3.291c2.821,0,5.089-1.693,5.089-3.291v-19.32
 c0-8.579,5.653-13.855,11.4-13.855h3.009c1.985,0,3.772-1.975,3.772-4.43C423.111,384.575,421.324,382.6,419.338,382.6z" />
        <path style="fill:#171614;" d="M461.364,356.592c-2.821,0-4.995,1.317-4.995,3.197v29.968c-2.069-3.678-6.311-7.158-12.246-7.158
 c-9.237,0-16.865,8.38-16.865,18.557v8.673c0,9.801,7.722,18.745,16.583,18.745c0.439,0,0.867-0.021,1.296-0.063
 c7.607-8.328,14.67-17.168,21.128-26.446V359.79C466.265,357.909,464.196,356.592,461.364,356.592z M456.37,411.993
 c0,2.267-4.232,7.826-9.707,7.826c-5.183,0-9.509-4.901-9.509-9.989v-8.673c0-4.901,4.326-9.801,9.613-9.801
 c4.702,0,9.603,3.678,9.603,9.801V411.993z" />
      </g>
    </svg>
    <svg version="1.1" id="Capa_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
      <path style="fill:#2196F3;" d="M480,127.992H32c-17.632,0-32,14.368-32,32v288c0,17.664,14.368,32,32,32h448
c17.664,0,32-14.336,32-32v-288C512,142.36,497.664,127.992,480,127.992z" />
      <rect y="191.992" style="fill:#455A64;" width="512" height="96" />
      <g>
        <path style="fill:#FAFAFA;" d="M144,383.992H80c-8.832,0-16-7.168-16-16s7.168-16,16-16h64c8.832,0,16,7.168,16,16
S152.832,383.992,144,383.992z" />
        <path style="fill:#FAFAFA;" d="M512,286.264V159.992c0-17.632-14.336-32-32-32H224v32c0,100.224,38.144,161.248,136.192,217.696
c7.232,4.128,15.488,6.304,23.808,6.304s16.576-2.176,23.904-6.368C456.416,349.752,490.208,320.664,512,286.264z" />
      </g>
      <path style="fill:#009688;" d="M502.304,81.304l-112-48c-4.064-1.728-8.576-1.728-12.64,0l-112,48
C259.808,83.8,256,89.592,256,95.992v64c0,88.032,32.544,139.488,120.032,189.888c2.464,1.408,5.216,2.112,7.968,2.112
s5.504-0.704,7.968-2.112C479.456,299.608,512,248.152,512,159.992v-64C512,89.592,508.192,83.8,502.304,81.304z" />
      <path style="fill:#FAFAFA;"
        d="M368,239.992c-0.224,0-0.48,0-0.672,0c-5.088-0.224-9.792-2.848-12.64-7.104l-32-48
c-4.896-7.36-2.912-17.28,4.448-22.176c7.296-4.864,17.248-2.944,22.176,4.448l19.872,29.792l50.304-62.912
c5.536-6.88,15.616-7.968,22.496-2.496c6.912,5.536,8,15.584,2.496,22.496l-64,80C377.44,237.784,372.832,239.992,368,239.992z" />
      <g>
      </g>
    </svg>
  </div>

  <div class="sm-footer-social">

    <ul>
      <li><a [href]='social.whatsapp' target="_blank"> <i class="fa fa-whatsapp"></i></a></li>
      <li><a [href]='social.facebook' target="_blank"> <i class="fa fa-facebook"></i></a></li>
      <li><a [href]='social.instagram' target="_blank"> <i class="fa fa-instagram"></i></a></li>
      <li><a [href]='social.twitter' target="_blank"> <i class="fa fa-twitter"></i></a></li>
      <li><a [href]='social.youtube' target="_blank"> <i class="fa fa-youtube"></i></a></li>
      <li><a [href]='social.linkdin' target="_blank"> <i class="fa fa-linkedin"></i></a></li>
    </ul>
    
  </div>
  <p style="color: #fff;direction: ltr; font-size: 12px; line-height: 60px;">
    © Operated by Sahel Reach - Dubai
  </p>
</div>