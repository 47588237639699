export class TrainerApplication {
  Id?: number
  firstName: string;
  lastName: string;
  email: string;
  nationality: string;
  country: string;
  city: string;
  mobile: string;
  birthdate: Date;
  educationalLevel: EductionalLevel;
  major: string;
  specialized: string;
  programs: Programs[]

  totalYearsOfExperience?: number
  scopedExperience?: number
  languages: Languages[]
  additionalServices: AdditionalServices[]
  cvUrl: string;
  personalProgram: string;

}

export class Programs {
  Id?: number
  email: string;
  programId?: number
}

export class AdditionalServices {
  Id?: number
  email: string;
  serviceId?: number
}

export class Languages {
  Id?: number
  email: string;
  languageId?: number
}

export enum EductionalLevel {
  Diplma = 1,
  Bachelor = 2,
  Master = 3,
  Doctorate = 4
}
