import { courseCard, packageCard } from './../../_models/cards';
import { courseSlide, COUCategories } from './../../_models';
import { createAction, props } from '@ngrx/store';

export const AvailableCourses = createAction('[Courses] Load Available Courses');
export const LoadCorusesFail = createAction('[Courses] Load Available Courses Fail', props<{ error: any }>());
export const LoadCorusesSuccess = createAction('[Courses] Load Available Courses Success', props<{ courses: courseSlide[] }>());
export const GetSelectedCourse = createAction('[Courses] Load Current Course', props<{ course: courseCard }>());
export const SelectCourse = createAction('[Courses] Select And Update Course', props<{ courseId: string }>());
export const SelectCourseById = createAction('[Courses] Select And Update Course By Id', props<{ courseId: number }>());
export const SelectedCourseError = createAction('[Courses] Failed To Get Selected Course', props<{ error: any }>());

export const AllCategories = createAction('[Categories] Load All Categories');
export const LoadCategoriesFail = createAction('[Categories] Load All Categories Fail', props<{ error: any }>());
export const LoadCategoriesSuccess = createAction('[Categories] Load All Categories Success', props<{ categories: COUCategories[] }>());



// PACKAGES
export const AvailablePackages = createAction('[Packages] Load Available Packages');
export const LoadPackagesFail = createAction('[Packages] Load Available Packages Fail', props<{ error: any }>());
export const LoadPackagesSuccess = createAction('[Packages] Load Available Packages Success', props<{ packages: courseSlide[] }>());
export const GetSelectedPackages = createAction('[Packages] Load Current Package', props<{ packages: packageCard }>());
export const SelectPackages = createAction('[Packages] Select And Update Package', props<{ packageId: string }>());
export const SelectPackagesById = createAction('[Packages] Select And Update Package By Id', props<{ packageId: number }>());
export const SelectedPackagesError = createAction('[Packages] Failed To Get Selected Package', props<{ error: any }>());
