import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { cartActions } from '../_cart/cart.actions';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AccountService } from '../../services/account.service';
import { exhaustMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { myCourseCard, myCourseSlider } from '../../_models';
import { SettingsService } from '../../services';

@Injectable()
export class ProfileEffect {
  constructor(
    private actions$: Actions<cartActions>,
    private _storage: LocalStorage,
    private _router: Router,
    private _settings: SettingsService,
    // public oidcSecurityService: OidcSecurityService,
    private _service: AccountService,
  ) {
  }



  getAllMyCourses$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get My Courses'),
    exhaustMap((action: any) => this._service.getMyCourses(action.cid, 1, 1, null).pipe(
      map(paymentintent => ({ type: '[Profile] Get My Courses Success', courses: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))


  getMyCoursesEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Profile] Get My Courses Success'),
      map(() => ({ type: '[Profile] Stop Loading' }))
    ),
    { dispatch: false },
  );


  getCourseDetailsAfterAnUpdate$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Course Details After An Update'),
    exhaustMap((action: any) => this._service.getMyCourse(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Update Course Details', course: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } }))
      ),
    ))))


  getMyCourseAfterAnUpdate$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Update Course Progress'),
    exhaustMap((action: any) => this._service.getMyCourseProgress(action.attrs).pipe(
      map((paymentintent: myCourseSlider) => ({ type: '[Profile] Update Course Progress Success', course: { id: action.attrs.courseId, changes: paymentintent } }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } }))
      ),
    ))))



  getCourseDetails$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Course Details'),
    exhaustMap((action: any) => this._service.getMyCourse(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Get Course Details Successed', course: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } }))
      ),
    ))))


  getCourseDetailsForUpdate$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Course Details For Update'),
    exhaustMap((action: any) => this._service.getMyCourse(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Get Course Details For Update Successed', course: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } }))
      ),
    ))))

  addTaskFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Profile] Get Course Details Successed'),
      map((course: myCourseCard) => ({ type: '[Profile] Set Unit Lectures', units: course.courseContents })),
      tap(() => this._router.navigate(['study'])),
    ),
    { dispatch: false },
  );







  attendAnExam$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Attend A Quizz'),
    exhaustMap((action: any) => this._service.attendQuizz(action.exam).pipe(
      map(paymentintent => ({ type: '[Profile] Attend A Quizz Success', result: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ),
    )
    )))



  getLectureDetails$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Lecture Details'),
    exhaustMap((action: any) => this._service.getMyLecture(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Get Lecture Details Successed', lecture: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))



  // giveMeCookie$ = createEffect(() => this.actions$.pipe(
  //   ofType('[Profile] Give Me A Cookie'),
  //   exhaustMap((action: any) => this._settings.getSignCookieForMe().pipe(
  //     map(paymentintent => ({ type: '[Profile] Get Lecture Details Successed', lecture: paymentintent }),
  //       catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
  //       )
  //     ))
  //   )))

  // myQuizzCard
  getMyQuizzInfo$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Quizz Info'),
    exhaustMap((action: any) => this._service.getMyQuizzInfo(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Get Quizz Info Successed', data: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))



  getQuizzCard$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Quizz Card'),
    exhaustMap((action: any) => this._service.getQuizzCard(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Get Quizz Card Successed', quizz: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))



  getNextLectureDetails$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Next Lecture Details'),
    exhaustMap((action: any) => this._service.getMyNextLecture(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Get Lecture Details Successed', lecture: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))


  getVideoLinkSignedUrl$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get Current Video Signed Url'),
    exhaustMap((action: any) => this._service.getSignedURL(action.attrs).pipe(
      map(paymentintent => ({ type: '[Profile] Get Current Video Signed Url Successed', link: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))



  getCommentsOnVideo$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Get All Comments On Video'),
    exhaustMap((action: any) => this._service.getLectureDisccuion(action.lectureId).pipe(
      map(paymentintent => ({ type: '[Profile] Get All Comments On Video Success', discussions: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))




  addCommentOnVideo$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Add New Comment On Video'),
    exhaustMap((action: any) => this._service.addOrModifyCommentOnVideo(action.comment).pipe(
      map(paymentintent => ({ type: '[Profile] Add New Comment On Video Success', comment: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))



  addBookmarkOnVideo$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Add New Bookmark On Video'),
    exhaustMap((action: any) => this._service.addNewBookmark(action.bookmark).pipe(
      map(paymentintent => ({ type: '[Profile] Add New Bookmark On Video Success', bookmark: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))


  amIAnAffiliate$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] Am I An Affiliate'),
    exhaustMap((action: any) => this._service.amIAnAffiliate(action.userId).pipe(
      map(paymentintent => ({ type: '[Profile] Am I An Affiliate Success', result: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))


  whatIsMyAffiliateStatus$ = createEffect(() => this.actions$.pipe(
    ofType('[Profile] What Is My Affiliate Status '),
    exhaustMap((action: any) => this._service.whatIsMyAffiliateStatus(action.userId).pipe(
      map(paymentintent => ({ type: '[Profile] What Is My Affiliate Status  Success', result: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))




}
