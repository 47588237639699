

export enum ConnectionState {
  Connecting = 1,
  Connected = 2,
  Reconnecting = 3,
  Disconnected = 4
}


import { Injectable, Inject, EventEmitter, OnDestroy } from '@angular/core';
import * as signalR from '@microsoft/signalr'; import { AccountService, CoursesService } from '.';
import { select, Store } from '@ngrx/store';
import * as courseActions from '../state/_courses/courses.actions';
import * as profileReducer from '../state/_profile/profile.reducer';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { myCourseCard, myLectureCard } from '../_models';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BroadcastService implements OnDestroy {

  constructor(@Inject('API_URL') private apiUrl: string, private courses: CoursesService, private authenticationService: AuthenticationService,
    private _account: AccountService, private _router: Router,
    private _store: Store,) {
    // console.log('WE ARE RIGHT HERE BOYS');
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();

  }


  restartConnection() {
    // this._hubConnection.stop();
    this.startConnection();
  }


  subOfCourse$ = new Subscription();


  ngOnDestroy(): void {
    this.subOfCourse$.unsubscribe();
  }

  private createConnection() {
    console.log('connection');

    const user = this.authenticationService.userValue;
    // const isLoggedIn = user && user.jwtToken;
    if (typeof window !== 'undefined') {
      this._hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(this.apiUrl + "/mastery", { accessTokenFactory: () => user.jwtToken })
        .configureLogging(signalR.LogLevel.Information)
        .build();
    }
  }

  // public connectionId;

  private startConnection(): void {
    let that = this ;
    if(this.statusOfConnection.value == false) {}
    this._hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;

        // console.log('Hub connection started');
        let self = this;
        this.connectionEstablished.emit(true);
        this._hubConnection.invoke('getConnectionId')
          .then(function (connectionId) {
            // console.log(connectionId);

            sessionStorage.setItem('connectionId', connectionId);
            self._account.updateConnectionId().pipe(take(1)).subscribe(value => {
              console.log("Updated connection Id");
            });
            self.statusOfConnection.next(true);
            // Send the connectionId to controller
          }).catch(err => console.error(err.toString()));;
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        console.log(err);

        // setTimeout(function () { that.startConnection(); }, 3000);
      });
  }


  private _hubConnection: signalR.HubConnection;
  // private _hubConnectionx: signalR.HubConnection;
  connectionEstablished = new EventEmitter<Boolean>();
  private connectionIsEstablished = false;
  statusOfConnection = new BehaviorSubject(false);



  Invoke(channel, ...data) {
    this._hubConnection.invoke(channel, ...data);
  }

  // Invoke(channel, ...data) {
  //   this._hubConnection.invoke(channel, ...data);
  // }

  GetConnection() {
    return this._hubConnection;
  }

  lecture$: Observable<myLectureCard>;
  course$: Observable<myCourseCard>;
  announcement$ = new BehaviorSubject<any>(null);


  private registerOnServerEvents(): void {
    this._hubConnection.on("Courses", data => {
      // console.log(data);
      this._store.dispatch({ type: '[Courses] Load Available Courses' });
      // this.customers.updateAllCustomers(data);
    });

    // this._hubConnection.on("Update Something", (data: string) => {
    //   // console.log(data);

    //   var text = data.split('lectureId: ');
    //   var text2 = text[1].split(' userId: ')
    //   // console.log(text2);

    //   // this._store.dispatch({ type: '[Profile] Get Lecture Details', attrs: { userId: text2[1], lectureId: text2[0]} });

    //   // this.customers.updateAllCustomers(data);
    // });
    this._hubConnection.on("newannouncement", (data, message) => {
      // console.log(data);
      this.announcement$.next(data)
    });
    this._hubConnection.on("SendAsync", (data, userId, message) => {
      console.log(data);
      console.log(message);
    });
    this._hubConnection.on("Update Quizz", (data: string) => {
      this.LessonUpdate.next(data);
    });


    this._hubConnection.on("Update Something", (data: string) => {
      // console.log(data);
      // // this._account.updateTheStatusOfCourse(JSON.parse(data).courseId, JSON.parse(data).userId);
      // // console.log('WE ALERTED');
      // this.LessonUpdate.next(data);
    });

    this._hubConnection.on("SendMessage", (data, userId, message) => {
      // console.log(data);
      // console.log(message);
      // console.log(userId);
      if (data == "Update Something") {
        // console.log('finished Lesson');
        
        this.LessonUpdate.next(message);
      }


      if (data == "NewAnnouncement") {
        console.log(message);

      }
    })


    this._hubConnection.on("New Order", (data: string) => {
      console.log('WE HAVE A SALE ', data);

    });
    this._hubConnection.on("Update Activity", (data: string) => {
      // console.log(data);
      // this._account.updateTheStatusOfCourse(JSON.parse(data).courseId, JSON.parse(data).userId);
      this._router.navigate(['/course/' + JSON.parse(data).courseId + '/lesson/' + JSON.parse(data).lectureId]);
    });

    this._hubConnection.on('CourseUpdatedAttendanceLog', (data: string) => {
      console.log(data);

    })
  }


  LessonUpdate = new BehaviorSubject(null);


}
