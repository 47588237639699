<div class="mas-b2b-container">


  <h4>{{'HOME.APPLICATIONFORTRAINERSUBSCRIPTION' | translate }}</h4>
  <div *ngIf="!isapplied" class="row" style="direction: ltr;">
    <div class="col-md-6">
      <div class="">

        <div class="panel-content bg-default" style="
        background: #fff;
        border: 1px solid;
        border-radius: 5px;
        color: #000;
    ">

          <form [formGroup]="newCourse" (ngSubmit)="onSubmit()" style="padding: 1em;">

            <div class="row">
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.TITLE'
                  | translate}}
                </label>
                <br />
                <ng-select [items]="titles" bindLabel="name" formControlName="title" bindValue="name">
                </ng-select>
                <div *ngIf="submitted && xf.firstName.errors" class="invalid-feedback">
                  <div *ngIf="xf.firstName.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.FULLNAME' |
                  translate }}</label>
                <input type="text" formControlName="name" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.name.errors }" />
                <div *ngIf="submitted && xf.name.errors" class="invalid-feedback">
                  <div *ngIf="xf.name.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.EMAIL' | translate
                  }}</label>
                <input type="text" formControlName="email" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.email.errors }" />
                <div *ngIf="submitted && xf.email.errors" class="invalid-feedback">
                  <div *ngIf="xf.email.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>

              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.PHONE' |
                  translate }}</label> <br>
                <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="[CountryISO.SaudiArabia]"
                  [enableAutoCountrySelect]="false" [searchCountryFlag]="true"
                  [customPlaceholder]="'SYSTEM.ENTERPHONE' | translate"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.SaudiArabia" [maxLength]="15" [phoneValidation]="true" name="phone"
                  [(ngModel)]="phonex" [ngModelOptions]="{standalone: true}">
                </ngx-intl-tel-input>
                <div *ngIf="submitted && !phonex" class="invalid-feedback">
                  <div>
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>

              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.NATIONALITY' |
                  translate }}</label>
                <input type="text" formControlName="nationality" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.nationality.errors }" />
                <div *ngIf="submitted && xf.nationality.errors" class="invalid-feedback">
                  <div *ngIf="xf.nationality.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.COUNTRY' |
                  translate }}</label>
                <input type="text" formControlName="country" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.country.errors }" />
                <div *ngIf="submitted && xf.country.errors" class="invalid-feedback">
                  <div *ngIf="xf.country.errors.email">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.CITY' | translate
                  }}</label>
                <input type="text" formControlName="city" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.city.errors }" />
                <div *ngIf="submitted && xf.city.errors" class="invalid-feedback">
                  <div *ngIf="xf.city.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>




              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.BIRTHDATE' |
                  translate }}</label>
                <input type="date" formControlName="birthdate" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.birthdate.errors }" />
                <div *ngIf="submitted && xf.birthdate.errors" class="invalid-feedback">
                  <div *ngIf="xf.birthdate.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>


              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.EDUCATIONALLEVEL'
                  | translate }}</label>
                <br />
                <ng-select [items]="studies" bindLabel="name" formControlName="educationalLevel" bindValue="name">
                </ng-select>
                <div *ngIf="submitted && xf.firstName.errors" class="invalid-feedback">
                  <div *ngIf="xf.firstName.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>


              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.MAJOR' | translate
                  }}</label>
                <br>
                <ng-select [items]="majorNames" [addTag]="addTagFn" [ngModelOptions]="{standalone: true}"
                  [hideSelected]="true" multiple="true" bindLabel="name" [(ngModel)]="selectedMajors">
                </ng-select>
                <div *ngIf="submitted && xf.major.errors" class="invalid-feedback">
                  <div *ngIf="xf.major.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>




              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.EXPERIENCE' |
                  translate }}</label>
                <br />
                <ng-select [items]="experiences" bindLabel="name" formControlName="totalYearsOfExperience"
                  bindValue="name">
                </ng-select>
                <div *ngIf="submitted && xf.firstName.errors" class="invalid-feedback">
                  <div *ngIf="xf.firstName.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>


              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.SCOPEDEXPERIENCE'
                  | translate }}</label>
                <br />
                <ng-select [items]="expfields" bindLabel="name" formControlName="scopedExperience" bindValue="name">
                </ng-select>
                <div *ngIf="submitted && xf.firstName.errors" class="invalid-feedback">
                  <div *ngIf="xf.firstName.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>


              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.LANGUAGES' |
                  translate }}</label>
                <br />
                <ng-select [items]="langs" bindLabel="name" formControlName="languages" bindValue="name">
                </ng-select>
                <div *ngIf="submitted && xf.firstName.errors" class="invalid-feedback">
                  <div *ngIf="xf.firstName.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>



              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.CONSULTANCY' |
                  translate }}</label>

                <div class="form-group">
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="consultancy" value="true" formControlName="consultancy"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && xf.consultancy.errors }" />
                    {{'TRAINER.YES' | translate }}</label>
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="consultancy" value="false" formControlName="consultancy"
                      class="form-control" [ngClass]="{ 'is-invalid': submitted && xf.consultancy.errors }" />
                    {{'TRAINER.NO' | translate }}</label>
                </div>
                <div *ngIf="submitted && xf.consultancy.errors" class="invalid-feedback">
                  <div *ngIf="xf.consultancy.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>

              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.PERSONALPROGRAM' |
                  translate }}</label>
                <input type="text" formControlName="personalProgram" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.personalProgram.errors }" />
                <div *ngIf="submitted && xf.personalProgram.errors" class="invalid-feedback">
                  <div *ngIf="xf.personalProgram.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>
              </div>


              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.DOYOUTUBE' |
                  translate }}</label>

                <div class="form-group">
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="youtube" value="true" (change)="setYoutube(true)"
                      formControlName="youtube" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.famous.errors }" />
                    {{'TRAINER.YES' | translate }}</label>
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="youtube" value="false" (change)="setYoutube(false)"
                      formControlName="youtube" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.famous.errors }" />
                    {{'TRAINER.NO' | translate }}</label>
                </div>
                <div *ngIf="submitted && xf.youtube.errors" class="invalid-feedback">
                  <div *ngIf="xf.youtube.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>

              <!-- Platform -->
              <div *ngIf="isYoutube" class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.YOUTUBECHANNEL'
                  |
                  translate }}</label>
                <input type="text" formControlName="youtubechannel" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.youtubechannel.errors }" />
                <div *ngIf="submitted && xf.youtubechannel.errors" class="invalid-feedback">
                  <div *ngIf="xf.youtubechannel.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>


              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.DOINSTAGRAM' |
                  translate }}</label>

                <div class="form-group">
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="instagram" value="true" (change)="setInstagram(true)"
                      formControlName="instagram" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.famous.errors }" />
                    {{'TRAINER.YES' | translate }}</label>
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="instagram" value="false" (change)="setInstagram(false)"
                      formControlName="instagram" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.famous.errors }" />
                    {{'TRAINER.NO' | translate }}</label>
                </div>
                <div *ngIf="submitted && xf.instagram.errors" class="invalid-feedback">
                  <div *ngIf="xf.instagram.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>

              <!-- Platform -->
              <div *ngIf="isInstagram" class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.INSTAGRAMPROFILE'
                  |
                  translate }}</label>
                <input type="text" formControlName="instagramprofile" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.instagramprofile.errors }" />
                <div *ngIf="submitted && xf.instagramprofile.errors" class="invalid-feedback">
                  <div *ngIf="xf.instagramprofile.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.DOFACEBOOK' |
                  translate }}</label>

                <div class="form-group">
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="facebook" value="true" (change)="setFacebook(true)"
                      formControlName="facebook" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.famous.errors }" />
                    {{'TRAINER.YES' | translate }}</label>
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="facebook" value="false" (change)="setFacebook(false)"
                      formControlName="facebook" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.famous.errors }" />
                    {{'TRAINER.NO' | translate }}</label>
                </div>
                <div *ngIf="submitted && xf.facebook.errors" class="invalid-feedback">
                  <div *ngIf="xf.facebook.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>

              <!-- Platform -->
              <div *ngIf="isFacebook" class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.FACEBOOKPAGE'
                  |
                  translate }}</label>
                <input type="text" formControlName="facebookpage" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.facebookpage.errors }" />
                <div *ngIf="submitted && xf.facebookpage.errors" class="invalid-feedback">
                  <div *ngIf="xf.facebookpage.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>




              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif; color:#000;">{{'TRAINER.RECORDEDCOURSES' |
                  translate }}</label>

                <div class="form-group">
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="recorded" value="true" formControlName="recorded" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.recorded.errors }" />
                    {{'TRAINER.YES' | translate }}</label>
                  <label for="register-form-name"
                    style="width: 50%;  text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;">
                    <input type="radio" name="recorded" value="false" formControlName="recorded" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && xf.recorded.errors }" />
                    {{'TRAINER.NO' | translate }}</label>
                </div>
                <div *ngIf="submitted && xf.recorded.errors" class="invalid-feedback">
                  <div *ngIf="xf.recorded.errors.required">
                    {{'TRAINER.REQUIRED' | translate }}</div>
                </div>

              </div>





            </div>


            <div class="col_full nobottommargin">
              <button class="button button-3d button-green nomargin btn btn-success btn-embossed"
                id="register-form-submit" style="text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;"
                name="register-form-submit" value="register"> {{button}} </button>



            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <ng-container *ngFor="let item of policies">
            <h3 style="text-align: right; font-family: 'JF-Flat';">
              {{item.header}}
            </h3>
            <p style="text-align: right;" [innerHTML]='item.body'>

            </p>
            <br> <br>
          </ng-container>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="isapplied" class="row">
    <div class="col-md-12">
      <h3 style="text-align: center;">
        {{'TRAINER.YOURAPPLICATIONHASBEENSENT' | translate }}
      </h3>
    </div>
  </div>
</div>