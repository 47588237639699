<div class="sm-video-question">
  <div class="sm-video-question-container">
    <div class="sm-question-header">
      <div class="sm-question-title">
        ضع الكلمات الصحيحة في المكان الصحيح
      </div>
      <div class="sm-quesiton-repeat">
        <div class="sm-question-vrepeat">
          <i class="glyphicon glyphicon-repeat" (click)="repeatQuestion()"></i>
        </div>
        <div class="sm-question-qrepeat"></div>
      </div>
    </div>
    <div class="sm-video-question-body">
      <div style="position: relative; height: 100%;">
        <div style="position: absolute; top: 0; right: 0; width: auto ; bottom: 0; height: 35%; padding: 1vw 0;">
          <div id="questionTag">
            <span *ngIf="answers.length == numberOfWords" style="
            padding: 1vw;
            font-size: 1.4vw;
            font-family: 'Droid Arabic Kufi';">{{result}}</span>
            <span class="sm-vid-qu-wrds" *ngFor="let item of question.answers; let i =index;" draggable="true"
              id="answer-{{item.order}}" (dragstart)="drag($event)">{{item.answer}}</span>
          </div>
        </div>
        <hr style="position: absolute; top: 35%; right: 0; left: 0; margin:.5vw 0; ">
        <div class=" sm-question-words" id="answersTag" style='
        position: absolute;
        top: 35%;
        right: 0;
        left: 0;
        margin-top: 1vw;'>
          <ng-container *ngFor="let word of question.questionbody; let i = index;">
            <p *ngIf="word == 'X'" class="sm-word-empty" id="box-{{i}}" (drop)="drop($event)"
              (dragover)="allowDrop($event)"></p>
            <p *ngIf="word != 'X'" class="sm-word">{{word}}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
