<div class="row">
  <div
    *ngIf="alertTitle == 1"
    class="col-md-12 bg-danger"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
  </div>
  <div
    *ngIf="alertTitle == 2"
    class="col-md-12 bg-success"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
  </div>
</div>

<div
  class="login-background"
  [ngStyle]="{
    'background-image': 'url(' + template.image + ')'
  }"
></div>
<div class="login-container">
  <div class="box">
    <span class="borderLine"></span>

    <form
      method="post"
      class="pt-4"
      [formGroup]="formGroup"
      (ngSubmit)="submit()"
    >
      <img
        style="object-fit: contain"
        [style.height]="!template.companyId ? '200px' : '200px'"
        [style.width]="!template.companyId ? '200px' : '200px'"
        [src]="template.logo"
        [alt]="template.companyName"
      />
      <h3 class="card-title text-center fs-25 pb-5">يرجى ادخال كود التحقق</h3>

      <div class="input-box">
        <div class="form-group">
          <div class="textBoxs">
            <input
              type="text"
              maxlength="1"
              formControlName="code1"
              (keydown)="moveToNextTextbox($event, 1)"
            />
            <input
              type="text"
              maxlength="1"
              formControlName="code2"
              (keydown)="moveToNextTextbox($event, 2)"
            />
            <input
              type="text"
              maxlength="1"
              formControlName="code3"
              (keydown)="moveToNextTextbox($event, 3)"
            />
            <input
              type="text"
              maxlength="1"
              formControlName="code4"
              (keydown)="moveToNextTextbox($event, 4)"
            />
            <input
              type="text"
              maxlength="1"
              formControlName="code5"
              (keydown)="moveToNextTextbox($event, 5)"
            />
            <input
              type="text"
              maxlength="1"
              formControlName="code6"
              (keydown)="moveToNextTextbox($event, 6)"
            />
          </div>
        </div>
      </div>

      <div class="btn-box text-center">
        <button class="btn theme-btn px-5" type="submit">متابعة</button>
      </div>
    </form>
  </div>
</div>
