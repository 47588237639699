import { B2BProfile, B2BDeaprtments } from './../_models/b2b';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { Affiliate } from '../_models';

@Injectable()
export class B2BService {

  private b2bCurrentProfile = new BehaviorSubject<B2BProfile>(null);

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  top_divisions() {
    return this.http.get(this.apiUrl + '/api/b2b/top_divisions', this.jwt());
  }
  top_learners(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/top_learners', attrs, this.jwt());
  }
  most_earned(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/most_earned', attrs, this.jwt());
  }
  most_watched(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/most_watched', attrs, this.jwt());
  }
  complete_assigned(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/complete_assigned', attrs, this.jwt());
  }
  assigned_progress(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/assigned_progress', attrs, this.jwt());
  }
  trainees_assigned(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/trainees_assigned', attrs, this.jwt());
  }
  assigned_courses(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/assigned_courses', attrs, this.jwt());
  }
  epic_trainees(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/epic_trainees', attrs, this.jwt());
  }
  super_trainees(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/super_trainees', attrs, this.jwt());
  }
  active_trainees(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/active_trainees', attrs, this.jwt());
  }
  completed_consutancies(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/completed_consutancies', attrs, this.jwt());
  }
  earned_certificates(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/earned_certificates', attrs, this.jwt());
  }
  total_watched_videos(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/total_watched_videos', attrs, this.jwt());
  }
  completed_courses(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/completed_courses', attrs, this.jwt());
  }
  library_courses() {
    return this.http.get(this.apiUrl + '/api/b2b/library_courses', this.jwt());
  }
  trainees() {
    return this.http.get(this.apiUrl + '/api/b2b/trainees', this.jwt());
  }
  days_left() {
    return this.http.get(this.apiUrl + '/api/b2b/days_left', this.jwt());
  }
  subscription_dates() {
    return this.http.get(this.apiUrl + '/api/b2b/subscription_dates', this.jwt());
  }



  getCurrentB2B() {
    return this.b2bCurrentProfile;
  }

  updateCurrentB2BProfile(b2bId) {
    var attr = {
      id: b2bId
    }
    this.getB2BSubscription(attr).subscribe(data => this.b2bCurrentProfile.next(data));
  }


  AddOrModifyB2BSubscription(attr): Observable<B2BProfile> {
    return this.http.post<B2BProfile>(this.apiUrl + '/api/b2b/subscribe', attr, this.Withoujwt());
  }
  getB2BSubscription(attr): Observable<B2BProfile> {
    return this.http.post<B2BProfile>(this.apiUrl + '/api/b2b/subscription', attr, this.jwt());
  }
  getAllB2BSubscriptions(): Observable<B2BProfile[]> {
    return this.http.get<B2BProfile[]>(this.apiUrl + '/api/b2b/all', this.jwt());
  }
  deleteB2BSubscription(attr): Observable<B2BProfile> {
    return this.http.post<B2BProfile>(this.apiUrl + '/api/b2b/deletesubscription', attr, this.jwt());
  }


  importUsersForB2BProfile(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/importusers', attrs, this.jwt());
  }

  importDepartmentsForB2BProfile(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/importdepartments', attrs, this.jwt());
  }


  getAllDepartmentsForB2B(b2bId): Observable<B2BDeaprtments[]> {
    return this.http.get<B2BDeaprtments[]>(this.apiUrl + '/api/b2b/departments/' + b2bId, this.jwt());
  }


  TESTAPI(): Observable<any> {
    // return this.http.get<any>(this.apiUrl + '/api/courses/categories');
    return this.http.get<any>(this.apiUrl + '/v1/test');
  }


  assignCourses(attrs) {
    return this.http.post(this.apiUrl + '/api/b2b/assigncourses', attrs, this.jwt());
  }

  availableCoursesForDepartment(departmentId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/b2b/coursesfordepartment/' + departmentId, this.jwt());
  }


  deleteCourseFromDepartment(courseId) {
    return this.http.delete(this.apiUrl + '/api/b2b/coursefromdepartment/' + courseId, this.jwt());
  }


  getAllUsersForB2B(b2bId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/b2b/users/' + b2bId, this.jwt());
  }





  protected handleError(error: any) {

    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


  private jwt() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
          'Content-Type': 'application/json'
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


}
