import { createAction, props } from "@ngrx/store";
import { myLearningPlan } from "../../_models";





export const getMyCurrentPlan = createAction('[Planner] Get My Current Plan', props<{ userId: number }>());
export const getMyCurrentPlanSuccess = createAction('[Planner] Get My Current Plan Success', props<{ plan: myLearningPlan }>());


export const setNewPlan = createAction('[Planner] Set New Learning Plan', props<{ plan: any }>());
export const setNewPlanSuccess = createAction('[Planner] Set New Learning Plan Success', props<{ plan: any }>());



export type plannerActions =
  ReturnType<typeof getMyCurrentPlan> |
  ReturnType<typeof getMyCurrentPlanSuccess>


