import { createReducer, on, Action } from '@ngrx/store'
import * as authAtions from '../_actions/auth.actions';


export interface authState {
  isLoggedIn: boolean;
  authToken: string;
  profile: any;
  isProfileLoaded: boolean;
}

export const defaultState: authState = {
  isLoggedIn: false,
  authToken: undefined,
  profile: undefined,
  isProfileLoaded: false
}


const authreducer = createReducer(
  defaultState,
  on(authAtions.Login, (state, { authToken }) => ({ isLoggedIn: true, authToken: authToken, isProfileLoaded: false, profile: undefined })),
  on(authAtions.Register, (state, { authToken }) => ({ isLoggedIn: true, authToken: authToken, isProfileLoaded: false, profile: undefined })),
  on(authAtions.Logout, state => (defaultState)),
  on(authAtions.LoadProfile, (state, { profile }) => ({ ...state, isProfileLoaded: true, profile: profile }))
);


export function AuthReducer(state: authState | undefined, action: Action) {
  return authreducer(state, action);
}
