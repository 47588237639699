import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'sm-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressbarComponent implements OnInit {


  @Input() progress: number = 0;

  constructor(private cd: ChangeDetectorRef) { }
  ngOnInit(): void {
  }

}
