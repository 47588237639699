// import { ProfileModule } from './profile/profile.module';
// import { CoursesModule } from './courses/courses.module';
import { RouterModule } from '@angular/router';
import { BaseService } from './services/base.service';
import { SliderModule } from './../slider/slider.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModule } from './home/home.module';
import { ComponentsModule } from './components/components.module';
import { TranslateModule } from '@ngx-translate/core';
// import { CoursesliderModule } from './components/courseslider/courseslider.module';
import { LoadmoreModule } from './components/loadmore/loadmore.module';
// import { ConsultancyModule } from './consultancy/consultancy.module';
import { NewmainmenuComponent } from './dashboard/newmainmenu/newmainmenu.component';



@NgModule({
  declarations: [NewmainmenuComponent],
  exports: [NewmainmenuComponent],
  imports: [
    ComponentsModule, 
    // ProfileModule,
    //  ConsultancyModule,
      TranslateModule,
    CommonModule, SliderModule, HomeModule, RouterModule, 
    // CoursesliderModule,
     LoadmoreModule],
  providers: [
  ],
})
export class ViewsModule { }
