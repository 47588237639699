import { discussionCard } from './../../_models/cards';
import { myLectureCard, myQuizzAnswers, myQuizzAttempt, myQuizzQuestions } from './../../_models/profile';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { myCourseSlider, myCourseCard, contentVideo, myQuizzCard } from '../../_models';
import { createReducer, on, createFeatureSelector, createSelector, ActionReducer, Action } from '@ngrx/store';
import * as profileActions from './profile.actions';
// import { stat } from 'fs';
// import { attempt } from 'lodash';


export interface profileState extends EntityState<myCourseSlider> {
  selectedItemId?: number;
  total: number;
  isSelectedCourse: boolean;
  quizzAttempt: myQuizzAttempt;
  currentQuestion: myQuizzQuestions;
  quizzAnswers: myQuizzAnswers[];
  selectedCourse: myCourseCard;
  currentVideo: contentVideo;
  selectedLecture: myLectureCard;
  discussion: discussionCard;
  isSelectedLecture: boolean;
  percent: number;
  isLoading: boolean;
  rtl: boolean;
  quizzResult: any;
  currentQuizz: myQuizzCard;
  status: string;
  route: string;
  affiliate: boolean;
  affiliateStatus: string;
  isLoggedIn: boolean;
}

export function selectItemId(a: myCourseSlider): string {
  //In this case this would be optional since primary key is id
  return a.id.toString();
}

export function sortByName(a: myCourseSlider, b: myCourseSlider): number {
  return a.header.localeCompare(b.header);
}

export const myCourseSlidersAdapter: EntityAdapter<myCourseSlider> = createEntityAdapter<myCourseSlider>();

// export const defaultItems: profileState = {
//   ids: [],
//   entities: {},
//   selectedItemId: null,
//   total: 0,
// }

const initialState = myCourseSlidersAdapter.getInitialState({
  selectId: selectItemId,
  sortComparer: sortByName,
  selectedItemId: null,
  isSelectedCourse: false,
  selectedCourse: undefined,
  rtl: true,
  quizzAttempt: undefined,
  quizzAnswers: undefined,
  isSelectedLecture: false,
  isLoading: false,
  currentQuestion: undefined,
  affiliate: false,
  affiliateStatus: undefined,
  quizzResult: undefined,
  percent: undefined,
  currentQuizz: undefined,
  selectedLecture: undefined,
  discussion: undefined,
  isLoggedIn: false,
  route: undefined,
  status: 'active',
  currentVideo: undefined,
  total: 0
});


const _reducer = createReducer(
  initialState,

  on(profileActions.setCurrentRoute, (state) => {
    return { ...state, isLoading: true }
  }),

  on(profileActions.amIanAffiliateSuccessed, (state, { result }) => {
    return { ...state, affiliate: result }
  }),
  on(profileActions.CompletedCurrentLecture, (state, { course }) => {
    return { ...state, selectedCourse: course }
  }),
  on(profileActions.whatIsMyAffiliateStatusSuccessed, (state, { result }) => {
    return { ...state, affiliateStatus: result, affiliate: (result != "I AM NOT AN AFFILIATE") ? true : false }
  }),
  on(profileActions.updateCourseProgressSuccess, (state, { course }) => {
    return myCourseSlidersAdapter.updateOne(course, { ...state, percent: course.changes.percent });
  }),
  // on(profileActions.updateCourseProgress, (state) => {
  //   return { ...state, }
  // }),
  on(profileActions.getMyCourses, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.setQuizzResult, (state, { result }) => {
    return { ...state, quizzResult: result }
  }),
  on(profileActions.clearQuizzResult, (state) => {
    return { ...state, quizzResult: undefined }
  }),
  on(profileActions.getMyCourse, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.clearCurrentCourse, (state) => {
    return { ...state, selectedCourse: undefined, isSelectedCourse : false , isSelectedLecture : false , }
  }),
  on(profileActions.startQuizzAttempt, (state, { attempt }) => {
    return { ...state, quizzAttempt: attempt }
  }),
  on(profileActions.getLecture, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.notLoadingAnyMore, (state) => {
    return { ...state, isLoading: false }
  }),
  on(profileActions.isLoadingRightNow, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.resetLoader, (state) => {
    return { ...state, isLoading: false }
  }),
  on(profileActions.getNextLecture, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.setCurrentQuestion, (state, { question }) => {
    return { ...state, currentQuestion: question }
  }),
  on(profileActions.clearCurrentQuestion, (state) => {
    return { ...state, currentQuestion: undefined }
  }),
  on(profileActions.getQuizzCard, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.getCurrentVideoLinkSigned, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.getAllCommentsOnVideo, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.directionOfSystem, (state, { dir }) => {
    return { ...state, rtl: dir }
  }),
  on(profileActions.addNewCommentOnVideo, (state) => {
    return { ...state, isLoading: true }
  }),
  on(profileActions.attendQuizz, (state) => {
    return { ...state, isLoading: true }
  }),



  on(profileActions.logoutAndClearEverything, (state) => {

    return myCourseSlidersAdapter.removeAll({...state, currentVideo: null, discussion: null,
      currentQuestion : null,currentQuizz : null, quizzAttempt: null,
      isSelectedCourse: false , isSelectedLecture : false ,
        percent : 0, selectedLecture : null , selectedCourse: null });
  }),
  on(profileActions.attendQuizzSuccessed, (state, { result }) => {
    return { ...state, isLoading: false, quizzResult: result }
  }),
  on(profileActions.getMyCourseSuccessed, (state, { courses }) => {
    if (courses) {
      return myCourseSlidersAdapter.setAll(courses, { ...state, isLoading: false });
    } else {
      return { ...state, isLoading: false }
    }

    // return { ...state, isLoading: false }
  }),
  on(profileActions.getMyCourseDetailsSuccessed, (state, { course }) => {
    return { ...state, isLoading: false, isSelectedCourse: true, selectedCourse: course, selectedLecture: course.currentLecture }
  }),
  on(profileActions.getCourseDetailsForUpdateSuccess, (state, { course }) => {
    return { ...state, isLoading: false, isSelectedCourse: true, selectedCourse: course }
  }),
  on(profileActions.getMyCourseAfterAnUpdate, (state, { course }) => {
    return { ...state, isLoading: false, isSelectedCourse: true, selectedCourse: course }
  }),
  on(profileActions.getLectureSuccess, (state, { lecture }) => {
    return { ...state, isLoading: false, isSelectedLecture: true, selectedLecture: lecture, currentVideo: lecture.currentVideo }
  }),
  on(profileActions.getCurrentVideoLinkSignedSuccessed, (state, { link }) => {
    return { ...state, isLoading: false, currentVideo: link }
  }),
  on(profileActions.addAnswerToQuizzQuestion, (state, { answers }) => {
    return { ...state, quizzAnswers: answers }
  }),
  on(profileActions.SetCoursePercent, (state, { percent }) => {
    return { ...state, isLoading: false, percent: percent }
  }),
  on(profileActions.clearContent, (state) => {
    return { ...state, currentVideo: undefined, selectedLecture: undefined }
  }),
  on(profileActions.isNotLoggedIn, (state) => {
    return { ...state, isLoggedIn: false }
  }),
  on(profileActions.isLoggedIn, (state) => {
    return { ...state, isLoggedIn: true }
  }),
  on(profileActions.getQuizzSuccess, (state, { quizz }) => {
    return { ...state, isLoading: false, currentQuizz: quizz }
  }),
  on(profileActions.getAllCommentsOnVideoSuccess, (state, { discussions }) => {
    return { ...state, isLoading: false, discussion: discussions }
  }),
  on(profileActions.clearQuizzAndAnswers, (state) => {
    return { ...state, quizzAnswers: undefined, quizzAttempt: undefined, currentQuestion: undefined, currentQuizz: undefined }
  }),


)





export const profileReducerFeatureKey = 'profile';
export const profileFeature = createFeatureSelector<profileState>(profileReducerFeatureKey);


export const getSeletedItemId = (state: profileState) => state.selectedItemId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = myCourseSlidersAdapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectEntities;

// select the total user count
export const selectUserTotal = selectTotal;


export const getMyCourses = createSelector(
  profileFeature, selectAll
)


export const getCoursePercent = createSelector(
  profileFeature, (state: profileState) => {
    return state.percent
  })


export const getCurrentQuizzAttempt = createSelector(
  profileFeature, (state: profileState) => {
    return state.quizzAttempt
  })


export const getDirectionOfScreen = createSelector(
  profileFeature, (state: profileState) => {
    return state.rtl
  }
)


export const getAllQuizzAnswers = createSelector(
  profileFeature, (state: profileState) => {
    return state.quizzAnswers
  }
)

export const CurrentCourse = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.selectedCourse
  }
)



export const QuizzResult = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.quizzResult
  }
)


export const amIAnAffiliate = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.affiliate
  }
)



export const getCurrentQuestion = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.currentQuestion
  }
)


export const affiliateStatus = createSelector(
  profileFeature, (state: profileState) => {
    return state.affiliateStatus
  }
)

export const discussios = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.discussion
  }
)




export const CurrentQuizz = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.currentQuizz
  }
)


export const CurrentRoute = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.route
  }
)


export const CurrentSignedVideoUrl = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.currentVideo
  }
)


export const isLoggedIn = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.isLoggedIn
  }
)


export const isLoading = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.isLoading
  }
)

export const CuurentLecture = createSelector(
  profileFeature,
  (state: profileState) => {
    return state.selectedLecture
  }
)

// export const totalCart = createSelector(
//   profileFeature,
//   (state: profileState) => {
//     const allItems = Object.values(state.entities);
//     return allItems.reduce((a, b) => a + b.productPrice, 0);
//   })


export const totalItems = createSelector(
  profileFeature,
  (state: profileState) => {
    const allItems = Object.values(state.entities);
    return allItems.length
  })


// export const totalOPrice = createSelector(
//   profileFeature,
//   (state: profileState) => {
//     const allItems = Object.values(state.entities);
//     return allItems.reduce((a, b) => a + b.productOPrice, 0);
//   })


// export const marchantId = createSelector(
//   profileFeature,
//   (state: profileState) => {
//     return state.paymentintent
//   }
// )

export function persistStateReducer(_reducer: ActionReducer<profileState>) {
  const localStorageKey = '__profile';
  return (state: profileState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = sessionStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    sessionStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}

export function profileReducer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<profileState>) {
  return (state: profileState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}


