<!-- <div class="sis">
  {{error}}
</div>


<h1 style="opacity: 0;">
  {{'SYSTEM.ACADEMYNAME' | translate }}
</h1>


<ng-container *ngIf="(loading |async) == true ">
  <sm-loader></sm-loader>

</ng-container>

<ng-container *ngIf="(loading | async) == false">
  <sm-cslider [id]="1" [cardType]="'course'" (viewAllSlides)="goToAll($event)" [rtl]=true
    (selectedCard)="getItem($event,1 )" [itemHeight]="280" [transition]="'slowmo'" [data]='data$ | async'
    [header]='header1'>
  </sm-cslider>
</ng-container>

<ng-container *ngIf="(loading | async) == false">
  <sm-cslider [id]="3" [cardType]="'category'" (viewAllSlides)="goToAll($event)" [rtl]=true
    (selectedCard)="getItem($event,4)" [itemHeight]="0" [scale]="'1:1'" [transition]="'power2'"
    [data]='packages$ | async' [header]='header3'>
  </sm-cslider>
</ng-container>

<ng-container *ngIf="(loading | async) == false">
  <sm-cslider [id]="4" [cardType]="'trainer'" (viewAllSlides)="goToAll($event)" [rtl]=true
    (selectedCard)="getItem($event,3)" [itemHeight]="350" [scale]="'16:9'" [transition]="'slowmo'"
    [data]='consultancies$ | async' [header]='header4'>
  </sm-cslider>
</ng-container>









<!--

<sm-cslider [id]="2" [cardType]="'course'" (viewAllSlides)="goToAll($event)" [rtl]=true [scale]="'14:9'"
  (selectedCard)="getItem($event,2)" [itemHeight]="400" [transition]="'elastic'" [data]='data5' [header]='header2'>
</sm-cslider>
<sm-cslider [id]="3" [cardType]="'category'" (viewAllSlides)="goToAll($event)" [rtl]=true
  (selectedCard)="getItem($event,3)" [itemHeight]="350" [scale]="'14:9'" [transition]="'power2'" [data]='data5'
  [header]='header3'>
</sm-cslider>
<sm-cslider [id]="4" [cardType]="'category'" (viewAllSlides)="goToAll($event)" [rtl]=true
  (selectedCard)="getItem($event,3)" [itemHeight]="350" [scale]="'16:9'" [transition]="'slowmo'" [data]='data5'
  [header]='header4'>
</sm-cslider>
<sm-cslider [id]="5" [cardType]="'video'" (viewAllSlides)="goToAll($event)" [rtl]=true [scale]="'16:9'"
  (selectedCard)="getItem($event,4)" [itemHeight]="350" [transition]="'power1'" [data]='data5' [header]='header5'>
</sm-cslider> --> -->
