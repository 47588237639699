import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountService } from '../services/account.service';

//declare var LocalStorage: Storage;

@Injectable()
export class AuthGuard2  {

  userProfile: any;

  // , @Inject(LocalStorage) private localStorage
  constructor(private router: Router, private _account: AccountService, private _store: Store<any>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    var id = route.queryParamMap.get('clientId')
    if (typeof window !== 'undefined') {
      if (localStorage.getItem('__bb__prf')) {
        // logged in so return true
        this.userProfile = localStorage.getItem('__bb__prf');
        // this._account.test().subscribe((data) => {
        //   return true;
        // });

        return true;
      }
    }


    this._store.dispatch({ type: '[Profile] Stop Loading' });
    // not logged in so redirect to login page with the return url

    let returnUrl = state.url || (id) ? 'clientId/' + id + '/dashboard' : '/dashboard';

    if (this.userProfile && this.userProfile.bsuper) {

      this.router.navigate(['clientId/' + id + '/supervision'], { queryParams: { returnUrl: state.url } });
    } else if (this.userProfile && !this.userProfile.bsuper) {

      this.router.navigate(['clientId/' + id + '/dashboard'], { queryParams: { returnUrl: state.url } });
    } else {
      this.router.navigate(['login/?clientId/' + id], { queryParams: { returnUrl: state.url } });
    }
    return false;
  }


}
