import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[smloadmore]'
})
export class LoadmoreDirective {

  @Output() scrollingFinished = new EventEmitter<void>();

  emitted = false;

  @HostListener("window:scroll", [])
  onScroll(): void {
    // console.log('fdsf dsfd fds');

    if (!this.emitted && (window.scrollY > 200)) {
      this.emitted = true;
      this.scrollingFinished.emit();
    } else {
      this.emitted = false;
      // this.scrollingFinished.emit();
    }
  }

}
