export * from './categories';
export * from './courses';
export * from './unites'
export * from './typesOfThings';
export * from './materials';
export * from './quizzes';
export * from './prices';
export * from './coupons';
export * from './rules';
export * from './assignments';
export * from './affiliates';
export * from './b2b';
export * from './settings';
export * from './slides';
export * from './profile';
export * from './trainer';
export * from './learningplanner' ;
export * from './newprofile';
