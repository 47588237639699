export class LearningPlannerDTO {
  Id?: number ;
  id?: number ;
  userId : number ;
  weekNo: number ;
  fromDate : Date ;
  toDate : Date ;
  totalToAchieve : number ;
  reminder : boolean ;
  isCompleted : boolean ;

}



export class myLearningPlan {
  planId : number ;
  fromDate  : Date
  toDate : Date
  progress: number ;
  daysLeft : number ;
}
