import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sm-selectfile',
  templateUrl: './selectfile.component.html',
  styleUrls: ['./selectfile.component.scss']
})
export class SelectfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
