import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountService } from '../services/account.service';
import { AuthenticationService } from './authentication.service';

//declare var LocalStorage: Storage;

@Injectable()
export class AuthGuard  {

  // , @Inject(LocalStorage) private localStorage
  constructor(private router: Router, private _account: AccountService,
    private _route: ActivatedRoute, private authenticationService: AuthenticationService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authenticationService.userValue;
    var id = this._route.snapshot.queryParamMap.get('clientId')
    // console.log(this._route.url);
    // console.log(id);
    
    if (user) {
      // logged in so return true
      return true;
    } else {
      if (id) {
        // console.log('there is id');
        
        this.router.navigate(['/login'], { queryParams: { clientId: id } });
      } else {
        // console.log('there is No id');
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      }

      return false;
    }
  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

  //   if (typeof window !== 'undefined') {
  //     if (localStorage.getItem('profile')) {
  //       // logged in so return true

  //       this._account.test().subscribe((data) => {
  //         return true;
  //       });

  //       return true;
  //     }
  //   }


  //   this._store.dispatch({ type: '[Profile] Stop Loading' });
  //   // not logged in so redirect to login page with the return url
  //   this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
  //   return false;
  // }


}
