import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export enum AlertType {
  Information = 1,
  Success = 2,
  Warning = 3,
  Error = 4,
  Primary = 5,
}

export interface AlertData {
  title: string;
  body: string;
  type: AlertType;
}



@Component({
  selector: 'elt-alert-message',
  templateUrl: './alertmessage.component.html',
  styleUrls: ['./alertmessage.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AlertMessageComponent implements OnInit {


  alertType: AlertType;
  alertTitle: string;
  alertBody;
  alertTypeClass = ''

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertData, private _dialog: MatDialogRef<AlertMessageComponent>) {
    this.alertBody = data.body;
    this.alertTitle = data.title;
    this.alertType = data.type;
    switch (this.alertType) {
      case AlertType.Error:
        this.alertTypeClass = 'dangers'
        break;
      case AlertType.Information:
        this.alertTypeClass = 'infos'
        break;
      case AlertType.Warning:
        this.alertTypeClass = 'warnings'
        break;
      case AlertType.Success:
        this.alertTypeClass = 'successs'
        break;
      case AlertType.Primary:
        this.alertTypeClass = 'primarys'
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this._dialog.close();
    // }, 2000);
  }

}
