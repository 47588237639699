<div class="mas-who-we-are">
  <h1>
    من نحن
  </h1>
</div>

<!-- 
<div class="mas-the-company">
  <article>

    تأسست ماستري أكاديمي للتدريب والتطوير Mastery Academy Training and Development في عام 2007 في المملكة المتحدة –
    بريطانيا، وتوسعت فروعها في كل من الأردن 2010 و ماليزيا 2017 لتقدم أعمالها وخدماتها في كل من الشرق الأوسط وجنوب آسيا.

    وتقدم ماستري أكاديمي خدمات التدريب القيادي والإداري للقيادات العليا والوسطى في القطاعات الحكومية والخاصة.

    كما أنها دشنت أكاديمية التدريب الألكتروني في مطلع عام 2018 لتقدم خدماتها في التدريب الالكتروني حول العالم.

    وتقدم الأكاديمية خدمة Customized Online Training Academy للشركات والهيئات الحكومية. للإطلاع على الأكاديمية
    Emasteryacademy.com
  </article>
</div>

<hr>

<div class="mas-vision">

  <h1>رؤيتنا</h1>
  <br>
  <article>

    نسعى لأن نصل إلى شهادات وتوصيات عالمية بأننا الأفضل في تدريب وتطوير القيادات الإدارية والتقنية في القطاع الحكومي
    والخاص.

  </article>

</div>
<hr>
<div class="mas-mission">
  <h1>رسالتنا</h1>
  <br>
  <article>

    نقدم برامج تدريبية قائمة على الاحتياجات النوعية موجهة نحو مساعدة القادة والمدراء على تحقيق أهدافهم المهنية
    والوظيفية.


  </article>
</div>




<hr>
<div class="mas-our-team">
  <h1>فريقنا</h1>
  <br>
  <article>


    نسعى لأن نصل إلى شهادات وتوصيات عالمية

    تعمل ماستري أكاديمي مع أفضل الخبراء والمدربين في مختلف المجالات وتقدم خدماتها بالتعاون مع المختصين وأصحاب الكفائة
    والخبرة المهنية العالية في مجال التدريب والاستشارات.

    كما أننا نوظف فقط من يستطيع أن يسعد عملائنا ويقدم لهم خدمة تفوق التوقعات. فريق عملنا هم موظفين دائمي الابتسامة
    ومتفائلين
    ويرون النجاح من عيون العميل. هذه حقيقة وليست صيغة مبالغة. جرب أن تتواصل معهم يوماً ما.

    بأننا الأفضل في تدريب وتطوير القيادات الإدارية والتقنية في القطاع الحكومي والخاص.


  </article>
</div>


<div class="mas-our-services">
  <h1>خدماتنا</h1>
  <br>

  <article>
    التدريب الإحترافي
    التطوير والاستشارات
    التدريب الإلكتروني
    الحقائب التدريبية

  </article>

</div> -->
