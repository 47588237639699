import { Router, RouterStateSnapshot } from '@angular/router';
import { videoCard } from '../_models/video';
import { consultancyCard } from '../_models/consultancies';
import { courseCard } from '../_models/cards';
import { courseSlide, myQuizzesN } from '../_models';

import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable()
export class AccountService {


  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string, private _router: Router, private _store: Store<any>,) {
    this.canGoToCoures.next(true);
  }


  getAll(controller: any) {
    return this.http.get(`${this.apiUrl}${controller}`);
  }


  canGoToCoures = new BehaviorSubject<boolean>(true);
  canMoveNext = new BehaviorSubject<boolean>(null);

  get enableBackToCourses$(): Observable<boolean> {
    return this.canGoToCoures.asObservable();
  }


  get enableNextLessonBtn() {
    return this.canMoveNext.value;
  }
  set enableNextLessonBtn(status) {
    this.canMoveNext.next(status);
  }


  finishCurrenctLesson = new BehaviorSubject<boolean>(false);
  get finishCurrentLesson() {
    return this.finishCurrenctLesson.value
  }
  set finishCurrentLesson(status) {
    this.finishCurrenctLesson.next(status);
  }



  getProfile(url) {
    return this.http.get(this.apiUrl + '/v2/url/' + url);
  }


  ratecourse(bookmark) {
    return this.http.post(this.apiUrl + '/v1/ratecourse', bookmark);
  }
  canIRateCourse(courseId) {
    return this.http.get(this.apiUrl + '/v1/didirate/' + courseId);
  }
  retryMatching(bookmark) {
    return this.http.post(this.apiUrl + '/v1/retry_matching', bookmark);
  }

  retrySorting(bookmark) {
    return this.http.post(this.apiUrl + '/v1/retry_sorting', bookmark);
  }

  retryFilling(bookmark) {
    return this.http.post(this.apiUrl + '/v1/retry_filling', bookmark);
  }

  retryDecide(bookmark) {
    return this.http.post(this.apiUrl + '/v1/retry_decision', bookmark);
  }

  retryCase(bookmark) {
    return this.http.post(this.apiUrl + '/v1/retry_case', bookmark);
  }

  retryQuiz(bookmark) {
    return this.http.post(this.apiUrl + '/v1/retry_quiz', bookmark);
  }




  addOrModifyGlossaryConcept(concept) {
    return this.http.post(this.apiUrl + '/api/courses/concept/', concept);
  }


  whatIsMyAffiliateStatus(userId) {
    return this.http.get(this.apiUrl + '/v1/affiliatestatus/' + userId);
  }


  addTagForAddToCart(userId, courseId) {
    return this.http.get(this.apiUrl + '/v1/tagaddtocart/' + userId + '-' + courseId);
  }

  addTagForCheckout(userId, courseId) {
    return this.http.get(this.apiUrl + '/v1/tagcheckout/' + userId + '-' + courseId);
  }



  getCourseForum(courseId) {
    return this.http.get(this.apiUrl + '/v1/forum/' + courseId);
  }



  getSigned(videoId, courseId, userId) {
    return this.http.get(this.apiUrl + '/v1/signed?videoId=' + videoId + '&courseId=' + courseId + '&userId=' + userId, this.Withoujwt());
  }


  getCourseWiki(courseId) {
    return this.http.get(this.apiUrl + '/v1/wiki/' + courseId);
  }


  getForumTopicReplies(courseId) {
    return this.http.get(this.apiUrl + '/v1/topicreplies/' + courseId);
  }
  getCaseStudyCommentsAndReplies(courseId) {
    return this.http.get(this.apiUrl + '/v1/casereplies/' + courseId);
  }

  amIAnAffiliate(userId) {
    return this.http.get(this.apiUrl + '/v1/amiaffiliate/' + userId)
  }


  addNewBookmark(bookmark) {
    return this.http.post(this.apiUrl + '/v1/bookmark', bookmark);
  }


  removeBookmak(bookmarkid) {
    return this.http.delete(this.apiUrl + '/v1/bookmark/' + bookmarkid);
  }



  getMyttendanceInCaseStudy(attrs) {
    return this.http.post(this.apiUrl + '/v1/caseattendance', attrs);
  }
  attendCaseStudy(attrs) {
    return this.http.post(this.apiUrl + '/v1/attendcase', attrs);
  }

  evaluateCasestudyAttendance(forum) {
    return this.http.post(this.apiUrl + '/v1/evaluatecase/', forum);
  }


  getMybookMarks(attrs) {
    return this.http.post(this.apiUrl + '/v1/bookmarks', attrs);
  }

  getSubmissionsOfAssignments(assignmentId, userId) {
    return this.http.get(this.apiUrl + '/v1/submissions/' + assignmentId + '-' + userId, this.jwt());
  }

  updateAssignment(file: File, userId): Observable<any> {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(userId));
    return this.http.post<any>(this.apiUrl + '/v1/markassignment', input, this.jwt2());
  }


  getMyCertificates(attrs) {
    return this.http.get(this.apiUrl + '/v1/mycertificates/' + attrs);
  }


  getMyNotifications(attrs) {
    return this.http.get(this.apiUrl + '/v1/notifications/' + attrs);
  }


  getCasestudyAttendance(wikiId, userId) {
    return this.http.get(this.apiUrl + '/v1/caseattendances/' + wikiId + '-' + userId);
  }

  getMyInfo(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/myinfo');
  }
  getAllMyBookmarksForPanel(userId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/mybookmarks/' + userId);
  }


  getMyTraineeInfo(userId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/info/' + userId);
  }


  getMyPaymentInfo(userId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/mypayments/' + userId);
  }


  getMyPreCourseQuizz(courseId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/prequizz/' + courseId);
  }

  changePassword(form) {
    return this.http.post(this.apiUrl + '/v1/changepassword', form);
  }

  sendRequest(order): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/payrequest', order);
  }


  finishContent(order): Observable<any> {
    // let input = new FormData();
    // input.append("attrs", JSON.stringify(order));
    return this.http.post<any>(this.apiUrl + '/v1/completeContent', order);
  }


  TrackProgress(order): Observable<any> {
    let input = new FormData();
    input.append("attrs", JSON.stringify(order));
    return this.http.post<any>(this.apiUrl + '/v1/track-progress', input, this.jwt2());
  }


  payTheBill(order): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/billpay', order);
  }



  addTopicReply(order): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/topicreply', order);
  }

  addCaseStudyComment(order): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/casecomment', order);
  }



  addArticleDetailOnWiki(order): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/articledetail', order);
  }



  updateOrder(order): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/updateorder', order);
  }


  updateBankInfoForAffiliate(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/bankinfo', attrs);
  }

  updateMyInfo(attrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/updateinfo', attrs);
  }



  getMyCourses(sub: string, pageSize, pageNumber, keywords): Observable<any> {

    return this.http.get<any>(`${this.apiUrl}/v1/mycourses?pageSize=${pageSize}&pageNumber=${pageNumber}&keywords=${keywords}`);
  }


  getMyAssignedCoursesToMe(): Observable<any> {

    return this.http.get<any>(this.apiUrl + '/v2/assigned-tome');
  }




  getLearningPathCourses(leanringPathId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v2/learningpath-courses?learningPathId=' + leanringPathId);
  }


  getCategoryCourses(leanringPathId): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v2/category?categoryId=' + leanringPathId);
  }
  getMyContinueToWatch(): Observable<any> {

    return this.http.get<any>(this.apiUrl + '/v2/continue-watching');
  }


  getMyAssignedCourses(sub: string): Observable<any> {

    return this.http.post<any>(this.apiUrl + '/v1/myassignedcourses', { sub: sub });
  }


  getMyCourseProgress(attrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourseupdate', attrs);
  }


  getMyAssignments(attrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/myassignments', attrs);
  }


  getAnnouncementsForCourse(detail) {
    return this.http.post(this.apiUrl + '/api/courses/getannouncements/', detail);
  }


  getin(userId): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/account/getin', userId, this.Withoujwt());
  }


  changepasswordx(model): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/account/changepassword', model, this.Withoujwt());
  }


  exportpdf(id) {
    return this.http.post(this.apiUrl + '/v1/exportpdf ', id, this.pdfJWT());
  }





  exportpdfJSON(id) {
    // const options = { headers: this.jwt(), responseType: 'blob' };
    return this.http.post(this.apiUrl + '/v1/exportpdf', id, this.jwt());

  }

  initCourseRecordForTrainee(attrs) {
    return this.http.post(this.apiUrl + '/v1/init_course_log', attrs).pipe(catchError(error => this.handleError(error)));
  }

  getMyNewCourse(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse', attrs);
  }

  getMyCourse(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/myoldcourse', attrs);
  }



  UpdateProgress$ = new BehaviorSubject<boolean>(true);
  CurrentLecture$ = new BehaviorSubject<number>(0);

  getCurrentProgressForCourse(userId, courseId) {
    return this.http.get(`${this.apiUrl}/v2/current-progress?courseId=${courseId}`, this.jwt());
  }
  getTheNextLecture(userId, courseId) {
    return this.http.get(`${this.apiUrl}/v2/next-lecture?courseId=${courseId}`, this.jwt());
  }


  getMyCourseWiki(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-wiki', attrs, this.jwt());
  }
  getMyCourseCases(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-cases', attrs, this.jwt());
  }
  getMyCourseGlossary(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-glossary', attrs, this.jwt());
  }
  getMyCourseForum(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-forum', attrs, this.jwt());
  }
  getMyCourseLecture(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-lecture', attrs, this.jwt());
  }
  getMyCourseAssignments(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-assignments', attrs, this.jwt());
  }
  getMyCourseQuizzes(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-quizzes', attrs, this.jwt());
  }
  getMyCourseRecord(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-record', attrs, this.jwt());
  }
  getMyCourseUnitsNames(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-units-names', attrs, this.jwt());
  }
  getMyCourseUnitDetails(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-unit-details', attrs, this.jwt());
  }
  getMyCourseContents(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycourse-units', attrs, this.jwt());
  }

  nextlecture(attrs: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/nextlecture?courseId=' + attrs);
  }

  getMyNotebook(attrs: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/notes/' + attrs);
  }


  getCoursesByCategoryName(categoryName: string) {
    return this.http.get(`${this.apiUrl}/v1/category_courses/${categoryName}`);
  }

  getLibraryCategories(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/Courses/categories');
  }


  deleteNoteFromMyNotebook(attrs: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/v1/note/' + attrs);
  }


  addOrMofiyNote(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/note/', attrs);
  }



  getMyCourseUnits(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mylectures', attrs);
  }
  getLearningPlanProgress(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/planprogress', attrs);
  }

  getMyLecture(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mylecture', attrs);
  }
  mylecturevideo(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mylecturevideo', attrs);
  }
  mylecturecontents(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mylecturecontents', attrs);
  }


  ddlg(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v2/ddlg', attrs);
  }


  getMyNextLecture(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/nextlecture', attrs);
  }



  getMyQuizzInfo(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/myquizzinfo', attrs);
  }

  submitQuizzAttempt(attrs: any, connectionId: string): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/submitquizz/' + connectionId, attrs);
  }



  getQuizzCard(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/myquizz', attrs);
  }



  addOrModifyCommentOnVideo(attrs: any): Observable<any> {
    // console.log('dsadfsalkfdsgjfdds');

    return this.http.post<any>(this.apiUrl + '/v1/comment', attrs);
  }

  removeCommentOnVideo(commentId: any): Observable<any> {
    // console.log('dsadfsalkfdsgjfdds');

    return this.http.delete<any>(this.apiUrl + `/v1/comment?commentId=${commentId}`);
  }

  getMyCommentsOnDiscussion(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/mycomments', attrs);
  }


  getGlossary(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/glossary', attrs);
  }

  getWikiArticle(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/wikiarticle', attrs);
  }


  getForumTopic(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/forumtopic', attrs);
  }

  getCourseCasestudy(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/coursecasestudy', attrs);
  }



  attendQuizz(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/attendquizz', attrs);
  }




  getMyQuizzesList(attrs: any): Observable<myQuizzesN[]> {
    return this.http.get<myQuizzesN[]>(this.apiUrl + '/v1/myquizzeslist/' + attrs);
  }


  getMyConsultancies(attrs: any): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/myconsultancies/' + attrs);
  }

  getMyConsultancySessions(attrs: any): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/csessions/' + attrs);
  }


  getCalendlyLink(attrs: any): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/cal_link/' + attrs);
  }


  getMyConsultancyInfo(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/myconultancyinfo/', attrs);
  }


  getMilestonesForConsultancy(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/milestones/', attrs);
  }



  getTrainersForCourseInVideosQuestions(attrs: any): Observable<myQuizzesN[]> {
    return this.http.get<myQuizzesN[]>(this.apiUrl + '/v1/trainersq/' + attrs);
  }



  setNewLearningPlan(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/myplan', attrs);
  }


  getProgressOfMyPlan(userId: any): Observable<number> {
    return this.http.get<number>(this.apiUrl + '/v1/myplan/' + userId);
  }


  getAllMyPlans(userId: any): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/myplans/' + userId);
  }


  checkYourCertificate(userId: any): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/check/' + userId, this.Withoujwt());
  }


  getMyCurrentPlan(userId: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/currentplan/' + userId);
  }
  deletePlan(planId: any, userId: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/v1/plan/' + planId + '-' + userId);
  }

  getMyAssignmentsList(attrs: any): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/myassignmentslist/' + attrs);
  }


  newAssignmentsList(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/myassignmentslist', attrs);
  }

  getLectureDisccuion(attrs: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/v1/disucssion/' + attrs);
  }

  removeCommentOnDiscussion(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/removecomment', attrs);
  }
  applyForTrainerWithus(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/trainerapplication', attrs, this.Withoujwt());
  }



  attendActivityQuizz(attrs: any, connectionId): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/activity_quizz/' + connectionId, attrs);
  }
  attendActivityMatching(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/activity_match', attrs);
  }
  attendActivityFillingWords(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/activity_filling', attrs);
  }
  attendActivityLogicalOrder(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/activity_logical', attrs);
  }
  attendActivityCase(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/activity_case', attrs);
  }
  attendActivityDecisionmaking(attrs: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/activity_decision', attrs);
  }

  getSignedURL(attrs) {
    return this.http.post(this.apiUrl + '/v1/signurl/', attrs);
  }



  test() {
    return this.http.get(this.apiUrl + '/api/users/test');
  }

  reactivate(email) {
    return this.http.get(this.apiUrl + '/api/users/reactivate/' + email);
  }


  private currentData = new BehaviorSubject(false);
  private data = new BehaviorSubject(false);
  currentLectureState = this.currentData.asObservable();
  currentLessonState = this.data.asObservable();

  changeData(data: boolean) {
    this.data.next(data)
  }

  setLectureState(data: boolean) {
    this.currentData.next(data)
  }



  updateTheStatusOfCourse(courseId, userId) {
    // let coureRecord = JSON.parse(localStorage.getItem('cs-r-' + courseId));
    this.getMyCourse({ courseId: courseId, userId: userId }).subscribe((data) => {
      localStorage.setItem('cs-r-' + courseId, JSON.stringify(data.courseRecord));
    })
  }



  updateAvatar(file: File, userId): Observable<any> {
    let input = new FormData();
    input.append("file", file);
    return this.http.post<any>(this.apiUrl + '/v1/avatar/' + userId, input, this.jwt2());
  }



  uploadAssignment(file: File, userId): Observable<any> {
    let input = new FormData();
    input.append("file", file);
    input.append("jsonData", JSON.stringify(userId));
    return this.http.post<any>(this.apiUrl + '/v1/assignment', input, this.jwt2());
  }


  updateConnectionId() {
    return this.http.put(this.apiUrl + '/v1/usrconn', {
      connectionId: sessionStorage.getItem('connectionId')
    });
  }

  getB2BTotalDepartmentUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_deps', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getB2BProgressForCourse(courseId, userId) {
    return this.http.get(this.apiUrl + '/v1/bb_cour_prgs/' + courseId + '-' + userId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  assignCourses(attrs) {
    return this.http.post(this.apiUrl + '/v1/assignb2bcourse', attrs, this.jwt());
  }
  unAssignCourses(attrs) {
    return this.http.post(this.apiUrl + '/v1/unassignb2bcourse', attrs, this.jwt());
  }

  getB2BUsersInDepartment() {
    return this.http.post(this.apiUrl + '/v1/usersindepartment', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getB2bEmployeeCourses(employeeId) {
    return this.http.get(this.apiUrl + '/v1/usr_courses/' + employeeId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }
  getB2bCompanyCourses() {
    return this.http.get(this.apiUrl + '/v1/comp_courses/', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }
  getB2bDepartmentCourses(departmentId) {
    return this.http.get(this.apiUrl + '/v1/dpt_courses/' + departmentId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }
  getB2BDepartments() {
    return this.http.get(this.apiUrl + '/v1/mydepartments/', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }



  deactivateLearningPath(lpId) {
    return this.http.get(this.apiUrl + '/v2/deactivate-b2b-learning-path?learningPathId=' + lpId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  deleteLearningPathAssigned(lpId) {
    return this.http.delete(this.apiUrl + '/v2/delete-b2b-learning-path?learningPathId=' + lpId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getB2BCourseLibrary() {
    return this.http.get(this.apiUrl + '/v1/courses_lib/', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getB2BCourseLibraryNIds() {
    return this.http.get(this.apiUrl + '/v1/courses-lib/', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BTotalEmployeesUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_emps', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BTotalCoursesUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_courses', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BTotalConsultanciesUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_consuls', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BTotalTakenConsultanciesUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_takensuls', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BTotalGraduatedUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_grads', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BMostGraduatedCourseUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_mogr_cours', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getB2BAssignedCoursesUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tas_cours', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BMostViwedCourseUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_mos_cour', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BTotalQuizzesAttendedUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_quiz', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BTotalAssignementAppliedUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_assin', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BBestUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_bes_depo', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BBestEmployeeUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_bes_empo', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getB2BTotalMinutesViewedUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_mins', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getB2BTotalMinutesShouldBeViewedUnderMyPermissions() {
    return this.http.get(this.apiUrl + '/v1/bb_tot_mins_tobeseen', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }



  getOurDepartments() {
    return this.http.get(this.apiUrl + '/v1/departments', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getOurDepartmentReport(departmentId) {
    return this.http.get(this.apiUrl + '/v1/department/' + departmentId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getTraineeNewQuickReport() {
    return this.http.get(this.apiUrl + '/v2/trainee-quick-report/', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getOurDepartmentsNewVersion() {
    return this.http.get(this.apiUrl + '/v2/b2b-departments', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }



  browseavailableLearningPathsForB2b() {
    return this.http.get(this.apiUrl + '/v2/b2b-learningpaths', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getMyB2BConsultanciesRequests(userId) {
    return this.http.get(this.apiUrl + '/v1/mycrequests/' + userId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getMyDivisionConsultancies() {
    return this.http.get(this.apiUrl + '/v1/division_consultancies', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getAllDivisionConsultanciesRequests() {
    return this.http.get(this.apiUrl + '/v1/alldivisions_requests', this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }
  sendB2BConsultancyRequest(attrs) {
    return this.http.post(this.apiUrl + '/v1/request_b2b_consultancy/', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }



  getb2bEmployeeReport(traineeId) {
    return this.http.get(this.apiUrl + '/v1/b2bemployeereport/' + traineeId, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getb2bAssignmentReport(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-learning-path-report', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  getb2bCoursetReport(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-course-progress-report', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }


  updateB2BConsultancyRequest(requestId, status) {
    return this.http.get(this.apiUrl + '/v1/update_b2bc_request/' + requestId + '-' + status, this.jwt()).pipe(catchError(error => this.handleError(error)));;
  }

  getB2BProfileByDomainExtension(courseId) {
    return this.http.get(this.apiUrl + '/v1/b2bprofile/' + courseId, this.jwt());
  }



  getCourseCertificate(attrs) {
    return this.http.post(this.apiUrl + '/v1/course-certificate', attrs, this.jwt());
  }




  addB2BUsersToGroup(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-users-to-group', attrs, this.jwt());
  }



  changeB2BUsersStatus(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-change-status', attrs, this.jwt());
  }



  getCourseConditions(courseId) {
    return this.http.get(this.apiUrl + '/v1/course-conditions/' + courseId, this.Withoujwt());
  }






  getallb2bassignments() {
    return this.http.get(this.apiUrl + '/v2/b2b-assignment', this.jwt());
  }


  newAssignIn(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-assignment', attrs, this.jwt());
  }


  assignmentCourses(assignmentId) {
    return this.http.get(this.apiUrl + '/v2/b2b-assignment-courses-list?assignmentId=' + assignmentId, this.jwt());
  }

  deleteLearningPath(attrs) {
    return this.http.delete(this.apiUrl + '/v2/b2b-learning-path/' + attrs, this.jwt());
  }

  getLearningPath(attrs) {
    return this.http.get(this.apiUrl + '/v2/b2b-learning-path/' + attrs, this.jwt());
  }


  getLearningPaths() {
    return this.http.get(this.apiUrl + '/v2/b2b-learning-paths/', this.jwt());
  }
  nomLearningPath(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-learning-path', attrs, this.jwt());
  }
  nomDepartment(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-department', attrs, this.jwt());
  }


  deDepartment(departmentId) {
    return this.http.delete(this.apiUrl + '/v2/b2b-department/' + departmentId, this.jwt());
  }


  newB2BUser(attrs) {
    return this.http.post(this.apiUrl + '/v2/b2b-user', attrs, this.jwt());
  }


  getMyAssignedCoursesN() {
    return this.http.get(this.apiUrl + '/v2/b2b-assignment-courses/', this.jwt());
  }
  getMyAssignedPaths() {
    return this.http.get(this.apiUrl + '/v2/b2b-assignment-paths/', this.jwt());
  }



  getToContinueWatch() {
    return this.http.get(this.apiUrl + '/v2/b2b-continue/', this.jwt());
  }


  sendQuizAttendanceRequestB2B(attrs) {
    return this.http.post(this.apiUrl + '/v2/quiz-request', attrs, this.jwt());
  }

  getAllQuizAttendanceRequestsB2B(courseId, traineeId, quizzId) {
    return this.http.get(`${this.apiUrl}/v2/quiz-requests?courseId=${courseId}&traineeId=${traineeId}&quizId=${quizzId}`, this.jwt());
  }
  updateQuizAttendanceRequestB2B(request) {
    return this.http.post(this.apiUrl + '/v2/update-quiz-request', request, this.jwt());
  }



  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 401) {
        console.log('HELLO');

        this._store.dispatch({ type: '[Profile] Stop Loading' });
        this._store.dispatch({ type: '[Profile] User Is Not Logged In' });
        this._router.navigate(['/login'], { queryParams: { returnUrl: '/dashboard' } });
      }
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      console.log(error);

    }
    // return an observable with a user-facing error message
    return throwError(error);
  };


  // handleError(error: any) {

  //   var applicationError = error.headers.get('Application-Error');

  //   // either application-error in header or model error in body
  //   if (applicationError) {
  //     return throwError(applicationError);
  //   }

  //   var modelStateErrors: string = '';

  //   // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
  //   for (var key in error.error) {
  //     if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
  //   }

  //   modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
  //   return throwError(modelStateErrors || 'Server error');
  // }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


  private jwt() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
          'Content-Type': 'application/json'
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }

  private jwtx(token) {
    if (typeof window !== 'undefined') {
      let headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      });
      const httpOptions = {
        headers: headers
      };
      return httpOptions;
    }
  }

  private pdfJWT() {
    // const userToken = sessionStorage.getItem("authorizationData_inframeOne");
    // create authorization header with jwt token
    var profile = JSON.parse(localStorage.getItem('profile'));

    let token = profile.token;
    let tokenValue = '';
    if (token !== '') {
      tokenValue = 'Bearer ' + token;
      // requestToForward = req.clone({ setHeaders: { Authorization: tokenValue } });
    }

    let headers = new HttpHeaders({
      'Authorization': tokenValue,
    });
    const httpOptions = {
      responseType: 'blob' as 'blob',
      headers: headers
    };
    return httpOptions;
  }


}
