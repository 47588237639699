import { SettingsService } from '../../core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { map, filter, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
// import { User } from '../_models/system';
import { Store } from '@ngrx/store';
// import { NgxPermissionsService } from 'ngx-permissions';
import { User } from '../_models';
import { CookieService } from 'ngx-cookie-service';

//declare var LocalStorage: Storage;
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {



  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public timeOut = 0;
  originUrl: string = environment.APIURL;

  constructor(private http: HttpClient,
    private _settings: SettingsService, private _cookie: CookieService,
    private route: ActivatedRoute, private _router: Router, private _store: Store<any>) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();

  }

  public get userValue(): User {
    if (this.userSubject.value) {


      var profile = {
        avatar: this.userSubject.value.avatar,
        name: this.userSubject.value.name,
        email: this.userSubject.value.email,
        userId: this.userSubject.value.userId,
        groupId: this.userSubject.value.groupId,
        bSuper: this.userSubject.value.bSuper,
        bOwner: this.userSubject.value.bOwner,
        trainerId: this.userSubject.value.trainerId,
        sub: this.userSubject.value.id
      }
      const sv = {
        tk: this.userSubject.value.jwtToken,
        rt: this.userSubject.value.refreshToken
      }
      localStorage.setItem('profile', JSON.stringify(profile));
      localStorage.setItem('sv', JSON.stringify(sv));
      // console.log(sv);

      // this._store.dispatch({ type: '[Reports] Get My Coupons', userId: this.userSubject.value.userId });
      this._store.dispatch({ type: '[Profile] User Is Logged In' })
    }
    return this.userSubject.value;
  }



  //, @Inject(LocalStorage) private localStorage
  login(loginName: string, password: string, companyId: number) {
    if (companyId) {
      if (typeof window !== 'undefined') {

        return this.http.post<any>(this.originUrl + '/account/bauthenticate', { loginName: loginName, password: password, companyId: companyId }, { withCredentials: true })
          .pipe(

            // catchError((error) => of(error)),
            switchMap((user) => {
              // console.log(user);
              // return ;
              this.userSubject.next(user);

              this._settings.getSignCookieForMe().subscribe((data: any) => {


                this._cookie.delete('CloudFront-Policy');
                this._cookie.delete('CloudFront-Key-Pair-Id');
                this._cookie.delete('CloudFront-Signature');
                document.cookie = 'CloudFront-Policy=' + data.cookie.policy.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
                document.cookie = 'CloudFront-Key-Pair-Id=' + data.cookie.keyPairId.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
                document.cookie = 'CloudFront-Signature=' + data.cookie.signature.value + ";path=/;domain=" + environment.THECLOUD;
                this._store.dispatch({ type: '[Profile] User Is Logged In' })
              })
              this.startRefreshTokenTimer();
              return of(user);
            }));
      }
    } else {
      return this.http.post<any>(this.originUrl + '/account/authenticate', { loginName: loginName, password: password }, { withCredentials: true })
      // .pipe(map(user => {
      //   // console.log(user);

      //   this.userSubject.next(user);

      //   this._settings.getSignCookieForMe().subscribe((data: any) => {


      //     this._cookie.delete('CloudFront-Policy');
      //     this._cookie.delete('CloudFront-Key-Pair-Id');
      //     this._cookie.delete('CloudFront-Signature');
      //     document.cookie = 'CloudFront-Policy=' + data.cookie.policy.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
      //     document.cookie = 'CloudFront-Key-Pair-Id=' + data.cookie.keyPairId.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
      //     document.cookie = 'CloudFront-Signature=' + data.cookie.signature.value + ";path=/;domain=" + environment.THECLOUD;
      //     this._store.dispatch({ type: '[Profile] User Is Logged In' })
      //   })
      //   this.startRefreshTokenTimer();
      //   return user;
      // }));
    }

    // login successful if there's a jwt token in the response
  }


  login2fa(attrs) {
    return this.http.post<any>(this.originUrl + '/account/2facheck', attrs, { withCredentials: true })
      .pipe(map(user => {
        // console.log(user);

        this.userSubject.next(user);

        this._settings.getSignCookieForMe().subscribe((data: any) => {


          this._cookie.delete('CloudFront-Policy');
          this._cookie.delete('CloudFront-Key-Pair-Id');
          this._cookie.delete('CloudFront-Signature');
          document.cookie = 'CloudFront-Policy=' + data.cookie.policy.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
          document.cookie = 'CloudFront-Key-Pair-Id=' + data.cookie.keyPairId.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
          document.cookie = 'CloudFront-Signature=' + data.cookie.signature.value + ";path=/;domain=" + environment.THECLOUD;
          this._store.dispatch({ type: '[Profile] User Is Logged In' })
        })
        this.startRefreshTokenTimer();
        return user;
      }));
  }






  loginb(loginName: string, password: string) {
    return this.http.post<any>(this.originUrl + '/account/authenticate', { loginName: loginName, password: password }, { withCredentials: true })
      .pipe(map(user => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
    // login successful if there's a jwt token in the response
  }

  logout() {

    var id = this.route.snapshot.queryParamMap.get('clientId')
    const rt: any = JSON.parse(localStorage.getItem('sv'))
    // const jwtToken = (rt) ? JSON.parse(atob(rt.tk.split('.')[1])) : null;
    // console.log(rt.tk);
    if (!rt) return;

    let returnUrl = (id) ? 'login?clientId/' + id : '/login';
    this.http.post<any>(this.originUrl + '/account/revoke-token', { Token: rt.tk }, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    localStorage.removeItem('profile')
    localStorage.removeItem('__bb__prf')
    localStorage.removeItem('permis')
    localStorage.removeItem('sv')
    this.userSubject.next(null);
    this._router.navigate([returnUrl]);
  }

  refreshToken() {
    const rt: any = JSON.parse(localStorage.getItem('sv'))
    const profile: any = JSON.parse(localStorage.getItem('profile'))

    // console.log(profile.sub);

    if (rt) {
      const jwtToken = JSON.parse(atob(rt.tk.split('.')[1]));
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      this.timeOut = timeout;


      if (timeout > 1000 && profile.name) {

        let user: any = {
          avatar: profile.avatar,
          name: profile.name,
          email: profile.email,
          userId: profile.userId,
          bSuper: profile.bSuper,
          bOwner: profile.bOwner,
          id: profile.sub,
          groupId: profile.groupId,
          jwtToken: rt.tk,
          refreshToken: rt.rt
        }


        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return new Observable<User>(user).pipe(map((user) => {
          return user;
        }))
      } else {
        return this.http.post<any>(this.originUrl + '/account/refresh-token', {}, { withCredentials: true })
          .pipe(map((user) => {
            this.userSubject.next(user);
            this.startRefreshTokenTimer();
            return user;
          }));
      }
    } else {
      return this.http.post<any>(this.originUrl + '/account/refresh-token', {}, { withCredentials: true })
        .pipe(map((user) => {
          // console.log(user);

          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          return user;
        }));
    }
  }


  // refreshToken() {
  //   const rt: any = JSON.parse(localStorage.getItem('sv'))
  //   const profile: any = JSON.parse(localStorage.getItem('profile'))
  //   if (rt) {
  //     const jwtToken = JSON.parse(atob(rt.tk.split('.')[1]));

  //     // set a timeout to refresh the token a minute before it expires
  //     const expires = new Date(jwtToken.exp * 1000);
  //     const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //     if (timeout > 1000 && profile.name) {
  //       let user: any = {
  //         avatar: profile.avatar,
  //         name: profile.name,
  //         email: profile.email,
  //         userId: profile.userId,
  //         groupId: profile.groupId,
  //         jwtToken: rt.tk,
  //         refreshToken: rt.rt
  //       }
  //       this.userSubject.next(user);
  //       this.startRefreshTokenTimer();
  //       return new Observable<User>(user).pipe(map((user) => {
  //         return user;
  //       }))
  //     }

  //   }
  //   else {
  //     return this.http.post<any>(this.originUrl + '/account/refresh-token', {}, { withCredentials: true })
  //       .pipe(map((user) => {
  //         this.userSubject.next(user);
  //         this.startRefreshTokenTimer();
  //         return user;
  //       }));
  //   }

  // }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);


    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  load() {
    return
  }



  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  register(model: any) {

    return this.http.post(this.originUrl + '/account/register', model);

  }

  reset(model: any) {

    return this.http.post(this.originUrl + '/account/reset', model);

  }


  //   logout() {
  //     this.http.post(`${this.baseUrl}account/revoke-token`, {}, { withCredentials: true  }).subscribe()
  //     localStorage.removeItem('user');
  //     localStorage.removeItem('token');
  //     $('#menu_popup').modal('hide');
  //     this.router.navigate(['/'])
  //     this.stopRefreshTokenTimer();
  // }

  revokeToken() {
    return this.http.post<any>(this.originUrl + '/account/revoke-token', {}, { withCredentials: true })
  }


  private jwt() {
    // create authorization header with jwt token
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
          'Content-Type': 'application/json'
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }



}
