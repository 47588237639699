import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducer, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { myLearningPlan } from './../../_models/learningplanner';
import * as plannerActions from './planner.actions';



export interface plannerState {
  plan: myLearningPlan;
}



export const myPlanAdapter: EntityAdapter<any> = createEntityAdapter<any>();


const initialState = myPlanAdapter.getInitialState({
  plan: undefined
});


const _reducer = createReducer(
  initialState,

  on(plannerActions.getMyCurrentPlanSuccess, (state, { plan }) => {
    return { ...state, plan: plan }
  }),
);



export const plannerReducerFeatureKey = 'planner';
export const plannerFeature = createFeatureSelector<plannerState>(plannerReducerFeatureKey);


export const getCurrentPlan = createSelector(
  plannerFeature, (state: plannerState) => {
    return state.plan
  });




export function persistStateReducer(_reducer: ActionReducer<plannerState>) {
  const localStorageKey = '__plan';
  return (state: plannerState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}

export function plannerReducer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<plannerState>) {
  return (state: plannerState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}



