import { plannerActions } from './planner.actions';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AccountService } from '../../services/account.service';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';






@Injectable()
export class PlannerEffect {
  constructor(
    private actions$: Actions<plannerActions>,
    private _storage: LocalStorage,
    private _router: Router,
    // public oidcSecurityService: OidcSecurityService,
    private _service: AccountService,
  ) { }


  getMyCurrentPlan$ = createEffect(() => this.actions$.pipe(
    ofType('[Planner] Get My Current Plan'),
    exhaustMap((action: any) => this._service.getMyCurrentPlan(action.userId).pipe(
      map(paymentintent => ({ type: '[Planner] Get My Current Plan Success', plan: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))



  setNewPlan$ = createEffect(() => this.actions$.pipe(
    ofType('[Planner] Set New Learning Plan'),
    exhaustMap((action: any) => this._service.setNewLearningPlan(action.plan).pipe(
      map(paymentintent => ({ type: '[Planner] Set New Learning Plan Success', userId: paymentintent }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))


  setNewPlanSuccessed$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Planner] Set New Learning Plan Success'),
      exhaustMap((action: any) => this._service.getMyCurrentPlan(action.userId).pipe(
        map(paymentintent => ({ type: '[Planner] Get My Current Plan Success', plan: paymentintent }),
          catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
          )
        ))
      )
    ),
    { dispatch: false },
  );

}
