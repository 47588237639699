import { CookieService } from 'ngx-cookie-service';
import { SettingsService } from '../../../core/services';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from './../../../core/auth/authentication.service';
import { SystemService } from './../../../core/services';
import { AccountService } from './../../../core/services/account.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;



@Component({
  selector: 'sm-loginb',
  templateUrl: './loginb.component.html',
  styleUrls: ['./loginb.component.scss']
})
export class LoginbComponent implements OnInit {

  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle = 0;
  logo
  bProfile;
  template

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _acount: AccountService,
    private _settings: SettingsService,
    private _store: Store<any>,
    private _cookie: CookieService,
    private cd: ChangeDetectorRef,
    private _system: SystemService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {

    this._store.dispatch({ type: '[Profile] Stop Loading' });
    this.authenticationService.logout();
    this.template = this.route.snapshot.data['loginData'];
    if (!this.template) {
      this.template = {
        logo: environment.logoImage,
        companyName: "أكاديمية ماستري للتدريب الإلكتروني",
        primary: '#ec5252',
        accent: '#000',
        image: 'https://public.emasteryacademy.com/masteryoffice.png',
      }
    } else {
      if (this.template.image == '') this.template.image = 'https://public.emasteryacademy.com/masteryoffice.png'
    }
    // // console.log(id);

    // if (id == null) {
    //   this.router.navigate(['/']);
    // } else {
    //   this._acount.getB2BProfileByDomainExtension(id).subscribe((data: any) => {
    //     if (!data) {
    //       this.router.navigate(['/']);
    //     } else {
    //       localStorage.setItem('__bb__prf', JSON.stringify(data));
    //       this.logo = data.logo
    //       this.bProfile = data;
    //       this.cd.markForCheck();
    //       this._store.dispatch({ type: '[Profile] Stop Loading' });
    //       this.authenticationService.logout();
    //       // reset login status
    //       //

    //       // get return url from route parameters or default to '/'
    //       // console.log(this.route.snapshot.queryParams['returnUrl']);

    //       this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || (id) ? 'clientId/' + id + '/dashboard' : '/dashboard';
    //     }

    //   })
    // }

  }

  login() {
    if (this.model.clientId == null) return;

    this._acount.getB2BProfileByDomainExtension(this.model.clientId).subscribe((data: any) => {
      if (!data) {
        return
      } else {
        localStorage.setItem('__bb__prf', JSON.stringify(data));
        this.logo = data.logo
        this.bProfile = data;
        this.cd.markForCheck();
        this._store.dispatch({ type: '[Profile] Stop Loading' });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || (this.model.clientId) ? 'clientId/' + this.model.clientId + '/dashboard' : '/dashboard';

        let companyId = (this.bProfile) ? this.bProfile.companyId : null;
        this.authenticationService.login(this.model.loginName, this.model.password, companyId)



          .pipe(first())
          .subscribe({


            next: () => {
              
              self.router.navigate([self.returnUrl]);
              // this.getMyPermissions();
            },
            error: error => {
              console.log(error);
              if (error == 'No User found') {
                self.alertTitle = 1;
                self.alertMessage = "هذا البريد غير مسجل في الموقع";
                self.loading = false;
                self.cd.markForCheck();
              } else if (error == 'Failed') {
                self.alertTitle = 1;
                self.alertMessage = "كلمة المرور غير صحيحة يرجى كتابة كلمة المرور الصحيحة او اعادة تعيين كلمة المرور";
                self.loading = false;
                self.cd.markForCheck();
              } else if (error == 'Not Activated') {
                self.alertTitle = 1;
                self.alertMessage = "لم يتم تفعيل الحساب, تم إرسال رسالة تفعيل إلى بريدكم المسجل يمكن ان تكون الرسالة في الـ spam / Junk ";
                self.loading = false;
                self.cd.markForCheck();
              }
              self.loading = false;
            }
          });

      }

    })
    this.loading = true;
    let self = this;







  }

}