// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  THECLOUD: ".emasteryacademy.com",
  GTMIDF: "GTM-M42B658",
  StripeKey: "pk_live_51Kx69uEekY6rrsbSDnCrL7ExT3n1blrHZtMJcFJjrJvB40IGAqndDXqJta5DdZqO6qcXcQUa2n6UNVQBrRTpkRXg00htcaG4D8",
  APIURL: "https://v1.emasteryacademy.com",
  HOMEURL: "https://www.emasteryacademy.com",
  SITEURL: "www.emasteryacademy.com",
  defaultImage :'https://d277bwtxqorw7z.cloudfront.net/default_cover.png',
  domain: "https://www.emasteryacademy.com",
  AUTHURL: "https://a1.emasteryacademy.com",
  CLIENTNAME: 'Mastery Academy',
  LMSVersion: '2.0.0',
  logoImage: '/assets/logo.svg',
  MAINColor : "#ec5252",
  B2BModule: true 

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
