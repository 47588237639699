export class courseSlide {
  id?: number;
  courseLink: any;
  header?: any;
  body?: any;
  trainer: string;
  price: number;
  newPrice: number;
  footer?: any;
  squareImage: string;
  categoryId?: number;
  authorized: boolean;
  image?: string;
  discount?: boolean;
  percent?: number;

}
