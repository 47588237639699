// China
export const locale = {
  lang: 'en',
  data: {
    STATUS: {
      PENDING: 'Pending',
      PROCESSING: 'Processing',
      PAID: 'Paid',
      SUSPENDED: 'Suspended',
    },
    AFFILIATES: {
      AFFILIATES: 'Affiliate Program',
      MYPAYMENTS: 'Payments Log',
      REQUESTPAYMENT: 'Send Payment Request',
      YOUARENOTREGISTERED: 'You Are Not Registered In The Affiliate Program',
      YOUHAVEREGISTEREDBEFORE: 'You Have Applied To The Affiliate Program',
      STATUSOFYOURAPPLICATION: 'Application Status',
      SUBSCRIBE: 'Application',
      NAME: 'Full Name',
      EMAIL: 'Your Email Address',
      PHONE: 'Your Phone Number',
      IDNUMBER: 'National Id',
      REQUIRED: 'Required Field',
      COUNTRY: 'Country',
      CITY: 'City',
      POSTALCODE: 'Postal Code',
      POSTOFFICE: 'Postal Office',
      AREYOUFAMOUS: 'Are You Famouse?',
      YES: 'Yes',
      NO: 'No',
      FAMOUSPLATFORM: 'Waht Is The Social Network You Use',
      DOYOUHAVESOCIALMEDIA: 'Do You Have Followers On The Social Networks',
      FACEBOOK: 'Facebook',
      INSTAGRAM: 'Instagram',
      TWITTER: 'Twitter',
      SNAPCHAT: 'Snapchat',
      YOUTUBE: 'Youtube',
      OTHERPLATFORM: 'Others',
      AREYOUBLOGGER: 'Are You A Blogger?',
      BLOGGERSITE: 'Blogger Url ',
      DOYOUHAVEMAILINGLIST: ' Do You Have Newsletter Subscripers ? ',
      NUMBEROFSUBSCRIPERS: 'Newsletter Subscirpers Amount',
      SUBSCRIBERSINTERESTS: 'What Does Interest Your Subscripers ?',
      DOYOUHAVEWHATSAPPLIST: 'Do You Have WhatsApp Broadcast List ? ',
      NUMBEROFWHTSAPPSUBSCRIBERS: 'How Many People Do You Have On WhatsApp Group ?',
      EXPLAINYOURPLAN: 'Explain Your Plan For Markting With Us?',
      WELCOME: 'Welcome',
      MYCOMMISSIONS: 'Earned Commissions',
      MYACCTOUNT: 'Payment Account Info',
      LINKS: 'Products Links',
      COPYLINK: 'Copy Link',
      ADDRESS: 'Address',
      BANKINFO: 'Bank Information',
      BANKNAME: 'Bank Name',
      ACCOUNTNAME: 'Account Holder Name',
      ACCOUNTNUMBER: 'Account Number',
      BANKSWIFT: 'SWIFT CODE',
      BANKADDRESS: 'Bank Address',
      ACCOUNTIBAN: 'Account IBAN',
      ACCOUNTADDRESS: 'Account Holder Address',
      COURSE: 'Course',
      COMMISSION: 'Commission',
    },
    GLOSSARY: {
      CONCEPTNAME: 'Concept Title',
      KEYWORDS: 'Keywords',
      DEFINITION: 'Concept Definition',
      REPLY: 'Write In The Forums'
    },

    INFO: {
      INSERTTEXTHERE: 'Write Your Text Here',
      INSERTQUESTION: 'Add Your Question Here',
      INSERTREPLY: 'Add Your Answer Here',
    },
    CONSULTANCIES: {
      REQUESTID: 'Request Id',
      NOTSTARTEDYET: 'Session Has Not Been Started Yet...',
      USEREMAIL: 'Customer Email',
      DATE: 'Date',
      ENTERSESSION: 'Enter The Session',
      EARLYSESSION: 'Do You Want To Start The Session Earlier ?',
      SENDREMINDER: 'Send A Reminder To The Customer',
      ENDSESSION: 'End Session',
      DONE: 'Session Ended',
      DAY: 'Day',
      TIMELEFT: 'Session Will Start In',
      EMAIL: 'Customer Email',
      HOUR: 'Hour',
      PENDING: 'Waiting Approval',
      HOURS: 'Hour',
      DAYS: 'Day',
      TIMEZONE: 'Timezone',
      STARTNOW: 'Start Session Now',
      MINUTES: 'Minute',
      SECONDS: 'Second',
      CONFIGURE: 'Configure',
      ACCEPT: 'Approve',
      DELAY: 'Postpone',
      TOTALINFO: 'Info Block',
      TOTALSESSIONS: 'Session',
      INFO: 'Consultancy Info',
      SESSIONS: 'Consultancy Session',

    },
    DASHBOARD: {
      ADDEDBY: 'By',
      HOUR: 'Hour',
      DAY: 'Day',
      VIDEOQUESTIONS: 'Choose The Instructor Or Ask A Public Question',
      MYCONSULTANCIES: 'My Consultancies',
      AVILABLEDATES: 'Available Day',
      AVILABLEHOURS: 'Available Hours',
      LECTURENAME: 'Lecture',
      TIMEZONE: 'Timezone',
      ATTIME: 'Time On Video',
      REQUESTMEETING: 'Request Meeting Session',
      MYSESSIONS: 'My Sessions',
      NOTE: 'Notes',
      MYBOOKMARKSTITLE: 'My Bookmarks',
      PUBLICQUESTION: 'Public Question',
      HOMEPAGE: 'Quick Reports',
      COMMISSION: 'Earned Commissions',
      ADDBOOKMARK: 'Add Bookmark',
      NOCOMMENTS: 'No Replies Yet',
      REQUESTDATE: 'Request Date',
      TOTALAMOUNT: 'Total Amount',
      COURSEWIKI: 'Course Wiki',
      REQUESTSTATUS: 'Request Status',
      TOTALPAIDCOMMISSIONS: 'Total Paid Commissions',
      TOTALUNPAIDCOMMISSIONS: 'Total Unpaid Commissions',
      SELECTPERIOD: 'Select Timezone',
      TOTALCOMMISSIONS: 'Total Earned Commissions',
      ADDARTICLE: 'New Article',
      CURRENTLEARNINGPLAN: 'Current Learning Plan',
      FROMDATE: 'From Date',
      TODATE: 'To Date',
      LEARNPROGRESS: 'Progress',
      SELECTTWODATES: 'Select Two Dates ',
      CHANGEPASSWORD: 'Update My Password',
      MYFINANCE: 'My Payments Log',
      ORDERID: 'Order ID',
      PRODUCTNAME: 'Product Name',
      PURCHASEDDATE: 'Purchase Date',
      PRICE: 'Price',
      EDITINFO: 'Update Info',
      ANNOUNCEMENTS: 'Notifications',
      NONOTIFICATIONS: 'You Do Not Have Notificaitons',
      MYLEARNINGPLAN: 'Learning Plan',
      MENU: 'Main Menu',
      ACADEMY: 'Elane Platform',
      PROFILEVERSION: 'V.1.052113',
      HEADER: 'Title',
      SENDANSWER: 'Send An Answer',
      ANSWER: 'Answer',
      LOGOUT: 'Logout',
      CASESTUDIES: 'Case Studies',
      DOWNLOADCERTIFICATE: 'Download Certificate',
      SETREMINDER: 'Daily Reminder',
      SELECTPLANTYPE: 'Leanring Plan Target',
      TIMEGOAL: 'Total Hours',
      COURSEGOAL: 'Total Courses',
      TARGETTIME: 'Spend Time Learning',
      SETTIMEPERHOUR: 'Total Hours',
      SUBMITPLAN: 'Save Learning Plan',
      BODY: 'Article Body',
      ADDEDAT: 'Date',
      ADDREPLY: 'Add Reply To Discussion',
      MYCOURSES: 'My Courses',
      MYCERTIFICATES: 'My Certificates',
      TOPICSLIST: 'Topics List',
      GOTOTOPICS: 'Back To Topics List',
      TOTALTOPICS: 'Total Posts',
      ADDCONCEPT: 'Add Concept',
      MYQUIZZES: 'My Quizzes',
      COURSENAME: ' Course Name',
      QUIZZNAME: ' Quizz Name',
      STATUS: 'Status',
      MYASSIGNMENTS: 'My Assignments',
      MYBOOKMARKS: 'My Bookmarks',
      COMPLETE: 'Complete %',
      COMPLETED: 'Total Complete',
      DATE: 'Date',
      FILE: 'File',
      FILEBACK: 'Trainee File',
      COMMENT: 'Instructor Reply',
      DONE: 'You Complete Lesson',
      ATTACHFILE: 'Attach File',
      TOTAL: 'Total',
      NOTPASSED: 'Not Passed',
      PASSED: 'Accepted',
      PENDING: 'Waiting Evaluation',
      CONTINUE: 'Continue',
      CONTENTS: 'Contents',
      GOBACK: 'Back To Profile',
      PERVIOUSLESSON: 'Previous Lesson',
      NEXTLESSON: 'Next Lesson',
      GENERALINFO: 'General Information About The Quizz',
      TOTALATTEMPTS: 'Number Of Attempts',
      TIMELIMIT: 'Timelime Per Try',
      NUMBEROFTRIES: 'Numebr Of Finished Attempts ',
      ONCE: ' Time ',
      MINUTES: ' Minute ',
      TIME: ' Time ',
      YOUDIDNTTRY: 'You Have Not Attempted Yet',
      DONTCLICK: 'You Are Not To Go Back, Quizz Will End Automatically ',
      TRYATTEMPQUIZZ: 'Attempt The Quizz Now',
      NEXTQUESTION: 'Next Question',
      TIMELEFT: 'Time Left',
      ATTEMPTINFO: 'Last Attempt Information',
      TOTALANSWERED: 'Total Answered Questions',
      TOTALUNANASWERED: 'Total Unanswered Questions',
      ATTENDEDAT: 'Attempt Date',
      SUBMITYOURQUIZZ: 'Quizz Result',
      QUIZZESSECTION: 'Quizzes',
      ATTEMPTRESULT: 'Quizz Result',
      YOURSCORE: 'Score',
      RESULT: 'Final Result',
      DIDPASS: 'Congratulations! You Have Passed The Quizz',
      DIDNTPASS: 'Unfortunately You Did Not Pass The Quiz',
      GOHOME: 'Continue',
      CONTINUEQUIZZ: 'Continue Last Attempt',
      TIMEISUP: 'Time is Up',
      ASSIGNMENTSECTION: 'Assignments'
    },
    B2B: {
      B2B: 'B2B',
      SUBSCRIBE: 'الاشتراك',
      COMPANYNAME: 'اسم الشركة',
      COMPANYPHONE: 'رقم هاتف الشركة',
      COMPANYEMAIL: 'بريد الشركة',
      CONTACTPHONE: 'رقم هاتف المسؤول',
      CONTACTEMAIL: 'بريد المسؤول',
      CONTACTNAME: 'اسم المسؤول',
      ADDRESS: 'العنوان',
      MANAGESUBSCRIPTIONS: 'إدارة الاشتراكات',
      NUMBEROFENROLLMENTS: 'عدد المستخدمين',
      STARTINGAT: 'يبدأ بتاريخ',
      ENDINGAT: 'ينتهي بتاريخ',
      ISACTIVE: 'مفعل',
      COUNTRY: 'الدولة',
      NUMBEROFUSERS: 'عدد الموظفين المرشحين',
      IMPORTUSERS: 'استيراد المستخدمين',
      MANAGEUSERS: 'ادارة المستخدمين',
      IMPORTDEPARTMENTS: 'إستيراد الاقسام',
      DEPARTMENTS: 'الاقسام والادارات',
      COURSES: 'الدورات المتاحة',
      MANAGECOURSES: 'ادارة الدورات المتاحة للاشتراك',
      SELECTCATEGORY: 'اختر التصنيف',
      INCLUSIVECOURSES: 'اختر الدورات المستثناة',
    },
    WIKI: {
      ARTICLES: 'Articles'
    },
    MENU: {
      ABOUT: 'Academy',
      CATEGORIES: 'Diplomas & Packages',
      COURSES: 'Courses',
      JOINUSASAFFILIATE: 'Affiliate Program',
      COURSEPAACKGES: 'Packages',
      CONSULTANTS: 'Consultancies',
      VIDEOS: 'Videos',
      COMPANIESREQUESTS: 'Business Applications',
      JOINUSASTRAINER: 'Join us As Instructor',
      CONTACTUS: 'Contactus',
    },
    COURSES: {
      ALLCOURSES: 'Courses',
      ALL: 'All'
    },
    CONSULTANCY: {
      ALLCONSULTANCIES: 'Consultancies',
      ALL: 'All'
    },
    CART: {
      TOTALORIGINALPRICE: 'Original Price',
      TOTALPRICE: 'Price After Discount',
      APPLIEDCOUPON: 'Coupon Code',
      ENTERCOUPON: 'Do You Have Coupon',
      COUPON: 'Add Your Coupon Code',
      SUBMITCOUPON: 'Add Coupon',
      PROCEED: 'Pay Now!',
      YOURCART: 'My Cart'
    },
    CHECKOUT: {
      PRODUCTPRICE: 'Price',
      PRODUCTNAME: 'Product Name',
      ORDERSUMMERY: 'Order Summary',
      TOTALDUEPAY: 'Total Amount',
      ACCOUNTDETAILS: 'Account Details',
      FIRSTNAME: 'Name',
      EMAIL: 'Email',
      LASTNAME: 'Surename',
      PAYMENTMETHOD: 'Payment Method',
      PAYBYCREDIT: 'Pay With Credit Card',
      WEACCEPTCARDS: 'You Can Use Credit Or Prepaid Cards',
      PAYNOW: 'Pay Now!',
      CHECKOUTREVIEW: 'Complete'
    },
    PACKAGE: {
      BUYNOW: 'Buy Now !',
      INFO: 'Courses Includeed',
      ALLPACKAGES: 'Diplomas And Packages',
      ALL: 'All',
    },
    SLIDER: {
      DISCOUNT: 'On Sale',
      DIPLOMA: 'Diplomas',
      COURSES: 'Courses',
    },
    ACCOUNT: {
      MYINFORMATION: 'My Information',
      MYCOURSESLIST: 'My Courses',
      CHANGEMYPASSWORD: 'Update Password',
      MYFINANCIALRECORD: 'Payment Log',
      LOGOUT: 'Logout'
    },
    SEX: {
      MALE: "Male",
      FEMALE: 'Female'
    },
    HOME: {
      WELCOMETOAFFILIATE: 'أهلا بكم في نظام التسويق بالعمولة',
      APPLICATIONFORB2BSUBSCRIPTION: 'طلب الاشتراك الخاص بالشركات',
      APPLICATIONFORTRAINERSUBSCRIPTION: 'الاشتراك ك مدرب في ماستري',
      PRIVACY: 'Privacy Policy',
    },
    TRAINER: {
      FIRSNAME: 'الاسم',
      LASTNAME: 'اللقب',
      EMAIL: 'البريد الالكتروني',
      NATIONALITY: 'الجنسية',
      COUNTRY: 'الدولة التي تقيم فيها',
      CITY: 'المدينة التي تقيم فيها',
      MOBILE: 'رقم الموبايل',
      BIRTHDATE: 'تاريخ ميلادك',
      EDUCATIONALLEVEL: 'آخر درجة تعليمية حصلت عليها',
      MAJOR: 'التخصص العام',
      SPECIALIZED: 'التخصص الدقيق',
      PROGRAMS: 'مجالات التدريب الرئيسية التي تحترف التدريب فيها',
      EXPERIENCE: 'سنوات الخبرة كمدرب محترف',
      SCOPEDEXPERIENCE: 'معظم خبرتك في التدريب كانت في تدريب القطاع',
      LANGUAGES: 'اختر اللغة التي تحترف التدريب فيها بطلاقة',
      ADDITIONALSERVICES: 'ماهي الخدمات الإضافية التي تقدمها إضافة لخدمة التدريب داخل القاعة ولديك خبرة لا تقل عن سنتين فيها',
      PERSONALPROGRAM: 'اسم البرنامج الذي ترغب تدريبه معنا',
      REQUIRED: 'Required',
    },
    PROFILE: {
      WELCOMEYA: 'Welcome',
      ATTACHMENTS: 'Attachments',
      HOMEPAGE: 'Homepage',
      MYACCOUNT: 'My Account',
      MYCOURSES: 'My Courses',
      SUBMITREPLY: 'Reply',
      CERTIFICATES: 'My Certificates',
      MYCOURSE: 'My Courses ',
      NOTIFICATIONS: 'My Notfiications',
      NOTES: 'My Notes',
      DISCUSSION: 'Comments',
      NODISCUSSION: 'No Comments',
      SUBMITCOMMENT: 'Add Comment',
      REPLY: 'Reply',
      QUIZZNAME: 'Quizz Name',
      STARTTHEQUIZZ: 'Start The Attempt',
      NEXTQUESTION: 'Next Question',
      SUBMITYOURANSWERS: 'Sutbmit',
      NAME: 'Full Name',
      DATEOFBIRTH: 'Birthdate',
      GENDER: 'Gender',
      UPDATEPASSWORD: 'Update Password',
      PHONENUMBER: 'Phone number',
      UPDATEINFO: 'Update Info',
      DRAGYOURANSWERHERE: 'Drage The Correct Answer Here',
      SUBMITYOURANSWERSMESSAGE: 'You Have Asnwered All Questions',
      CURRENTPASSWORD: 'Current Password',
      NEWPASSWORD: 'New Password',
      REPEATPASSWORD: 'Repeat New Password',
      NONOTIFICATIONS: 'You Do Not Have New Notifications'
    },
    QUIZZ: {
      WELCOMEMESSAGE: 'In Thiz Quizz',
      NUMBEROFQUESTIONS: 'Questions',
      NUMBEROFREPETION: 'Number Of Attempts',
      TIMELIMIT: 'Quizz Time',
      GOODLUCK: 'Good luck!'
    },
    SYSTEM: {
      SUCCESS: 'Done!',
      YOUWILLBETRANSFERED: 'You Will Be Redirected To Login Page ',
      NEWBOOKMARK: 'New Bookmark',
      ACADEMYNAME: 'Academy',
      FAILED: 'Faild',
      SUBMITBOOKMARK: 'Save Bookmark',
    },
    ACADEMY: {
      NAME: 'اكاديمية مكنون',
      ADDRESS: 'ماليزيا- السعودية وجميع انحاء العالم',
      PHONE: 'رقم هاتف الاكاديمية',
      FAX: 'رقم فاكس الاكاديمية',
      IMPORTANTLINKS: 'Important Links',
      OURSERVICE: 'Our Services',
      PRIVECY: 'Privacy',
      HELPCENTER: 'Help Center',
      TERMSOFUSE: 'Terms Of Use',
      POLICY: 'Policy',
      PAYEMENTS: 'Paymnets Method',
      FAQ: 'FAQ',
      LEGALNOTICE: 'Legal Notice',
      BUSINESS: 'Business',
      B2B: 'Business Program',
      AFFILIATES: 'Affiliate Program',
      TRAINING: 'Training Program',
      PARTNERSHIP: 'Partnership',
      COPYRIGHTS: 'Copyright 2021',
    }
  },
  cookie: {
    header: "هذا الموقع يستخدم كوكيز",
    message: "هذا الموقع يستخدم كوكيز للحصول على أداء افضل وامان اعلى",
    dismiss: "Dimiss",
    allow: "Accept",
    deny: "Deny",
    link: "More",
    policy: "Policy"
  },
};
