import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class BaseService {

  private _currentY = new BehaviorSubject<number>(0);


  getCurrentYPosition() {
    return this._currentY;
  }

  updateCurrentYPosition(currentY: number) {
    this._currentY.next(currentY);
  }

}
