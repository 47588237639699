export class Course {
  Id?: number;
  id?: number;
  name: string;
  description: string;
  categoryId: number;
  banner: string;
  intro: string;
  languageId?: number;
  discussed: boolean;
  price?: number;
  isDeploma: boolean;
  active: boolean;
  image: string;
  // trainerId?: number;
  notes: string;
  addedBy?: number;
  addedDate: Date;
  updateAt?: Date;
}


export class CourseTrainers {
  courseId?: number;
  trainerId?: number;
}
