<div class="mas-privacy-container">
  <h4>{{'HOME.PRIVACY' | translate }}</h4>




  <p> تقوم سياسة الخصوصية بتوضيح كيفية استخدام المعلومات المدخلة لموقع " ماستري أكاديمي " وتحمي هذه السياسة أي معلومات
    يتم
    تقديمها بواسطة المشتركين بالموقع عند استخدامهم لهذا الموقع.
    ويلتزم موقع www.emasteryacademy.com بضمان حماية الخصوصية لكافة المعلومات المدخلة إليه، بحيث يمكن للمسجلين بالموقع
    تقديم معلومات معينة إن طلبنا منهم ذلك لنحدد من خلالها هوية المستخدم لهذا الموقع وعليهم أن يكونوا متأكدين مطمئنين بأن
    هذه البيانات لن تُستخدم إلا وفقا لبيان الخصوصية هذا.
  </p>
  <div style="text-align: right; padding: 2em;">
    <strong> حساب المشترك وكلمة السر/ المرور</strong>
    <p>يتم تسجيل المشترك عن طريق البريد الإلكتروني ورقم الموبايل الشخصي ويجب على كافة المسجلين بالموقع اختيار كلمة مرور
      خاصة
      بهم، وتكون مسؤولية حماية كلمة المرور هذه وعدم مشاركتها أو نشرها على المشترك، وفي حال حدوث أي معاملات باستخدام كلمة
      المرور هذه يتحمل المشترك كافة المسؤوليات المترتبة على ذلك، دون أدنى مسؤولية على موقع www.emasteryacademy.com.
      يتم التعامل مع جميع المعلومات التي يزودها المشتركين بالموقع بسرية تامة ولا يتم بيعها أو مشاركتها مع أي طرف اخر غير
      مخوّل بالوصول إليها، بعض حالات استخدام المعلومات:</p>

    <ul>
      <li>تطوير الخدمات التي يقدمها الموقع وخدمات شركتنا المختلفة.</li>
      <li>استخدامها في حملات التسويق الالكتروني بكافة أشكالها. </li>
      <li>في حال التعاقد مع طرف آخر لتطوير الخدمات التي نقدمها بعد الالتزام بعدم مشاركة أي معلومات تتعارض مع هذه البنود.
      </li>
      <li>إذا كنا ملزمين قانونيا بأمر قضائي بالكشف عن أي معلومات.</li>
    </ul>
  </div>

  <div style="text-align: right; padding: 2em;">
    <strong>أمن المعلومات</strong>
    <p> قد يتم جمع وتخزين واستخدام المعلومات الشخصية التالية: 
    </p>
    <ul>
      <li>معلومات حول الجهاز الخاص بالمشتركين بالموقع بما في ذلك عنوان بروتوكول الإنترنت الخاص بهم، والموقع الجغرافي،
        ونوع
        المتصفح والإصدار، ونظام التشغيل.</li>
      <li>معلومات حول زيارة الموقع واستخدامه بما في ذلك مصدر الإحالة، ومدة الزيارة، وما يشاهده المستخدمين على الصفحة،
        ومسارات
        التصفح في الموقع.</li>
      <li>المعلومات التي يدخلها المشتركين والمستخدمين عند التسجيل في الموقع، مثل عنوان البريد الإلكتروني و رقم الجوال.
      </li>
      <li>المعلومات التي يدخلها المشتركين عند إنشاء ملف تعريف على موقعنا – على سبيل المثال، الاسم، صور الملف الشخصي،
        الجنس
        ،
        تاريخ الميلاد ، الحالة الاجتماعية ، الاهتمامات والهوايات ، الحالة التعليمية ، وتفاصيل الوظيفة.</li>
      <li>المعلومات التي يدخلها المشتركين أثناء استخدام الخدمات المتوفرة على موقعنا.</li>
      <li>المعلومات التي يتم إنشاؤها أثناء استخدام موقعنا، بما في ذلك مواعيد وتكرار استخدام الموقع والظروف التي تستخدم
        فيها
        الموقع.</li>
      <li>المعلومات الواردة في أي مراسلات ارسلها إلينا المشترك عبر البريد الإلكتروني أو عبر الموقع، بما في ذلك المحتوى
        المشترك
        والبيانات الوصفية.</li>
    </ul>
    <p>
      <br>


      يقر جميع المشتركين و المستخدمين لموقعنا بالموافقة على تخزين كل هذه المعلومات سالفة الذكر ، ويلتزم موقعنا
      www.emasteryacademy.com بضمان حماية الخصوصية لكافة هذه المعلومات المدخلة اليه.
    </p>

  </div>


  <div style="text-align: right; padding: 2em;">
    <strong>سياسة الشراء والدفع</strong>
    <ul>
      <li>يحق لكل المشتركين بالموقع شراء أي دورة تدريبة من الدورات المقدمة من موقعنا www.emasteryacademy.com في أي وقت
        خلال
        مدة اشتراكهم بالموقع، ويمكنهم الاستفاده من هذه الدورات المشتراه واستخدامها <span style="color: red;">لمدة سنة
          ميلادية</span> تبدأ من
        تاريخ شرائهم
        الدورة.</li>
      <li> يحق لكل المشتركين بالموقع شراء الاستشارات الخاصة المقدمة من موقعنا، ويتم تحديد موعد التواصل مع المدرب المختص
        في
        الاستشارة عند شراء الخدمة، وهذه <span style="color: red;">الاستشارة تكون لمدة ستون دقيقة فقط </span> ، يستطيع من
        خلالها المتدرب
        الاستفادة من المدرب
        المختص بشكل خاص.</li>
      <li>وموقعنا يوفر طرق دفع مختلفة عبر بوابات الدفع الالكتروني حتى يتمكن المشتركين من دفع ثمن هذه الدورات بالطريقة
        التي
        تناسبهم ومن هذه الطرق يمكن الدفع بواسطة (بطاقات فيزا – بطاقات ماستركارد)</li>

    </ul>
  </div>



  <div style="text-align: right; padding: 2em;">
    <strong>سياسة استخدام الدورات التدريبية بالموقع</strong>
    <p>
      يحق لصاحب الحساب فقط حضور الدورة التدريبية الالكترونية ولا يحق له اعطاء الاذن للدخول باستخدام بياناته لأي شخص آخر،
      وفي
      حال تعرض الحساب لدخول متكرر من أماكن مختلفة قد يتعرض الحساب للإغلاق لحين اثبات قانونية الاستخدام.
    </p> <span style="color: red;">
      • بمعنى ان المشترك لا يحق له مشاركة حسابه الالكتروني المشترك به في الدورة مع شخص آخر فكل دورة تكون مخصصة لمالك
      الحساب
      المشترك في الدورة ولا يجوز الدخول بهذا الحساب من عدة أجهزة مختلفة، ويجب على كل المشتركين بالدورات التدريبية
      الالتزام
      بالشروط والضوابط الآتية: -
    </span>

    <p> شروط الاستخدام لكل دورة</p>

    <ul>
      <li>يستطيع كل متدرب إجراء نقاشات وحوارات مباشرة مع المدرب أو زملائه المشاركين في نفس الدورة في المكان المخصص
        للنقاشات
        داخل الدورة التدريبية</li>
      <li>اشتراك المتدرب في دورة تدريبية يعطيه الأحقية بالدخول وحضور الدورة المشترك بها في أي وقت لمدة سنة تبدأ من تاريخ
        الاشتراك بالدورة التدريبية، وبعد انتهاء مدة الاشتراك بالدورة وهي (سنة ميلادية) يتم حذف الدورة من حساب المشترك
        بالدورة.</li>
      <li>يستطيع المتدرب حضور الدورة من 3 أجهزة شخصية فقط (الكمبيوتر - الجوال - التابلت) بتوافق تام وسهولة استخدام، مع
        التزام
        المتدرب بعدم الحضور من أجهزة مختلفة حتى لا يتعرض للحظر من الموقع.</li>
      <li>تمنحك " ماستري أكاديمي " في ماليزيا شهادة حضور الكترونية معتمدة باللغة الانجليزية أو العربية (حسب الدورة) فور
        انتهاء
        المتدرب من حضور الدورة.</li>
    </ul>
    <p>الحفاظ على سرية المعلومات الخاصة بالمشتركين
      نحن موقع " ماستري أكاديمي " نطمئن كافة المستخدمين للموقع من المتدربين بأن كافة المناقشات التي تتم بين المتدربين
      وبعضهم
      البعض في الدورات التدريبية لا يتم الإفصاح عنها لأي طرف آخر بدون موافقة المتدربين، وبالنسبة لكافة المناقشات التي
      تتم
      بين المدربين والمتدربين في الاستشارات الشخصية الخاصة لا نفصح عنها لأي طرف آخر بأي حال من الأحوال فهي بالنسبة لنا
      معلومات سرية ويجب الحفاظ عليها.

      • يقر جميع المدربين بالموقع بعدم افشاء أي معلومات تم الحصول عليها من المتدربين المستخدمين لموقع " ماستري أكاديمي "
      اثناء المناقشات في الدورات التدريبية.
      • يقر جميع المدربين من مقدمي الخدمات (دورات التدريب) عبر موقعنا " ماستري أكاديمي " بالحفاظ على سرية المعلومات
      والبيانات الخاصة بالمتدربين من مستخدمين خدمات الموقع سواء الدورات التدريبية او الاستشارات الشخصية بموقعنا.
    </p>
    <p>
      ملفات تعريف الارتباط "كوكيز"
      ملف تعريف الارتباط هو ملف صغير يطلب السماح لوضعه على القرص الصلب (هارد درايف) لجهاز الكمبيوتر الخاص بالمشترك ويتم
      إضافته بمجرد موافقة المستخدم للموقع، وهو يساعد على تحليل حركة المرور على الشبكة أو يبلغ المشترك عندما يقوم بزيارة
      موقع
      معين، وتسمح الكوكيز لتطبيقات الشبكة بالرد عليه والتجاوب معه كفرد، ويمكن لتطبيقات الشبكة أن تشكل عملياتها حسب
      احتياجات
      المشترك وما يحب وما لا يحب عن طريق جمع وتذكر معلومات عن الأمور التي يفضلها.
       نحن نستخدم كوكيز سجل حركة المرور لتحديد الصفحات التي تم استخدامها، الأمر الذي يساعدنا على تحليل بيانات حركة مرور
      الصفحة على الشبكة وتحسين موقعنا الالكتروني لتشكيله حسب احتياجات المشترك، ولا نستخدم هذه المعلومات إلا لأغراض
      التحليلات
      الإحصائية ومن ثم يتم إزالة البيانات من النظام.
      وبالعموم تساعدنا الكوكيز على توفير وتقديم موقع الكتروني أفضل من خلال تمكننا من مراقبة الصفحات لتحديد ما يجدون منها
      مفيداً ونافعاً لهم أكثر من غيرها، ونؤكد أن الكوكيز لا تتيح لنا بأي حال من الأحوال الوصول لأجهزتهم أو لأي معلومات
      عنهم
      عدا تلك البيانات التي اختاروا تقاسمها ومشاركتها معنا.
      يمكنك اختيار قبول أو رفض الكوكيز، ورغم أن كثيراً من المتصفحات الالكترونية تقبل الكوكيز تلقائياً، يمكنك أن تعدل
      الإعدادات الخاصة بك لرفض الكوكيز إن أردت، مع أن هذا الأمر قد يعيق الاستفادة الكاملة من الموقع.
    </p>
    <p>
      الرقابة على المحتوى
      يحتفظ موقعنا " ماستري أكاديمي " بالحق في مراقبة أي محتوى يدخله المشترك او المستخدم، دون أن يكون ذلك لزاما علينا،
      لذا
      نحتفظ بالحق (من دون التزام) في حذف أو إزالة أو تحرير أي مواد مدخلة من شأنها انتهاك شروط وأحكام الموقع دون الرجوع
      لصاحب
      المحتوى.

    </p>
    <p>
      التعديل
      يجوز لنا تغيير هذه السياسة من وقت لآخر عن طريق تحديث محتوى هذه الصفحة ، لذا على جميع المشتركين التحقق والرجوع
      إليها
      من
      وقت لآخر للتأكد من أن التغييرات التي تمت سوف تحظى بقبولهم ورضاهم ، و بنود هذه السياسة سارية من تاريخ نشرها على
      الموقع.

    </p>
  </div>



</div>