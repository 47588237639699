export class B2BProfile {
  Id?: number;
  id?: number;
  groupId?: number;
  companyName: string;
  companyPhone: string;
  companyEmail: string;
  contactName: string;
  contactEmail: string;
  jobTitle: string;
  companySize: number;
  country: string;
  city: string;
  contactPhone: string;
  address: string;
  status: B2BStatus;
  userId: number;
  activatedAt: Date;

}


export enum B2BStatus {
  Pending = 1,
  Connected = 2,
  Defining = 3,
  Signing = 4,
  Active = 5,
  Suspended = 6,
  Terminated = 7,
  Ended = 8
}
export class B2BDeaprtments {
  Id?: number;
  id?: number;
  companyId?: number;
  parentId?: number;
  maximumOfTrainees?: number;
  supervisorId?: number;
  name: string;
  image: string;
  email: string;
  description: string;
}



export enum RequestStatus {
  Pending = 1,
  Approved = 2,
  Declined = 3,
  Waiting = 4,
  Complete = 5
}