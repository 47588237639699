import { TraineeActivities } from './activities';
import { QuestionTypes } from './quizzes';
import { trainerCard, quizzCard, discussionCard, VIDBookmarksDTO } from './cards';
import { MaterialTypes, questionWithAnswers, Result } from '.';

export class myCourseSlider {
  id?: number;
  Id?: number;
  courseLink: any;
  header?: any;
  body?: any;
  trainer: string;
  footer?: any;
  authorized: boolean;
  image?: string;
  hasBeforeQuizz: boolean;
  total?: number;
  percent?: number;
}



export class myCourseCard {
  Id: number;
  id?: number;
  courseName: string;
  courseLink: string;
  courseCover: string;

  preCourseQuizzId: number;
  courseVideo: string;
  trainers: trainerCard[];
  courseContents: myUnitCard[];
  assignments: assignments[];
  quizz: quizzCard[];
  currentLecture: myLectureCard;
  percent?: number;
  wiki: courseWiki;
  forum: courseForum;
  glossary: courseGlossary
  caseStudy: caseStudy[]
}

export class caseStudy {
  casestudyId: number;
  open: boolean
  header: string;
  body: string;
  solution: caseSolution
}

export class caseSolution {
  solutionId: number;
  answers: casestudyAnswer[]
}
export class casestudyAnswer {
  answerId: number;
  answer: string;
}


export class assignments {
  id: number;
  assignemnet: string;
  rule: string;
  isDone: boolean;
  fromDate: Date;
  toDate: Date;
  appliedAt?: Date;
  notes: string;
  file: string;
  body: string;
  year: number;
}

export class myUnitCard {
  Id: number
  id?: number
  unitName: string;
  unitDesc: string;
  unitBanner: string;
  isDone: boolean;
  percent: number;
  totalScore: number;
  completitionDate: Date;

  contents: unitContents[];
  quizz: quizzCard
}

export class unitContents {
  Id: number
  id?: number
  name: string;
  type: UnitContentType
  isDone: boolean;
}

export enum UnitContentType {
  Lecture = 1,
  Quizz = 2
}

export class myLectureCard {
  materialId: number
  materialName: string;
  order: number;
  materialLink: string;
  materialLength: string;

  isDone: boolean;
  unitId: number;
  totalScore: number;
  discussion: discussionCard;
  // currentVideoLink: string;
  currentVideo: contentVideo;
  completitionDate: Date;
  contents: myLectureContentCard[];
}

export class contentVideo {
  url: string;
  questions: questionWithAnswers[] = [];
  bookmarks: VIDBookmarksDTO[] = [];
}

export class myLectureContentCard {
  Id?: number;
  id?: number;
  materialId: number
  order: string;
  type: MaterialTypes
  fileId: number;
  file: any;
  fileUrl: string;
  isFree: boolean;
  activityId?: number;
  activity?: TraineeActivities
  body: string;
}








export class myQuizzCard {
  Id?: number;
  id?: number;
  materialId?: number;
  courseId?: number;
  unitId?: number;
  name: string;
  timeLimit: number;
  shuffle: boolean;
  beforeStart: boolean;
  optional: boolean;
  repetition: number;
  ifPassed: Result;
  ifFailed: Result;
  minScore: number;
  attendance?: myQuizzAttendance[];
  questions?: myQuizzQuestions[];

}

export class myQuizzQuestions {
  Id: number;
  id?: number;
  questionType: QuestionTypes;
  question: string;
  score: number;
  optional: boolean;
  quizzId?: number;
  answers: myQuestionAnswers[];
  pairs: myQuestionPairs[];
}

export class myQuestionAnswers {
  Id: number;
  id?: number;
  questionId?: number;
  answer: string;
}

export class myQuestionPairs {
  Id: number;
  id?: number;
  questionId?: number;

  first: string;
  second: string;

}

export class myQuizzAttendance {
  quizzId: number;
  userId: number;
  Id: number;
  attendedAt: Date;
  finished: boolean;
  totalDegree: number;
  attendanceTable: myAttendanceDetails[];
}

export class myAttendanceDetails {
  Id: number;
  attendanceId?: number;
  qustionId?: number;
  answerId?: number;
  pairId?: number;
  selectedId?: number;
  degree: number;
}



export class myQuizzAttempt {
  quizzId?: number;
  quizzName: string;
  numberOfAttempt: number;
  isActive: boolean;
  startedAt: Date;

}

export class myQuizzAnswers {
  questionId: number;
  answerId?: number;
  pairId?: number;
  selectedId?: number;
}




export class courseGlossary {
  glossaryId: number;
  name: string;
  description: string;
  concepts: courseGlossaryConcepts[];
}

export class courseGlossaryConcepts {
  conceptId: number;
  addedBy: string
  concept: string
  file: string
  definition: string
  keywords: string
  attachment: string
  date: Date
}

export class courseForum {
  forumId: number;
  forumName: string

  description: string
  // public bool? displayDescription { get; set; }
  // public int userId { get; set; }
  fromDate?: Date
  toDate: Date
  topics: courseForumTopic[]
}

export class courseForumTopic {
  topicId: number
  addedBy: string
  topic: string
  attachment: string
  date: Date
  replies: courseForumTopicReplies[]

}

export class courseForumTopicReplies {
  replyId: number;
  addedBy: string
  reply: string
  attachment: string
  date: Date
}

export class courseWiki {
  wikiId: number;
  wikiName: string
  articles: courseWikiArticles[]

}
export class courseWikiArticles {
  articleId: number;
  articleName: string
  addedBy: string
  date: Date
  // public bool isPublished { get; set; }
  details: courseWikiArticleDetails[]
}

export class courseWikiArticleDetails {
  detailId: number;
  addedBy: string
  header: string
  body: string
  date: Date
  // public bool isPublished { get; set; }
}


