import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { environment } from "src/environments/environment";
import { AccountService } from "./account.service";

/**
 * 
 * @param route 
 * @param state 
 * @returns 
 * 
 * Page Resolver to intercept loading HTML - CSS - content until API request Complete 
 */
export const LoginResolver: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {

    // console.log(window.location.host);

    let pageUrl = environment.production ? window.location.host : environment.domain
    const encodedpageUrl = encodeURI(pageUrl);
    // console.log('Called URL from course Resolver line 40')
    return inject(AccountService).getProfile(`${encodedpageUrl}`);
}


