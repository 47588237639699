import { AccountService } from './../../services/account.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, tap, filter, catchError, exhaustMap, concatMap } from 'rxjs/operators';
import { of, fromEvent, EMPTY } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { cartActions, } from './cart.actions';
import { CoursesService } from '../../services';





@Injectable()
export class CartEffect {
  constructor(
    private actions$: Actions<cartActions>,
    private _storage: LocalStorage,
    private _service: AccountService,
  ) {
  }

  localStorageKey = '__cart';

  createPayemntIntent$ = createEffect(() => this.actions$.pipe(
    ofType('[Cart] Create PaymentIntent For Checkout'),
    exhaustMap((action: any) => this._service.sendRequest(action.order).pipe(
      map(paymentintent => ({ type: '[Cart] Get Customer PaymentIntent', paymentintent: paymentintent.client_secret }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    )))

}
