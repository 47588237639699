<div class="sm-player-container" id="sm-player-container" (mouseenter)="enterMouseOnVideo()"
  (mousemove)="onVideoHover($event)" (mouseleave)="OnVideoRelease()" (mousedown)="onMouseDown($event)">

  <div class="sm-player-inside-container">

    <div class="sm-player-panel">
      <div class="sm-player-info"></div>

      <div class="sm-player-video">
        <!-- <video id="video-player-{{id}}" class="sm-video video-js vjs-fluid" controls> -->
        <video id="video-player-{{id}}" class="sm-video video-js  vjs-theme-forest" controls>

        </video>
        <sm-question *ngIf="toBeQuestioned | async" (didAnswer)="getAnswer($event)" [question]='question'></sm-question>
        <!-- <div class="bookmarks-container-inside">
          <ng-container *ngFor="let bookmark of bookmarks; let i = index ;">
            <span class="sm-question sm-question-{{i}}">
              d
            </span>
          </ng-container>
        </div> -->
        <!-- <div class="sm-play-btn" id="sm-play-btn" (mouseenter)="hoverPlayBtn()" (mouseleave)="releasePlayBtn()"
          (click)="play()">
          <span>
            <div class="sm-play-btns">
              <i class="icon-control-play"></i>
              <i class="icon-control-pause"></i>
            </div>
          </span>
        </div>
         -->
      </div>


      <!-- <div class="sm-player-control" (mouseenter)="controlbtns=true" (mouseleave)="!controlbtns=false">
        <div class="sm-player-control-container">
          <div class="sm-player-control-seeker">

            <div id="sm-player-seeker-container" class="sm-player-seeker-container" (pan)="click($event)"
              (mousedown)="click($event)">
              <div class="sm-player-seeker-progress">

              </div>
              <div class="sm-player-seeker-questions">


              </div>
              <div id="sm-player-seeker-done" class="sm-player-seeker-done">
                <div id="sm-player-seeker-done" class="sm-player-seeker-done-overlay">

                </div>
              </div>
            </div>

          </div>
          <div class="sm-player-contol-body">
            <div style="position: relative; height: 100%; width: 100%;">
              <div class="sm-player-control-btns" (mouseenter)="hoverPlayBtn()" (mouseleave)="releasePlayBtn()"
                (click)="play()">
                <div class="sm-play-btns">
                  <i class="icon-control-play"></i>
                  <i class="icon-control-pause"></i>
                </div>
              </div>
              <div class="sm-player-speedup-btns">
                <div class="sm-control-speed-menu" id="sm-control-speed-menu" data-clicked="false">
                  <span class='sm-control-speed-btn' *ngFor="let item of speedcontrol" (click)="speedUpDown(item)"
                    style="
font-size: 1em;
padding: 0.3em;
background: rgb(0, 0, 0);
color: rgb(255, 255, 255);
line-height: 0.8em;
text-align: center;
vertical-align: middle;
cursor: pointer;
margin: .1em;
            ">{{item}}</span>
                </div>
                <span (click)="onSpeedHover()" style="
                position: absolute;
                top: 50%;
                font-size: 1em;
                margin-top: -1.2em;
                border-radius: .25em;
                font-weight: 600;
                cursor: pointer;
                padding: 0 .5em;
                border: 1px solid;
                background: white;
            ">{{playback | async}}</span>
              </div>
              <div class="sm-player-control-time">
                <div class="sm-time-elapsed" id="sm-time-elapsed">{{elapsed | async }}</div>

                <div class="sm-time-total" id="sm-time-total">{{ total | async }}</div>
              </div>

              <div class="sm-player-control-sound">
                <div class="sm-player-sound-control">
                  <input class="volume" id="volume" [(ngModel)]='volume' type="range" max="1" min="0" step="0.01"
                    style="direction: ltr;" (mouseenter)="soundHovered=true" (mouseleave)="!soundHovered=false">
                </div>

                <div (click)="unMute()" (mouseover)="hoverVolume()" (mouseleave)="releaseVolume()">
                  <i *ngIf="sound.value > 0 && sound.value < 0.50 " class="icon-volume-1"></i>
                  <i *ngIf="sound.value != 0 && sound.value > 0.50 " class="icon-volume-2"></i>
                  <i *ngIf="sound.value == 0" class="icon-volume-off"></i>
                </div>




              </div>
              <span (click)="fullScreen()" style="
              position: absolute;
              top: 0;
              right: 3em;
              z-index: 5555;
              font-size: 1.5em;
          ">
                <i class="fa fa-expand" style="color: #fff;"></i>
              </span>
            </div>
          </div>




        </div>
      </div> -->
    </div>



  </div>


</div>
