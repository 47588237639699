import { TimelineMax, TweenMax, TimelineLite, Power1 } from 'gsap';
import { Component, OnInit } from '@angular/core';
declare var SplitText: any;

@Component({
  selector: 'sm-testx',
  templateUrl: './testx.component.html',
  styleUrls: ['./testx.component.scss']
})
export class TestxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    TweenMax.set('#thehand', { rotation: -80, transformOrigin: "100% 50%" })
    var tl = new TimelineMax({ repeat: -1, repeatDelay: .51 });
    tl
      .from('#whole', 1, { autoAlpha: 0, y: 50 })
      .from('#shadow', 1, { scale: 0, transformOrigin: "50% 50%" }, '-=.8')
      .fromTo('#thehand', .3, { rotation: 45, transformOrigin: "100% 50%" }, { rotation: 0, transformOrigin: "100% 50%", yoyo: true, repeat: 4 })
      .to('#thehand', .2, { rotation: -80, transformOrigin: "100% 50%" })
      .from('#greeting1', .4, { autoAlpha: 0, y: 42, ease: Power1.easeOut })
  }








}
