<div class="row">
  <div
    *ngIf="alertTitle == 1"
    class="col-md-12 bg-danger"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
  </div>
  <div
    *ngIf="alertTitle == 2"
    class="col-md-12 bg-success"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
  </div>
</div>

<div
  class="login-background"
  [ngStyle]="{
    'background-image': 'url(' + template.image + ')'
  }"
></div>
<div class="login-container">
  <div class="box">
    <span class="borderLine"></span>

    <form
      name="form"
      (ngSubmit)="f.form.valid && login()"
      #f="ngForm"
      novalidate
    >
      <img
        style="width: 250px; height: 100px"
        [style.width]="template.logo === logo ? '250px' : '100px'"
        [src]="template.logo"
        [alt]="template.companyName"
      />
      <div class="input-box">
        <label class="label-text">البريد الإلكتروني</label>
        <div
          class="form-group"
          [ngClass]="{ 'has-error': f.submitted && !loginName.valid }"
        >
          <input
            class="form-control form--control"
            type="text"
            name="loginName"
            [(ngModel)]="model.loginName"
            #loginName="ngModel"
            required
          />
          <span class="la la-envelope input-icon"></span>
          <div *ngIf="f.submitted && !loginName.valid" class="help-block">
            بريد المستخدم مطلوب
          </div>
        </div>
      </div>
      <!-- end input-box -->
      <div class="input-box mb-3">
        <label class="label-text">كلمة المرور</label>
        <div
          class="input-group"
          [ngClass]="{ 'has-error': f.submitted && !password.valid }"
        >
          <input
            class="form-control form--control password-field"
            type="password"
            [(ngModel)]="model.password"
            name="password"
            #password="ngModel"
            required
          />
          <span class="la la-lock input-icon"></span>
        </div>
        <div *ngIf="f.submitted && !password.valid" class="help-block">
          كلمة المرور مطلوبة
        </div>
      </div>
      <!-- end input-box -->
      <div class="input-box mb-3">
        <label class="label-text">معرف الشركة</label>
        <div
          class="input-group"
          [ngClass]="{ 'has-error': f.submitted && !clientId.valid }"
        >
          <input
            class="form-control form--control password-field"
            type="text"
            [(ngModel)]="model.clientId"
            name="clientId"
            #clientId="ngModel"
            required
          />
          <span class="la la-person input-icon"></span>
        </div>
        <div *ngIf="f.submitted && !password.valid" class="help-block">
          كلمة المرور مطلوبة
        </div>
      </div>
      <!-- end input-box -->
      <div class="btn-box" style="width: 100%">
        <!-- <div class="d-flex align-items-center justify-content-between pb-4">
          <a routerLink="/reset" class="btn-text">نسيت كلمة المرور؟</a>
        </div> -->
        <button class="btn theme-btn2 btn-block">
          تسجيل الدخول <i class="la la-arrow-right icon ml-1"></i>
        </button>
        <button class="btn btn-info btn-block" routerLink="/login">
          تسجيل دخول الأفراد
        </button>
        <!-- <button class="btn btn-success btn-block" routerLink="/business-login">
          تسجيل دخول الشركات
        </button> -->
        <!-- <p class="fs-14 pt-3 text-center">
          ليس لديك حساب؟
          <a routerLink="/register" class="text-color-8 hover-underline"
            >اشتراك جديد</a
          >
        </p> -->
      </div>
    </form>
  </div>
</div>
