import { order } from './../../_models/cards';
import { cartItem } from './../../_models/cart';
import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity';



export const getCart = createAction('[Cart] Get Cart');
export const getCartSuccessed = createAction('[Cart] Get Cart Success', props<{ items: cartItem[] }>());

export const addItemToCart = createAction('[Cart] Add Item To Cart', props<{ item: cartItem }>());
export const clearCart = createAction('[Cart] Clean Cart');
export const checkoutCart = createAction('[Cart] Check Out', props<{ items: cartItem[] }>());
export const removeItemFromCart = createAction('[Cart] Remove Item From Cart', props<{ id: string }>());

export const updateCartStorage = createAction('[Cart] Update Local Storage', props<{ items: cartItem[] }>());
export const getItmesFromStorageSuccess = createAction('[Cart] Get Items From Local Storage Success', props<{ items: cartItem[] }>());
export const getItmesFromStorageFailed = createAction('[Cart] Get Items From Local Storage Failed', props<{ error: any }>());
export const addItemToCartToStorage = createAction('[Cart] Add Item To Cart To Storage', props<{ item: cartItem }>());

export const updateCartItems = createAction('[Cart] Update All Items', props<{ items: Update<cartItem>[] }>());
export const updateCartItem = createAction('[Cart] Update Single Item', props<{ item: Update<cartItem> }>());


export const createPaymentIntent = createAction('[Cart] Create PaymentIntent For Checkout', props<{ order: order }>());
export const getPayemntItentId = createAction('[Cart] Get Customer PaymentIntent', props<{ paymentintent: string }>());



export type cartActions = ReturnType<typeof getCart> |
  ReturnType<typeof getCartSuccessed> |
  ReturnType<typeof updateCartStorage> | ReturnType<typeof addItemToCart> |
  ReturnType<typeof clearCart> | ReturnType<typeof checkoutCart> |
  ReturnType<typeof addItemToCartToStorage> |
  ReturnType<typeof updateCartItem> | ReturnType<typeof updateCartItems> |
  ReturnType<typeof createPaymentIntent> | ReturnType<typeof getPayemntItentId>
