import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from '../../core/auth/auth.guard';
import { SafeHtmlPipe } from './../../core/services/safehtml.pipe';
import { BaseService } from './../services/base.service';
import { CartComponent } from './../cart/cart/cart.component';
import { CartEffect } from './../../core/state/_cart/cart.effects';
import { CartModule } from './../cart/cart.module';
import { CoursesEffect } from './../../core/state/_courses/course.effect';
import { ConsultancyEffect } from './../../core/state/_consultancies/consultancy.effect';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadmoreDirective } from '../components/loadmore/loadmore.directive';
import { LoaderModule } from './../loader/loader.module';
// import { CoursesliderModule } from './../components/courseslider/courseslider.module';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { BaseComponent } from '../base/base.component';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../components/components.module';
// import { CoursesComponent } from '../courses/courses/courses.component';
import { LoadmoreModule } from '../components/loadmore/loadmore.module';
// import { CourseComponent } from '../courses/course/course.component';
import { NgStringPipesModule, ShortenPipe, NgPipesModule } from 'ngx-pipes';
import * as courseReducer from '../../core/state/_courses/course.reducer';
import * as packagesReducer from '../../core/state/_courses/packages.reducer';
import * as cartReducer from '../../core/state/_cart/cart.reducer';
import * as consultancyReducer from '../../core/state/_consultancies/consultancy.reducers';
import { AboutusComponent } from './aboutus/aboutus.component';
import { JoinastrainerComponent } from './joinastrainer/joinastrainer.component';
import { Joinasb2bComponent } from './joinasb2b/joinasb2b.component';
import { JoinasaffiliateComponent } from './joinasaffiliate/joinasaffiliate.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CertcheckerComponent } from './certchecker/certchecker.component';


const routes: Routes = [
  { path: 'hon', component: HomeComponent },
  { path: 'b2bprogram', component: Joinasb2bComponent },
  // { path: 'affiliates', component: JoinasaffiliateComponent, canActivate: [AuthGuard] },

  { path: 'trainer', component: JoinastrainerComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'mastery', component: AboutusComponent },
  // { path: 'certificates', component: CertcheckerComponent },
  { path: 'terms', component: TermsComponent },
  { path: '', redirectTo: '', pathMatch: 'full' }
];


@NgModule({
  declarations: [HomeComponent, AboutusComponent, JoinastrainerComponent, Joinasb2bComponent, PrivacyComponent, TermsComponent, CertcheckerComponent],
  imports: [
    NgxIntlTelInputModule,
    CommonModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule,
    StoreModule.forFeature(courseReducer.courseFeatureKey, courseReducer.courseReducer),
    StoreModule.forFeature(cartReducer.cartReducerFeatureKey, cartReducer.cartReducer),
    StoreModule.forFeature(consultancyReducer.consultancyKeyFeature, consultancyReducer.consultancyReducer),
    StoreModule.forFeature(packagesReducer.packagesFeatureKey, packagesReducer.packagesReducer),
    EffectsModule.forFeature([CoursesEffect]),
    EffectsModule.forFeature([ConsultancyEffect]),
    EffectsModule.forFeature([CartEffect]),
    // CoursesliderModule,
    NgSelectModule,
    TranslateModule, LoaderModule,
    LoadmoreModule, NgPipesModule],
  providers: [
    ShortenPipe, SafeHtmlPipe, AuthGuard
  ]
})
export class HomeModule { }
