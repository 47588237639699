import { consultancyCard } from './../../_models/consultancies';
import { createReducer, on, Action, createFeatureSelector, createSelector } from '@ngrx/store';

import * as consultancyActions from './consultancy.actions';
import { appState } from '../app.state';

export interface consultancyState {
  isLoaded: boolean;
  isLoading: boolean;
  consultancies: consultancyCard[];
  error: any;
  selectedConsultancy: consultancyCard;
  isConsultancySelected: boolean;
}


export const defaultState: consultancyState = {
  isLoaded: false,
  isLoading: false,
  consultancies: undefined,
  error: undefined,
  selectedConsultancy: undefined,
  isConsultancySelected: false
}



const _reducer = createReducer(
  defaultState,
  // on(consultancyActions.AvaliableConsultancies, state => ({ isLoaded: false, isLoading: true })),
  on(consultancyActions.SelectConsultancy, (state: consultancyState, { consultancyId: number }) => ({ ...state, isConsultancySelected: false })),
  on(consultancyActions.GetSelectedConsultancy, (state: consultancyState, { consultancies }) => ({ ...state, selectedConsultancy: consultancies, isConsultancySelected: true })),
  on(consultancyActions.LoadConsultanciesFail, (state: consultancyState) => ({ ...state, isLoading: false, isLoaded: false, error: state.error })),
  on(consultancyActions.LoadConsultanciesSuccess, (state: consultancyState, { consultancies }) => ({ ...state, isLoaded: true, isLoading: false, consultancies: consultancies })),
  on(consultancyActions.SelectedConsultancyError, (state: consultancyState, { error }) => ({ ...state, isConsultancySelected: false, error: error }))
)


export function consultancyReducer(state: consultancyState | undefined, action: Action) {
  return _reducer(state, action);
}


export const consultancyKeyFeature = 'consultancy';

export const selectConsultancyFeature = createFeatureSelector<appState, consultancyState>(consultancyKeyFeature);

export const loadingStatus = createSelector(
  selectConsultancyFeature,
  (state: consultancyState) => state.isLoading
)

export const availableConsultancies = createSelector(
  selectConsultancyFeature,
  (state: consultancyState) => state.consultancies
)


export const selectedConsultancy = createSelector(
  selectConsultancyFeature,
  (state: consultancyState) => state.selectedConsultancy
)

export const isSelectedConsultancy = createSelector(
  selectConsultancyFeature,
  (state: consultancyState) => state.isConsultancySelected
)
