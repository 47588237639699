import { videoCard } from './../_models/video';
import { consultancyCard } from './../_models/consultancies';
import { courseCard, packageCard } from './../_models/cards';
import { courseSlide, COUCategories } from './../../core/_models';

import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CoursesService {


  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string,) {
  }



  getCourseCardById(courseId: number): Observable<courseCard> {
    return this.http.get<courseCard>(this.apiUrl + '/v1/cardbyid/' + courseId, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }


  myCertificates(userId: number): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/mycertificates/' + userId, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }

  getCourses(): Observable<courseSlide[]> {
    return this.http.get<courseSlide[]>(this.apiUrl + '/v1/latest', this.Withoujwt()).pipe(catchError(error => this.handleError(error)))
  }



  getPacakges(): Observable<courseSlide[]> {
    return this.http.get<courseSlide[]>(this.apiUrl + '/v1/latestp', this.Withoujwt()).pipe(catchError(error => this.handleError(error)))
  }

  getCourseCard(courseId: string): Observable<courseCard> {
    return this.http.get<courseCard>(this.apiUrl + '/v1/card/' + courseId, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }


  getPackageCard(courseId: string): Observable<packageCard> {
    return this.http.get<packageCard>(this.apiUrl + '/v1/package/' + courseId, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }


  applyCoupon(code, products) {
    return this.http.post(this.apiUrl + '/v1/coupon/' + code, products, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }









  getConsultancies(): Observable<consultancyCard[]> {
    return this.http.get<consultancyCard[]>(this.apiUrl + '/v1/consultancies', this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }



  getConsultancyCard(id): Observable<consultancyCard> {
    return this.http.get<consultancyCard>(this.apiUrl + '/v1/consultancy/' + id, this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }


  AllCategories(): Observable<COUCategories[]> {
    return this.http.get<COUCategories[]>(this.apiUrl + '/api/courses/categories/', this.Withoujwt()).pipe(catchError(this.handleError));
  }



  getVideos(): Observable<videoCard[]> {
    return this.http.get<videoCard[]>(this.apiUrl + '/v1/videos', this.Withoujwt()).pipe(catchError(error => this.handleError(error)));
  }


  availableDaysForConsultancy(consultancyId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/calendar/' + consultancyId, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }


  availableDaysForConsultancyByMeetingRoom(roomId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/roomcalendar/' + roomId, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }


  getTimezoneForConsultancy(roomId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/roomtimezone/' + roomId, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getReservedDatesForMeetingRoom(roomId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/roomreserved/' + roomId, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  allHoursFromCalendarToConsultancy(consultancyId): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/v1/hourso/' + consultancyId, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }


  availableHoursConsultancy(attrs): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl + '/v1/hours', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }


  schedule_calendly(attrs): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl + '/v1/schedule_calendly', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }



  availableHoursConsultancyByRoomId(attrs): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl + '/v1/roomhours', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  reserveMeetingForConsultancy(attrs): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/v1/reservemeeting', attrs, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  // handleError(error: any) {

  //   var applicationError = error.headers.get('Application-Error');

  //   // either application-error in header or model error in body
  //   if (applicationError) {
  //     return throwError(applicationError);
  //   }

  //   var modelStateErrors: string = '';

  //   // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
  //   for (var key in error.error) {
  //     if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
  //   }

  //   modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
  //   return throwError(modelStateErrors || 'Server error');
  // }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


  private jwt() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
          'Content-Type': 'application/json'
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


}
