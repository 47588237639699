import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingComponent } from './meeting/meeting.component';



@NgModule({
  declarations: [MeetingComponent],
  imports: [
    CommonModule, HttpClientModule
  ],
  exports: [MeetingComponent]
})
export class MeetingsModule { }
