import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { CoursesliderModule } from './courseslider/courseslider.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { FooterComponent } from './footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { TestxComponent } from './testx/testx.component';
import { LoadmoreDirective } from './loadmore/loadmore.directive';
import { LoadmoreModule } from './loadmore/loadmore.module';
import { BookmarkComponent } from './bookmark/bookmark.component';
import { SafeHtmlPipe } from 'src/app/core/services/safehtml.pipe';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ResetComponent } from './reset/reset.component';
import { ConfirmedComponent } from './confirmed/confirmed.component';
import { ResetformComponent } from './resetform/resetform.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BusinessLoginComponent } from './businesslogin/businesslogin.component';
import { BusinessResetComponent } from './blreset/blreset.component';
import { SelectfileComponent } from './selectfile/selectfile.component';
import { LoginbComponent } from './loginb/loginb.component';
import { TwofactorComponent } from './twofactor/twofactor.component';



@NgModule({
  declarations: [HeaderComponent, ProgressbarComponent, FooterComponent,
    TestxComponent, BookmarkComponent, SafeHtmlPipe, LoginComponent, BusinessLoginComponent, BusinessResetComponent,
    RegisterComponent, ResetComponent, ConfirmedComponent, ResetformComponent, SelectfileComponent, LoginbComponent, TwofactorComponent],
  imports: [
    CommonModule, TranslateModule, 
    // CoursesliderModule,
     LoadmoreModule,
    RouterModule, FormsModule, NgxIntlTelInputModule, ReactiveFormsModule,
  ], providers: [
    SafeHtmlPipe
  ],

  exports: [ProgressbarComponent, HeaderComponent, FooterComponent, TestxComponent, BookmarkComponent, SafeHtmlPipe]
})
export class ComponentsModule { }
