import { CookieService } from 'ngx-cookie-service';
import { SettingsService } from '../../../core/services';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from './../../../core/auth/authentication.service';
import { SystemService } from './../../../core/services';
import { AccountService } from './../../../core/services/account.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;



@Component({
  selector: 'sm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle = 0;
  logo
  bProfile;
  template;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _acount: AccountService,
    private _settings: SettingsService,
    private _store: Store<any>,
    private _cookie: CookieService,
    private cd: ChangeDetectorRef,
    private _system: SystemService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.template = this.route.snapshot.data['loginData'];
    if (!this.template) {
      this.template = {
        logo: environment.logoImage,
        companyName: "أكاديمية ماستري للتدريب الإلكتروني",
        primary: environment.MAINColor,
        accent: '#000',
        image: 'https://public.emasteryacademy.com/masteryoffice.png',
      }
    } else {
      if (this.template.image == '') this.template.image = 'https://public.emasteryacademy.com/masteryoffice.png'
    }
    document.documentElement.style.setProperty('--primary-color', this.template.primary);
    localStorage.setItem('__template', JSON.stringify(this.template));
    this._store.dispatch({ type: '[Profile] Stop Loading' });
    this.authenticationService.logout();
    // var id = this.route.snapshot.queryParamMap.get('clientId')
    // console.log(id);

    let pageUrl = environment.production ? window.location.host : environment.domain
    const customDomain = encodeURI(pageUrl);

    if (customDomain != environment.HOMEURL) {
      this._acount.getB2BProfileByDomainExtension(customDomain).subscribe((data: any) => {
        if (!data) {
          this.router.navigate(['/']);
        } else {
          localStorage.setItem('__bb__prf', JSON.stringify(data));
          this.logo = data.logo
          this.bProfile = data;
          this.cd.markForCheck();
          this._store.dispatch({ type: '[Profile] Stop Loading' });
          this.authenticationService.logout();
          // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || (id) ? 'clientId/' + id + '/dashboard' : '/dashboard';
        }

      })
    }

  }

  login() {
    this.loading = true;
    let self = this;
    // var id = this.route.snapshot.queryParamMap.get('clientId')
    // console.log(this.route.snapshot.queryParams['returnUrl']);

    var returnUrl = (this.route.snapshot.queryParams['returnUrl']);
    let companyId = (this.template.companyId) ? this.template.companyId : null;
    this.authenticationService.login(this.model.loginName, this.model.password, companyId)
      .pipe(first())


      .subscribe({
        next: (value) => {
          // console.log(value);

          if (self.template.companyId) {
            if (returnUrl)
              self.router.navigate([returnUrl]);

            self.router.navigate(['/dashboard']);
          } else {
            self.router.navigate(['/2facheck'], { queryParams: { returnUrl: returnUrl, email: value.email, userId: value.userId } });
          }
        },
        error: (error) => {
          console.log(error);

          if (error.error) {
            if (error.error.message == 'No User found') {
              self.alertTitle = 1;
              self.alertMessage = "هذا البريد غير مسجل في الموقع";
              self.loading = false;
              self.cd.markForCheck();
            } else if (error.error.message == 'Faild to login ') {
              self.alertTitle = 1;
              self.alertMessage = "كلمة المرور غير صحيحة يرجى كتابة كلمة المرور الصحيحة او اعادة تعيين كلمة المرور";
              self.loading = false;
              self.cd.markForCheck();
            } else if (error.error.message == 'Not Activated') {
              self.alertTitle = 1;
              self.alertMessage = "لم يتم تفعيل الحساب, تم إرسال رسالة تفعيل إلى بريدكم المسجل يمكن ان تكون الرسالة في الـ spam / Junk ";
              self.loading = false;
              self.cd.markForCheck();
            }
            else if (error.error.message == 'No Permissions') {
              self.alertTitle = 1;
              self.alertMessage = "كلمة المرور غير صحيحة يرجى كتابة كلمة المرور الصحيحة او اعادة تعيين كلمة المرور";
              self.loading = false;
              self.cd.markForCheck();
            }
          }

          self.loading = false;
        }
      });



  }

}
