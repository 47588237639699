<div class="row">
  <div
    *ngIf="alertTitle == 1"
    class="col-md-12 bg-danger"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
  </div>
  <div
    *ngIf="alertTitle == 2"
    class="col-md-12 bg-success"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
  </div>
</div>

<div
  class="login-background"
  [ngStyle]="{
    'background-image': 'url(' + template.image + ')'
  }"
></div>
<div class="login-container">
  <div class="box">
    <span class="borderLine"></span>

    <form
      name="form"
      (ngSubmit)="f.form.valid && register()"
      #f="ngForm"
      novalidate
    >
      <img
        style="object-fit: contain"
        [style.height]="!template.companyId ? '200px' : '200px'"
        [style.width]="!template.companyId ? '200px' : '200px'"
        [src]="template.logo"
        [alt]="template.companyName"
      />
      <div class="input-box">
        <label class="label-text">الاسم الكامل</label>
        <div
          class="form-group"
          [ngClass]="{ 'has-error': f.submitted && !fullname.valid }"
        >
          <input
            class="form-control form--control"
            type="text"
            name="fullname"
            [(ngModel)]="model.fullname"
            #fullname="ngModel"
            required
          />
          <span class="la la-user input-icon"></span>
        </div>
        <div *ngIf="f.submitted && !fullname.valid" class="help-block">
          اسم المشترك مطلوب
        </div>
      </div>
      <!-- end input-box -->
      <div class="input-box">
        <label class="label-text">البريد الإلكتروني</label>
        <div
          class="form-group"
          [ngClass]="{ 'has-error': f.submitted && !email.valid }"
        >
          <input
            class="form-control form--control"
            type="text"
            name="email"
            [(ngModel)]="model.email"
            #email="ngModel"
            required
          />
          <span class="la la-envelope input-icon"></span>
        </div>
        <div *ngIf="f.submitted && !email.valid" class="help-block">
          االبريد الالكتروني مطلوب
        </div>
      </div>
      <!-- end input-box -->
      <div class="input-box">
        <label class="label-text">رقم الجوال</label>
        <div
          class="form-group"
          [ngClass]="{ 'has-error': f.submitted && !email.valid }"
        >
          <ngx-intl-tel-input
            [cssClass]="'form-control form--control'"
            [preferredCountries]="[CountryISO.SaudiArabia]"
            [enableAutoCountrySelect]="false"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.SaudiArabia"
            [maxLength]="15"
            [phoneValidation]="true"
            name="phone"
            [(ngModel)]="phonex"
            [ngModelOptions]="{ standalone: true }"
          >
          </ngx-intl-tel-input>
        </div>
      </div>
      <!-- end input-box -->
      <div class="input-box">
        <div
          class="form-group"
          [ngClass]="{ 'has-error': f.submitted && !password.valid }"
        >
          <label class="label-text">كلمة المرور</label>

          <div class="right-inner-addon input-container">
            <i
              class="fa"
              [ngClass]="{
                'fa-eye': typeOfPassword,
                'fa-eye-slash': !typeOfPassword
              }"
              (click)="showPassword()"
            ></i>
            <input
              [attr.type]="getTypeOfPassword()"
              id="password"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="model.password"
              #password="ngModel"
              class="form-control form--control password-field"
            />
          </div>
        </div>
        <div *ngIf="f.submitted && !password.valid" class="help-block">
          كلمة المرور مطلوبة
        </div>
      </div>
      <!-- end input-box -->
      <div class="btn-box">
        <div class="custom-control custom-checkbox mb-4 fs-15">
          <input
            type="checkbox"
            class="custom-control-input"
            name="acceptterms"
            id="acceptterms"
            [(ngModel)]="model.acceptterms"
            #acceptterms="ngModel"
            required
          />
          <label
            class="custom-control-label custom--control-label"
            for="acceptterms"
            >أوافق على
            <a
              href="/home/terms"
              target="_blank"
              class="text-color-7 hover-underline"
              >شروط الأستخدام</a
            >
            و
            <a href="/home/privacy" class="text-color-7 hover-underline"
              >سياسة الخصوصية</a
            >
          </label>
        </div>
        <!-- end custom-control -->
        <div *ngIf="f.submitted && !acceptterms.valid" class="help-block">
          يجب الموافقة على الشروط والأحكام
        </div>
        <button class="btn theme-btn2 btn-block">
          اشتراك <i class="la la-arrow-right icon ml-1"></i>
        </button>
        <p class="fs-14 pt-3 text-center">
          هل لديك حساب؟
          <a routerLink="/login" class="text-color-8 hover-underline">
            تسجيل دخول</a
          >
        </p>
      </div>
      <!-- end btn-box -->
    </form>
  </div>
</div>
