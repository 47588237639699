import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadmoreService {

  private itemsSubject = new BehaviorSubject<any[]>([]);
  items$ = this.itemsSubject.asObservable();
  items: any[] = [];

  private _allItems = new BehaviorSubject<any[]>([]);
  get allItems() {
    return this._allItems.value;
  }
  set allItems(attr) {
    this._allItems.next(attr);
  }

  constructor() {
    this.getNextItems();
    this.itemsSubject.next(this.items);
  }

  loadMore(): void {
    if (this.getNextItems()) {
      this.itemsSubject.next(this.items);
    }
  }

  getNextItems(): boolean {
    if (this.items.length >= this.allItems.length) {
      return false;
    }
    const remainingLength = Math.min(4, this.allItems.length - this.items.length);
    this.items.push(...this.allItems.slice(this.items.length, this.items.length + remainingLength));
    return true;
  }
}
