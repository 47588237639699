import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
// import { TweenMax } from 'gsap';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as _cartReducer from './../../../core/state/_cart/cart.reducer';
import { environment } from './../../../../environments/environment';
declare var TweenMax: any;

@Component({
  selector: 'sm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  cartItems$: Observable<number>;

  profile;

  constructor(private _store: Store, private _router: Router) { }
  ngOnDestroy(): void {
    this.cartSub.unsubscribe();
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.profile = JSON.parse(localStorage.getItem('profile'));
    }
    this.cartItems$ = this._store.pipe(select(_cartReducer.totalItems));
  }


  showMenu() {
    TweenMax.set('.sm-mobile-menu', {
      css: {
        display: 'block'
      }
    })

    TweenMax.from('#close', .5, { autoAlpha: 0, rotateX: 160, transformOrigin: "50% 50%" })
    TweenMax.staggerFrom('#menu li', .5, { autoAlpha: 0 }, .02)
  }

  cartSub = new Subscription();

  goToProfile() {
    this.closeMenu();
    this._router.navigate(['/dashboard']);
  }

  goToCart() {
    this.closeMenu();
    this.cartSub = this.cartItems$.subscribe(data => {
      if (data) {
        this._router.navigate(['/cart']);
      }
    })
  }

  closeMenu() {
    TweenMax.set('.sm-mobile-menu', {
      css: {
        display: 'none'
      }
    })
  }


  allCourses() {
    this.closeMenu();
    this._router.navigate(['/courses']);

  }
  allCategories() {
    this.closeMenu();
    this._router.navigate(['/courses/packages']);

  }
  allConsultancies() {
    this.closeMenu();
    this._router.navigate(['/consultancy']);
  }
  b2bRequests() {
    this.closeMenu();
    this._router.navigate(['/home/b2bprogram']);
  }
  trainerRequest() {
    this.closeMenu();
    this._router.navigate(['/home/trainer']);
  }
  affiliateRequest() {
    this.closeMenu();
    this._store.dispatch({ type: '[Profile] Stop Loading' });
    this._router.navigate(['/affiliates']);
    this._store.dispatch({ type: '[Profile] Am I An Affiliate', userId: this.profile.userId })
    this._store.dispatch({ type: '[Profile] What Is My Affiliate Status ', userId: this.profile.userId })
  }


  certificateRequest() {
    this.closeMenu();
    this._store.dispatch({ type: '[Profile] Stop Loading' });
    this._router.navigate(['/home/certificates']);
  }



}
