import { myLectureCard, myQuizzAnswers, myQuizzAttempt, myQuizzQuestions, myUnitCard } from './../../_models/profile';
import { createAction, props } from '@ngrx/store';
import { myCourseSlider, myCourseCard, myQuizzCard } from '../../_models';
import { discussionCard } from '../../_models/cards';
import { Update } from '@ngrx/entity';

export const getMyCourses = createAction('[Profile] Get My Courses', props<{ cid: string }>());
export const getMyCourse = createAction('[Profile] Get Course Details', props<{ attrs: any }>());
export const updateMyCourseAfterAnUpdate = createAction('[Profile] Get Course Details After An Update', props<{ attrs: any }>());
export const getMyCourseAfterAnUpdate = createAction('[Profile] Update Course Details', props<{ course: myCourseCard }>());
export const getMyCourseDetailsSuccessed = createAction('[Profile] Get Course Details Successed', props<{ course: myCourseCard }>())
export const getMyCourseSuccessed = createAction('[Profile] Get My Courses Success', props<{ courses: myCourseSlider[] }>());

export const getLecture = createAction('[Profile] Get Lecture Details', props<{ attrs: any }>());
export const getNextLecture = createAction('[Profile] Get Next Lecture Details', props<{ attrs: any }>());
export const getQuizzCard = createAction('[Profile] Get Quizz Card', props<{ attrs: any }>());
export const clearContent = createAction('[Profile] Clear Lecture');
export const getLectureSuccess = createAction('[Profile] Get Lecture Details Successed', props<{ lecture: myLectureCard }>())
export const getQuizzSuccess = createAction('[Profile] Get Quizz Card Successed', props<{ quizz: myQuizzCard }>())
export const getCurrentVideoLinkSigned = createAction('[Profile] Get Current Video Signed Url', props<{ attrs: any }>());
export const getCurrentVideoLinkSignedSuccessed = createAction('[Profile] Get Current Video Signed Url Successed', props<{ link: string }>());
export const isNotLoggedIn = createAction('[Profile] User Is Not Logged In');
export const isLoggedIn = createAction('[Profile] User Is Logged In');
export const notLoadingAnyMore = createAction('[Profile] Stop Loading');
export const isLoadingRightNow = createAction('[Profile] Start Loading');
export const uploadAvatar = createAction('[Profile] Upload Profile Image', props<{ attrs: any }>())
export const SetCoursePercent = createAction('[Profile] Set Course Percent', props<{ percent: any }>())
export const uploadAvatarSuccess = createAction('[Profile] Upload Profile Image Success', props<{ attrs: any }>())
export const CompletedCurrentLecture = createAction('[Profile] Completed Current Lecture', props<{ course: any }>())



export const getAllCommentsOnVideo = createAction('[Profile] Get All Comments On Video', props<{ lectureId: any }>());
export const getAllCommentsOnVideoSuccess = createAction('[Profile] Get All Comments On Video Success', props<{ discussions: discussionCard }>());
export const addNewCommentOnVideo = createAction('[Profile] Add New Comment On Video', props<{ comment: any }>());
export const addNewCommentOnVideoSuccess = createAction('[Profile] Add New Comment On Video Success', props<{ comment: any }>());




export const getMyBookmarksOnVideo = createAction('[Profile] Get My Bookmarks On Video', props<{ attrs: any }>());
export const getMyBookmarksOnVideoSuccess = createAction('[Profile] Get My Bookmarks On Video Success', props<{ discussions: discussionCard }>());
export const addNewBookmark = createAction('[Profile] Add New Bookmark On Video', props<{ bookmark: any }>());
export const addNewBookmarkSuccess = createAction('[Profile] Add New Bookmark On Video Success', props<{ bookmark: any }>());

export const attendQuizz = createAction('[Profile] Attend A Quizz', props<{ exam: any[] }>());
export const attendQuizzSuccessed = createAction('[Profile] Attend A Quizz Success', props<{ result: string }>());

export const setCurrentRoute = createAction('[Profile] Store Current Route', props<{ route: string }>());



export const logoutAndClearEverything = createAction('[Profile] Clear Out Everything');
export const clearCurrentCourse = createAction('[Profile] Clear Current Course');


export const amIanAffiliate = createAction('[Profile] Am I An Affiliate', props<{ userId: number }>());
export const amIanAffiliateSuccessed = createAction('[Profile] Am I An Affiliate Success', props<{ result: boolean }>());

export const whatIsMyAffiliateStatus = createAction('[Profile] What Is My Affiliate Status ', props<{ userId: number }>());
export const whatIsMyAffiliateStatusSuccessed = createAction('[Profile] What Is My Affiliate Status  Success', props<{ result: string }>());


export const directionOfSystem = createAction('[Profile] Set Screen Direction', props<{ dir: boolean }>());


export const resetLoader = createAction('[Profile] Stop Loading');


export const getMyQuizzAttemptionInfo = createAction('[Profile] Get Quizz Info', props<{ attrs: any }>());
export const getMyQuizzAttemptionInfoSuccess = createAction('[Profile] Get Quizz Info Successed', props<{ data: any }>());

export const startQuizzAttempt = createAction('[Profile] Start Attempt Quizz', props<{ attempt: myQuizzAttempt }>())
export const addAnswerToQuizzQuestion = createAction('[Profile] Answer A Question', props<{ answers: myQuizzAnswers[] }>())
export const setCurrentQuestion = createAction('[Profile] Set Current Question', props<{ question: myQuizzQuestions }>())
export const clearCurrentQuestion = createAction('[Profile] Clear Current Question')
export const clearQuizzAndAnswers = createAction('[Profile] End Quizz Attempt');

export const setQuizzResult = createAction('[Profile] Set Quizz Result', props<{ result: any }>());
export const clearQuizzResult = createAction('[Profile] Clear Quizz Result');






export const setUnitLectures = createAction('[Profile] Set Unit Lectures', props<{ units: myUnitCard[] }>());
export const completeLecture = createAction('[Profile] Complete Lecture', props<{ lecture: Update<myUnitCard> }>());
export const updateCourseProgress = createAction('[Profile] Update Course Progress', props<{ attrs: any }>());
export const updateCourseProgressSuccess = createAction('[Profile] Update Course Progress Success', props<{ course: Update<myCourseSlider> }>());
export const getCourseDetailsForUpdate = createAction('[Profile] Get Course Details For Update', props<{ attrs: any }>());
export const getCourseDetailsForUpdateSuccess = createAction('[Profile] Get Course Details For Update Successed', props<{ course: myCourseCard }>())
export const clearAllLectures = createAction('[Profile] Clear All Lectures');








// export const getCookieForMe = createAction('[Profile] Give Me A Cookie');
// export const getCookieForMeSuccessed = createAction('[Profile] Give Me A Cookie Successed', props<{ cookie: any }>());



export type profileActions =
  ReturnType<typeof getMyCourse> |
  ReturnType<typeof getMyCourseSuccessed>

