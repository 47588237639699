<header class="cs-dashboard-content-wrap dashboard-content-wrap bgdashgray d-flex flex-column p-0">
  <div class="header-menu-content dashboard-menu-content bg-white shadow-sm">
    <div class="container-fluid">
      <div class="main-menu-content">
        <div class="row align-items-center">
          <div class="col-lg-12 altogther">
            <div class="logo-box ">
              <a *ngIf="bProfile && bProfile.companyId" href="../dashboard/quick" class="logo">
                <img [src]='bProfile.logo' style="width: 200px; height: 100px; object-fit: contain;" />
              </a>
              <!-- <ul>
                <li>
                  <a href="../dashboard/quick" class="logo">
                    <span>
                      {{'DASHBOARD.DASHBOARD' | translate}}
                    </span>
                  </a>
                </li>
              </ul> -->
              <div class="user-btn-action">
                <!-- <div class="search-menu-toggle icon-element icon-element-sm shadow-sm mr-2" data-toggle="tooltip"
                  data-placement="top">
                  <i class="la la-search"></i>
                </div> -->
                <!-- <div class="off-canvas-menu-toggle icon-element icon-element-sm shadow-sm mr-2" style="
                width: auto;
                max-width: 25%;
            "
                data-toggle="tooltip" data-placement="top">
                  <i class="la la-bell"></i>
                </div> -->
                <div class="dashboard-menu-toggler icon-element icon-element-sm shadow-sm" (click)="toggleMenu()"
                  data-toggle="tooltip" data-placement="top">
                  <i class="la la-bars"></i>
                </div>
              </div>
            </div><!-- end logo-box -->
            <div class="menu-wrapper" style="justify-content: space-between;">
              <!-- <img
              [src]="template.logo"
              [alt]="template.companyName"
              style="width: 100px; height: 100px"
            /> -->
              <div class="nav-right-button d-flex align-items-center">
                <div class="user-action-wrap d-flex align-items-center">
                  <div class="shop-cart notification-cart pr-3">
                    <ul>
                      <li>
                        <p class="shop-cart-btn">
                          <i class="la la-bell"></i>
                          <span *ngIf="myNotifications.length != 0" class="dot-status bg-1"></span>
                        </p>
                        <ul class="cart-dropdown-menu after-none p-0 notification-dropdown-menu">
                          <li class="menu-heading-block d-flex align-items-center justify-content-between">
                            <h4>الإشعارات</h4>
                            <span class="ribbon fs-14">{{myNotifications.length}}</span>
                          </li>
                          <li *ngIf="myNotifications && myNotifications.length != 0">
                            <div class="notification-body">
                              <a href="#" class="media media-card align-items-center">
                                <div class="icon-element icon-element-sm flex-shrink-0 bg-10 mr-3 text-white">
                                  <i class="la la-user"></i>
                                </div>
                                <div class="media-body">
                                  <h5>تم إنشاء حسابك بنجاح</h5>
                                  <span class="d-block lh-18 pt-1 text-gray fs-13">12 نوفمبر 2019</span>
                                </div>
                              </a>
                              <a href="#" class="media media-card align-items-center">
                                <div class="icon-element icon-element-sm flex-shrink-0 bg-8 mr-3 text-white">
                                  <i class="la la-lock"></i>
                                </div>
                                <div class="media-body">
                                  <h5>لقد قمت بتغيير كلمة المرور</h5>
                                  <span class="d-block lh-18 pt-1 text-gray fs-13">12 نوفمبر 2019</span>
                                </div>
                              </a>
                              <a href="#" class="media media-card align-items-center">
                                <div class="icon-element icon-element-sm flex-shrink-0 bg-9 mr-3 text-white">
                                  <i class="la la-lock"></i>
                                </div>
                                <div class="media-body">
                                  <h5>لقد قمت بتغيير كلمة المرور</h5>
                                  <span class="d-block lh-18 pt-1 text-gray fs-13">12 نوفمبر 2019</span>
                                </div>
                              </a>
                            </div>
                          </li>

                          <li *ngIf="myNotifications.length == 0">
                            <div class="notification-body">
                              <a class="media media-card align-items-center">
                                <div class="icon-element icon-element-sm flex-shrink-0 bg-10 mr-3 text-white">
                                  <i class="la la-user"></i>
                                </div>
                                <div class="media-body">
                                  <h5>{{'DASHBOARD.NONOTIFICATIONS' | translate}}</h5>
                                  <!-- <span class="d-block lh-18 pt-1 text-gray fs-13">12 نوفمبر 2019</span> -->
                                </div>
                              </a>
                            </div>
                          </li>
                          <li *ngIf="myNotifications.length != 0" class="menu-heading-block">
                            <a href="#" class="btn theme-btn2 w-100"> كافة الإشعارات<i
                                class="la la-arrow-right icon ml-1"></i></a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div><!-- end shop-cart -->
                </div>
              </div><!-- end nav-right-button -->
            </div><!-- end menu-wrapper -->
          </div><!-- end col-lg-10 -->
        </div><!-- end row -->
      </div>
    </div><!-- end container-fluid -->
  </div><!-- end header-menu-content -->
   
  <div class="body-overlay"></div>
</header><!-- end header-menu-area -->
<!--======================================
        END HEADER AREA
  ======================================-->
<!-- ================================
  START DASHBOARD AREA
================================= -->
<section class="dashboard-area">
  <div
    id="mobile-menu"
    class="off-canvas-menu dashboard-off-canvas-menu off--canvas-menu custom-scrollbar-styled pt-20px"
  >
    <div
      (click)="toggleMenu()"
      class="off-canvas-menu-close dashboard-menu-close icon-element icon-element-sm shadow-sm"
      data-toggle="tooltip"
      data-placement="left"
    >
      <i class="la la-times"></i>
    </div>
    <!-- end off-canvas-menu-close -->
    <div class="logo-box px-4">
      <a href="#" class="logo"> </a>
    </div>
    <sm-b2b-mobilemnue></sm-b2b-mobilemnue>
  </div>
  <!-- end off-canvas-menu -->
  <router-outlet></router-outlet>
</section>
<!-- end dashboard-area -->
<!-- ================================
    START DASHBOARD AREA
  ================================= -->
<!-- <section class="dashboard-area">

  <router-outlet ></router-outlet>
</section> -->



<div class="row align-items-center dashboard-copyright-content pb-4 dashboard-content-wrap mt-auto mgren0">
  <div class="col-lg-6">
    <p class="copy-desc">ELT © {{currentDate}} | Version. {{currentVersion}} </p>
  </div>
  <div class="col-lg-6">
    <ul class="generic-list-item d-flex flex-wrap align-items-center fs-14 justify-content-end">
      <li class="mr-3"><a [routerLink]="['/home/terms']" routerLinkActive="router-link-active">شروط الأستخدام</a></li>
      <li><a [routerLink]="['/home/privacy']" routerLinkActive="router-link-active">سياسة الخصوصية</a></li>
    </ul>
  </div>
</div>




<!-- ================================
    END DASHBOARD AREA
  ================================= -->

<!-- start scroll top -->
<div id="scroll-top">
  <i class="la la-arrow-up" title="Go top"></i>
</div>
<!-- end scroll top -->

<!-- Modal -->
