import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { SystemService } from 'src/app/core/services';
import { AccountService } from 'src/app/core/services/account.service';
import { environment } from 'src/environments/environment';
declare var $: any;



@Component({
  selector: 'sm-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {



  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle = 0;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia];
  phonex: any;
  didacceptterms = false;
  template
  typeOfPassword = true;
  password
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _acount: AccountService,
    private _store: Store<any>,
    private cd: ChangeDetectorRef,
    private _system: SystemService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.template = {
      logo: environment.logoImage,
      companyName: "أكاديمية ماستري للتدريب الإلكتروني",
      primary: '#ec5252',
      accent: '#000',
      image: 'https://public.emasteryacademy.com/masteryoffice.png',
    }

    // this.authenticationService.logout();
    // reset login status
    //

    // get return url from route parameters or default to '/'
    // console.log(this.route.snapshot.queryParams['returnUrl']);

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  getTypeOfPassword(): any {
    if (this.typeOfPassword) return 'password'
    if (!this.typeOfPassword) return 'text'
  }

  showPassword() {
    console.log('eerr');

    this.typeOfPassword = !this.typeOfPassword;
  }

  register() {
    this.loading = true;
    if (!this.model.acceptterms) {
      this.didacceptterms = false;
    } else {
      this.didacceptterms = true;
    }
    // if (this.model.password != this.model.repassword) {
    //   this.alertTitle = 1;
    //   this.alertMessage = "يجب تطابق كلمات المرور";
    //   this.loading = false;
    //   this.cd.markForCheck();

    //   setTimeout(() => {
    //     this.alertTitle = 0;
    //     this.cd.markForCheck();
    //   }, 2500);
    //   this.loading = false;
    //   return;
    // }
    this.model.phone = this.phonex.e164Number;
    this.authenticationService.register(this.model).subscribe((data) => {
      console.log(data);
      if (data == 'existed') {
        this.alertTitle = 1;
        this.alertMessage = "هذا البريد مسجل من قبل ";
        this.loading = false;
        this.cd.markForCheck();

        setTimeout(() => {
          this.alertTitle = 0;
          this.cd.markForCheck();
        }, 2500);
      } else {

        this.loadScript();
        this.alertTitle = 2;
        this.alertMessage = " سيتم تحويلكم إلى الصفحة الرئيسية تم إرسال رسالة تفعيل إلى بريدكم \n  يمكن ان تصل الرسالة إلى بريد Junk Mail"
        this.cd.markForCheck();
        setTimeout(() => {
          this.alertTitle = 0;
          this.cd.markForCheck();
          this.router.navigate(['/']);
        }, 5000);
      }
    })

  }




  loadScript() {
    let node = document.createElement('script'); // creates the script tag
    node.text = "snaptr('track', 'SIGN_UP', {success: 1 });"; // sets the source (insert url in between quotes)
    node.type = 'text/javascript'; // set the script type
    node.async = true; // makes script run asynchronously
    node.charset = 'utf-8';
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);
  }




}
