import { Affiliate } from './../_models/affiliates';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AffiliateService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string,) {
  }



  private currentAffiliate = new BehaviorSubject<Affiliate>(null);


  getCurrentAffiliate() {
    return this.currentAffiliate;
  }

  updateCurrentAffiliate(affiliateId) {
    this.getAffiliateProfileById(affiliateId).subscribe((data) => {
      this.currentAffiliate.next(data);
    })
  }

  // subscribe
  SubscribeForAffiliate(attr): Observable<Affiliate> {
    return this.http.post<Affiliate>(this.apiUrl + '/api/affiliates/subscribe', attr, this.jwt());
  }
  // myprofile/{userId}
  getMyAffiliateProfile(userId): Observable<Affiliate> {
    return this.http.get<Affiliate>(this.apiUrl + '/api/affiliates/myprofile/' + userId, this.jwt());
  }
  // profile/{affiliateId}
  getAffiliateProfileById(affiliateId): Observable<Affiliate> {
    return this.http.get<Affiliate>(this.apiUrl + '/api/affiliates/profile/' + affiliateId, this.jwt());
  }
  // delete/{affiliateId}
  deleteAffiliateProfile(affiliateId) {
    return this.http.delete(this.apiUrl + '/api/affiliates/delete/' + affiliateId, this.jwt());
  }
  // all
  getAllAffiliateAccounts(): Observable<Affiliate[]> {
    return this.http.get<Affiliate[]>(this.apiUrl + '/api/affiliates/all', this.jwt());
  }
  // remove
  removeAllDeletedAffiliates() {
    return this.http.post(this.apiUrl + '/api/affiliates/remove', this.jwt());
  }
  // updatestatus
  updateAffiliateStatus(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/updatestatus', attrs, this.jwt());
  }
  // bankinfo
  updateBankInfoForAffiliate(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/bankinfo', attrs, this.jwt());
  }
  getMyBankInfo(attrs) {
    return this.http.get(this.apiUrl + '/api/affiliates/bankinfo/' + attrs, this.jwt());
  }
  // commission
  addCommissionToProduct(attrs) {
    return this.http.post(this.apiUrl + '/api/affiliates/commission', attrs, this.jwt());
  }
  // confirmed
  getAllConfirmedCommissionsFroAnAffiliate(attrs) {
    return this.http.post(this.apiUrl + '/v1/confirmedcommissions', attrs, this.jwt());
  }


  getTotalPaidCommissions(attrs) {
    return this.http.get(this.apiUrl + '/v1/paidcomissions/' + attrs, this.jwt());
  }


  getTotalUnPaidCommissions(attrs) {
    return this.http.get(this.apiUrl + '/v1/unpaidcomissions/' + attrs, this.jwt());
  }

  getMyPaymentRequests(attrs) {
    return this.http.get(this.apiUrl + '/v1/paymentrequests/' + attrs, this.jwt());
  }


  getCurrentPaymentRequest(attrs) {
    return this.http.get(this.apiUrl + '/v1/currentpaymentrequest/' + attrs, this.jwt());
  }


  getAffiliatePolicies() {
    return this.http.get(this.apiUrl + '/v1/affiliatepolicies/', this.jwt());
  }

  getAllMyCoupons(userId) {
    return this.http.get(this.apiUrl + '/v1/mycoupons/' + userId , this.jwt());
  }
  getTrainersPolicies() {
    return this.http.get(this.apiUrl + '/v1/trainerspolicies/', this.jwt());
  }
  getB2BPolicies() {
    return this.http.get(this.apiUrl + '/v1/b2bpolicies/', this.jwt());
  }


  getLatestPaymentRequestDate(attrs) {
    return this.http.get(this.apiUrl + '/v1/lastpaymentdate/' + attrs, this.jwt());
  }

  sendPaymentRequest(attrs) {
    return this.http.get(this.apiUrl + '/v1/paymentrequest/' + attrs, this.jwt());
  }




  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


  private jwt() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
          'Content-Type': 'application/json'
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


}
