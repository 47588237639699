// import { NgxPaginationModule } from 'ngx-pagination';
// import { QuillModule } from 'ngx-quill';
import { DragulaModule } from 'ng2-dragula';
import { AuthenticationService } from './core/auth/authentication.service';
// import { ProfileComponent } from './views/profile/profile/profile.component';
// import { ProfileModule } from './views/profile/profile.module';
import { ViewsModule } from './views/views.module';
import { NgPipesModule, ShortenPipe } from 'ngx-pipes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BaseService } from './views/services/base.service';
import { QuicklinkModule } from 'ngx-quicklink';
import localar from '@angular/common/locales/ar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgModule, APP_INITIALIZER, Inject, Injectable, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { BaseComponent } from './views/base/base.component';
import { ComponentsModule } from './views/components/components.module';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { LoaderModule } from './views/loader/loader.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoursesEffect } from './core/state/_courses/course.effect';
import { MenuModule } from './menu/menu.module';
import { HomeModule } from './views/home/home.module';
import { SliderModule } from './slider/slider.module';
import { PlayerModule } from './player/player.module';
// import { CoursesliderModule } from './views/components/courseslider/courseslider.module';
import { MeetingsModule } from './meetings/meetings.module';
import { LoadmoreService } from './views/components/loadmore/loadmore.service';
import { AuthGuard } from './core/auth/auth.guard';
import { SystemService, CoursesService, AuthInterceptor, AffiliateService, B2BService, SHA1CryptoService, SettingsService, TranslationService } from './core/services';
import { environment } from '../environments/environment';
import { BroadcastService } from './core/services/channel.service';
import * as reducer from './core/state/_auth/_reducers/auth.reducer'
import { AccountService } from './core/services/account.service';
// import { PreviewComponent } from './views/courses/preview/preview.component';
import { ProfileEffect } from './core/state/_profile/profile.effect';
import * as profileRedcuer from './core/state/_profile/profile.reducer';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as Hammer from 'hammerjs';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CallbackComponent } from './callback/callback.component';
import { LazyLoadImageModule } from 'ng-lazyload-image'; // <-- import it
import { NgrxRouterStoreModule } from './core/state/_router/ngrx-router.module';
import { PlannerEffect } from './core/state/_planner/planner.effects';
import * as plannerReducer from './core/state/_planner/planner.reducer';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { NgSelectModule } from '@ng-select/ng-select';
import { xAuthGuard2 } from './core/auth/xauthb2b.guard';
import { AuthGuard2 } from './core/auth/authb2b.guard';
import { NgxSpinnerModule } from "ngx-spinner";
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatDialogModule } from '@angular/material/dialog';
import { JoditAngularModule } from 'jodit-angular';
import { JwtInterceptor } from './core/services/jwtintercepetor';
import { ErrorInterceptor } from './core/services/errorhandler';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';


import { PlyrModule } from 'ngx-plyr';
import { NewmainmenuComponent } from './views/dashboard/newmainmenu/newmainmenu.component';
import { SupervisionModule } from './views/supervision/supervision.module';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxPaginationModule } from 'ngx-pagination';


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.SITEURL
  },
  palette: {
    popup: {
      background: '#000',
      text: '#fff'
    },
    button: {
      background: '#002d62'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  content: {

    cookiePolicyHref: 'https://cookie.com',
    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: 'https://privacy.com',


  }
};


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: false }
  };
}


export function appInitializer(authenticationService: AuthenticationService, translationService: TranslationService) {
  return () => new Promise(resolve => {
    // attempt to refresh token on app start up to auto authenticate
    const lang = localStorage.getItem('language')
    const prfile = JSON.parse(localStorage.getItem('mprofile'));
    if (!lang || lang == 'ar') {
      translationService.setLanguage('ar');

    } else {
      translationService.setLanguage(lang);
    }
    authenticationService.refreshToken()
      .subscribe()
      .add(resolve);
  });
}




// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// registerLocaleData(localar);

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    BaseComponent,
    // PreviewComponent,
    CallbackComponent,
  ],
  imports: [
    ComponentsModule, AppRoutingModule, QuicklinkModule, 
    NgxPaginationModule,
    TranslateModule.forRoot(), JoditAngularModule,
    NgrxRouterStoreModule,
    // VimeModule,
    // QuillModule.forRoot(),
    
    LazyLoadImageModule,
    LoadingBarModule,
    MatNativeDateModule,
    MatDialogModule,
    // PlyrModule,
    // NgcCookieConsentModule.forRoot(cookieConfig),
    // UniversalCookieConsentModule.forRoot({
    //   autoShow: true,
    //   consentTypes: [
    //     {
    //       id: 'base',
    //       title: 'Base Functionality',
    //       description: 'These cookies are required for the functionality of this website and can\'t be disabled.',
    //       mandatory: true
    //     },
    //     {
    //       id: 'analytics',
    //       title: 'Analytics',
    //       description: 'We use these cookies to improve our website.',
    //       color: 'orange'
    //     }
    //   ],
    //   disableBodyScroll: true
    // }),
    // NgxDaterangepickerMd.forRoot(),
    PdfViewerModule,
    DragulaModule.forRoot(),
    BrowserModule,
    NgxSpinnerModule,
    LoaderModule,
    StoreModule.forRoot({ state: reducer.AuthReducer, }),
    // StoreDevtoolsModule.instrument({
    //   name: 'Mastery Academy',
    //   logOnly: environment.production,
    // }),
    EffectsModule.forRoot([CoursesEffect]),
    EffectsModule.forRoot([PlannerEffect]),
    StoreModule.forFeature(profileRedcuer.profileReducerFeatureKey, profileRedcuer.profileReducer),
    StoreModule.forFeature(plannerReducer.plannerReducerFeatureKey, plannerReducer.plannerReducer),
    EffectsModule.forFeature([ProfileEffect]),
    HttpClientModule,
    // HammerModule,
    MenuModule,
    NgSelectModule,
    ViewsModule,
    //  FontAwesomeModule,
    // AuthModule.forRoot(),
    // SliderModule,
    PlayerModule,
    // CoursesliderModule,
    MeetingsModule,
    BrowserAnimationsModule,
    NgPipesModule,
    BsDropdownModule.forRoot(), NgxIntlTelInputModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LoadmoreService, AccountService, AuthGuard2,
    AuthGuard, SystemService, CoursesService, AuthInterceptor, AffiliateService, B2BService, BaseService,
    AuthenticationService,
    SHA1CryptoService,
    ShortenPipe, SettingsService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    CookieService,
    // { provide: LOCALE_ID, useValue: 'ar-SA' },
    BroadcastService,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, TranslationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
