import { CookieService } from 'ngx-cookie-service';
import { SettingsService } from '../../../core/services';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { SystemService } from '../../../core/services';
import { AccountService } from '../../../core/services/account.service';
import { environment } from 'src/environments/environment';
declare var $: any;



@Component({
  selector: 'sm-login',
  templateUrl: './businesslogin.component.html',
  styleUrls: ['./businesslogin.component.scss']
})
export class BusinessLoginComponent implements OnInit {

  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _acount: AccountService,
    private _settings: SettingsService,
    private _store: Store<any>,
    private _cookie: CookieService,
    private cd: ChangeDetectorRef,
    private _system: SystemService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {

    this._store.dispatch({ type: '[Profile] Stop Loading' });
    this.authenticationService.logout();
    // reset login status
    //

    // get return url from route parameters or default to '/'
    // console.log(this.route.snapshot.queryParams['returnUrl']);

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/business';
  }

  login() {
    this.loading = true;
    this.authenticationService.loginb(this.model.loginName, this.model.password).subscribe((response: any) => {

      console.log(response);
      // return ;

      let user = response;
      if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (typeof window !== 'undefined') {
          localStorage.setItem('profile', JSON.stringify(user));


          this._settings.getSignCookieForMe().subscribe((data: any) => {



            this._cookie.delete('CloudFront-Policy');
            this._cookie.delete('CloudFront-Key-Pair-Id');
            this._cookie.delete('CloudFront-Signature');
            // this._cookie.set('CloudFront-Expires', data.cookie.expires.value);
            // this._cookie.set('CloudFront-Key-Pair-Id', data.cookie.keyPairId.value);
            // this._cookie.set('CloudFront-Signature', data.cookie.signature.value);
            document.cookie = 'CloudFront-Policy=' + data.cookie.policy.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
            document.cookie = 'CloudFront-Key-Pair-Id=' + data.cookie.keyPairId.value + ";path=/;domain=" + environment.THECLOUD + ";expires=" + new Date().setHours(4);
            document.cookie = 'CloudFront-Signature=' + data.cookie.signature.value + ";path=/;domain=" + environment.THECLOUD;
            this.cd.markForCheck();
          })

          this._store.dispatch({ type: '[Profile] User Is Logged In' })
          this._store.dispatch({ type: '[Profile] Get My Courses', cid: user.sub });
          this.router.navigate([this.returnUrl]);
        }

      }


    }, error => {
      if (error.status == 401) {
        this.alertTitle = 1;
        this.alertMessage = "كلمة المرور غير صحيحة يرجى كتابة كلمة المرور الصحيحة او اعادة تعيين كلمة المرور";
        this.loading = false;
        this.cd.markForCheck();

        setTimeout(() => {
          this.alertTitle = 0;
          this.cd.markForCheck();
        }, 2500);
      }
      this.loading = false;
    });


  }

}
