<div class="logo-box px-4">

  <a (click)="goHome()" class="logo">
    <img [src]="logo" style="min-height: 30px;" alt="">
    <!-- <img src="/assets/jahezkw.png" style="min-height: 21px;" alt=""> -->
    <!-- <img src="/assets/images/maknooncertificate.png" style="min-height: 21px;" alt=""> -->
  </a>

</div>

<ul  style="margin-top: 50px;" class="cs-new-sub-generic-list-item generic-list-item off-canvas-menu-list off--canvas-menu-list">
  <li *ngIf="profile.bSuper === 'true'" routerLinkActive="page-active"><a
      [routerLink]="['/clientId/' + clientId  + '/supervision']" routerLinkActive="page-active"><svg class="mr-2"
        xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z" />
      </svg> {{'DASHBOARD.SUPERVISION' | translate }}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/quick']" routerLinkActive="page-active"><svg
        class="mr-2" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z" />
      </svg> {{'DASHBOARD.DASHBOARD' | translate }}</a></li>
  <li routerLinkActive="page-active"><a class="btn" style="color: #000;cursor: pointer;" (click)="goToHome()"><i
        class="la la-home font20 mr-2"></i>{{'DASHBOARD.HOMEPAGE' |
      translate }}</a></li>
  <li [routerLink]="['/dashboard/planner']" routerLinkActive="page-active"><a [routerLink]="['/dashboard/planner']"
      routerLinkActive="page-active"><i class="la la-edit font20 mr-2"></i>{{'DASHBOARD.LEARNINGPLAN' |
      translate }}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/update_info']" routerLinkActive="page-active"><svg
        class="mr-2" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
      </svg> {{'DASHBOARD.PERSONALINFO' | translate}}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/mycourses']"
      routerLinkActive="router-link-active"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="18px"
        viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
      </svg> {{'DASHBOARD.MYCOURSES' | translate }}</a></li>
  <li *ngIf="profile.groupId == 4 " routerLinkActive="page-active"><a [routerLink]="['/dashboard/assigned_courses']"
      routerLinkActive="router-link-active"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="18px"
        viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
      </svg> {{'DASHBOARD.MYASSIGNEDCOURSES' | translate }}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/consultancies']"
      routerLinkActive="router-link-active"><i class="la la-comment font20 mr-2"></i> {{'DASHBOARD.MYCONSULTANCIES'
      | translate}}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/quizzes']" routerLinkActive="router-link-active">
    <svg
        class="mr-2" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="18px"
        viewBox="0 0 24 24" width="18px">
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <path
            d="M11,21h-1l1-7H7.5c-0.88,0-0.33-0.75-0.31-0.78C8.48,10.94,10.42,7.54,13.01,3h1l-1,7h3.51c0.4,0,0.62,0.19,0.4,0.66 C12.97,17.55,11,21,11,21z" />
        </g>
      </svg> {{'DASHBOARD.MYQUIZZES' | translate}}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/assignments']"
      routerLinkActive="router-link-active"><i class="la la-book font20 mr-2"></i> {{'DASHBOARD.MYASSIGNMENTS' |
      translate}}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/notebook']"
      routerLinkActive="router-link-active"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="18px"
        viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z" />
      </svg> {{'DASHBOARD.MYBOOKMARKS' | translate}}</a></li>
  <li routerLinkActive="page-active"><a [routerLink]="['/dashboard/certificates']"
      routerLinkActive="router-link-active"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="18px"
        viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 3L1 9l4 2.18v6L12 21l7-3.82v-6l2-1.09V17h2V9L12 3zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9zM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72z" />
      </svg> {{'DASHBOARD.MYCERTIFICATES' | translate}}</a></li>
  <li *ngIf="!bProfile" routerLinkActive="page-active"><a [routerLink]="['/dashboard/orders']" routerLinkActive="router-link-active"><svg
        class="mr-2" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M21 7.28V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-2.28c.59-.35 1-.98 1-1.72V9c0-.74-.41-1.37-1-1.72zM20 9v6h-7V9h7zM5 19V5h14v2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6v2H5z" />
        <circle cx="16" cy="12" r="1.5" />
      </svg> {{'DASHBOARD.MYFINANCE' | translate}}</a></li>
  <li *ngIf="!bProfile" routerLinkActive="page-active"><a [routerLink]="['/affiliates']"><i class="la la-tag font20 mr-2"></i> {{'DASHBOARD.AFFILIATEPROGRAM' |
      translate}}</a></li>
  <li routerLinkActive="page-active"><a style="cursor: pointer; color: #1e3d7d;" (click)="logout()"><svg class="mr-2"
        xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" />
      </svg> {{'DASHBOARD.LOGOUT' | translate}}</a></li>
</ul>
