import { AccountService } from './../../../core/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sm-confirmed',
  templateUrl: './confirmed.component.html',
  styleUrls: ['./confirmed.component.scss']
})
export class ConfirmedComponent implements OnInit, OnDestroy {


  id: string;
  private sub: any;

  id2: number;
  private sub2: any;


  model: any = {};


  constructor(private route: ActivatedRoute, private _account: AccountService, private _store: Store<any>, private _router: Router) { }

  ngOnInit(): void {


    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']; // (+) converts string 'id' to a number
      // console.log(this.id);
      this.sub2 = this.route.params.subscribe(params => {
        this.id2 = params['id2']; // (+) converts string 'id' to a number
        // console.log(this.id2);
        this.model.id = this.id;
        this.model.userId = this.id2;
        this._account.getin(this.model).subscribe((data) => {

          let user = data;
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (typeof window !== 'undefined') {
              localStorage.setItem('profile', JSON.stringify(user));
              this._store.dispatch({ type: '[Profile] User Is Logged In' })
              // console.log(user);
              this._router.navigate(['/']);
            }

          }
        })
      });
    });




  }


  ngOnDestroy() {
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }

}
