<div class="mas-terms-container">
  <h4>{{'ACADEMY.TERMSOFUSE' | translate }}</h4>

  <strong>الشروط والاحكام</strong>

  <p>تحدد هذه الشروط والأحكام ضوابط استخدام موقع <span style="color: #0b0245;">emasteryacademy.com</span> ، حيث يعد
    استخدام هذا الموقع قبولاً
    تاماً بكامل هذه
    الشروط والأحكام، فإن لم يكن المستخدم للموقع موافقاً عليها أو على أي جزء منها فيجب عليه الامتناع عن استخدام موقعنا
    الالكتروني.</p>
  <ul>
    <li>فهذه الشروط والاحكام تعتبر اتفاقية بين المشتركين بالموقع من جانب وبين موقع emasteryacademy.com من جانب اخر، لذلك
      يجب
      الالتزام بكافة بنود هذه الاتفاقية والا يتعرض مخالفها للمسؤولية القانونية، ويلتزم بالتعويض الجابر للأضرار الناشئة
      عن
      المخالفة.</li>
    <li>تعود ملكية موقع emasteryacademy.com لشركة  Mastery Academy Training Solutions، ،والمرخصة في ماليزيا رخصة رقم :
      1321637-V</li>
  </ul>

  <strong> وصف الموقع</strong>

  <div>
    <p>
      <span style="color: red;">
        موقع <span style="color: #0b0245;">emasteryacademy.com</span> عبارة عن موقع الكتروني يتم من خلاله تقديم خدمات
        التدريب الإلكتروني للأفراد والشركات
        بمختلف أنواعها في مجالات ريادة الأعمال والمشاريع الصغيرة والمتوسطة والتنمية الذاتية ومختلف المجالات والمهارات
        التي
        تخدم المستفيدين في سوق العمل، وذلك من خلال مدربين محترفين ومتخصصين وأصحاب كفاءة وخبرة في مجالات مختلفة يقدمون
        هذا
        التدريب بأساليب متعددة لتتناسب مع جميع المتلقين من المتدربين، واليكم بعض المميزات التي يوفرها موقعنا وهي كالاتي.

      </span>
    </p>
    <ul>
      <li>يوفر موقعنا <span style="color: #0b0245;">emasteryacademy.com</span> خدمات التدريب للأفراد و للشركات.</li>
      <li> يوفر موقعنا <span style="color: #0b0245;">emasteryacademy.com</span> دورات تدريبية وتعليمية و دبلومات في
        مجالات مختلفة منها ما هو مسجل بموقعنا
        ومنها
        ما يتم تقديمة في بث مباشر للمتدربين المشتركين في الدورات وهذه الدورات تكون بمقابل مادي يتم دفعه عند الاشتراك
        بالدورة.</li>
      <li>كما يوفر موقعنا <span style="color: #0b0245;">emasteryacademy.com</span> بعض الدورات المجانية يقدمها موقعنا
        لكافة المشتركين بدون مقابل.</li>
      <li>يوفر موقعنا <span style="color: #0b0245;">emasteryacademy.com</span> خدمة الاستشارات الشخصية في بعض المجالات،
        من خلال مكالمة فيديو لمدة 60
        دقيقة بين
        المدرب المختص والمتدرب وتتم هذه المكالمة عبر موقعنا، وذلك مقابل مبلغ من المال يدفع مقدما عند طلب الاستشارة.</li>
      <li>يستطيع كل متدرب إجراء نقاشات وحوارات مباشرة مع المدرب أو زملائه المشاركين في نفس الدورة في المكان المخصص
        للنقاشات
        داخل الدورة التدريبية.</li>
      <li>في نهاية كل دورة تدريبية يعطي الموقع للمتدرب شهادة الكترونية بإتمامه الدورة التدريبية.</li>
      <li>يوفر موقعنا <span style="color: #0b0245;">emasteryacademy.com</span> خدمة عملاء ودعم فني للمشتركين بالدورات
        التدريبية الخاصة بنا.</li>
      <li>يوفر موقعنا <span style="color: #0b0245;">emasteryacademy.com</span> وسائل دفع مختلفة ليقوم العميل باختيار
        الوسيلة المناسبة له ويدفع بها</li>

    </ul>
  </div>
  <div>
    <p>
      معانى بعض المصطلحات الواردة في بنود هذه الاتفاقية
      نحن ، موقعنا ، لنا ، الموقع : كل هذه المصطلحات المقصود بها موقع " ماستري اكاديمي "
      المشترك ، المشتركين ، المسجلين ، المستخدمين : يقصد بهذه المصطلحات كل من يستخدم موقع " ماستري اكاديمي" و مشترك به
      بهدف
      الاستفادة من المنتجات او الخدمات المقدمة من الموقع سواء كان هذا المشترك مدرب او متدرب.
      المدرب ، المدربين ، المستشار: يقصد بهذه المصطلحات كل من يقدم دورات تدريبة او استشارات خاصة عبر موقعنا.
      المتدرب ، المتدربين ، المتلقي : يقصد بهذه المصطلحات كل من يسجل في الدورات التدريبية المقدمة من موقعنا بهدف التدريب
      والاستفادة منها وهؤلاء المتدربين ممكن ان يكونوا افراد او شركات.
    </p>
  </div>

  <div style="text-align: right; padding: 2em;">
    <strong>
      كيفية التسجيل والاشتراك بالموقع
    </strong>
    <p>يتم التسجيل بموقع <span style="color: #0b0245;">emasteryacademy.com</span> بواسطة البريد الالكتروني ورقم الجوال
      الخاص بكل شركة او فرد يريد التسجيل
      مع
      تخصيص كلمة مرور له وذلك بعد الموافقة على كل ما جاء بهذه الشروط والاحكام من بنود تحكم كيفية استخدام الموقع.</p>
    <ul>
      <li>لا يشترط للتسجيل بالموقع سن معين للاشتراك.</li>
      <li>الاشتراك بالموقع مجانا مدى الحياة.</li>
      <li>يكون لهؤلاء المشتركين والمسجلين على الموقع خلال فترة الاشتراك، إمكانية شراء الدورات التدريبية او الخدمات
        الموجودة
        والمعروضة بالموقع خلال فترة اشتراكهم.</li>
      <li>وقد يتاح لزوار الموقع تصفح بعض الصفحات والخدمات والاطلاع على بعض المعلومات العامة بدون التسجيل والاشتراك
        بالموقع.
      </li>
    </ul>
  </div>
  <div style="text-align: right; padding: 2em;">
    <strong>الغاء الاشتراك</strong>
    <p>

      يجوز لكافة المشتركين بموقع <span style="color: #0b0245;">emasteryacademy.com</span> الغاء الاشتراك بالموقع في أي
      وقت وحسب رغبتهم.
    </p>
    <ul>
      <li>في حالة رغبة المتدرب انهاء الاشتراك بالدورة التدريبية المشترك بها او الاستشارة الخاصة فلا يحق لهذا المشترك
        استرداد
        أي مبالغ مالية تم دفعها.</li>
      <li>يحق لموقع <span style="color: #0b0245;">emasteryacademy.com</span> انهاء علاقته بأي مشترك وازالته وحظره من
        الموقع إذا خالف هذا المشترك أي بند من
        هذه
        الشروط والأحكام او سياسة الخصوصية الخاصة بنا وذلك بدون انذار مسبق، وبعد انهاء اشتراك العميل يجوز للموقع و وفقًا
        لتقديره الخاص الاحتفاظ بأي بيانات قام المشترك بتحميلها في أنظمة الموقع أو حذفها.سياسة الاسترجاع
        نظرًا لطبيعة المنتج ، فإن القاعدة العامة هي أن موقع <span style="color: #0b0245;">emasteryacademy.com</span> لا
        يقدم المبالغ المستردة للدورات
        المباعة.
        جميع مبيعات الدورات من <span style="color: #0b0245;">emasteryacademy.com</span> نهائية.</li>
    </ul>
    <p>
      ومع ذلك ، تقوم <span style="color: #0b0245;">emasteryacademy.com</span> برد المبالغ المدفوعة عن الدورات التدريبية
      المباعة وفقًا للشروط التالية:
    </p>
    <ul>
      <li>يجب أن يتم استلام طلب الاسترداد في غضون أسبوع كحد أقصى من تاريخ شراء الدورة.</li>
      <li>إذا لم يشاهد المشتري أكثر من 25٪ من فيديوهات الدورة.</li>
      <li>لن يتم استرداد الأموال إلا من خلال طريقة الدفع الأصلية.
        المسؤولية القانونية
        بالنسبة لجميع المشتركين المسجلين بالموقع فكل ما يتم إدخاله من خلالهم الى الموقع فيكون على مسؤوليتهم، فليس لموقع
        <span style="color: #0b0245;">emasteryacademy.com</span> أي مسؤولية عن صحة هذه البيانات.
      </li>
    </ul>

    <ul>
      <li>يقر جميع المشتركين المسجلين بالموقع بالتزامهم بعدم إدخال أي محتويات لها علاقة بالاختراق او التجسس وعدم نشر أي
        برامج
        ضارة أو فيروسات وعدم نشر المواد الجنسية والاباحية او المخالفة والمخلة بالآداب العامة وفي حالة مخالفة هذا البند
        يتحمل
        صاحب الفعل كامل المسؤولية القانونية عن أي ضرر قد ينتج من مخالفة هذا البند ويلزم بدفع تعويض جابر للضرر.</li>
      <li>يقر أيضا جميع المشتركين بالموقع بأن كل ما يتم إضافته من الصور والفيديوهات والمحتوى والعلامات التجارية
        والمواضيع
        المرفوعة على الموقع من ناحيتهم تكون على مسؤوليتهم، ويجب ألا تكون الصور والعلامات التجارية محمية من الغير، وفي
        حالة
        مخالفة هذا البند يتحمل صاحب الفعل كامل المسؤولية القانونية.</li>
      <li>يمنع كافة المسجلين بالموقع بشكل قطعي استخدام برامج الروبوت، والعناكب والزواحف وغيرها من البرامج أو التسجيل
        بالموقع
        عبر برامج أخرى غير مصرحة، كما يمنع استخدام برامج جمع وحصد العناوين البريدية أو عناوين البريد الإلكتروني من
        الموقع
        لأغراض غير مناسبة.</li>
      <li>يمنع كافة المشتركين في الموقع من نشر أي روابط لمواقع أخرى سواء كانت متخصصة في نفس مجال الخدمات أو مجالات أخرى
        وكذلك
        يمنع نشر أي روابط متعلقة بتطبيقات الشات والمناقشات مثل واتساب وتلجرام وجميع وسائل التواصل الاجتماعي. </li>
      <li>وبخصوص خدمات الدفع الالكتروني المقدمة من جانب موقع <span style="color: #0b0245;">emasteryacademy.com</span>
        يقر
        كافة المستخدمين لهذه الخدمة بأن
        كارت
        الائتمان او بطاقة الدفع المستخدمة في إجراء هذه العملية مصرح باستخدامها لإجراء عمليات تجارية خاصة بالمستخدم لهذه
        الخدمة
        (الدفع الالكتروني) وتحت مسؤوليتهم الشخصية.</li>
    </ul>

  </div>
  <div style="text-align: right; padding: 2em;">
    <strong>انتفاء المسؤولية القانونية</strong>
    <p>• يقر كافة المسجلين بالموقع بان كل ما يتم تقديمه أو نشره على الموقع من جانبهم هو على مسؤوليتهم، ويقروا بأن موقع
      <span style="color: #0b0245;">emasteryacademy.com</span> غير مسؤول عن كل ما يتم ادخاله او نشره من جانب المشتركين.
      • موقع <span style="color: #0b0245;">emasteryacademy.com</span> لا يضمن تحقيق نجاح للمتدرب على أرض الواقع، وتقع
      مسؤولية تطبيق المعلومات على عاتق
      المتدرب وحده. 
    </p>
  </div>

  <div style="text-align: right; padding: 2em;">
    <strong>التعويض</strong>
    <p>يقر كل المشتركين بالموقع بعدم تحمل هذا الموقع <span style="color: #0b0245;">emasteryacademy.com</span> في كافة
      الأحوال بأي تعويض مادي او عيني لأي
      خطأ قد يحدث في المستقبل، وهذا الإقرار يسري أيضا لصالح كل من يعمل بإدارة الموقع ومالكي الموقع سواء كان مدير او موظف
      او مساهم فكل هؤلاء غير ملزمين باي تعويض يطالبوا به.
    </p>
  </div>
  <div style="text-align: right; padding: 2em;">
    <strong>حقوق التأليف والنشر والملكية الفكرية</strong>
    <p>

      جميع المحتويات في هذا الموقع، بما في ذلك التصميم والصور والبرامج والنصوص وغيرها من المعلومات هي من ممتلكات موقع
      <span style="color: #0b0245;">emasteryacademy.com</span> أو الجهات المرخصة بموجب العقود الموقعة مع الموقع، وجميع
      هذه المحتويات محمية بموجب قوانين
      وأنظمة حقوق المؤلف وغيرها من قوانين الملكية الفكرية.
      • لا يجوز لكافة المشتركين نسخ أو عرض أو تعديل أو نشر أو بيع أو ترخيص أي جزء من محتويات هذا الموقع، أو منتجاته أو
      خدماته، والتي تم الحصول عليها من هذا الموقع في أي وسيلة و لأي أحد، ما عدا ما ينص بشكل صريح على السماح به بموجب
      القانون
      المعمول به أو على النحو المبين في هذه الشروط والبنود ذات الصلة أو اتفاق مشترك.
      • لا يحق للمشتركين بالدبلومات والدورات التدريبية المقدمة من موقعنا ان يقوموا بتنزيل او مشاركة او نسخ او تسجيل
      المحتويات الخاصة بنا من فيديوهات تعليمية او غير ذلك، فكل المشتركين بموقعنا غير مصرح لهم بتسجيل او تنزيل هذه
      الفيديوهات
      التعليمية الموجودة في موقعنا، وفي حالة مخالفة هذا البند يحق لموقعنا <span
        style="color: #0b0245;">emasteryacademy.com</span> ان يحظر المشترك
      المخالف
      لذلك فورا من موقعنا وأن يقوم برفع دعوى قضائية ضد المشترك المخالف للمطالبة بكامل التعويضات التي يكفلها القانون. 
      • يحق للمشتركين بالدورات التدريبية والدبلومات تنزيل او طباعة المرفقات النصية فقط والمرفقة بالدورات والمصرح
      بتنزيلها من
      الموقع للاستخدام الشخصي فقط وليس للاستخدام التجاري، شريطة عدم المساس بجميع حقوق التأليف والنشر والعلامات التجارية
      واتفاقيات الملكية الفكرية الأخرى.
      • يقر جميع المشتركين بالموقع بعدم المساس بجميع حقوق التأليف والنشر والعلامات التجارية واتفاقيات الملكية الفكرية
      الأخرى. 
    </p>
  </div>
  <div style="text-align: right; padding: 2em;">
    <strong>الأخطاء  والسهو وعدم الدقة</strong>
    <p> في بعض الأحيان قد يوجد معلومات على موقعنا تحتوي على أخطاء مطبعية او غير دقيقة وقد يكون هناك سهو يتعلق بأوصاف
      الخدمة او
      الدورة التدريبية والتسعير والعروض الترويجية و الرسوم و أوقات التوافر وغير ذلك، و في هذا الصدد نحن غير مسؤولين بأي
      شكل
      من الاشكال عن هذه الأخطاء وما قد ينتج عنها. </p>
  </div>
  <div style="text-align: right; padding: 2em;">
    <strong>روابط المواقع الخارجية</strong>
    <p> قد تشمل بعض المحتويات والمنتجات و الخدمات المتوفرة عبر موقعنا <span
        style="color: #0b0245;">emasteryacademy.com</span> على مواد او روابط خارجية
      (ليسوا
      مقدمي خدمات على الموقع)، وهذه الروابط قد توجه المشترك الى جهات خارجية ليس لها علاقة بالموقع، ففي هذه الحالة نحن
      لسنا
      مسؤولين عن فحص او تقييم المحتوي ولا نضمن هذه الروابط ولن نتحمل أي مسؤولية عن كل ما يخص هذه الروابط الخارجية ، ولا
      نتحمل أي مسؤولية خاصة بما تقدمه هذه الروابط من منتجات او خدمات.
      • ونحن لسنا مسؤولين عن أي اضرار تتعلق باستخدام الخدمات او المحتوي او أي معاملات أخرى تتعلق باي موقع ويب تابع لجهة
      خارجية. </p>
  </div>

  <div style="text-align: right; padding: 2em;">
    <strong>التعديلات</strong>
    <p>• يجوز لنا تغيير هذه الشروط والأحكام من وقت لآخر عن طريق تحديث محتوى هذه الصفحة، لذا على كل المشتركين بالموقع
      التحقق
      من هذه الصفحة والرجوع إليها من وقت لآخر للتأكد من أن التغييرات التي تمت سوف تحظى بقبولك ورضاك. وهذه الشروط
      والاحكام
      سارية من تاريخ نشرها على الموقع.
      • يقر كل المشتركين بالموقع بموافقتهم على هذه الشروط والاحكام و يقروا بموافقتهم على أي تعديلات قد تتم. </p>
  </div>
  <div style="text-align: right; padding: 2em;">
    <strong>الاختصاص القضائي</strong>
    <p> تخضع هذه الشروط والاحكام في تفسيرها وتطبيقها لقوانين دولة ماليزيا وكذلك تخضع جميع الدعاوى القضائية لنفس
      القوانين، و في
      حالة لقدر الله حدوث أي نزاع بين احد المشتركين بالموقع و الموقع فيجري حل النزاع وفقا للقانون الماليزي.  </p>
  </div>
  <div style="text-align: right; padding: 2em;">
    <strong> الموافقة على الشروط والأحكام</strong>
    <p> يقر جميع المشتركين بالموقع بأنهم قد قرأوا هذه الشروط و الاحكام بالتفصيل وملتزمون بكل ما جاء فيها ولا يجوز لهم
      مخالفة
      هذه البنود، و يقروا بموافقتهم على هذه الشروط والاحكام بالكامل، وفي حالة مخالفة هذه الاتفاقية يجوز للموقع إيقاف
      ظهور
      المشترك او المستخدم المخالف على الموقع كما يجوز للموقع حظر المخالف اذا تمادى في المخالفة. </p>
  </div>























</div>