import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TweenMax } from 'gsap';
import { SettingsService } from '../../../../app/core/services';

@Component({
  selector: 'sm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  social: any = {
    whatsapp: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    linkdin: '',
  };


  constructor(private _store: Store<any>, private _router: Router, private _settings: SettingsService,
    private route: ActivatedRoute, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._settings.getSocialMedia().subscribe((data) => {
      this.social = data;
      this.cd.markForCheck();
    })
  }

  closeMenu() {
    TweenMax.set('.sm-mobile-menu', {
      css: {
        display: 'none'
      }
    })
  }

  certificateRequest() {
    this.closeMenu();
    this._store.dispatch({ type: '[Profile] Stop Loading' });
    this._router.navigate(['/home/certificates']);
  }


}
