import { courseCard, packageCard } from '../../_models/cards';
import { createReducer, on, Action, createSelector, createFeatureSelector } from '@ngrx/store';
import { courseSlide } from '../../_models';
import * as packagesActions from './courses.actions';
import { appState } from '../app.state';

export interface packagesState {
  isLoaded: boolean;
  isLoading: boolean;
  packages: courseSlide[];
  selectedPackage: packageCard;
  isPackageSelected: boolean;
  error: any;
}

const defaultState: packagesState = {
  isLoaded: false,
  isLoading: false,
  packages: undefined,
  selectedPackage: undefined,
  isPackageSelected: false,
  error: undefined
}


const _reducer = createReducer(
  defaultState,
  on(packagesActions.AvailablePackages, state => ({ ...state, isLoaded: false, isLoading: true })),
  on(packagesActions.SelectPackages, (state, { packageId: string }) => ({ ...state, isPackageSelected: false })),
  on(packagesActions.SelectPackagesById, (state, { packageId: number }) => ({ ...state, isPackageSelected: false })),
  on(packagesActions.GetSelectedPackages, (state: packagesState, { packages }) => ({ ...state, selectedPackage: packages, isPackageSelected: true })),
  on(packagesActions.LoadPackagesFail, (state: packagesState) => ({ ...state, isLoading: false, isLoaded: false, error: state.error })),
  on(packagesActions.LoadPackagesSuccess, (state: packagesState, { packages }) => ({ ...state, isLoaded: true, isLoading: false, packages: packages })),
  on(packagesActions.SelectedPackagesError, (state: packagesState, { error }) => ({ ...state, isPackageSelected: false, error: error }))
)


export function packagesReducer(state: packagesState | undefined, action: Action) {
  return _reducer(state, action);
}


export const packagesFeatureKey = 'package';

export const selectPacakgesFeature = createFeatureSelector<appState, packagesState>(packagesFeatureKey);

export const loadingStatus = createSelector(
  selectPacakgesFeature,
  (state: packagesState) => state.isLoading
)

export const availablePackages = createSelector(
  selectPacakgesFeature,
  (state: packagesState) => state.packages
)


export const selectedPackage = createSelector(
  selectPacakgesFeature,
  (state: packagesState) => state.selectedPackage
)

export const isSelectedPackage = createSelector(
  selectPacakgesFeature,
  (state: packagesState) => state.isPackageSelected
)
