import { cartItem, productType } from './../../_models/cart';
import { createReducer, on, Action, createSelector, createFeatureSelector, ActionReducer } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as cartActions from './cart.actions';
import { appState } from '../app.state';


export interface cartState extends EntityState<cartItem> {
  selectedItemId?: number;
  total: number;
  status: string;
  paymentintent: string;
}

export function selectItemId(a: cartItem): string {
  //In this case this would be optional since primary key is id
  return a.id.toString();
}

export function sortByName(a: cartItem, b: cartItem): number {
  return a.productName.localeCompare(b.productName);
}

export const cartItemsAdapter: EntityAdapter<cartItem> = createEntityAdapter<cartItem>();

// export const defaultItems: cartState = {
//   ids: [],
//   entities: {},
//   selectedItemId: null,
//   total: 0,
// }

const initialState = cartItemsAdapter.getInitialState({
  selectId: selectItemId,
  sortComparer: sortByName,
  selectedItemId: null,
  paymentintent: null,
  status: 'empty',
  total: 0
});


const _reducer = createReducer(
  initialState,
  on(cartActions.getPayemntItentId, (state, { paymentintent }) => {
    return { ...state, paymentintent: paymentintent }
  }),
  on(cartActions.updateCartItems, (state, { items }) => {
    return cartItemsAdapter.updateMany(items, state);
  }),
  on(cartActions.updateCartItem, (state, { item }) => {
    return cartItemsAdapter.updateOne(item, state);
  }),
  on(cartActions.getCartSuccessed, (state, { items }) => {
    return cartItemsAdapter.setAll(items, state)
  }),
  on(cartActions.addItemToCart, (state, { item }) => {
    return cartItemsAdapter.setOne(item, state)
  }),
  on(cartActions.removeItemFromCart, (state, { id }) => {
    return cartItemsAdapter.removeOne(id, state);
  }),
  on(cartActions.clearCart, (state) => {
    return cartItemsAdapter.removeAll({ ...state, selectedItemId: null });
  })
)





export const cartReducerFeatureKey = 'cart';
export const cartFeature = createFeatureSelector<cartState>(cartReducerFeatureKey);


export const getSeletedItemId = (state: cartState) => state.selectedItemId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = cartItemsAdapter.getSelectors();

// select the array of user ids
export const selectUserIds = selectIds;

// select the dictionary of user entities
export const selectUserEntities = selectEntities;

// select the array of users
export const selectAllUsers = selectEntities;

// select the total user count
export const selectUserTotal = selectTotal;


export const getCart = createSelector(
  cartFeature, selectAll
)


export const totalCart = createSelector(
  cartFeature,
  (state: cartState) => {
    const allItems = Object.values(state.entities);
    return allItems.reduce((a, b) => a + b.productPrice, 0);
  })


export const totalItems = createSelector(
  cartFeature,
  (state: cartState) => {
    const allItems = Object.values(state.entities);
    return allItems.length
  })


export const totalOPrice = createSelector(
  cartFeature,
  (state: cartState) => {
    const allItems = Object.values(state.entities);
    return allItems.reduce((a, b) => a + b.productOPrice, 0);
  })


export const marchantId = createSelector(
  cartFeature,
  (state: cartState) => {
    return state.paymentintent
  }
)

export function persistStateReducer(_reducer: ActionReducer<cartState>) {
  const localStorageKey = '__cart';
  return (state: cartState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted = localStorage.getItem(localStorageKey);
      return persisted ? JSON.parse(persisted) : _reducer(state, action);
    }
    const nextState = _reducer(state, action);
    localStorage.setItem(localStorageKey, JSON.stringify(nextState));
    return nextState;
  };
}

export function cartReducer(state, action: Action) {
  return updateStateReducer(persistStateReducer(_reducer))(state, action);
  // return _reducer(state, action);
}
export function updateStateReducer(_reducer: ActionReducer<cartState>) {
  return (state: cartState | undefined, action: Action) => {
    return _reducer(state, action);
  };
}


