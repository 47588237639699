import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { environment } from 'src/environments/environment';
declare var $: any;


@Component({
  selector: 'sm-twofactor',
  templateUrl: './twofactor.component.html',
  styleUrls: ['./twofactor.component.scss']
})
export class TwofactorComponent implements OnInit, OnDestroy {
  showPassword = false;
  sub = new Subscription();
  userId = 0;
  template;




  constructor(private fb: UntypedFormBuilder, private authService: AuthenticationService,
    private router: Router, private routes: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
    this.template = {
      logo: environment.logoImage,
      companyName: "أكاديمية ماستري للتدريب الإلكتروني",
      primary: '#ec5252',
      accent: '#000',
      image: 'https://public.emasteryacademy.com/masteryoffice.png',
    }
  }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }



  ngOnInit(): void {
    this.sub = this.routes.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.userId = params['userId'];
      this.returnUrl = params['returnUrl'];
      this.formGroup = this.fb.group({
        email: [this.email],
        userId: [this.userId],
        code1: [{ value: '', disabled: this.loading }, Validators.required],
        code2: [{ value: '', disabled: this.loading }, Validators.required],
        code3: [{ value: '', disabled: this.loading }, Validators.required],
        code4: [{ value: '', disabled: this.loading }, Validators.required],
        code5: [{ value: '', disabled: this.loading }, Validators.required],
        code6: [{ value: '', disabled: this.loading }, Validators.required],
      });
    })

  }

  formGroup = this.fb.group({
    email: [null, Validators.required],
    // password: [null, Validators.required]
  });

  submit() {
    let self = this;
    if (this.formGroup.valid) {
      const formdata = this.formGroup.value;

      const code = this.formGroup.get('code1').value +
        this.formGroup.get('code2').value +
        this.formGroup.get('code3').value +
        this.formGroup.get('code4').value +
        this.formGroup.get('code5').value +
        this.formGroup.get('code6').value;


      const attrs = { email: this.formGroup.value.email, userId: this.formGroup.value.userId, code: code };
      this.authService.login2fa(attrs)

        .subscribe({
          next(value) {
            self.router.navigate([self.returnUrl]);
            // self.toaster.success('Junk Mail تم إرسال رسالة لإعادة تعيين كلمة المرور الخاصة بكم إلى بريدكم  يمكن ان تصل الرسالة إلى بريد ', 'تم');
            // // self.alertMessage = "Junk Mail تم إرسال رسالة لإعادة تعيين كلمة المرور الخاصة بكم إلى بريدكم  يمكن ان تصل الرسالة إلى بريد "
            // // self.cd.markForCheck();
            // setTimeout(() => {

            //   // self.router.navigate(['/']);
            // }
          },
          complete() {

          },
          error(err) {
            // if (err.error.error == 'Not Activated') {
            //   self.toaster.error('هذا الحساب غير مفعل يرجى تفعيل الحساب من البريد الذي وصلكم إلى بريدكم ', 'خطأ');
            // }
            // if (err.error.error == 'No User found') {

            //   self.toaster.error('هذا البريد غير مسجل', 'خطأ');
            // }
            // if (err.error.error == 'Failed') {

            //   self.toaster.error('كلمة المرور غير صحيحة', 'خطأ');
            // }

          },
        });
    } else {

    }
  }

  togglepassword() {
    this.showPassword = !this.showPassword;
  }



  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle = 0;
  email = '';
  notactivated: any;


  moveToNextTextbox(event: KeyboardEvent, currentTextbox: number): boolean {

    let nextTextbox: number;
    if (event.key === 'ArrowLeft') {

      nextTextbox = currentTextbox - 1;
      if (nextTextbox >= 1 && nextTextbox <= 6) {
        const currentInput = document.querySelector(
          "input:nth-child(" + currentTextbox + ")"
        ) as HTMLInputElement;
        const nextInput = document.querySelector(
          "input:nth-child(" + nextTextbox + ")"
        ) as HTMLInputElement;

        nextInput.focus();
      }
      event.preventDefault();
      return false;
    } else if (event.key === 'ArrowRight') {

      nextTextbox = currentTextbox + 1;
      if (nextTextbox >= 1 && nextTextbox <= 6) {
        const currentInput = document.querySelector(
          "input:nth-child(" + currentTextbox + ")"
        ) as HTMLInputElement;
        const nextInput = document.querySelector(
          "input:nth-child(" + nextTextbox + ")"
        ) as HTMLInputElement;

        nextInput.focus();
      }
      event.preventDefault();
      return false;
    } else if (event.key === 'Backspace') {
      nextTextbox = currentTextbox - 1;
      const currentInput = document.querySelector(
        "input:nth-child(" + currentTextbox + ")"
      ) as HTMLInputElement;
      currentInput.value = "";
      if (nextTextbox >= 1 && nextTextbox <= 6) {
        const nextInput = document.querySelector(
          "input:nth-child(" + nextTextbox + ")"
        ) as HTMLInputElement;

        nextInput.focus();
      }
      event.preventDefault();
      return false;
    } else if (event.key === 'Delete') {
      nextTextbox = currentTextbox + 1;
      const currentInput = document.querySelector(
        "input:nth-child(" + currentTextbox + ")"
      ) as HTMLInputElement;
      currentInput.value = "";
      if (nextTextbox >= 1 && nextTextbox <= 6) {
        const nextInput = document.querySelector(
          "input:nth-child(" + nextTextbox + ")"
        ) as HTMLInputElement;

        nextInput.focus();
      }
      event.preventDefault();
      return false;
    } else if (
      event.key === '0' ||
      event.key === '1' ||
      event.key === '2' ||
      event.key === '3' ||
      event.key === '4' ||
      event.key === '5' ||
      event.key === '6' ||
      event.key === '7' ||
      event.key === '8' ||
      event.key === '9' ||
      event.keyCode === 96 ||
      event.keyCode === 97 ||
      event.keyCode === 98 ||
      event.keyCode === 99 ||
      event.keyCode === 100 ||
      event.keyCode === 101 ||
      event.keyCode === 102 ||
      event.keyCode === 103 ||
      event.keyCode === 104 ||
      event.keyCode === 105
    ) {
      nextTextbox = currentTextbox + 1;

      const currentInput = document.querySelector(
        "input:nth-child(" + currentTextbox + ")"
      ) as HTMLInputElement;
      const key = event.keyCode || event.which;
      const isKeypad = key >= 96 && key <= 105;
      const normalizedKeyCode = isKeypad ? key - 48 : key;
      const keyChar = String.fromCharCode(normalizedKeyCode);
      currentInput.value = keyChar;
      if (nextTextbox <= 6) {
        const nextInput = document.querySelector(
          "input:nth-child(" + nextTextbox + ")"
        ) as HTMLInputElement;

        if (currentInput.value.length === 1) {
          nextInput.focus();
        }
      }


      switch (currentTextbox) {
        case 1:
          this.formGroup.patchValue({
            "code1": event.key
          })
          break;
        case 2:
          this.formGroup.patchValue({
            "code2": event.key
          })
          break;
        case 3:
          this.formGroup.patchValue({
            "code3": event.key
          })
          break;
        case 4:
          this.formGroup.patchValue({
            "code4": event.key
          })
          break;
        case 5:
          this.formGroup.patchValue({
            "code5": event.key
          })
          break;
        case 6:
          this.formGroup.patchValue({
            "code6": event.key
          })
          break;

        default:
          break;
      }



      event.preventDefault();
      return false;
    } else {
      const currentInput = document.querySelector(
        "input:nth-child(" + currentTextbox + ")"
      ) as HTMLInputElement;
      if (!Number(currentInput.value)) {
        currentInput.value = "";
      }
      event.preventDefault();
      return false;
    }


  }
}
