<div *ngIf="videoId" style="position: relative; padding-top: 56.25%">
  <iframe
    [src]="videoId"
    loading="lazy"
    style="
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    "
    allow="encrypted-media *;fullscreen *;autoplay;"
    allowfullscreen="true"
  >
  </iframe>
</div>
