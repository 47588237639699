import { mergeMap, catchError, map, tap, exhaustMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoursesService } from '../../services';
import { EMPTY, of } from 'rxjs';
import * as consultancyActions from './consultancy.actions';
import { Action } from '@ngrx/store';






@Injectable()
export class ConsultancyEffect {
  constructor(
    private actions$: Actions,
    private _service: CoursesService,
  ) {
  }


  getSelectedConsultancy$ = createEffect(() => this.actions$.pipe(
    ofType('[Counsultancies] Select And Update Consultancy'),
    exhaustMap((action: any) => this._service.getConsultancyCard(action.consultancyId).pipe(
      map(course => ({ type: '[Counsultancies] Load Current Consultancy', consultancies: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );




  loadLatestConsultancies$ = createEffect(() => this.actions$.pipe(

    ofType('[Counsultancies] Load Available Consultancies'),
    mergeMap(() => this._service.getConsultancies()
      .pipe(
        map(consultancies => ({ type: '[Counsultancies] Load Available Consultancies Success', consultancies: consultancies }),
          catchError((error) => of({ type: consultancyActions.LoadConsultanciesFail, payload: { error } }))
        ))
    )));

  apiError$ = createEffect(
    () => this.actions$.pipe(
      ofType(consultancyActions.LoadConsultanciesFail),
      tap(({ error }) => {
        console.log({ error })
      }
      )
    ), { dispatch: false }
  )


}
