
export class User {
  id: number;
  email: string;
  groupId: string;
  userId: string;
  password: string;
  loginName: string;
  trainerId: string;
  bSuper: string;
  bOwner: string;
  // fullName: string;
  avatar: string;
  name: string;
  jwtToken?: string;
  refreshToken?: string;
}

export class myQuizzesN {
  quizzId: number;
  quizzName: string;
  courseName: string;
  courseId: number;
  progress: number;
  mark: number;
  isPassed: boolean;
  date: Date

}
