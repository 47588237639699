<div class="main-container">
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 1920 680.8" style="enable-background:new 0 0 1920 680.8;" xml:space="preserve">
    <style type="text/css">
      .stel0 {
        clip-path: url(#SVGID_2_);
        fill: #D1EBFF;
      }

      .stel1 {
        clip-path: url(#SVGID_2_);
        fill: #FFFFFF;
      }

      .stel2 {
        clip-path: url(#SVGID_2_);
        fill: #03336E;
      }

      .stel3 {
        clip-path: url(#SVGID_2_);
        fill: #0FBAB8;
      }

      .stel4 {
        clip-path: url(#SVGID_2_);
        fill: none;
        stroke: #03336E;
        stroke-width: 0.75;
        stroke-linejoin: round;
      }

    </style>
    <g>
      <defs>
        <rect id="SVGID_1_" width="1920" height="675.7" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlink:href="#SVGID_1_" style="overflow:visible;" />
      </clipPath>
      <path class="stel0"
        d="M1475.2,475.5l-50.7-28.5c4.4-3.2,6.9-7,6.9-11.1c0-11.3-19.3-20.5-43.2-20.5c-23.9,0-43.2,9.2-43.2,20.5
   c0,11.3,19.3,20.5,43.2,20.5c4.8,0,9.5-0.4,14.2-1.2l54.6,30.7c3.5,2,9.7,1.8,13.7-0.6C1475.3,483.1,1480.6,478.7,1475.2,475.5" />
      <path class="stel1" d="M1445,408.4l-22.2,12l33.1,61.2l0,0.1c1.9,3.6,8.5,3.8,14.6,0.5c6.1-3.3,9.5-8.9,7.6-12.5l-0.1-0.1L1445,408.4
   z" />
      <path class="stel2" d="M1462.1,485.5L1462.1,485.5c-3.3,0-5.9-1.2-7.1-3.4l-33.2-61.2c-0.3-0.5-0.1-1.2,0.4-1.5l0,0l22.2-12
   c0.5-0.3,1.2-0.1,1.5,0.4l33.2,61.2c2.3,4.3-1.2,10.4-8,14C1468.4,484.6,1465.3,485.5,1462.1,485.5 M1456.9,481L1456.9,481
   c1.6,3,7.8,3,13.2,0.1c5.5-3,8.8-8,7.2-11l-32.7-60.3l-20.3,10.9L1456.9,481z" />
      <path class="stel2" d="M1445,408.5c1.9,3.6-1.5,9.2-7.6,12.5c-6.1,3.3-12.7,3.1-14.6-0.5c-1.9-3.6,1.5-9.2,7.6-12.5
   C1436.6,404.7,1443.1,404.9,1445,408.5" />
      <path class="stel2" d="M1429,424.3c-3.3,0-5.9-1.2-7.1-3.4c-2.2-4.2,1.3-10.3,8.1-13.9c2.8-1.5,5.8-2.4,9-2.5c3.3,0,5.9,1.2,7.1,3.4
   c2.2,4.2-1.3,10.3-8.1,13.9C1435.2,423.4,1432.1,424.3,1429,424.3 M1438.9,406.7c-2.8,0.1-5.5,0.8-8,2.2c-5.5,3-8.8,8-7.2,11
   c1.5,2.8,7.8,2.9,13.1,0c5.5-3,8.8-8,7.2-11C1443.3,407.5,1441.4,406.7,1438.9,406.7" />
      <path class="stel3"
        d="M1430.5,392.2l-13.2,7.1l9.4,17.4l0,0c1.2,2.1,5,2.3,8.7,0.3c3.6-2,5.7-5.3,4.5-7.4l0,0l0,0L1430.5,392.2z" />
      <path class="stel2" d="M1430.3,419.6L1430.3,419.6c-1.8,0.2-3.6-0.7-4.6-2.3l-9.4-17.5c-0.1-0.3-0.2-0.6-0.1-0.8
   c0.1-0.3,0.3-0.5,0.5-0.6l13.2-7.1c0.5-0.3,1.2-0.1,1.5,0.4l0,0l9.4,17.4c1.5,2.7-0.7,6.7-4.9,8.9
   C1434.2,419,1432.3,419.5,1430.3,419.6 M1418.7,399.7l8.9,16.5c0.6,0.9,1.7,1.3,2.7,1.2c1.6-0.1,3.1-0.5,4.5-1.3
   c3.3-1.8,4.8-4.6,4.1-5.9l-8.9-16.5L1418.7,399.7z" />
      <path class="stel0" d="M1393.2,252c-16.5-9.5-31.4-10.6-42.3-4.7c-0.3,0.2-16,8.9-16.5,9.7c11.1-8.4,27.6-8.3,45.9,2.3
   c33.5,19.3,60.6,66.1,60.6,104.5c0,19.6-7.1,33.2-18.4,39.3c1-0.3,2-0.8,3-1.3c0.3-0.2,11-6.7,11.3-6.9c10.5-6.4,17-19.7,17-38.5
   C1453.8,318.1,1426.6,271.3,1393.2,252" />
      <path class="stel2" d="M1422.5,404.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.8,0.6-1c11.5-6.2,17.8-19.8,17.8-38.3
   c0-38-26.9-84.5-60-103.6c-9.4-5.4-18.4-8.2-26.7-8.2c-5.1-0.1-10.2,1.2-14.7,3.6c-0.9,0.5-1.6,1-2.2,1.4c-0.4,0.3-0.8,0.6-1.2,0.8
   c-0.5,0.4-1.2,0.3-1.5-0.2c-0.3-0.4-0.3-0.9,0-1.2c0.1-0.2,0.3-0.4,1.6-1.2c0.8-0.5,1.5-1,2.3-1.4c4.7-2.8,12.7-7.2,13-7.4
   c4.8-2.5,10.2-3.8,15.6-3.8c8.7,0,18,2.8,27.7,8.5c33.7,19.5,61.1,66.8,61.1,105.5c0,18.5-6.2,32.5-17.5,39.4
   c-0.1,0.1-11,6.7-11.4,6.9c-1,0.6-2.1,1.1-3.2,1.4C1422.7,404.2,1422.6,404.2,1422.5,404.2 M1353.1,249.9c8.7,0,18,2.8,27.7,8.5
   c33.7,19.5,61.1,66.8,61.1,105.5c0,13.7-3.4,24.9-9.7,32.6c2-1.2,3.7-2.3,3.9-2.3c10.6-6.5,16.5-19.8,16.5-37.5
   c0-38-26.9-84.5-60.1-103.6c-9.4-5.4-18.4-8.2-26.7-8.2c-5.1-0.1-10.1,1.1-14.6,3.5c-0.1,0.1-1.6,0.9-3.6,2
   C1349.5,250,1351.3,249.9,1353.1,249.9" />
      <path class="stel1" d="M1337.4,254.9c23.5-13.8,61.9,8.1,85.7,48.8c23.8,40.7,24.1,84.9,0.6,98.6c-23.5,13.8-61.9-8.1-85.7-48.8
   C1314.2,312.8,1313.9,268.7,1337.4,254.9" />
      <path class="stel2" d="M1407.7,407.6c-8.7,0-18-2.9-27.7-8.5c-33.7-19.5-61.1-66.8-61.1-105.5c0-27.1,13.2-44,34.5-44
   c8.7,0,18,2.8,27.7,8.5c33.7,19.5,61.1,66.8,61.1,105.5C1442.2,390.8,1429,407.6,1407.7,407.6 M1353.3,251.9
   c-19.9,0-32.3,16-32.3,41.8c0,38,26.9,84.5,60.1,103.6c9.4,5.4,18.4,8.2,26.7,8.2c19.9,0,32.3-16,32.3-41.8
   c0-38-26.9-84.5-60.1-103.6C1370.6,254.7,1361.6,251.9,1353.3,251.9" />
      <path class="stel0" d="M1389,374.2c-24.1-13.9-43.6-47.5-43.6-75.1c0-11.2,3.3-19.6,8.7-24.8c-9.3,3.8-15.3,13.9-15.3,29.1
   c0,27.6,19.5,61.2,43.6,75.1c14.3,8.3,26.9,7.6,34.9,0C1409.6,381.6,1399.8,380.4,1389,374.2" />
      <path class="stel2" d="M1402.3,392.1c-7,0-14.5-2.3-22.3-6.8c-27-15.6-49-53.5-49-84.6c0-21.5,10.9-35.4,27.8-35.4
   c7,0,14.5,2.3,22.3,6.8c27,15.6,49,53.5,49,84.6C1430.1,378.2,1419.2,392.1,1402.3,392.1 M1358.8,267.5
   c-15.8,0-25.6,12.7-25.6,33.2c0,30.3,21.5,67.4,47.9,82.7c7.5,4.3,14.6,6.5,21.2,6.5c15.8,0,25.6-12.7,25.6-33.2
   c0-30.3-21.5-67.4-47.9-82.7C1372.5,269.7,1365.4,267.5,1358.8,267.5" />
      <path class="stel0" d="M1233.3,697.6l-85.3-49.5c-5.1-3-4.5-8.1,1.4-11.6l74.8-28.8c5.9-3.4,14.9-3.8,20-0.8l57.3,33.6
   c5.1,3,4.5,8.1-1.4,11.6l-46.9,44.7C1247.4,700.2,1238.5,700.5,1233.3,697.6" />
      <path class="stel0"
        d="M1229.2,691.1l-75.2-43.7c-1.7-0.8-2.9-2.5-3.1-4.4v-8.8c0-2,1.5,1.5,4.3-0.2l66-25.4c5.2-3,13.1-3.3,17.7-0.7
   l50.6,29.6c1.5,0.9,2.6-2.8,3-1.7c0.1,0.4,0,8.7,0.1,9.1c0,2-1.4,4.2-4.3,5.9l-41.4,39.5C1241.7,693.4,1233.8,693.7,1229.2,691.1" />
      <path class="stel2" d="M1236.9,693.9c-2.9,0.1-5.7-0.6-8.2-1.9l-75.2-43.7c-2.1-1-3.4-3-3.6-5.3v-8.9c0-1.5,1-1.7,1.4-1.7
   c0.4,0,0.8,0.2,1.2,0.4c0.7,0.5,1.6,0.6,2.4,0.2l66.2-25.5c5.1-2.9,13.9-3.4,18.6-0.7l50.6,29.6c0.1-0.1,0.5-0.6,0.7-0.9
   c0.4-0.6,0.8-1.1,1.6-1.1c0.6,0,1.1,0.4,1.2,1c0.1,0.3,0.2,0.5,0.1,8.2c0,0.7,0,1.1,0,1.2c0.1,2.5-1.7,5-4.9,6.8l-41.2,39.3
   C1244.4,693,1240.7,694,1236.9,693.9 M1152,635.2v7.8c0.2,1.5,1.1,2.8,2.5,3.4l75.2,43.7c4,2.3,11.9,2,16.6-0.7l41.1-39.3
   c2.6-1.6,4-3.3,4-5v-1.3c0-1.1,0-3.7,0-5.6c-0.7,0.6-1.7,0.8-2.5,0.3l-50.6-29.6c-4-2.3-11.9-2-16.6,0.7l-66.2,25.5
   C1154.5,635.6,1153.2,635.7,1152,635.2L1152,635.2z" />
      <path class="stel1" d="M1229.2,682.4l-75.2-43.7c-4.5-2.6-4-7.2,1.3-10.2l66-25.4c5.2-3,13.1-3.3,17.7-0.7l50.6,29.6
   c4.5,2.6,4,7.2-1.3,10.2l-41.4,39.5C1241.7,684.6,1233.8,685,1229.2,682.4" />
      <path class="stel2" d="M1236.9,685.2c-2.9,0.1-5.7-0.6-8.2-1.9l-75.2-43.7c-2.1-1-3.5-3.1-3.6-5.4c0-2.5,1.8-4.9,4.9-6.7l66.2-25.5
   c5.1-2.9,13.9-3.4,18.6-0.7l50.6,29.6c2.1,1,3.5,3.1,3.6,5.4c0,2.5-1.8,4.9-4.9,6.7l-41.1,39.3
   C1244.4,684.3,1240.7,685.2,1236.9,685.2 M1231.3,601.7c-3.3,0-6.6,0.8-9.4,2.4l-66.2,25.5c-2.3,1.3-3.6,3.1-3.6,4.8
   c0.2,1.5,1.1,2.9,2.5,3.5l75.2,43.7c4,2.3,11.9,2,16.6-0.7l41.1-39.3c2.6-1.5,4-3.3,4-5c-0.2-1.5-1.1-2.9-2.5-3.5l-50.6-29.6
   C1236.2,602.1,1233.8,601.6,1231.3,601.7L1231.3,601.7z" />
      <path class="stel0" d="M1285,633.7l-47.5-27.8c-4.3-2.5-11.7-2.1-16.6,0.7l-31.3,12c0.3,0.2,0.6,0.5,1,0.7l70.7,41.1
   c1.1,0.6,2.2,1,3.4,1.3l19.1-18.3C1288.7,640.5,1289.3,636.2,1285,633.7" />
      <path class="stel1" d="M1345.9,684.8l-204.6-118.2c-10.5-6-18.9-22.2-18.9-36.2V373.9c0-13.9,8.5-20.4,18.9-14.3l204.6,118.2
   c10.5,6,18.9,22.2,18.9,36.2v156.4C1364.9,684.4,1356.4,690.8,1345.9,684.8" />
      <path class="stel2" d="M1353.5,688.1c-2.9-0.1-5.6-0.9-8.1-2.4l-204.6-118.2c-10.7-6.2-19.5-22.9-19.5-37.1V373.9
   c0-10.7,4.9-17.7,12.5-17.7c2.9,0.1,5.6,0.9,8.1,2.4l204.6,118.2c10.7,6.2,19.5,22.9,19.5,37.1v156.4
   C1366,681.2,1361.1,688.1,1353.5,688.1 M1133.9,358.5c-6.3,0-10.3,6.1-10.3,15.5v156.4c0,13.6,8.3,29.4,18.4,35.2l204.6,118.2
   c2.1,1.3,4.5,2,7,2.1c6.3,0,10.3-6.1,10.3-15.5V514c0-13.6-8.3-29.4-18.4-35.2l-204.6-118.2
   C1138.8,359.3,1136.3,358.5,1133.9,358.5" />
      <path class="stel1" d="M1348.4,673.9l-201.7-116.5c-8.4-5.3-13.6-16-13.6-27V372.9c0-1.9,1.5-3.4,3.4-3.4c0.6,0,1.2,0.2,1.7,0.5
   c0.3,0.2,0.5,0.3,0.6,0.3L1340.6,487c10,5.8,13.6,16,13.6,27v157c0,2-1.6,3.6-3.6,3.6c-0.6,0-1.2-0.2-1.8-0.5
   C1348.6,674,1348.5,673.9,1348.4,673.9" />
      <path class="stel2"
        d="M1350.6,675.7c-0.8,0-1.6-0.2-2.3-0.6l-0.3-0.2l-201.8-116.6c-8.6-5.5-14.2-16.4-14.2-27.9V372.9
   c0-2.5,2-4.4,4.4-4.4c0.8,0,1.6,0.2,2.3,0.6l0.6,0.3l201.9,116.7c9.3,5.3,14.2,15,14.2,27.9v157
   C1355.3,673.6,1353.2,675.7,1350.6,675.7 M1136.5,370.7c-1.3,0-2.3,1-2.3,2.3v157.5c0,10.8,5.1,21,13.1,26.1l202.1,116.7
   c1.2,0.7,2.8,0.3,3.5-1c0.2-0.4,0.3-0.8,0.3-1.2V514c0-8.4-2.3-19.8-13.1-26l-202.3-116.9C1137.4,370.8,1136.9,370.7,1136.5,370.7" />
      <path class="stel0" d="M1358,471.6l-204.6-118.2c-4.1-2.4-8-2.8-11.1-1.6c-0.4,0.2-12.3,6.4-12.7,6.6l0,0c3.2-1.6,7.3-1.3,11.8,1.3
   l204.6,118.2c10.5,6,18.9,22.2,18.9,36.2v156.4c0,8.7-3.3,14.5-8.3,16.1c0.6,0,1.2-0.2,1.7-0.6c0.2-0.1,12.6-6.8,12.8-6.9
   c3.6-2.5,5.8-7.7,5.8-14.9V507.7C1376.9,493.8,1368.4,477.6,1358,471.6" />
      <path class="stel2" d="M1356.6,687.7h-0.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.5,0.3-0.9,0.8-1c4.7-1.5,7.6-7.2,7.6-15.1V514
   c0-13.6-8.3-29.4-18.4-35.2l-204.6-118.2c-4-2.3-7.8-2.7-10.8-1.3c-0.5,0.3-1.2,0-1.5-0.5c-0.3-0.5,0-1.2,0.5-1.5
   c0.1,0,12.3-6.4,12.8-6.6c3.5-1.4,7.7-0.8,12,1.7l204.6,118.2c10.7,6.2,19.5,22.9,19.5,37.1v156.4c0,7.3-2.2,12.9-6.3,15.8
   c-0.1,0.1-1.1,0.6-9.9,5.3l-3.1,1.7C1358.1,687.4,1357.4,687.6,1356.6,687.7 M1135.7,356.4c2.2,0.3,4.4,1.1,6.3,2.3l204.6,118.2
   c10.7,6.2,19.5,22.9,19.5,37.1v156.4c0.1,4-0.8,7.9-2.6,11.5c3.1-1.7,6.8-3.7,7.1-3.8c3.4-2.4,5.3-7.5,5.3-13.9V507.7
   c0-13.6-8.3-29.4-18.4-35.2l-204.6-118.2c-3.7-2.1-7.2-2.7-10.1-1.6C1142.4,352.9,1138.9,354.7,1135.7,356.4" />
      <path class="stel0" d="M1523.6,221.7c50.5,0,91.4,14.6,91.4,32.5c0,18-40.9,32.5-91.4,32.5c-50.5,0-91.4-14.6-91.4-32.5
   C1432.2,236.3,1473.1,221.7,1523.6,221.7" />
      <path class="stel2"
        d="M1466.3,240.6l-8.8-9.4l-17.3,16.1c-1.5,1.4-0.8,4.7,1.7,7.3c2.4,2.6,5.6,3.6,7.1,2.2L1466.3,240.6z" />
      <path class="stel2" d="M1538.7,256.7l-11.7,5.3c0,0,9.8,21.5,9.8,21.5c0.9,1.9,4.2,2.2,7.4,0.7c3.2-1.5,5.2-4.2,4.3-6.1
   C1548.5,278.1,1538.7,256.7,1538.7,256.7" />
      <path class="stel0" d="M1586.9,201.9c0-47.5-33.6-105.4-74.9-129.3c-20-11.5-38.1-13-51.5-6.2l-45.3,23.3l5.4,9.3
   c-2.7,8.1-4,16.6-3.9,25.1c0,47.5,33.6,105.4,74.9,129.3c7.5,4.5,15.7,7.7,24.3,9.4l5.5,9.4l41.4-21.1
   C1577.6,244,1586.9,226.9,1586.9,201.9" />
      <path class="stel2" d="M1521.4,273.3c-0.4,0-0.7-0.2-0.9-0.5l-5.2-9c-8.5-1.8-16.7-5-24.1-9.4c-41.6-24-75.5-82.4-75.5-130.2
   c-0.1-8.5,1.2-16.9,3.8-25l-5.1-8.8c-0.3-0.5-0.1-1.2,0.4-1.5c0,0,0,0,0.1,0l45.3-23.3c5.7-2.8,12-4.3,18.4-4.2
   c10.7,0,22.2,3.5,34.2,10.4c41.6,24,75.5,82.4,75.5,130.2c0,24.3-9,42.6-24.7,50.2l-41.4,21.1
   C1521.8,273.3,1521.6,273.3,1521.4,273.3 M1416.7,90.2l4.8,8.3c0.2,0.3,0.2,0.6,0.1,0.9c-2.7,8-3.9,16.3-3.8,24.8
   c0,47.1,33.4,104.6,74.4,128.3c7.4,4.4,15.5,7.6,23.9,9.3c0.3,0.1,0.6,0.2,0.7,0.5l5,8.6l40.5-20.6c14.9-7.2,23.5-24.8,23.5-48.3
   c0-47.1-33.4-104.6-74.4-128.3c-11.7-6.7-22.8-10.1-33.1-10.1c-6-0.1-12,1.3-17.4,4L1416.7,90.2z" />
      <path class="stel1" d="M1417.6,88.3c29.1-17,76.5,10,105.9,60.4c29.4,50.3,29.7,104.9,0.7,121.9c-29.1,17-76.5-10-105.9-60.4
   C1388.8,159.9,1388.5,105.3,1417.6,88.3" />
      <path class="stel2" d="M1504.5,276.8c-10.7,0-22.2-3.5-34.2-10.4c-41.6-24-75.4-82.4-75.4-130.1c0-33.4,16.2-54.1,42.4-54.1
   c10.7,0,22.2,3.5,34.2,10.4c41.6,24,75.4,82.4,75.4,130.1C1546.9,256.1,1530.6,276.8,1504.5,276.8 M1437.3,84.4
   c-24.8,0-40.2,19.9-40.2,51.9c0,47,33.3,104.6,74.3,128.2c11.7,6.7,22.8,10.1,33.1,10.1c24.8,0,40.2-19.9,40.2-51.9
   c0-47-33.4-104.6-74.3-128.2C1458.7,87.8,1447.6,84.4,1437.3,84.4" />
      <path class="stel2" d="M1497.8,257.6c-8.6,0-17.8-2.8-27.4-8.4c-33.3-19.2-60.5-66-60.5-104.3c0-26.8,13.1-43.5,34.1-43.5
   c8.6,0,17.8,2.8,27.4,8.4c33.3,19.2,60.5,66,60.5,104.3C1531.9,240.9,1518.8,257.6,1497.8,257.6 M1444,103.6
   c-19.7,0-31.9,15.8-31.9,41.3c0,37.6,26.6,83.5,59.4,102.4c9.3,5.4,18.2,8.1,26.3,8.1c19.7,0,31.9-15.8,31.9-41.3
   c0-37.6-26.6-83.5-59.4-102.4C1461,106.3,1452.2,103.6,1444,103.6" />
      <path class="stel2" d="M1470.1,186.4c-0.2,0-0.4,0.1-0.6,0.1c-0.7,0-1.4-0.2-1.9-0.6c-2.3-1.5-3.8-4-3.9-6.7c-0.1-1.3,0.6-2.5,1.8-3
   v-48.9l5,2.9v48.4c1.1,1.4,1.7,3,1.8,4.8c0,0.2,0,0.3-0.1,0.5l43.1,53.4l-2,2.8L1470.1,186.4z" />
      <path class="stel2" d="M1469.7,182.2c0,1.1-0.8,1.6-1.8,1c-1-0.7-1.7-1.8-1.7-3c0-1.1,0.8-1.6,1.7-1
   C1469,179.9,1469.6,181,1469.7,182.2" />
      <path class="stel1" d="M1572.1,92.6c-17.9-18.6-44.2-22.4-58.7-8.4c-0.4,0.4-1.5,1.3-2.2,2c-14.5,14-9.7,37.1,8.2,55.7
   c17.9,18.6,42.1,25.7,56.6,11.7c0.4-0.4,1.5-1.3,2.2-2C1592.8,137.6,1590,111.2,1572.1,92.6" />
      <path class="stel2" d="M1557.2,162L1557.2,162c-12.5,0-26.9-7.2-38.6-19.4c-19.1-19.9-22.5-43.4-8.1-57.2c0.4-0.4,0.9-0.9,1.4-1.3
   c0.3-0.3,0.6-0.5,0.8-0.7c6-5.7,13.9-8.8,23-8.8c13.2,0,26.7,6.3,37.2,17.2c18.3,19,21,46.2,6.1,60.5c-0.4,0.4-0.9,0.9-1.4,1.3
   c-0.3,0.3-0.6,0.5-0.8,0.7C1571.6,159.4,1564.5,162.2,1557.2,162 M1535.7,76.9c-8.5,0-16,2.8-21.5,8.1c-0.2,0.2-0.5,0.4-0.9,0.8
   c-0.4,0.4-1,0.8-1.3,1.2c-13.4,12.9-10,35.2,8.2,54.2c11.3,11.7,25.1,18.7,37,18.7c6.7,0.2,13.2-2.4,18.1-7.1
   c0.2-0.2,0.5-0.4,0.9-0.8c0.4-0.4,1-0.8,1.3-1.2c14.1-13.5,11.3-39.3-6.1-57.5C1561.3,82.9,1548.3,76.9,1535.7,76.9" />
      <path class="stel1" d="M1432.1,30.6c-24.4,7.3-39.2,29.7-33.5,50.6c4.4,11.8,26.2,9.8,50.6,2.6c24.4-7.3,42.4-17.1,39.4-29.3
   C1481.5,34,1456.5,23.4,1432.1,30.6" />
      <path class="stel2" d="M1416.8,90.8L1416.8,90.8c-10.5,0-17-3.1-19.2-9.1c-5.9-21.8,9.1-44.6,34.2-52c5.1-1.5,10.5-2.3,15.8-2.3
   c19.6,0,36.5,10.8,42.1,26.8c2.9,11.7-10.6,22-40.2,30.7C1436.3,88.8,1425.3,90.8,1416.8,90.8 M1447.6,29.4
   c-5.1,0-10.3,0.8-15.2,2.2c-24,7.1-38.3,28.8-32.8,49.3c1.9,5,7.7,7.6,17.2,7.6c8.3,0,19.1-2,32.1-5.8c19.4-5.8,41.8-15.3,38.7-28
   C1482.3,39.6,1466.2,29.4,1447.6,29.4L1447.6,29.4z" />
      <path class="stel2" d="M1571.2,90.9L1571.2,90.9c-2.4-2.6-6.5-2.8-9.1-0.4c0,0,0,0,0,0c-3,2.8-4.5,4.2-4.5,4.2
   c-1.5,1.4-0.8,4.7,1.6,7.3c2.4,2.6,5.6,3.6,7.1,2.2c0,0,1.6-1.5,4.5-4.2C1573.5,97.6,1573.7,93.5,1571.2,90.9" />
      <path class="stel2" d="M1428.5,23.4L1428.5,23.4c-3.2,0.8-5.1,4-4.4,7.2c0,0,0,0,0,0c1,3.4,1.5,5.2,1.5,5.2c0.5,1.8,3.5,1.4,6.7,0.5
   c3.3-0.9,5.6-2.1,5.1-3.9c0,0-0.5-1.8-1.5-5.3C1434.9,24,1431.6,22.3,1428.5,23.4" />
      <path class="stel0" d="M1479.7,233.5c-28.6-16.5-51.8-56.6-51.8-89.4c0-13.3,3.9-23.4,10.3-29.6c-11.1,4.5-18.2,16.6-18.2,34.7
   c0,32.9,23.2,72.9,51.8,89.4c17,9.8,32.1,9.1,41.5,0C1504.3,242.3,1492.5,240.9,1479.7,233.5" />
      <polygon class="stel3" points="1430.1,200.8 1471.5,177.4 1471,176.5 1479.8,171.5 1482.5,176.1 1473.6,181.1 1473.2,180.3
   1431.8,203.7 	" />
      <path class="stel0" d="M1268.2,287.6h-90.7v4.9h0c0,0.1,0,3.2,0,3.4c0,14.6,20.3,26.4,45.3,26.4c25,0,45.4-11.8,45.4-26.4
   c0-0.1,0-3.2,0-3.4h0V287.6z" />
      <path class="stel2" d="M1222.9,323.4c-25.6,0-46.4-12.3-46.4-27.5c0-0.1,0-2.5,0-3.2c0-0.1,0-0.1,0-0.2v-4.9c0-0.6,0.5-1.1,1.1-1.1
   h90.7c0.6,0,1.1,0.5,1.1,1.1v4.9c0,0.1,0,0.1,0,0.2c0,0.7,0,3.1,0,3.2C1269.3,311.1,1248.5,323.4,1222.9,323.4 M1178.6,292.4
   c0,0.1,0,0.1,0,0.2c0,0.2,0,3.2,0,3.3c0,14,19.9,25.3,44.3,25.3s44.3-11.4,44.3-25.3c0-0.1,0-3.2,0-3.3c0-0.1,0-0.1,0-0.2v-3.7
   h-88.5V292.4z" />
      <path class="stel1" d="M1222.9,261.4c25,0,45.4,11.8,45.4,26.4c0,14.6-20.3,26.4-45.4,26.4c-25,0-45.4-11.8-45.4-26.4
   C1177.5,273.2,1197.9,261.4,1222.9,261.4" />
      <path class="stel2" d="M1222.9,315.3c-25.6,0-46.4-12.3-46.4-27.5c0-15.2,20.8-27.5,46.4-27.5c25.6,0,46.4,12.3,46.4,27.5
   C1269.3,303,1248.5,315.3,1222.9,315.3 M1222.9,262.5c-24.4,0-44.3,11.4-44.3,25.3c0,14,19.9,25.3,44.3,25.3
   c24.4,0,44.3-11.4,44.3-25.3C1267.2,273.9,1247.3,262.5,1222.9,262.5" />
      <path class="stel1" d="M1259.4,282.4v-5.2c0-18.1-10-34.7-25.9-43.2l-3.7-2c-1.2-0.6-1.6-2.1-1-3.2c0.2-0.4,0.5-0.7,0.9-0.9l6.2-3.7
   c14.6-8.9,23.6-24.7,23.6-41.9v-14.9h-72.9v14.5c0,17.3,9.2,33.4,24.1,42.2l6.3,3.7c1.1,0.7,1.5,2.1,0.8,3.2
   c-0.2,0.4-0.6,0.7-1,0.9l-3.8,2c-16.2,8.4-26.4,25.2-26.4,43.5v4.8c-0.6,5.8,3,11.8,10.7,16.2c14.3,8.3,37.5,8.3,51.7,0.1
   C1256.4,294,1259.9,288.1,1259.4,282.4" />
      <path class="stel2" d="M1223.1,305.6L1223.1,305.6c-10,0-19.4-2.3-26.6-6.4c-7.8-4.5-11.9-10.8-11.2-17.3v-4.6
   c0-18.7,10.4-35.9,27-44.4l3.8-2c0.4-0.2,0.7-0.6,0.7-1.1c0-0.5-0.2-0.9-0.6-1.1l-6.3-3.7c-15.2-9-24.6-25.4-24.6-43.1v-14.5
   c0-0.6,0.5-1.1,1.1-1.1h72.9c0.6,0,1.1,0.5,1.1,1.1v14.9c-0.1,17.5-9.2,33.7-24.1,42.8l-6.2,3.7c-0.6,0.4-0.8,1.2-0.4,1.8
   c0.1,0.2,0.3,0.4,0.5,0.5l3.7,2c16.3,8.7,26.5,25.7,26.5,44.2v5.2c0.6,6.3-3.4,12.5-11.1,17C1242.4,303.4,1233,305.6,1223.1,305.6
    M1187.5,168.5v13.4c0,16.9,9,32.6,23.5,41.2l6.3,3.7c1.6,1,2.2,3.1,1.2,4.7c-0.3,0.6-0.8,1-1.4,1.3l-3.8,2
   c-15.9,8.2-25.9,24.6-25.9,42.5v4.8c-0.6,5.7,3.1,11.2,10.1,15.3c6.8,3.9,15.8,6.1,25.5,6.1c9.5,0,18.5-2.1,25.1-6
   c6.9-4,10.5-9.4,10-15v-5.3c0-17.7-9.7-34-25.4-42.3l-3.7-2c-1.7-0.9-2.3-3-1.4-4.7c0.3-0.6,0.7-1,1.3-1.3l6.2-3.7
   c14.3-8.7,23-24.2,23-40.9v-13.8H1187.5z" />
      <path class="stel3"
        d="M1259.4,286.6v-5.8c0-15.6-10.2-15-23.2-19.6c-4.6-1.7-7.9-5.8-8.3-10.7c-0.8-8-2-15.7-2-17
   c-0.1-2.1,1-4,2.8-5.1c0,0,4.3-2.6,7.1-4.3c3.2-2,6.9-5.7,6.9-9.4c0-6.2-8.7-11.3-19.4-11.3c-10.7,0-19.4,5-19.4,11.3
   c0,3.6,4,7.7,7.8,9.9l6.3,3.5c1.8,1.1,2.9,3,2.9,5.2c0,1.4-1.3,9.1-2.1,17c-0.5,4.9-3.8,9-8.4,10.7c-13.3,4.6-23.7,4.1-23.7,19.9
   v5.3l0,0.2c-0.5,5.3,3.1,10.6,10,14.5c7.1,4.1,16.6,6.3,26.7,6.3c10,0,19.4-2.2,26.4-6.2c6.8-3.9,10.4-9.1,9.9-14.3L1259.4,286.6z" />
      <path class="stel2"
        d="M1223.2,308.4L1223.2,308.4c-10.3,0-20-2.3-27.2-6.5c-7.3-4.2-11.1-9.9-10.6-15.6l0-5.4
   c0-13.8,7.7-16,17.5-18.8c2.2-0.6,4.5-1.3,6.9-2.1c4.2-1.5,7.2-5.3,7.7-9.8c0.5-4.4,1.1-8.8,1.5-12c0.3-2.4,0.6-4.4,0.6-4.9
   c0-1.7-0.8-3.3-2.3-4.2l-6.3-3.5c-4-2.4-8.4-6.8-8.4-10.9c0-6.9,9-12.4,20.5-12.4c11.5,0,20.5,5.4,20.5,12.4c0,4-3.7,8-7.4,10.3
   c-2.8,1.7-7.1,4.3-7.1,4.3c-1.4,0.9-2.3,2.5-2.3,4.2c0,0.5,0.3,2.4,0.6,4.8c0.4,3.2,1,7.6,1.4,12.1c0.4,4.5,3.4,8.3,7.6,9.8
   c2.4,0.9,4.7,1.5,6.9,2.2c9.5,2.8,17.1,4.9,17.1,18.4v5.8c0.6,5.7-3.2,11.3-10.4,15.4C1242.9,306.2,1233.4,308.4,1223.2,308.4
    M1223.2,204.6c-9.9,0-18.3,4.7-18.3,10.2c0,3.1,3.7,6.9,7.3,9l6.3,3.5c2.2,1.3,3.5,3.6,3.4,6.1c0,0.7-0.3,2.5-0.6,5.2
   c-0.4,3.2-1,7.5-1.5,12c-0.6,5.3-4.1,9.8-9.1,11.6c-2.5,0.9-4.8,1.5-7.1,2.2c-9.6,2.8-15.9,4.6-15.9,16.7v5.3
   c-0.5,5.1,3,10,9.5,13.8c6.9,4,16.2,6.2,26.1,6.2v1.1v-1.1c9.8,0,19-2.2,25.8-6.1c6.4-3.7,9.8-8.5,9.3-13.3l0-6.1
   c0-11.9-6.2-13.6-15.5-16.3c-2.2-0.7-4.6-1.3-7-2.2c-5-1.8-8.5-6.3-9.1-11.6c-0.5-4.5-1-8.8-1.4-12.1c-0.3-2.6-0.6-4.4-0.6-5
   c-0.1-2.5,1.2-4.8,3.3-6.1c0,0,4.3-2.6,7.1-4.3c3.1-1.9,6.4-5.4,6.4-8.4C1241.4,209.2,1233.1,204.6,1223.2,204.6L1223.2,204.6z" />
      <path class="stel0" d="M1268.2,158.7h-90.7v4.9h0c0,0.1,0,3.2,0,3.4c0,14.6,20.3,26.4,45.3,26.4c25,0,45.4-11.8,45.4-26.4
   c0-0.1,0-3.2,0-3.4h0V158.7z" />
      <path class="stel2" d="M1222.9,194.5c-25.6,0-46.4-12.3-46.4-27.5c0-0.1,0-2.5,0-3.2c0-0.1,0-0.1,0-0.2v-4.9c0-0.6,0.5-1.1,1.1-1.1
   h90.7c0.6,0,1.1,0.5,1.1,1.1v4.9c0,0.1,0,0.1,0,0.2c0,0.7,0,3.1,0,3.2C1269.3,182.1,1248.5,194.5,1222.9,194.5 M1178.6,163.4
   c0,0.1,0,0.1,0,0.2c0,0.2,0,3.2,0,3.3c0,14,19.9,25.3,44.3,25.3s44.3-11.4,44.3-25.3c0-0.1,0-3.2,0-3.3c0-0.1,0-0.1,0-0.2v-3.7
   h-88.5V163.4z" />
      <path class="stel1" d="M1222.9,132.4c25,0,45.4,11.8,45.4,26.4c0,14.6-20.3,26.4-45.4,26.4c-25,0-45.4-11.8-45.4-26.4
   C1177.5,144.3,1197.9,132.4,1222.9,132.4" />
      <path class="stel2" d="M1222.9,186.4c-25.6,0-46.4-12.3-46.4-27.5c0-15.2,20.8-27.5,46.4-27.5c25.6,0,46.4,12.3,46.4,27.5
   C1269.3,174,1248.5,186.4,1222.9,186.4 M1222.9,133.5c-24.4,0-44.3,11.4-44.3,25.3c0,14,19.9,25.3,44.3,25.3
   c24.4,0,44.3-11.4,44.3-25.3C1267.2,144.9,1247.3,133.5,1222.9,133.5L1222.9,133.5z" />
      <path class="stel0" d="M679.9,575c27.9-16.1,53.3-18.5,72.3-9.5l15.3,8.7v0.1c-19-8.8-44.3-6.3-72,9.7
   c-59.1,34.1-107.1,116.8-107.1,184.7c0,32.9,11.3,56.3,29.7,67.8l-10.3-6c-21.6-10-35.1-34.7-35.1-70.7
   C572.8,691.9,620.7,609.2,679.9,575" />
      <path class="stel2" d="M618.1,837.5c-0.2,0-0.4-0.1-0.6-0.1l-10.3-6c-22.6-10.5-35.6-36.7-35.6-71.7c0-68.1,48.3-151.4,107.6-185.7
   c17.1-9.9,33.4-14.8,48.6-14.8c8.5-0.1,17,1.7,24.7,5.3l15.4,8.7c0.3,0.2,0.6,0.6,0.6,1c0,0.4-0.2,0.7-0.5,0.9
   c-0.3,0.2-0.7,0.2-1,0.1c-7.4-3.3-15.4-5-23.4-4.9c-14.8,0-30.8,4.9-47.5,14.6c-58.7,33.9-106.5,116.4-106.5,183.8
   c0,31.4,10.4,55.1,29.2,66.9c0.5,0.3,0.7,1,0.4,1.5C618.9,837.3,618.5,837.5,618.1,837.5L618.1,837.5z M727.9,561.4
   c-14.8,0-30.8,4.9-47.5,14.6c-58.8,33.9-106.5,116.4-106.5,183.8c0,33.5,12.1,58.6,33.3,69.2c-12.9-13.5-19.8-34.3-19.8-60.2
   c0-68.1,48.3-151.4,107.6-185.7c17.1-9.9,33.4-14.9,48.6-14.9c4.6,0,9.2,0.5,13.6,1.5l-5.6-3.2C744.2,563,736.1,561.3,727.9,561.4
   L727.9,561.4z" />
      <path class="stel1" d="M620.3,662.8c42.1-72,109.9-110.6,151.4-86.3c41.6,24.3,41.1,102.3-0.9,174.3c-42.1,72-109.9,110.6-151.4,86.3
   C577.8,812.9,578.2,734.8,620.3,662.8" />
      <path class="stel2" d="M647.5,845.5L647.5,845.5c-37.1,0-60.1-29.4-60.1-76.9c0-68.1,48.3-151.4,107.6-185.7
   c17.1-9.9,33.4-14.9,48.6-14.9c37.1,0,60.1,29.4,60.1,76.9c0,68.1-48.3,151.4-107.6,185.7C679,840.5,662.7,845.5,647.5,845.5
    M743.6,570.3c-14.8,0-30.8,4.9-47.5,14.6c-58.7,33.9-106.5,116.4-106.5,183.8c0,46.1,22.2,74.7,57.9,74.7
   c14.8,0,30.8-4.9,47.5-14.6c58.7-33.9,106.5-116.4,106.5-183.8C801.5,598.9,779.3,570.3,743.6,570.3" />
      <path class="stel0" d="M634.6,671.2c34.1-58.3,89-89.6,122.6-69.9c33.6,19.7,33.3,82.9-0.8,141.1c-34.1,58.3-89,89.6-122.6,69.9
   C600.2,792.7,600.6,729.5,634.6,671.2" />
      <path class="stel2" d="M656.6,819.3L656.6,819.3c-30.1,0-48.9-23.9-48.9-62.4c0-55.2,39.1-122.7,87.2-150.5
   c13.9-8,27.1-12.1,39.5-12.1c30.1,0,48.9,23.9,48.9,62.4c0,55.2-39.1,122.7-87.2,150.5C682.2,815.2,669,819.3,656.6,819.3
    M734.4,596.5c-11.9,0-24.8,4-38.4,11.8c-47.5,27.4-86.1,94.1-86.1,148.6c0,37.2,17.9,60.2,46.7,60.2c11.9,0,24.8-4,38.4-11.8
   c47.5-27.4,86.1-94.1,86.1-148.6C781.1,619.6,763.2,596.5,734.4,596.5" />
      <path class="stel1" d="M646.2,678c27.6-47.2,72.1-72.6,99.4-56.6c27.3,16,27,67.2-0.7,114.4c-27.6,47.2-72.1,72.6-99.4,56.6
   C618.3,776.4,618.6,725.2,646.2,678" />
      <path class="stel2" d="M664,798.2L664,798.2c-24.6,0-39.8-19.5-39.8-50.8c0-44.8,31.8-99.6,70.8-122.1c11.3-6.5,22.1-9.8,32.1-9.8
   c24.6,0,39.8,19.5,39.8,50.8c0,44.8-31.8,99.6-70.8,122.1C684.8,794.9,674,798.2,664,798.2 M727.1,617.6c-9.6,0-20.1,3.2-31,9.5
   c-38.4,22.2-69.7,76.1-69.7,120.3c0,30,14.4,48.6,37.6,48.6c9.6,0,20.1-3.2,31-9.5c38.4-22.2,69.7-76.1,69.7-120.3
   C764.7,636.3,750.3,617.6,727.1,617.6" />
      <path class="stel0" d="M657.1,684.3c21.5-36.8,56.2-56.6,77.4-44.1c21.2,12.4,21,52.3-0.5,89.1c-21.5,36.8-56.2,56.6-77.4,44.1
   C635.3,761,635.5,721.1,657.1,684.3" />
      <path class="stel2" d="M671,778.3L671,778.3c-19.3,0-31.3-15.3-31.3-39.8c0-35,24.8-77.8,55.3-95.4c8.8-5.1,17.3-7.7,25.1-7.7
   c19.3,0,31.3,15.3,31.3,39.8c0,35-24.8,77.8-55.3,95.4C687.3,775.7,678.8,778.3,671,778.3 M720.1,637.6c-7.5,0-15.6,2.5-24,7.4
   c-29.9,17.3-54.2,59.2-54.2,93.5c0,23.2,11.1,37.6,29.1,37.6c7.5,0,15.6-2.5,24-7.4c29.9-17.3,54.2-59.2,54.2-93.5
   C749.2,652,738.1,637.6,720.1,637.6" />
      <path class="stel1" d="M667.3,690.3c15.8-27,41.2-41.5,56.8-32.4c15.6,9.1,15.4,38.4-0.4,65.4c-15.8,27-41.2,41.5-56.8,32.4
   C651.4,746.6,651.5,717.3,667.3,690.3" />
      <path class="stel2" d="M677.5,759.5c-14.1,0-23.2-11.6-23.2-29.5c0-25.7,18.3-57.2,40.7-70.2c6.5-3.8,12.8-5.7,18.6-5.7
   c14.1,0,23.2,11.6,23.2,29.5c0,25.8-18.3,57.2-40.7,70.2C689.6,757.6,683.3,759.5,677.5,759.5 M713.6,656.3
   c-5.4,0-11.3,1.8-17.5,5.4c-21.8,12.6-39.6,43.2-39.6,68.3c0,16.9,8.1,27.3,21,27.3c5.4,0,11.3-1.8,17.5-5.4
   c21.8-12.6,39.6-43.3,39.6-68.3C734.6,666.8,726.5,656.3,713.6,656.3" />
      <path class="stel3" d="M679.8,697.7c8.8-15,22.9-23.1,31.6-18c8.7,5.1,8.6,21.4-0.2,36.4c-8.8,15-22.9,23.1-31.6,18
   C671,728.9,671.1,712.7,679.8,697.7" />
      <path class="stel3" d="M1016.8,752c-3.5,0.7-13.9,1.9-27.8-6.1c-17.3-9.9-12.9-35.7-12.9-35.7c1.7,2.4,3.6,4.6,5.8,6.6
   c-7.6-20.8-6.6-45.6-6.6-45.6s6.5,13,20.7,29.1c-0.4-6.5-0.4-13,0.2-19.4c3.1,6.8,6.9,13.4,11.2,19.5c4.4-22.6,3.1-38.5,3.1-38.5
   c6.3,9.9,11.4,20.6,15.2,31.8c6-2.7,12.5-4.3,19-4.8c0,0-5.3,2.6-8.3,11.7c13.4-4.1,22.7-28.4,22.7-28.4s3.2,50.2-14.9,69.3
   c-8.4,8.9-16.1,11.2-22,11.2C1020.4,752.7,1018.6,752.5,1016.8,752" />
      <path class="stel4" d="M1016.8,752c-3.5,0.7-13.9,1.9-27.8-6.1c-17.3-9.9-12.9-35.7-12.9-35.7c1.7,2.4,3.6,4.6,5.8,6.6
   c-7.6-20.8-6.6-45.6-6.6-45.6s6.5,13,20.7,29.1c-0.4-6.5-0.4-13,0.2-19.4c3.1,6.8,6.9,13.4,11.2,19.5c4.4-22.6,3.1-38.5,3.1-38.5
   c6.3,9.9,11.4,20.6,15.2,31.8c6-2.7,12.5-4.3,19-4.8c0,0-5.3,2.6-8.3,11.7c13.4-4.1,22.7-28.4,22.7-28.4s3.2,50.2-14.9,69.3
   c-8.4,8.9-16.1,11.2-22,11.2C1020.4,752.7,1018.6,752.5,1016.8,752z" />
      <path class="stel0" d="M853.2,698c-4.8-2.8-4.9-7.3-0.1-10.1l39.8-23c6.7-3.9,17.7-3.9,24.4,0c6.8,3.9,6.8,10.2,0.1,14.1l-35.5,20.5
   c-0.8,0.4-1.9,0.4-2.7,0c-0.4-0.1-0.7-0.6-0.5-1c0.1-0.2,0.3-0.4,0.5-0.5l35.5-20.5c5.2-3,5.2-7.9-0.1-11c-5.3-3-13.8-3-19,0
   l-39.8,23c-3.3,1.9-3.3,5.1,0,7c3.8,1.9,8.3,1.9,12.1,0l38.1-22c1.5-0.9,1.5-2.3,0-3.2c-1.7-0.9-3.7-0.9-5.5,0l-31.7,18.3
   c-0.1,0-0.2,0.1-0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.6,0l23.3-13.5c0.8-0.4,1.9-0.4,2.7,0c0.4,0.1,0.7,0.6,0.5,1
   c-0.1,0.2-0.3,0.4-0.5,0.5l-23.3,13.5c-1.9,1-4.1,1-6,0c-1.7-1-1.7-2.5,0-3.5l31.7-18.3c3.4-1.7,7.5-1.7,10.9,0c3,1.7,3,4.6,0,6.3
   l-38.1,22C865.2,700.8,858.7,700.8,853.2,698" />
      <path class="stel0" d="M367,313.9c4.8-2.8,4.9-7.3,0.1-10.1l-39.8-23c-6.7-3.9-17.7-3.9-24.4,0c-6.8,3.9-6.8,10.2-0.1,14.1l35.5,20.5
   c0.9,0.4,1.9,0.4,2.7,0c0.4-0.1,0.7-0.6,0.5-1c-0.1-0.2-0.3-0.4-0.5-0.5l-35.5-20.5c-5.2-3-5.2-7.9,0.1-11c5.3-3,13.8-3.1,19,0
   l39.8,23c3.3,1.9,3.3,5.1,0,7c-3.8,1.9-8.3,1.9-12.1,0l-38.1-22c-1.5-0.9-1.5-2.3,0-3.2c1.7-0.9,3.7-0.9,5.5,0l31.7,18.3
   c0.1,0,0.2,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.1-0.6,0l-23.3-13.5c-0.9-0.4-1.9-0.4-2.7,0c-0.4,0.1-0.7,0.6-0.5,1
   c0.1,0.2,0.3,0.4,0.5,0.5l23.3,13.5c1.9,1,4.1,1,6,0c1.7-1,1.7-2.5,0-3.5l-31.7-18.3c-3.4-1.7-7.5-1.7-10.9,0c-3,1.7-3,4.6,0,6.3
   l38.1,22C355,316.7,361.5,316.7,367,313.9" />
      <path class="stel0" d="M399.8,323.9c-6.3,1-13.5-1-16-4.3l-20.9-27.6c-3.5-4.7,0.8-9.6,9.5-10.9c8.8-1.4,18.8,1.3,22.3,6l18.7,24.7
   c0.4,0.5-0.1,1.1-1.1,1.2c-0.9,0.2-1.8-0.1-2.5-0.7l-18.7-24.7c-2.7-3.6-10.5-5.7-17.4-4.7c-6.8,1.1-10.2,4.9-7.4,8.5l20.9,27.6
   c1.7,2.3,6.7,3.6,11.1,3c4.4-0.7,6.5-3.1,4.7-5.4l-20-26.5c-0.8-1-3-1.6-5-1.3c-2,0.3-2.9,1.4-2.1,2.4l16.7,22
   c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3-0.2,0.2-0.3l-12.3-16.2c-0.4-0.5,0.1-1.1,1.1-1.2c0.9-0.2,1.8,0.1,2.5,0.7l12.3,16.2
   c0.9,1.2-0.2,2.4-2.4,2.7c-2.2,0.3-4.6-0.3-5.5-1.5l-16.7-22c-1.6-2.1,0.3-4.3,4.2-4.9c3.9-0.6,8.4,0.6,9.9,2.7l20,26.5
   C409.2,319.4,406.1,322.9,399.8,323.9" />
      <path class="stel0" d="M1007,263.6c-4.6-3.2-4.3-7.7,0.7-10.1l41.4-19.9c7-3.4,17.9-2.5,24.3,1.9c6.4,4.4,6,10.7-1,14.1l-37,17.8
   c-0.9,0.4-1.9,0.3-2.7-0.2c-0.7-0.5-0.7-1.2,0.1-1.6l37-17.8c5.4-2.6,5.8-7.5,0.8-10.9c-5-3.4-13.5-4.1-18.9-1.5l-41.4,19.9
   c-3.5,1.7-3.7,4.8-0.5,7c3.6,2.2,8.1,2.6,12.1,0.9l39.6-19.1c1.6-0.8,1.7-2.2,0.2-3.2c-1.6-1-3.7-1.2-5.5-0.4l-33,15.9
   c-0.1,0-0.2,0.1-0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0.2,0.1,0.4,0.1,0.6,0.1l24.3-11.7c0.9-0.4,1.9-0.3,2.7,0.2
   c0.7,0.5,0.7,1.2-0.1,1.6l-24.3,11.7c-2,0.8-4.2,0.6-6-0.5c-1.6-1.1-1.5-2.6,0.2-3.5l33-15.9c3.5-1.5,7.6-1.2,10.8,0.8
   c2.9,2,2.7,4.8-0.4,6.3l-39.6,19.1C1018.7,267.3,1012.3,266.8,1007,263.6" />
      <path class="stel0" d="M78.9,370.8c4.8-2.8,4.9-7.3,0.1-10.1l-39.8-23c-6.7-3.9-17.7-3.9-24.4,0C8,341.7,8,348,14.7,351.9l35.5,20.5
   c0.8,0.4,1.9,0.4,2.7,0c0.4-0.1,0.7-0.6,0.5-1c-0.1-0.2-0.3-0.4-0.5-0.5l-35.5-20.5c-5.2-3-5.2-7.9,0.1-11c5.3-3,13.8-3,19,0
   l39.8,23c3.3,1.9,3.3,5.1,0,7c-3.8,1.9-8.3,1.9-12.1,0l-38.1-22c-1.5-0.9-1.5-2.3,0-3.2c1.7-0.9,3.7-0.9,5.5,0l31.7,18.3
   c0.1,0,0.2,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.1-0.6,0l-23.3-13.5c-0.9-0.4-1.9-0.4-2.7,0c-0.4,0.1-0.7,0.6-0.5,1
   c0.1,0.3,0.3,0.5,0.5,0.5l23.3,13.5c1.9,1,4.1,1,6,0c1.7-1,1.7-2.5,0-3.5l-31.7-18.3c-3.4-1.7-7.5-1.7-10.9,0c-3,1.7-3,4.6,0,6.3
   l38.1,22C66.9,373.6,73.4,373.6,78.9,370.8" />
      <path class="stel0" d="M1123.2,310c-6.3,1-13.5-1-16-4.3l-20.9-27.6c-3.5-4.7,0.8-9.6,9.5-10.9c8.8-1.4,18.8,1.3,22.3,6l18.7,24.7
   c0.4,0.5-0.1,1.1-1.1,1.2c-0.9,0.2-1.8-0.1-2.5-0.7l-18.7-24.7c-2.7-3.6-10.5-5.7-17.4-4.7c-6.8,1.1-10.2,4.9-7.4,8.5l20.9,27.6
   c1.7,2.3,6.7,3.6,11.1,3c4.4-0.7,6.5-3.1,4.7-5.4l-20-26.5c-0.8-1-3-1.6-5-1.3c-2,0.3-2.9,1.4-2.1,2.4l16.7,22
   c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3-0.2,0.2-0.3l-12.3-16.2c-0.4-0.5,0.1-1.1,1.1-1.2c0.9-0.2,1.8,0.1,2.5,0.7l12.3,16.2
   c0.9,1.2-0.2,2.4-2.4,2.7c-2.2,0.3-4.6-0.3-5.5-1.5l-16.7-22c-1.6-2.1,0.3-4.3,4.3-4.9c3.9-0.6,8.4,0.6,9.9,2.7l20,26.5
   C1132.6,305.5,1129.5,309,1123.2,310" />
      <path class="stel0" d="M976.6,87.7v-0.3l0,0C976.6,87.5,976.6,87.6,976.6,87.7" />
      <path class="stel2" d="M976.6,88.8c-0.6,0-1.1-0.5-1.1-1.1c0,0,0-0.1,0-0.1l0-0.1c0-0.4,0.3-0.8,0.7-1c0.6-0.2,1.2,0.1,1.4,0.6
   c0,0.1,0.1,0.2,0.1,0.4v0.3C977.7,88.3,977.2,88.8,976.6,88.8C976.6,88.8,976.6,88.8,976.6,88.8" />
      <path class="stel0" d="M939.8,29.6c-0.4,0.7-1.2,1.1-2,1c-3.6,0.1-7,1.1-10.2,2.8c-4.1,2.4-5.6,5.7-4.5,8.8c-0.5,1.4-0.5,3,0.1,4.4
   c0.9-1.9,2.5-3.4,4.3-4.4c3.1-1.7,6.6-2.6,10.2-2.8c1.5-0.1,2.3-0.9,2-1.6L939.8,29.6z" />
      <path class="stel2" d="M923.3,47.7L923.3,47.7c-0.4,0-0.8-0.3-1-0.7c-0.7-1.5-0.8-3.2-0.3-4.8c-1.1-3.6,0.8-7.2,5.1-9.7
   c3.3-1.8,6.9-2.8,10.6-2.9c0.4,0,0.8-0.1,1.1-0.3c0.2-0.6,0.8-0.9,1.4-0.7c0.4,0.2,0.7,0.6,0.7,1.1v8.1c0.1,0.5,0,1.1-0.2,1.5
   c-0.7,0.9-1.7,1.4-2.8,1.4c-3.4,0.1-6.7,1-9.7,2.6c-1.7,0.9-3,2.2-3.9,3.9C924.1,47.4,923.8,47.7,923.3,47.7 M938.8,31.6
   c-0.3,0.1-0.6,0.1-0.9,0.1c-3.4,0.1-6.7,1-9.7,2.6c-3.5,2-5,4.8-4,7.5c0.1,0.2,0.1,0.5,0,0.8c-0.1,0.4-0.2,0.8-0.3,1.2
   c0.9-1,2-1.8,3.2-2.5c3.3-1.8,6.9-2.8,10.6-2.9c0.4,0,0.8-0.1,1.1-0.3L938.8,31.6z" />
      <path class="stel0" d="M972.5,89.9c-6.2,3.8-16.6,3.9-23,0.2c-2.9-1.7-4.4-3.8-4.7-5.9c-0.2-1.4-3.3-2.1-5-1l-27,15.6v8.8l27-15.6
   c1.8-1,4.8-0.4,5,1c0.3,2.2,1.8,4.3,4.7,5.9c6.4,3.7,16.8,3.6,23-0.2c2.7-1.7,4-3.8,4.1-6h0v-9.1C976.6,85.9,975.3,88.2,972.5,89.9
   " />
      <path class="stel2" d="M912.7,108.7c-0.6,0-1.1-0.5-1.1-1.1v-8.8c0-0.4,0.2-0.8,0.5-0.9l27-15.6c0.8-0.4,1.7-0.7,2.6-0.6
   c1.8,0,3.8,0.8,4,2.5c0.2,1.9,1.7,3.7,4.1,5.1c3.3,1.8,7,2.7,10.8,2.6c3.9,0.1,7.7-0.9,11.1-2.8c2.3-1.5,3.6-3.3,3.6-5.3v0
   c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v8.8c0,0.1,0,0.2,0,0.3h0c-0.1,2.6-1.8,5.1-4.7,6.9c-3.7,2.1-8,3.2-12.2,3.1
   c-4.1,0.1-8.2-0.9-11.9-2.9c-3.1-1.8-4.9-4.2-5.2-6.7c-0.5-0.4-1.2-0.6-1.9-0.6c-0.5,0-1.1,0.1-1.5,0.3l-27,15.6
   C913.1,108.6,912.9,108.7,912.7,108.7 M913.8,99.4v6.3L939.2,91c0.8-0.4,1.7-0.7,2.6-0.6c1.8,0,3.8,0.8,4,2.5
   c0.2,1.9,1.7,3.7,4.1,5.1c3.3,1.8,7,2.7,10.8,2.6c3.9,0.1,7.7-0.9,11.1-2.8c2.3-1.4,3.5-3.2,3.6-5.1c0,0,0-0.1,0-0.1v-3.7
   c-0.7,0.8-1.6,1.5-2.5,2c-3.7,2.1-8,3.2-12.2,3.1c-4.1,0.1-8.2-0.9-11.9-2.9c-3.1-1.8-4.9-4.2-5.2-6.7c-0.5-0.4-1.2-0.6-1.9-0.6
   c-0.5,0-1.1,0.1-1.5,0.4L913.8,99.4z" />
      <path class="stel0"
        d="M845,59.7c-1.6-0.9-2.5-2.7-2.3-4.5l-0.1,0v9.7h0.1c0.1,1.5,1,2.8,2.3,3.6l67.7,39.1v-8.8L845,59.7z" />
      <path class="stel2" d="M912.7,108.7c-0.2,0-0.4-0.1-0.5-0.1l-67.7-39.1c-1.5-0.9-2.5-2.3-2.8-4c-0.1-0.1-0.1-0.3-0.1-0.5v-9.7
   c0-0.6,0.5-1.1,1.1-1.1c0.1,0,0.3,0,0.4,0.1c0.5,0.2,0.8,0.6,0.8,1.2c-0.1,1.4,0.5,2.7,1.8,3.4l67.7,39.1c0.3,0.2,0.5,0.6,0.5,0.9
   v8.8c0,0.4-0.2,0.8-0.5,0.9C913.1,108.6,912.9,108.7,912.7,108.7 M843.8,64.6c0,0.1,0.1,0.2,0.1,0.3c0.1,1.1,0.8,2.2,1.8,2.7
   l66.1,38.1v-6.3l-67.2-38.8c-0.3-0.1-0.5-0.3-0.7-0.5L843.8,64.6z" />
      <path class="stel0" d="M976.6,83.7v-0.3l0,0C976.6,83.5,976.6,83.6,976.6,83.7" />
      <path class="stel2" d="M976.6,84.8c-0.6,0-1.1-0.5-1.1-1.1c0,0,0-0.1,0-0.1l0-0.1c0-0.4,0.3-0.8,0.7-1c0.3-0.1,0.7,0,1,0.2
   c0.3,0.2,0.5,0.5,0.5,0.9v0.3C977.7,84.3,977.2,84.8,976.6,84.8L976.6,84.8z" />
      <path class="stel0" d="M959.9,71.6c-1.8,1-0.6,2.8,1.8,2.9c0,0,0.1,0,0.1,0l-2,1.1c-1.8,1-0.6,2.8,1.8,2.9c0.4,0,0.9,0,1.3,0.1
   l23.9-13.8V56L959.9,71.6z" />
      <path class="stel2" d="M963,79.7L963,79.7c-0.5,0-0.9-0.1-1.4-0.1c-1.9-0.1-3.4-1-3.7-2.3c-0.2-1,0.2-2,1.1-2.5
   c-0.6-0.3-1-0.9-1.1-1.5c-0.2-1.1,0.3-2.2,1.3-2.6L986.4,55c0.5-0.3,1.2-0.1,1.5,0.4c0.1,0.2,0.1,0.4,0.1,0.5v8.8
   c0,0.4-0.2,0.8-0.5,0.9l-23.9,13.8C963.4,79.6,963.2,79.7,963,79.7 M985.8,57.8l-25.4,14.7c-0.3,0.1-0.3,0.3-0.3,0.3
   c0.4,0.4,1,0.6,1.6,0.6c0.5,0,0.9,0.4,1.1,0.8c0.1,0.5,0,1-0.4,1.2l-2,1.1c-0.3,0.2-0.3,0.3-0.3,0.3c0.4,0.4,1,0.6,1.6,0.6
   c0.3,0,0.7,0,1,0.1l23.1-13.3L985.8,57.8z" />
      <path class="stel1" d="M927.7,33.4c-6.8,3.9-6.4,10.4,1,14.1c6.1,3,16,3,21.9,0c3.3-1.7,5.1-3.9,5.5-6.3c0.2-1.1,2.5-1.6,3.9-0.8
   l27,15.6l-27.1,15.6c-1.8,1-0.6,2.8,1.8,2.9c3.8,0.1,7.4,1.1,10.7,3c5.6,3.5,5.7,9.1,0.1,12.5c-6.2,3.8-16.6,3.9-23,0.2
   c-2.9-1.6-4.4-3.8-4.7-5.9c-0.2-1.4-3.3-2.1-5-1l-27,15.6L845,59.7c-2.2-1.3-3-4.1-1.7-6.3c0.4-0.7,1-1.3,1.7-1.7l67.2-38.8
   l27,15.6c1.4,0.8,0.5,2.1-1.4,2.2C934.3,30.8,930.8,31.7,927.7,33.4" />
      <path class="stel2" d="M912.7,99.9c-0.2,0-0.4-0.1-0.5-0.1l-67.7-39.1c-2.7-1.6-3.7-5.1-2.1-7.8c0.5-0.9,1.2-1.6,2.1-2.1l67.2-38.8
   c0.3-0.2,0.8-0.2,1.1,0l27,15.6c0.9,0.4,1.4,1.4,1.2,2.3c-0.5,1.3-1.7,2.1-3.1,2c-3.4,0.1-6.7,1-9.7,2.6c-2.8,1.6-4.4,3.8-4.3,6
   c0.1,2.3,2,4.6,5.2,6.2c3.3,1.5,6.9,2.2,10.5,2.2c3.6,0.1,7.2-0.7,10.5-2.2c2.9-1.5,4.6-3.4,5-5.5c0.2-1.3,1.6-2.1,3.4-2.1
   c0.7,0,1.5,0.2,2.1,0.5l27,15.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-27.1,15.6c-0.3,0.1-0.3,0.3-0.3,0.3
   c0.4,0.4,1,0.6,1.6,0.6c3.9,0.1,7.8,1.1,11.2,3.1c3.1,1.9,4.8,4.5,4.8,7.2c0,2.7-1.7,5.3-4.7,7.2c-3.7,2.1-8,3.2-12.2,3.1
   c-4.1,0.1-8.2-0.9-11.9-2.9c-3.1-1.8-4.9-4.2-5.2-6.7c-0.5-0.4-1.2-0.6-1.9-0.6c-0.5,0-1.1,0.1-1.5,0.4l-27,15.6
   C913.1,99.8,912.9,99.9,912.7,99.9 M912.3,14.1l-66.7,38.5c-1.7,1-2.3,3.2-1.3,4.9c0.3,0.5,0.8,1,1.3,1.3l67.2,38.8l26.5-15.3
   c0.8-0.4,1.7-0.7,2.6-0.6c1.8,0,3.8,0.8,4,2.5c0.2,1.9,1.7,3.7,4.1,5.1c3.3,1.8,7,2.7,10.8,2.6c3.9,0.1,7.7-0.9,11.1-2.8
   c2.3-1.5,3.6-3.3,3.6-5.3c0-2-1.3-3.9-3.7-5.4c-3.1-1.8-6.6-2.7-10.2-2.8c-1.9-0.1-3.4-1-3.7-2.3c-0.2-1.1,0.3-2.2,1.3-2.6
   L984.7,56l-25.3-14.6c-0.3-0.2-0.7-0.3-1-0.2c-0.4,0-0.9,0.1-1.2,0.4c-0.4,2.7-2.6,5.2-6.1,7c-3.6,1.7-7.5,2.5-11.5,2.4
   c-3.9,0.1-7.9-0.7-11.5-2.4c-4-2-6.3-5-6.4-8.1c-0.1-3,1.8-5.9,5.4-7.9c3.3-1.8,6.9-2.8,10.6-2.9c0.3,0,0.6-0.1,0.9-0.2L912.3,14.1
   z" />
      <path class="stel1" d="M1654.2,715.2c6.7-3.9,17.8-3.7,24.3,0.4c5.2,3.4,5.3,9.4,0.1,12.8c-3.3,2-7,3.1-10.8,3.2
   c-1.9,0.1-2.8,1.4-1.4,2.2l27,15.6l27.1-15.6c1.8-1,4.8-0.4,5,1.1c0.3,2.3,2,4.5,5.1,6.2c6.1,3.2,15.7,3.3,21.7,0
   c6.7-3.6,6.8-9.6,0.4-13.3c-3.2-1.7-6.7-2.6-10.3-2.7c-2.5-0.1-3.5-1.9-1.8-2.9l27-15.6l-68.7-39.7c-3.7-2.1-8.3-2.1-12,0
   l-68.2,39.4l27,15.6c1.4,0.8,3.7,0.3,3.9-0.8C1649.8,718.9,1651.3,716.8,1654.2,715.2" />
      <path class="stel2" d="M1693.2,750.5c-0.2,0-0.4-0.1-0.5-0.1l-27-15.6c-0.9-0.4-1.4-1.4-1.2-2.3c0.5-1.3,1.7-2.1,3.1-2
   c3.6-0.1,7.2-1.1,10.3-3c2-1.1,3.3-3.2,3.4-5.4c-0.1-2.3-1.5-4.4-3.5-5.5c-7.2-3.9-15.8-4.1-23.1-0.4c-2.4,1.4-3.9,3.2-4.2,5.1
   c-0.2,1.3-1.6,2.1-3.3,2.1c-0.7,0-1.5-0.2-2.1-0.5l-27-15.6c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l68.2-39.4
   c4.1-2.3,9-2.3,13.1,0l68.7,39.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-27,15.6c-0.3,0.2-0.3,0.3-0.3,0.3
   c0,0.1,0.5,0.5,1.6,0.6c3.8,0.1,7.5,1.1,10.8,2.8c3.4,2,5.3,4.7,5.3,7.6c0,3-2.1,5.7-5.7,7.6c-7.2,3.4-15.5,3.4-22.7,0
   c-3.3-1.8-5.4-4.3-5.7-7c-0.5-0.4-1.2-0.7-1.8-0.6c-0.5,0-1,0.1-1.5,0.3l-27.1,15.6C1693.6,750.4,1693.4,750.5,1693.2,750.5
    M1666.9,732.9l26.3,15.2l26.5-15.3c0.8-0.4,1.7-0.7,2.6-0.6c1.8,0,3.8,0.8,4,2.5c0.2,2,1.8,3.9,4.5,5.3c3.2,1.6,6.8,2.4,10.4,2.3
   c3.6,0.1,7.1-0.7,10.3-2.3c2.9-1.5,4.5-3.6,4.5-5.7c0-2.1-1.4-4.1-4.2-5.7c-3-1.6-6.4-2.5-9.8-2.6c-1.9-0.1-3.3-1-3.6-2.3
   c-0.2-1.1,0.3-2.2,1.3-2.7l25.4-14.7l-67-38.7c-3.4-1.9-7.5-1.9-10.9,0l-66.6,38.4l25.3,14.6c0.3,0.2,0.7,0.3,1,0.2
   c0.4,0,0.9-0.1,1.2-0.4c0.4-2.5,2.3-4.8,5.3-6.6c8-4.1,17.5-3.9,25.4,0.5c2.7,1.5,4.3,4.3,4.5,7.4c-0.1,3-1.7,5.7-4.3,7.3
   c-3.4,2.1-7.4,3.2-11.4,3.4C1667.5,732.7,1667.2,732.8,1666.9,732.9" />
      <path class="stel0" d="M1008.2,179.2c-1.8-1-4.8-0.4-5,1c-0.2,2.2-1.8,4.3-4.6,5.9c-6.4,3.7-16.8,3.6-23-0.3
   c-2.1-1.1-3.6-3.1-4.2-5.4v3c-0.1,0.5,0,4,0,4c-0.4,2.5,1,5.2,4.2,7.2c6.2,3.9,16.7,4,23,0.3c2.8-1.6,4.4-3.8,4.6-5.9
   c0.2-1.4,3.2-2,5-1l27.2,15.7v-8.8L1008.2,179.2z" />
      <path class="stel2" d="M1035.4,204.8c-0.2,0-0.4-0.1-0.5-0.1l-27.2-15.7c-0.5-0.2-1-0.4-1.5-0.4c-0.7-0.1-1.3,0.1-1.8,0.6
   c-0.3,2.6-2.1,5-5.1,6.7c-7.6,3.9-16.6,3.8-24.1-0.3c-3.4-2.2-5.1-5.2-4.7-8.3c0-0.9-0.1-3.5,0-4l0-2.9c0-0.6,0.4-1,1-1.1
   c0.6-0.1,1.1,0.3,1.2,0.9c0.5,2,1.8,3.7,3.7,4.7c6.8,3.7,15,3.8,21.9,0.3c2.4-1.4,3.9-3.2,4.1-5.1c0.2-1.5,1.8-2.5,4-2.5
   c0.9,0,1.8,0.2,2.6,0.7l27.1,15.7c0,0,0.1,0,0.1,0.1c0.3,0.2,0.5,0.6,0.5,0.9v8.8c0,0.4-0.2,0.8-0.6,1
   C1035.7,204.8,1035.5,204.8,1035.4,204.8 M1006.1,186.4c0.9,0,1.8,0.2,2.6,0.6l25.6,14.8v-6.3l-26.7-15.4c-0.5-0.2-1-0.4-1.5-0.4
   c-0.7-0.1-1.3,0.1-1.8,0.6c-0.3,2.6-2.1,5-5.1,6.7c-7.6,3.9-16.6,3.8-24.1-0.3c-0.9-0.6-1.8-1.3-2.5-2.1c0,0.8,0,1.8,0,2.7
   c-0.4,2.4,1,4.6,3.6,6.3c6.8,3.7,15,3.8,21.9,0.3c2.4-1.4,3.9-3.2,4.1-5.1C1002.3,187.5,1003.9,186.4,1006.1,186.4" />
      <path class="stel0" d="M1098.9,130.1c0.1-0.6,0.1-1.2,0-1.8v-2.9c-0.5,2.3-2,4.2-4.1,5.3c-3.2,1.9-6.9,2.9-10.7,2.9
   c-2.5,0.1-3.6,1.8-1.8,2.9l2,1.2l-0.2,0c-2.5,0.1-3.6,1.8-1.8,2.9l3.2,1.8c3.3-0.2,6.5-1.2,9.3-2.8c3.2-2,4.5-4.6,4.1-7.2
   L1098.9,130.1z" />
      <path class="stel2" d="M1085.5,143.3c-0.2,0-0.4-0.1-0.5-0.1l-3.2-1.8c-1-0.5-1.6-1.6-1.4-2.7c0.2-0.6,0.6-1.2,1.1-1.5
   c-0.9-0.5-1.3-1.5-1.1-2.5c0.3-1.3,1.8-2.2,3.7-2.3c3.6,0,7-1,10.1-2.7c1.8-0.9,3.1-2.6,3.6-4.6c0.1-0.6,0.6-1,1.2-0.9c0,0,0,0,0,0
   c0.6,0,1,0.5,1,1.1v2.9c0.1,0.7,0.1,1.4,0,2l0,2c0.5,2.9-1.1,6-4.6,8.1C1092.4,142.1,1089.1,143.1,1085.5,143.3L1085.5,143.3z
    M1082.6,139.2c0.1,0.1,0.2,0.2,0.3,0.2l2.9,1.7c3-0.2,5.9-1.1,8.5-2.6c2.7-1.7,4-3.9,3.6-6.1l0-2.4c0-0.2,0-0.4,0.1-0.5
   c-0.7,0.8-1.6,1.4-2.5,2c-3.4,2-7.3,3-11.2,3.1c-1.1,0-1.6,0.5-1.6,0.6c0.1,0.1,0.2,0.2,0.3,0.2l2,1.1c0.5,0.3,0.7,1,0.4,1.5
   c-0.2,0.3-0.5,0.5-0.9,0.6C1083.1,138.7,1082.6,139.1,1082.6,139.2" />
      <path class="stel0"
        d="M1009.6,135.5c3.6,0.1,7,1.1,10.2,2.8c1.9,1,3.4,2.5,4.4,4.5c0.6-1.4,0.7-3,0.1-4.4c1.1-3.1-0.4-6.4-4.5-8.9
   c-3.1-1.7-6.6-2.7-10.2-2.8c-0.8,0.1-1.6-0.3-2-1.1v4.2c-0.1,0.2-0.1,0.4,0,0.6v3.4C1007.2,134.6,1008.1,135.4,1009.6,135.5" />
      <path class="stel2" d="M1024.1,143.8L1024.1,143.8c-0.4,0-0.8-0.3-1-0.6c-0.9-1.7-2.2-3.1-3.9-4c-3-1.6-6.3-2.5-9.7-2.7
   c-1.1,0-2.1-0.5-2.8-1.4c-0.3-0.4-0.4-1-0.2-1.5v-3.1c-0.1-0.3-0.1-0.6,0-0.9v-4c0-0.6,0.5-1.1,1.1-1.1c0.5,0,0.9,0.3,1,0.7
   c0.3,0.2,0.7,0.4,1.1,0.3c3.7,0.1,7.4,1.1,10.7,2.9c4.3,2.5,6.2,6.2,5.1,9.8c0.5,1.6,0.4,3.3-0.3,4.9
   C1025,143.6,1024.6,143.9,1024.1,143.8 M1008.6,134.2c0.3,0.1,0.7,0.1,1,0.2c3.7,0.1,7.4,1.2,10.7,2.9c1.2,0.7,2.3,1.5,3.2,2.6
   c0-0.4-0.1-0.8-0.3-1.2c-0.1-0.2-0.1-0.5,0-0.8c1-2.7-0.5-5.5-4.1-7.5c-3-1.6-6.3-2.5-9.7-2.7c-0.3,0-0.6-0.1-0.9-0.1v2
   c0,0.2,0,0.4-0.1,0.6l0.1,3.6C1008.6,134,1008.6,134.1,1008.6,134.2C1008.6,134.2,1008.6,134.2,1008.6,134.2" />
      <path class="stel0" d="M986.2,174.4c2.5-0.1,3.6-1.8,1.8-2.9l-2-1.2l0.2,0c2.5-0.1,3.6-1.8,1.8-2.9l-27.2-15.7v8.8l24.1,13.9
   C985.2,174.5,985.7,174.5,986.2,174.4" />
      <path class="stel2" d="M984.8,175.6c-0.2,0-0.4-0.1-0.5-0.1l-24.1-13.9c-0.3-0.2-0.5-0.6-0.5-0.9v-8.8c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0,0.6,0.1l27.2,15.7c1,0.5,1.6,1.6,1.4,2.6c-0.2,0.6-0.6,1.2-1.1,1.5c0.9,0.5,1.3,1.5,1.1,2.5c-0.3,1.3-1.8,2.2-3.7,2.3
   C985.8,175.6,985.3,175.6,984.8,175.6L984.8,175.6z M961.8,160l23.2,13.4c0.4,0,0.7,0,1.1-0.1c1.1,0,1.6-0.5,1.6-0.6
   c-0.1-0.1-0.2-0.2-0.3-0.2l-2-1.1c-0.5-0.3-0.7-1-0.4-1.5c0.2-0.3,0.5-0.5,0.9-0.6c1.3,0,1.8-0.5,1.8-0.6c-0.1-0.1-0.2-0.2-0.3-0.2
   l-25.6-14.8V160z" />
      <path class="stel0" d="M1179.8,108.7c0,0.1,0,0.1,0,0.2L1179.8,108.7L1179.8,108.7z" />
      <path class="stel2" d="M1179.8,109.9c-0.6,0-1-0.4-1.1-0.9c0,0,0,0,0,0c0,0,0-0.1,0-0.1l0-0.1c0-0.5,0.4-0.9,0.9-1.1
   c0.3-0.1,0.7,0,0.9,0.2c0.3,0.2,0.4,0.5,0.4,0.8C1181,109.3,1180.5,109.8,1179.8,109.9" />
      <path class="stel0" d="M1177.6,112.7l-67.6,39l-27-15.6c-0.3-0.1-0.5-0.3-0.6-0.6v4.1c-0.1,0.2-0.1,0.4,0,0.6v3.4
   c-0.2,0.4,0,0.9,0.6,1.2l27,15.6l67.6-39c1.2-0.7,2-1.9,2.2-3.2h0v-9.4C1179.9,110.5,1179,112,1177.6,112.7" />
      <path class="stel2" d="M1110.1,161.6c-0.2,0-0.4,0-0.5-0.1l-27-15.6c-0.9-0.4-1.4-1.4-1.1-2.4v-3c-0.1-0.3-0.1-0.7,0-1v-4
   c0-0.6,0.5-1.1,1.1-1.1c0.4,0,0.8,0.2,1,0.7l26.6,15.4l67-38.7c1-0.6,1.7-1.7,1.7-2.9c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
   v9.4c0,0.2,0,0.3-0.1,0.5c-0.3,1.5-1.3,2.9-2.6,3.7l-67.6,39C1110.5,161.6,1110.3,161.6,1110.1,161.6 M1083.6,137.7v1.9
   c0,0.2,0,0.4-0.1,0.6l0.1,3.6c0,0.2,0,0.3-0.1,0.4l26.6,15.1l67-38.7c0.9-0.5,1.5-1.4,1.6-2.4c0-0.1,0-0.1,0-0.2v-4.7
   c-0.2,0.1-0.4,0.3-0.6,0.4l-67.5,39c-0.3,0.2-0.8,0.2-1.1,0L1083.6,137.7z" />
      <path class="stel0" d="M1050,96c2.9,1.7,6.1,2.6,9.4,2.9l3.1-1.8c1.8-1,0.6-2.8-1.8-2.9c0,0-0.1,0-0.1,0l2-1.1c1.8-1,0.6-2.8-1.8-2.9
   c-3.8-0.1-7.4-1.1-10.7-3c-2.1-1.1-3.6-3.1-4.2-5.3v6.9C1045.4,91.3,1046.7,94,1050,96" />
      <path class="stel2" d="M1059.3,99.9L1059.3,99.9c-3.6-0.2-6.9-1.3-10-3c-3.5-2.2-5.2-5.2-4.6-8.4l0-6.7c0-0.6,0.4-1,1-1.1
   c0.6-0.1,1.1,0.3,1.2,0.9c0.5,2,1.8,3.7,3.7,4.6c3.1,1.8,6.6,2.7,10.2,2.8c1.9,0.1,3.4,1,3.7,2.3c0.2,1-0.2,2-1.1,2.5
   c0.6,0.3,1,0.9,1.1,1.5c0.2,1.1-0.3,2.2-1.3,2.6l-3.2,1.8C1059.7,99.9,1059.5,99.9,1059.3,99.9 M1046.9,86.1v2.6
   c-0.4,2.4,0.9,4.6,3.6,6.3c2.6,1.5,5.5,2.4,8.6,2.7l2.8-1.6c0.3-0.2,0.3-0.3,0.3-0.3c-0.4-0.4-1-0.6-1.6-0.6
   c-0.5,0-0.9-0.4-1.1-0.8c-0.1-0.5,0-1,0.4-1.2l2-1.1c0.3-0.2,0.3-0.3,0.3-0.3c-0.4-0.4-1-0.6-1.6-0.6c-3.9-0.1-7.8-1.1-11.2-3.1
   C1048.5,87.6,1047.6,86.9,1046.9,86.1" />
      <path class="stel0" d="M1093.7,125.9c2.3,1,4.1,2.7,5.3,4.9c0.6-1.4,0.7-3,0.1-4.4c1.2-3.3-0.6-6.9-5.4-9.3c-6.1-3-16-3-21.9,0
   c-3.3,1.7-5.1,3.9-5.5,6.3c-0.2,1.1-2.5,1.6-3.9,0.8l-27-15.6v8.8l27,15.6c1.4,0.8,3.7,0.3,3.9-0.8c0.4-2.3,2.2-4.6,5.5-6.3
   C1077.8,122.9,1087.7,122.8,1093.7,125.9" />
      <path class="stel2" d="M1064,134.5L1064,134.5c-0.7,0-1.5-0.2-2.1-0.5l-27-15.6c-0.3-0.2-0.5-0.6-0.5-0.9v-8.8c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0,0.5,0.1l27,15.6c0.3,0.2,0.7,0.3,1,0.2c0.4,0,0.9-0.1,1.2-0.4c0.4-2.7,2.6-5.2,6.1-7c3.6-1.7,7.5-2.5,11.5-2.4
   c3.9-0.1,7.9,0.7,11.5,2.4c5,2.5,7.3,6.4,6.1,10.3c0.5,1.6,0.4,3.3-0.3,4.8c-0.2,0.4-0.6,0.7-1,0.7c-0.4,0-0.8-0.3-1-0.7
   c-1-2-2.7-3.5-4.8-4.4c-3.3-1.5-6.9-2.2-10.5-2.2c-3.6-0.1-7.2,0.7-10.5,2.2c-2.9,1.5-4.6,3.4-5,5.5
   C1067.1,133.6,1065.7,134.5,1064,134.5 M1036.5,116.8l26.4,15.2c0.3,0.2,0.7,0.2,1,0.2c0.4,0,0.9-0.1,1.2-0.4
   c0.4-2.7,2.6-5.2,6.1-7c3.6-1.7,7.5-2.5,11.5-2.4c4-0.1,7.9,0.7,11.5,2.4c1.6,0.8,3,1.8,4.2,3.1c0-0.4-0.1-0.8-0.3-1.2
   c-0.1-0.2-0.1-0.5,0-0.8c1.1-2.9-0.8-5.9-4.9-8c-3.3-1.5-6.9-2.2-10.5-2.2c-3.6-0.1-7.2,0.7-10.5,2.2c-2.9,1.5-4.6,3.4-5,5.5
   c-0.2,1.3-1.6,2.1-3.4,2.1c-0.7,0-1.5-0.2-2.1-0.5l-25.3-14.6V116.8z" />
      <polygon class="stel0" points="1109.6,152.1 1035.4,194.9 1035.4,194.9 1035.4,203.7 1035.4,203.7 1109.6,160.9 1109.6,160.9
   1109.6,160.9 	" />
      <path class="stel2" d="M1035.4,204.8c-0.2,0-0.4,0-0.5-0.1c-0.3-0.2-0.5-0.6-0.6-0.9v-8.8c0-0.4,0.2-0.8,0.6-1l74.1-42.8
   c0.5-0.3,1.2-0.1,1.5,0.4c0.1,0.2,0.1,0.4,0.1,0.5v8.7v0.1c0,0.4-0.2,0.8-0.6,1l-74.1,42.8C1035.8,204.8,1035.6,204.8,1035.4,204.8
    M1036.5,195.6v6.3l72-41.6V154L1036.5,195.6z" />
      <path class="stel1" d="M998.6,186.2c2.8-1.6,4.4-3.8,4.6-5.9c0.2-1.4,3.2-2,5-1l27.2,15.7l74.2-42.8h0l-27.2-15.7
   c-1.8-1-0.7-2.8,1.8-2.9c3.7,0,7.4-1.1,10.7-2.9c5.6-3.5,5.6-9-0.1-12.5c-6.2-3.9-16.7-4-23-0.3c-2.8,1.6-4.4,3.8-4.6,5.9
   c-0.2,1.4-3.2,2-5,1l-27.2-15.7v0h0l-26.8,15.5c-1.4,0.8-0.5,2.1,1.4,2.2c3.6,0.1,7.1,1.1,10.2,2.8c6.8,3.9,6.6,10.4-0.8,14.1
   c-6,3-15.9,3-21.9-0.1c-3.3-1.7-5.2-4-5.6-6.3c-0.2-1.1-2.5-1.6-3.9-0.8l-26.8,15.5l27.2,15.7c1.8,1,0.7,2.8-1.8,2.9
   c-3.7,0-7.4,1.1-10.7,2.9c-5.6,3.5-5.6,9,0.1,12.5C981.8,189.7,992.2,189.8,998.6,186.2" />
      <path class="stel2" d="M1035.4,196c-0.2,0-0.4-0.1-0.5-0.1l-27.2-15.7c-0.5-0.3-1-0.4-1.5-0.4c-0.7-0.1-1.3,0.1-1.8,0.6
   c-0.3,2.6-2.1,5-5.1,6.7c-3.6,1.9-7.7,2.9-11.8,2.8c-4.3,0.1-8.6-1-12.4-3.1c-3.1-1.9-4.7-4.5-4.7-7.2c0-2.7,1.7-5.3,4.7-7.2
   c3.4-2,7.3-3,11.2-3.1c1.1,0,1.6-0.5,1.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.2l-27.2-15.7c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4
   l26.8-15.5c0.6-0.4,1.4-0.5,2.1-0.5c1.8,0,3.2,0.9,3.4,2.1c0.4,2.1,2.2,4,5,5.5c3.3,1.5,7,2.3,10.6,2.2c3.6,0.1,7.1-0.6,10.3-2.1
   c3.2-1.6,5.1-3.8,5.1-6.1c0.1-2.2-1.5-4.4-4.4-6c-3-1.6-6.3-2.5-9.7-2.7c-1.4,0.1-2.6-0.7-3.1-2c-0.2-0.9,0.3-1.9,1.2-2.3
   l26.8-15.5c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.5,0,0.8,0.1l27.2,15.7c0.5,0.2,1,0.4,1.6,0.4c0.7,0.1,1.3-0.1,1.8-0.6
   c0.3-2.6,2.1-5,5.1-6.7c7.6-3.9,16.6-3.8,24.1,0.3c3.1,1.9,4.7,4.5,4.7,7.2c0,2.8-1.7,5.3-4.7,7.2c-3.4,2-7.3,3-11.2,3.1
   c-1.1,0-1.6,0.5-1.6,0.6c0.1,0.1,0.2,0.2,0.3,0.2l27.2,15.7c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.1-0.2,0.2-0.3,0.3l-74.3,42.9
   C1035.8,196,1035.6,196,1035.4,196 M1006.1,177.6c0.9,0,1.8,0.2,2.6,0.6l26.7,15.4l72-41.6l-25.6-14.8c-1-0.5-1.6-1.6-1.3-2.6
   c0.3-1.3,1.8-2.2,3.7-2.3c3.6,0,7-1,10.1-2.7c2.4-1.5,3.7-3.4,3.7-5.3c0-2-1.3-3.9-3.7-5.4c-6.8-3.7-15-3.8-21.9-0.3
   c-2.4,1.4-3.9,3.2-4.1,5.1c-0.2,1.5-1.8,2.5-4,2.5c-0.9,0-1.8-0.2-2.6-0.6l-26.7-15.4l-26.2,15.1c0.3,0.1,0.6,0.2,0.9,0.2
   c3.7,0.1,7.4,1.2,10.7,2.9c3.6,2.1,5.6,4.9,5.5,8c-0.1,3.1-2.4,6.1-6.3,8c-7.3,3.2-15.6,3.1-22.9-0.1c-3.5-1.8-5.7-4.3-6.2-7.1
   c-0.4-0.2-0.8-0.4-1.2-0.3c-0.4,0-0.7,0.1-1,0.2l-25.1,14.5l25.6,14.8c1,0.5,1.6,1.6,1.4,2.6c-0.3,1.3-1.8,2.2-3.7,2.3
   c-3.6,0-7,1-10.1,2.7c-2.4,1.5-3.7,3.3-3.7,5.3c0,2,1.3,3.9,3.7,5.4c3.4,1.9,7.3,2.9,11.2,2.8c3.7,0.1,7.4-0.8,10.7-2.5
   c2.4-1.4,3.9-3.2,4.1-5.1C1002.3,178.7,1003.9,177.6,1006.1,177.6" />
      <path class="stel1" d="M1094.7,131.2c6.8-3.9,6.4-10.4-1-14.1c-6.1-3-16-3-21.9,0c-3.3,1.7-5.1,3.9-5.5,6.3c-0.2,1.1-2.5,1.6-3.9,0.8
   l-27-15.6l27.1-15.6c1.8-1,0.6-2.8-1.8-2.9c-3.8-0.1-7.4-1.1-10.7-3c-5.6-3.5-5.7-9.1-0.1-12.5c6.2-3.8,16.6-3.9,23-0.2
   c2.9,1.7,4.4,3.8,4.7,5.9c0.2,1.4,3.3,2.1,5,1l27-15.6l68,39.3c2.1,1.2,2.8,3.9,1.6,6c-0.4,0.7-0.9,1.2-1.6,1.6l-67.6,39l-27-15.6
   c-1.4-0.8-0.5-2.1,1.4-2.2C1088.1,133.8,1091.6,132.9,1094.7,131.2" />
      <path class="stel2" d="M1110.1,152.8c-0.2,0-0.4-0.1-0.5-0.1l-27-15.6c-0.9-0.4-1.4-1.4-1.2-2.3c0.5-1.3,1.7-2.1,3.1-2
   c3.4-0.1,6.7-1,9.7-2.6c2.8-1.6,4.4-3.8,4.3-6c-0.1-2.3-2-4.6-5.2-6.2c-3.3-1.5-6.9-2.2-10.5-2.2c-3.6-0.1-7.2,0.7-10.5,2.2
   c-2.9,1.5-4.6,3.4-5,5.5c-0.2,1.3-1.6,2.1-3.4,2.1c-0.7,0-1.5-0.2-2.1-0.5l-27-15.6c-0.5-0.3-0.7-1-0.4-1.5
   c0.1-0.2,0.2-0.3,0.4-0.4l27.1-15.6c0.3-0.2,0.3-0.3,0.3-0.3c-0.4-0.4-1-0.6-1.6-0.6c-3.9-0.1-7.8-1.1-11.2-3.1
   c-3.1-1.9-4.8-4.5-4.8-7.2c0-2.7,1.7-5.3,4.7-7.2c3.7-2.1,8-3.2,12.2-3.1c4.1-0.1,8.2,0.9,11.9,2.9c3.1,1.8,4.9,4.2,5.2,6.7
   c0.5,0.4,1.2,0.6,1.9,0.6c0.5,0,1.1-0.1,1.5-0.3l27-15.6c0.3-0.2,0.8-0.2,1.1,0l68,39.3c2.6,1.5,3.5,4.9,2,7.5
   c-0.5,0.8-1.2,1.5-2,2l-67.5,39C1110.5,152.8,1110.3,152.8,1110.1,152.8 M1083.7,135.2l26.4,15.2l67-38.7c1.6-0.9,2.1-3,1.2-4.6
   c-0.3-0.5-0.7-0.9-1.2-1.2l-67.5-39l-26.5,15.3c-0.8,0.4-1.7,0.7-2.6,0.6c-1.8,0-3.8-0.8-4-2.5c-0.2-1.9-1.7-3.7-4.1-5.1
   c-3.3-1.8-7-2.7-10.8-2.6c-3.9-0.1-7.7,0.9-11.1,2.8c-2.4,1.5-3.6,3.3-3.6,5.3c0,2,1.3,3.9,3.7,5.4c3.1,1.8,6.6,2.7,10.2,2.8
   c1.9,0.1,3.4,1,3.7,2.3c0.2,1.1-0.3,2.2-1.3,2.6l-25.4,14.7l25.3,14.6c0.3,0.2,0.7,0.3,1,0.2c0.4,0,0.9-0.1,1.2-0.4
   c0.4-2.7,2.6-5.2,6.1-7c3.6-1.7,7.5-2.5,11.5-2.4c3.9-0.1,7.9,0.7,11.5,2.4c4,2,6.3,5,6.4,8.1c0.1,3-1.8,5.9-5.4,7.9
   c-3.3,1.8-6.9,2.8-10.6,2.9C1084.3,135,1084,135.1,1083.7,135.2" />
      <path class="stel0" d="M966.1,593.3l-2-1.1l0.2,0c2.5-0.1,3.6-1.8,1.8-2.9l-27.2-15.7v8.8l24.1,13.9c0.5,0,0.9-0.1,1.4-0.1
   C966.8,596.1,967.9,594.3,966.1,593.3" />
      <path class="stel2" d="M963,597.3c-0.2,0-0.4,0-0.5-0.1l-24.1-13.9c-0.3-0.2-0.5-0.6-0.5-0.9v-8.8c0-0.4,0.2-0.8,0.5-0.9
   c0.3-0.2,0.7-0.2,1.1,0l27.2,15.7c1,0.5,1.6,1.6,1.3,2.7c-0.2,0.6-0.6,1.2-1.1,1.5c0.9,0.5,1.3,1.5,1.1,2.5
   c-0.3,1.3-1.8,2.2-3.7,2.3C963.9,597.3,963.5,597.3,963,597.3L963,597.3z M940,581.7l23.2,13.4c0.4,0,0.7,0,1.1-0.1
   c1.1,0,1.6-0.5,1.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.2l-2-1.1c-0.5-0.3-0.7-0.9-0.4-1.5c0.2-0.4,0.6-0.6,1-0.6c1.2,0,1.7-0.5,1.8-0.6
   c-0.1-0.1-0.2-0.2-0.3-0.2L940,575.4L940,581.7z" />
      <path class="stel0" d="M997.9,551.2c-3.1-1.7-6.6-2.7-10.2-2.8c-1.2-0.1-2-0.6-2.1-1.3h0v8.8h0c0,0.6,0.8,1.2,2.1,1.3
   c3.6,0.1,7.1,1.1,10.2,2.8c1.9,1,3.4,2.6,4.4,4.5c0.6-1.4,0.7-3,0.1-4.4C1003.6,557,1002.1,553.6,997.9,551.2" />
      <path class="stel2" d="M1002.3,565.6L1002.3,565.6c-0.4,0-0.8-0.3-1-0.7c-0.9-1.7-2.2-3.1-3.9-4c-3-1.6-6.3-2.5-9.7-2.6
   c-1.3,0.1-2.5-0.7-3-1.9c-0.1-0.1-0.1-0.3-0.1-0.4v-8.8c0-0.3,0.1-0.6,0.4-0.8c0.5-0.4,1.2-0.3,1.6,0.1c0.1,0.2,0.2,0.4,0.3,0.6
   c0.3,0.2,0.7,0.2,1.1,0.3c3.7,0.2,7.4,1.2,10.7,2.9c4.4,2.5,6.3,6.2,5.1,9.8c0.5,1.6,0.4,3.3-0.3,4.9
   C1003.1,565.3,1002.7,565.6,1002.3,565.6 M986.7,555.9c0.3,0.2,0.7,0.2,1.1,0.3c3.7,0.2,7.4,1.2,10.7,2.9c1.2,0.7,2.3,1.5,3.3,2.6
   c0-0.4-0.1-0.8-0.3-1.2c-0.1-0.2-0.1-0.5,0-0.8c1-2.7-0.5-5.5-4.1-7.5c-3-1.6-6.3-2.5-9.7-2.7c-0.3,0-0.7-0.1-1-0.1v6.3
   C986.7,555.7,986.7,555.8,986.7,555.9L986.7,555.9z" />
      <path class="stel0"
        d="M1040.9,600.6c-0.1,0.3-0.3,0.5-0.6,0.6l-26.8,15.5v8.8l26.8-15.5c0.6-0.3,0.7-0.8,0.6-1.2V600.6z" />
      <path class="stel2" d="M1013.5,626.5c-0.6,0-1.1-0.5-1.1-1.1v-8.8c0-0.4,0.2-0.8,0.5-0.9l26.8-15.5c0.2-0.5,0.7-0.8,1.3-0.7
   c0.5,0.1,0.9,0.5,1,1v8.1c0.2,0.9-0.3,1.9-1.1,2.3l-26.8,15.5C1013.9,626.5,1013.7,626.5,1013.5,626.5 M1014.6,617.3v6.3l25.2-14.5
   l0-6.3L1014.6,617.3z" />
      <path class="stel0"
        d="M986.3,600.9c-1.8-1-4.8-0.4-5,1c-0.2,2.2-1.8,4.3-4.6,5.9c-6.4,3.7-16.8,3.6-23-0.3c-2.7-1.7-4.1-3.9-4.2-6.1
   h0v8.8h0c0.1,2.2,1.5,4.4,4.2,6.1c6.2,3.9,16.7,4,23,0.3c2.8-1.6,4.4-3.8,4.6-5.9c0.2-1.4,3.2-2,5-1l27.2,15.7v-8.8L986.3,600.9z" />
      <path class="stel2" d="M1013.5,626.5c-0.2,0-0.4,0-0.5-0.1l-27.2-15.7c-0.5-0.3-1-0.4-1.6-0.4c-0.7-0.1-1.3,0.1-1.8,0.6
   c-0.3,2.6-2.1,5-5.2,6.7c-7.6,3.9-16.6,3.8-24.1-0.3c-2.9-1.8-4.5-4.2-4.7-6.8c0-0.1,0-0.2,0-0.3v-8.8c0-0.3,0.1-0.6,0.4-0.8
   c0.2-0.2,0.6-0.3,0.9-0.2c0.5,0.1,0.9,0.5,0.9,1c0.1,1.9,1.4,3.8,3.7,5.2c6.8,3.7,15,3.8,21.9,0.3c2.4-1.4,3.9-3.2,4.1-5.1
   c0.2-1.5,1.8-2.5,4-2.5c0.9,0,1.8,0.2,2.6,0.7l27.2,15.7c0.3,0.2,0.5,0.6,0.5,0.9v8.8C1014.6,626,1014.2,626.5,1013.5,626.5
   L1013.5,626.5z M984.2,608.1c0.9,0,1.8,0.2,2.6,0.7l25.6,14.8v-6.3l-26.7-15.4c-0.5-0.3-1-0.4-1.6-0.4c-0.7-0.1-1.3,0.1-1.8,0.6
   c-0.3,2.6-2.1,5-5.2,6.7c-7.6,3.9-16.6,3.8-24.1-0.3c-1-0.6-1.8-1.3-2.6-2.1v3.6c0,0.1,0,0.2,0,0.2c0.1,1.9,1.4,3.8,3.7,5.2
   c6.8,3.7,15,3.8,21.9,0.3c2.4-1.4,3.9-3.2,4.1-5.1C980.4,609.2,982.1,608.1,984.2,608.1L984.2,608.1z" />
      <path class="stel0" d="M1060.9,589.3c-1.4,0.8-3.7,0.3-3.9-0.8c-0.4-2.1-2-4.2-4.8-5.9c-6.8-3.9-18-3.8-24.4,0.5
   c-3.3,2.2-4.5,5.4-3.5,8.3c-0.5,1.4-0.5,3,0.1,4.4c0.7-1.6,1.9-2.9,3.4-3.9c6.4-4.3,17.6-4.4,24.4-0.5c2.9,1.6,4.5,3.7,4.8,5.9
   c0.2,1.1,2.5,1.6,3.9,0.8l26.8-15.5v-8.8L1060.9,589.3z" />
      <path class="stel2" d="M1059.4,599.6c-1.8,0-3.2-0.9-3.4-2.1c-0.3-1.9-1.9-3.7-4.3-5.1c-3.5-1.9-7.4-2.8-11.3-2.7
   c-4.2-0.1-8.3,1-11.9,3.1c-1.3,0.8-2.3,2-3,3.4c-0.2,0.4-0.6,0.7-1,0.7c-0.4,0-0.8-0.3-1-0.7c-0.6-1.5-0.7-3.2-0.3-4.8
   c-1-3.4,0.6-6.8,4-9.2c4-2.4,8.5-3.6,13.2-3.5c4.3-0.1,8.6,0.9,12.4,3c3,1.7,4.9,4.1,5.4,6.6c0.4,0.2,0.8,0.3,1.2,0.3
   c0.4,0,0.7-0.1,1-0.2l26.8-15.5c0.5-0.3,1.2-0.1,1.5,0.4c0.1,0.2,0.1,0.4,0.1,0.5v8.8c0,0.4-0.2,0.8-0.5,0.9l-26.8,15.5
   C1060.8,599.4,1060.1,599.6,1059.4,599.6 M1040.4,587.4c4.3-0.1,8.6,0.9,12.4,3c3,1.7,4.9,4.1,5.4,6.6c0.4,0.2,0.8,0.3,1.2,0.3
   c0.4,0,0.7-0.1,1-0.2l26.3-15.2v-6.3l-25.2,14.5c-0.6,0.4-1.4,0.5-2.1,0.5c-1.8,0-3.2-0.9-3.4-2.1c-0.3-1.9-1.9-3.7-4.3-5.1
   c-3.5-1.9-7.4-2.8-11.3-2.7c-4.2-0.1-8.3,1-11.9,3.1c-2.8,1.9-3.9,4.5-3.1,7c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.3-0.2,0.7-0.2,1.1
   c0.6-0.7,1.3-1.3,2.1-1.8C1031.2,588.5,1035.7,587.3,1040.4,587.4" />
      <path class="stel1" d="M986.3,546.2c-1.4,0.8-0.5,2.1,1.4,2.2c3.6,0.1,7,1.1,10.2,2.8c6.8,3.9,6.6,10.4-0.8,14.1
   c-6,3-15.9,3-21.9-0.1c-3.3-1.7-5.2-4-5.6-6.3c-0.2-1.1-2.5-1.6-3.9-0.8l-26.8,15.5l27.2,15.7c1.8,1,0.7,2.8-1.8,2.9
   c-3.7,0.1-7.4,1.1-10.7,2.9c-5.6,3.5-5.6,9,0.1,12.5c6.2,3.9,16.7,4,23,0.3c2.8-1.6,4.4-3.8,4.6-5.9c0.2-1.4,3.2-2,5-1l27.2,15.7
   l26.8-15.5c1.4-0.8,0.5-2.1-1.5-2.2c-3.8-0.1-7.6-1.3-10.9-3.2c-5.3-3.5-5.4-9.2-0.2-12.7c6.4-4.3,17.6-4.4,24.4-0.5
   c2.9,1.6,4.5,3.7,4.8,5.9c0.2,1.1,2.5,1.6,3.9,0.8l26.8-15.5l-74.7-43.1L986.3,546.2z" />
      <path class="stel2" d="M1013.5,617.7c-0.2,0-0.4-0.1-0.5-0.1l-27.2-15.7c-0.5-0.3-1-0.4-1.6-0.4c-0.7-0.1-1.3,0.1-1.8,0.6
   c-0.3,2.6-2.1,5-5.2,6.7c-7.6,3.9-16.6,3.8-24.1-0.3c-3.1-1.9-4.7-4.5-4.7-7.2s1.7-5.3,4.7-7.2c3.4-2,7.3-3,11.2-3.1
   c1.1,0,1.6-0.5,1.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.2l-27.2-15.7c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l26.8-15.5
   c0.6-0.4,1.4-0.5,2.1-0.5c1.8,0,3.2,0.9,3.4,2.1c0.4,2.1,2.1,4,5,5.5c3.3,1.5,7,2.3,10.6,2.2c3.6,0.1,7.1-0.6,10.3-2.1
   c3.2-1.6,5.1-3.8,5.1-6.1c0.1-2.2-1.5-4.4-4.4-6c-3-1.6-6.3-2.5-9.7-2.7c-1.4,0.1-2.6-0.7-3.1-2c-0.2-0.9,0.3-1.9,1.2-2.3
   l26.8-15.5c0.3-0.2,0.7-0.2,1.1,0l74.7,43.1c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-26.8,15.5c-0.6,0.4-1.4,0.5-2.1,0.5
   c-1.8,0-3.2-0.9-3.4-2.1c-0.3-1.9-1.9-3.7-4.3-5.1c-3.5-1.9-7.4-2.8-11.3-2.7c-4.2-0.1-8.3,1-11.9,3.1c-2,1.1-3.2,3.1-3.4,5.4
   c0,2.1,1.3,4,3.5,5.5c3.1,1.9,6.7,2.9,10.3,3.1c1.4-0.1,2.6,0.7,3.1,2c0.2,0.9-0.3,1.9-1.2,2.3l-26.8,15.5
   C1013.9,617.7,1013.7,617.7,1013.5,617.7 M984.3,599.3c0.9,0,1.8,0.2,2.6,0.6l26.7,15.4l26.2-15.1c-0.3-0.1-0.6-0.2-0.9-0.2
   c-4-0.2-8-1.3-11.4-3.4c-2.9-1.9-4.5-4.5-4.5-7.3c0.1-3,1.8-5.7,4.3-7.2c4-2.4,8.5-3.6,13.2-3.5c4.3-0.1,8.6,0.9,12.4,3
   c3,1.7,4.9,4.1,5.4,6.6c0.4,0.2,0.8,0.3,1.2,0.3c0.4,0,0.7-0.1,1-0.2l25.2-14.5l-72.5-41.8l-26.2,15.1c0.3,0.1,0.6,0.2,0.9,0.2
   c3.7,0.2,7.4,1.2,10.7,2.9c3.6,2.1,5.6,4.9,5.5,8c-0.1,3.1-2.4,6.1-6.3,8c-7.3,3.2-15.6,3.1-22.9-0.1c-3.5-1.8-5.7-4.3-6.2-7.1
   c-0.4-0.2-0.8-0.4-1.2-0.3c-0.4,0-0.7,0.1-1,0.2l-25.2,14.5l25.6,14.8c1,0.5,1.6,1.6,1.3,2.7c-0.3,1.3-1.8,2.2-3.7,2.3
   c-3.6,0-7,1-10.1,2.7c-2.4,1.5-3.7,3.4-3.7,5.3c0,2,1.3,3.9,3.7,5.4c6.8,3.7,15,3.8,21.9,0.3c2.4-1.4,3.9-3.2,4.1-5.1
   C980.4,600.4,982.1,599.3,984.3,599.3" />
      <path class="stel0" d="M1792.7,134.3c0-14.1-10-31.4-22.3-38.5c-2.7-1.7-5.8-2.7-9.1-3c0-0.2,0-0.4,0-0.6c0-27.4-19.3-60.8-43.2-74.5
   c-13.4-7.7-25.1-6.7-33.3-1.2l-13.8,7.9c-7.8-4-14.8-4-19.7-0.6l-16.4,9.6c5-2.2,11.6-1.5,18.8,2.7c1.6,0.9,3.1,1.9,4.5,3.1
   c5.6-16.6,21.9-21.6,41.1-10.5c23.9,13.8,43.2,47.2,43.2,74.5c0,0.2,0,0.4,0,0.6c3.2,0.3,6.3,1.3,9.1,3
   c12.3,7.1,22.3,24.4,22.3,38.5c0,6-1.8,10.5-4.8,13.1l16.3-9.2l0,0C1789.9,146.9,1792.7,141.8,1792.7,134.3" />
      <path class="stel2" d="M1769.2,159.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.6,0.4-0.8c2.9-2.5,4.4-6.8,4.4-12.3
   c0-13.8-9.8-30.6-21.8-37.6c-2.6-1.6-5.5-2.6-8.6-2.9c-0.6,0-1-0.5-1-1.1c0,0,0-0.3,0-0.3c0-27.2-19.1-60.2-42.7-73.8
   c-6.6-3.8-13-5.8-18.8-5.8c-9.9,0-17.2,5.6-20.7,15.7c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7,0-1-0.2c-1.4-1.1-2.9-2.1-4.4-3
   c-6.6-3.8-13-4.8-17.9-2.6c-0.6,0.2-1.2,0-1.4-0.6c-0.2-0.5,0-1.1,0.4-1.4l16.4-9.6c5.1-3.5,12.5-3.5,20.2,0.3l13.3-7.6
   c4.5-3,9.7-4.5,15.1-4.5c6.8,0.2,13.4,2.1,19.2,5.7c24,13.9,43.6,47.4,43.8,75.1c3,0.4,5.9,1.5,8.5,3.1c12.6,7.3,22.9,25,22.9,39.4
   c0,7.5-2.8,13.1-7.6,15.5c-0.1,0.1-0.1,0.1-0.2,0.1l-16.3,9.2C1769.6,159.3,1769.4,159.4,1769.2,159.4 M1743.7,102.7
   c3,0.4,5.9,1.5,8.5,3.1c12.6,7.3,22.9,25,22.9,39.4c0.1,3.3-0.6,6.6-1.9,9.6l11.5-6.5c0.1-0.1,0.2-0.2,0.3-0.2
   c4.2-2,6.6-7,6.6-13.7c0-13.8-9.8-30.6-21.8-37.6c-2.6-1.6-5.5-2.6-8.6-2.9c-0.6,0-1-0.5-1-1.1l0-0.4c0-27.2-19.1-60.2-42.7-73.8
   c-11.7-6.8-23.3-7.2-32.1-1.2l-7.4,4.2c6.7-0.5,14.2,1.5,21.9,6C1724,41.5,1743.5,75.1,1743.7,102.7 M1641.1,31.1
   c4.7,0.2,9.3,1.6,13.3,4.1c1.2,0.7,2.3,1.4,3.4,2.3c2-5.5,5.8-10.1,10.8-13c-6.4-2.8-12.3-2.7-16.6,0.2L1641.1,31.1z" />
      <path class="stel1" d="M1751.7,106.7c-2.7-1.7-5.8-2.7-9.1-3c0-0.2,0-0.4,0-0.6c0-27.4-19.3-60.8-43.2-74.5
   c-19.2-11.1-35.4-6.1-41.1,10.5c-1.4-1.1-2.9-2.1-4.5-3.1c-15.7-9.1-28.5-1.8-28.5,16.2c0,18.1,12.8,40.1,28.5,49.1l97.8,56.5
   c12.3,7.1,22.3,1.4,22.3-12.7S1764,113.8,1751.7,106.7" />
      <path class="stel2" d="M1761.7,162.1L1761.7,162.1c-3.7-0.1-7.4-1.2-10.6-3.2l-97.8-56.5c-16-9.2-29-31.7-29-50.1
   c0-12.9,6.6-21.2,16.8-21.2c4.7,0.2,9.3,1.6,13.3,4.1c1.2,0.7,2.3,1.4,3.4,2.3c3.9-10.1,12-15.8,22.2-15.8c6.2,0,12.9,2,19.9,6.1
   c24,13.9,43.6,47.4,43.8,75.1c3,0.4,5.9,1.5,8.5,3.1c12.6,7.3,22.9,25,22.9,39.4c0,5.1-1.3,9.4-3.7,12.3
   C1769.1,160.5,1765.5,162.2,1761.7,162.1 M1641,33.2c-9,0-14.6,7.3-14.6,19.1c0,17.7,12.5,39.3,28,48.2l97.8,56.5
   c2.9,1.8,6.1,2.8,9.5,2.9c3.1,0.1,6.1-1.3,8-3.7c2.1-2.6,3.2-6.4,3.2-11c0-13.8-9.8-30.6-21.8-37.6c-2.6-1.6-5.5-2.6-8.6-2.9
   c-0.6,0-1-0.5-1-1.1c0,0,0-0.3,0-0.3c0-27.2-19.1-60.2-42.7-73.8c-6.6-3.8-13-5.8-18.8-5.8c-9.9,0-17.2,5.6-20.7,15.7
   c-0.1,0.3-0.4,0.6-0.7,0.7c-0.3,0.1-0.7,0-1-0.2c-1.4-1.1-2.9-2.1-4.4-3C1649.6,34.7,1645.4,33.4,1641,33.2" />
      <path class="stel0" d="M1722.7,199.8l-66.3-38.3c-6.9-4-6-11,1.9-15.6c8-4.6,20-5.1,27-1.1l66.3,38.3c6.9,4,6,11-1.9,15.6
   C1741.7,203.3,1729.6,203.8,1722.7,199.8" />
      <path class="stel0" d="M569.3,296c55,0,99.6,18.6,99.6,41.5c0,22.9-44.6,41.5-99.6,41.5c-55,0-99.6-18.6-99.6-41.5
   C469.7,314.6,514.3,296,569.3,296" />
      <path class="stel0" d="M642.1,279.1c0-77.9-55-172.8-122.9-212c-32.7-18.9-62.5-21.4-84.5-10.2l-0.1-0.1l-19.1,10.6l8.8,15.2
   c-4.1,11.7-6.4,25.5-6.4,41.2c0,77.9,55,172.8,122.9,212c13.9,8,27.3,13.1,39.8,15.4l9,15.5l12.8-6.9
   C626.9,348.2,642.1,320.1,642.1,279.1" />
      <path class="stel2" d="M589.9,367.8c-0.4,0-0.7-0.2-0.9-0.5l-8.8-15.1c-12.8-2.4-26.1-7.6-39.7-15.4
   C472.4,297.5,417.1,202,417.1,123.9c0-15.3,2.1-29.1,6.2-41.1L414.7,68c-0.3-0.5-0.1-1.2,0.4-1.5c0,0,0,0,0,0l19.1-10.6
   c0.2-0.1,0.4-0.1,0.6-0.1c9.1-4.5,19.2-6.7,29.4-6.6c17.4,0,36.1,5.7,55.7,17c68.1,39.3,123.4,134.8,123.4,212.9
   c0,39.6-14.6,69.4-40.1,81.8l-12.7,6.8C590.2,367.8,590.1,367.8,589.9,367.8 M417.2,67.9l8.3,14.2c0.2,0.3,0.2,0.6,0.1,0.9
   c-4.2,11.9-6.3,25.6-6.3,40.8c0,77.4,54.9,172.1,122.3,211c13.5,7.8,26.8,13,39.5,15.3c0.3,0.1,0.6,0.3,0.7,0.5l8.5,14.6l11.8-6.4
   c24.8-12,38.9-41,38.9-79.8c0-77.4-54.9-172.1-122.3-211c-19.2-11.1-37.6-16.7-54.6-16.7c-10-0.1-19.9,2.1-28.8,6.6
   c-0.2,0.1-0.3,0.1-0.5,0.1L417.2,67.9z" />
      <path class="stel1" d="M414.7,67.9C462.4,40,540.2,84.4,588.5,167c48.3,82.6,48.8,172.2,1.1,200.1C541.9,395,464.1,350.6,415.8,268
   C367.5,185.4,367,95.8,414.7,67.9" />
      <path class="stel2" d="M557.3,376.5c-17.4,0-36.1-5.7-55.7-17c-68.1-39.3-123.4-134.8-123.4-212.9c0-54.3,26.4-88,68.8-88
   c17.4,0,36.1,5.7,55.7,17c68.1,39.3,123.4,134.8,123.4,212.9C626.1,342.8,599.7,376.5,557.3,376.5 M447,60.7
   c-41.1,0-66.6,32.9-66.6,85.9c0,77.4,54.9,172.1,122.3,211c19.2,11.1,37.6,16.7,54.6,16.7c41.1,0,66.6-32.9,66.6-85.9
   c0-77.4-54.9-172.1-122.3-211C482.4,66.3,464,60.7,447,60.7" />
      <path class="stel1" d="M427.5,89.8c40.7-23.8,107.1,14,148.3,84.5c41.2,70.5,41.7,146.9,1,170.7c-40.7,23.8-107.1-14-148.3-84.5
   C387.3,190.1,386.8,113.6,427.5,89.8" />
      <path class="stel2" d="M549.2,353.3c-14.9,0-30.9-4.9-47.6-14.5C443.5,305.2,396.2,223.7,396.2,157c0-46.4,22.6-75.3,58.9-75.3
   c14.9,0,30.9,4.9,47.6,14.5c58.1,33.6,105.4,115.1,105.4,181.8C608.1,324.5,585.5,353.3,549.2,353.3 M455.1,83.9
   c-35,0-56.7,28-56.7,73.1c0,66,46.8,146.7,104.3,179.9c16.4,9.5,32,14.3,46.5,14.3c35,0,56.7-28,56.7-73.1
   c0-66-46.8-146.7-104.3-179.9C485.2,88.6,469.5,83.9,455.1,83.9L455.1,83.9z" />
      <path class="stel0" d="M508.3,328.2c-55.2-31.9-100-109.1-100-172.5c0-31.7,11.2-54,29.4-64.3c-20.5,9.2-33.4,32.3-33.4,66.3
   c0,63.4,44.8,140.6,100,172.5c27.6,15.9,52.5,17.5,70.6,7.3C557.2,345.4,533.9,343,508.3,328.2" />
      <path class="stel2" d="M507.9,220.8c0,3.6-2.6,5.1-5.7,3.3c-3.4-2.2-5.5-5.9-5.7-9.9c0-3.6,2.6-5.1,5.7-3.3
   C505.5,213.1,507.6,216.8,507.9,220.8" />
      <path class="stel2" d="M503.7,115.2L503.7,115.2c-1-0.7-1.6-1.8-1.6-3v-5.3c0-1.2,0.7-1.7,1.6-1.2c1,0.7,1.6,1.8,1.6,3v5.3
   C505.3,115.2,504.6,115.8,503.7,115.2" />
      <path class="stel2" d="M503.7,329.3L503.7,329.3c-1-0.7-1.6-1.8-1.6-3v-5.3c0-1.2,0.7-1.7,1.6-1.2c1,0.7,1.6,1.8,1.6,3v5.3
   C505.3,329.3,504.6,329.8,503.7,329.3" />
      <path class="stel2" d="M414.6,166.1L414.6,166.1c0,1-0.6,1.5-1.4,1l-5.6-3.2c-0.8-0.6-1.4-1.6-1.4-2.6c0-1,0.6-1.5,1.4-1l5.6,3.2
   C414.1,164,414.6,165,414.6,166.1" />
      <path class="stel2" d="M601.2,273.8L601.2,273.8c0,1-0.6,1.5-1.4,1l-5.6-3.2c-0.8-0.6-1.4-1.6-1.4-2.6c0-1,0.6-1.5,1.4-1l5.6,3.2
   C600.7,271.8,601.2,272.7,601.2,273.8" />
      <path class="stel2" d="M459.2,103.2L459.2,103.2c-0.8,0.1-1.7-0.8-2.2-1.9l-2.6-6.6c-0.4-1.1-0.2-2.1,0.6-2.2
   c0.8-0.1,1.7,0.8,2.2,1.9l2.6,6.6C460.2,102.2,459.9,103.2,459.2,103.2" />
      <path class="stel2" d="M552.5,342.5L552.5,342.5c-0.8,0.1-1.7-0.8-2.2-1.9l-2.6-6.6c-0.4-1.1-0.2-2.1,0.6-2.2
   c0.8-0.1,1.7,0.8,2.2,1.9l2.6,6.6C553.5,341.4,553.2,342.4,552.5,342.5" />
      <path class="stel2" d="M426.6,224.1L426.6,224.1c0.4,1.1,0.2,2.1-0.6,2.2l-4.5,0.4c-0.8,0.1-1.7-0.8-2.2-1.9
   c-0.4-1.1-0.2-2.1,0.6-2.2l4.5-0.4C425.2,222.1,426.1,222.9,426.6,224.1" />
      <path class="stel2" d="M588.1,210.3L588.1,210.3c0.4,1.1,0.2,2.1-0.6,2.2l-4.5,0.4c-0.8,0.1-1.7-0.8-2.2-1.9
   c-0.4-1.1-0.2-2.1,0.6-2.2l4.5-0.4C586.7,208.3,587.7,209.2,588.1,210.3" />
      <path class="stel2" d="M426.6,121.8L426.6,121.8c-0.4,0.6-1.4,0.4-2.2-0.6l-4.5-5.6c-0.7-0.7-1-1.9-0.6-2.8c0.4-0.6,1.4-0.4,2.2,0.6
   l4.5,5.6C426.7,119.7,426.9,120.8,426.6,121.8" />
      <path class="stel2" d="M588.1,322.1L588.1,322.1c-0.4,0.6-1.4,0.4-2.2-0.6l-4.5-5.6c-0.7-0.7-1-1.9-0.6-2.8c0.4-0.6,1.4-0.4,2.2,0.6
   l4.5,5.6C588.3,320.1,588.5,321.2,588.1,322.1" />
      <path class="stel2" d="M459.2,280.3L459.2,280.3c0.7,0.7,1,1.9,0.6,2.8l-2.6,3.6c-0.4,0.6-1.4,0.4-2.2-0.6c-0.7-0.7-1-1.9-0.6-2.8
   l2.6-3.6C457.5,279.1,458.4,279.4,459.2,280.3" />
      <path class="stel2" d="M552.5,148.8L552.5,148.8c0.7,0.7,1,1.9,0.6,2.8l-2.6,3.6c-0.4,0.6-1.4,0.4-2.2-0.6c-0.7-0.7-1-1.9-0.6-2.8
   l2.6-3.6C550.7,147.6,551.7,147.8,552.5,148.8" />
      <polygon class="stel2" points="504.9,223.3 499.4,220.1 499.4,131.2 504.9,134.4 	" />
      <polygon class="stel2" points="503.1,216.3 501.4,218.7 576.4,311.6 578.1,309.2 	" />
      <path class="stel2" d="M505,219.2c0,1.8-1.3,2.6-2.9,1.6c-1.7-1.1-2.8-2.9-2.9-4.9c0-1.8,1.3-2.6,2.9-1.6
   C503.8,215.3,504.9,217.1,505,219.2" />
      <polygon class="stel3"
        points="524.2,208.6 521.2,203.4 509,210.3 509.7,211.7 434,254.9 435.4,257.4 511.2,214.2 512,215.5 	" />
      <polygon class="stel0" points="1747.7,498.2 1900.9,383.7 1647.1,274.8 1493.9,389.3 	" />
      <polygon class="stel1" points="1746.2,491.1 1896.4,378.8 1647.5,272 1497.2,384.3 	" />
      <path class="stel2" d="M1746.2,492.2c-0.1,0-0.3,0-0.4-0.1l-249-106.8c-0.6-0.2-0.8-0.9-0.6-1.4c0.1-0.2,0.2-0.3,0.4-0.5l150.3-112.4
   c0.3-0.2,0.7-0.3,1.1-0.1l249,106.8c0.6,0.2,0.8,0.9,0.6,1.4c-0.1,0.2-0.2,0.3-0.3,0.4L1746.8,492
   C1746.6,492.1,1746.4,492.2,1746.2,492.2 M1499.4,384.1L1746,489.9L1894.3,379l-246.7-105.8L1499.4,384.1z" />
      <polygon class="stel1" points="1727.4,489.8 1889.9,396 1655.3,260.5 1492.8,354.3 	" />
      <path class="stel2" d="M1727.4,490.9c-0.2,0-0.4-0.1-0.5-0.1l-234.6-135.5c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4
   l162.5-93.8c0.3-0.2,0.8-0.2,1.1,0L1890.4,395c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-162.5,93.8
   C1727.8,490.8,1727.6,490.9,1727.4,490.9 M1495,354.3l232.4,134.2l160.3-92.6l-232.4-134.2L1495,354.3z" />
      <path class="stel2" d="M1571.8,347.8l-16.8,9.7l16.7,9.6l16.8-9.7L1571.8,347.8z M1571.7,369.3c-0.4,0-0.8-0.1-1.1-0.3l-16.7-9.6
   c-1-0.6-1.4-1.9-0.8-2.9c0.2-0.3,0.5-0.6,0.8-0.8l16.8-9.7c0.7-0.4,1.5-0.4,2.2,0l16.7,9.6c1,0.6,1.4,1.9,0.8,2.9
   c-0.2,0.3-0.5,0.6-0.8,0.8l-16.8,9.7C1572.4,369.2,1572.1,369.3,1571.7,369.3L1571.7,369.3z" />
      <path class="stel2" d="M1596.4,341.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.8,0.6-1l58.9-34c0.5-0.3,1.2-0.1,1.5,0.4
   c0.3,0.5,0.1,1.2-0.4,1.5l-58.9,34C1596.8,341.8,1596.6,341.9,1596.4,341.9" />
      <polygon class="stel3" points="1578.8,361.8 1576.6,343.8 1572.4,344.3 1573.9,356.6 1551.1,352.2 1550.3,356.3 	" />
      <path class="stel2" d="M1610.4,370.9l-16.8,9.7l16.7,9.6l16.8-9.7L1610.4,370.9z M1610.3,392.3c-0.4,0-0.8-0.1-1.1-0.3l-16.7-9.6
   c-1-0.6-1.4-1.9-0.8-2.9c0.2-0.3,0.5-0.6,0.8-0.8l16.8-9.7c0.7-0.4,1.5-0.4,2.2,0l16.7,9.6c1,0.6,1.4,1.9,0.8,2.9
   c-0.2,0.3-0.5,0.6-0.8,0.8l-16.8,9.7C1611,392.2,1610.7,392.3,1610.3,392.3" />
      <path class="stel2" d="M1635,364.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.8,0.6-1l58.9-34c0.5-0.3,1.2-0.1,1.5,0.4
   c0.3,0.5,0.1,1.2-0.4,1.5l-58.9,34C1635.4,364.9,1635.2,364.9,1635,364.9" />
      <polygon class="stel3" points="1617.4,384.9 1615.2,366.8 1611,367.4 1612.5,379.7 1589.7,375.2 1589,379.3 	" />
      <path class="stel2" d="M1649,393.9l-16.8,9.7l16.7,9.6l16.8-9.7L1649,393.9z M1648.9,415.4c-0.4,0-0.8-0.1-1.1-0.3l-16.7-9.6
   c-1-0.6-1.4-1.9-0.8-2.9c0.2-0.3,0.5-0.6,0.8-0.8l16.8-9.7c0.7-0.4,1.5-0.4,2.2,0l16.7,9.6c1,0.6,1.4,1.9,0.8,2.9
   c-0.2,0.3-0.5,0.6-0.8,0.8l-16.8,9.7C1649.7,415.3,1649.3,415.4,1648.9,415.4L1648.9,415.4z" />
      <path class="stel2" d="M1673.6,388c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.8,0.6-1l58.9-34c0.5-0.3,1.2-0.1,1.5,0.4
   c0.3,0.5,0.1,1.2-0.4,1.5l-58.9,34C1674,387.9,1673.8,388,1673.6,388" />
      <polygon class="stel3" points="1656,407.9 1653.8,389.9 1649.7,390.4 1651.1,402.7 1628.4,398.3 1627.6,402.4 	" />
      <path class="stel2" d="M1687.6,417l-16.8,9.7l16.7,9.6l16.8-9.7L1687.6,417z M1687.5,438.4c-0.4,0-0.7-0.1-1.1-0.3l-16.7-9.6
   c-1-0.6-1.4-1.9-0.8-2.9c0.2-0.3,0.5-0.6,0.8-0.8l16.8-9.7c0.7-0.4,1.5-0.4,2.2,0l16.7,9.6c1,0.6,1.4,1.9,0.8,2.9
   c-0.2,0.3-0.5,0.6-0.8,0.8l-16.8,9.7C1688.3,438.3,1687.9,438.4,1687.5,438.4" />
      <path class="stel2" d="M1712.3,411c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.8,0.6-1l58.9-34c0.5-0.3,1.2-0.1,1.5,0.4
   c0.3,0.5,0.1,1.2-0.4,1.5l-58.9,34C1712.6,411,1712.5,411,1712.3,411" />
      <polygon class="stel3" points="1694.6,431 1692.4,413 1688.3,413.5 1689.8,425.8 1667,421.3 1666.2,425.5 	" />
      <path class="stel2" d="M1726.2,440l-16.8,9.7l16.7,9.6l16.8-9.7L1726.2,440z M1726.1,461.5c-0.4,0-0.7-0.1-1.1-0.3l-16.7-9.6
   c-1-0.6-1.4-1.9-0.8-2.9c0.2-0.3,0.5-0.6,0.8-0.8l16.8-9.7c0.7-0.4,1.5-0.4,2.2,0l16.7,9.6c1,0.6,1.4,1.9,0.8,2.9
   c-0.2,0.3-0.5,0.6-0.8,0.8l-16.8,9.7C1726.9,461.4,1726.5,461.5,1726.1,461.5L1726.1,461.5z" />
      <path class="stel2" d="M1750.9,434.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.8,0.6-1l58.9-34c0.5-0.3,1.2-0.1,1.5,0.4
   c0.3,0.5,0.1,1.2-0.4,1.5l-58.9,34C1751.3,434,1751.1,434.1,1750.9,434.1" />
      <polygon class="stel3" points="1733.2,454 1731,436 1726.9,436.5 1728.4,448.8 1705.6,444.4 1704.8,448.5 	" />
      <path class="stel0" d="M448.9,668.3l234.2-135.7c10.2-5.9,10.4-9-1.4-15.8l-162.9-93.7c-11.8-6.8-14.6-6.2-24.8-0.3L260.9,557.7
   c-10.2,5.9-8.9,11.5,2.9,18.4l158.6,91.6C434.2,674.5,438.7,674.2,448.9,668.3" />
      <path class="stel3" d="M448.7,663.3l99.6-63.8l5.4,0.3c2.5,0.2,5-0.4,7.2-1.7l6.9-4c1.6-0.9,3-2.2,4-3.7l5.4-7.7l103.7-53.9
   c10.1-5.8,10.3-8.9-1.4-15.6L518,420.3c-11.7-6.8-14.4-6.2-24.6-0.3L262.3,553.7c-10.1,5.9-8.9,11.4,2.8,18.2l157.2,90.8
   C434.1,669.4,438.6,669.1,448.7,663.3" />
      <path class="stel2" d="M436.3,668.8L436.3,668.8c-3.9,0-8-1.4-14.5-5.2l-157.2-90.8c-7-4-10.2-7.6-10.2-11.2c0-3.1,2.4-6,7.4-8.9
   l231.1-133.6c4.9-2.9,8.5-4.7,12.4-4.7c3.8,0,7.8,1.8,13.3,5L680,512.2c6.8,3.9,9.5,6.5,9.4,9.3c0,3-3.2,5.4-8,8.2l-103.5,53.8
   l-5.3,7.5c-1.1,1.6-2.6,3-4.4,4l-6.9,4c-2.4,1.4-5.1,2-7.8,1.8l-5-0.3l-99.3,63.6C444.4,667,440.5,668.8,436.3,668.8 M505.2,416.5
   c-3.1,0-6,1.4-11.3,4.4L262.9,554.6c-4.2,2.4-6.3,4.8-6.3,7c0,2.7,3.1,5.8,9.1,9.3l157.2,90.8c6.1,3.5,9.8,4.9,13.4,4.9
   c3.7,0,7.3-1.6,11.9-4.3l99.5-63.8c0.2-0.1,0.4-0.2,0.7-0.2l5.4,0.3c2.3,0.1,4.6-0.4,6.6-1.5l6.9-4c1.5-0.8,2.7-2,3.7-3.4l5.4-7.7
   c0.1-0.1,0.2-0.3,0.4-0.3l103.7-53.9c4.8-2.7,6.9-4.7,6.9-6.3c0-1.8-2.6-4.1-8.4-7.4l-161.4-92.8
   C511.4,417.8,508.2,416.5,505.2,416.5L505.2,416.5z" />
      <path class="stel1"
        d="M560.5,588.1l3.5-2.5c13.3-9.6,27.2-18.4,41.7-26.3l78.7-42.9L507,414.2l-123.8,71.7L560.5,588.1z" />
      <path class="stel2" d="M560.5,589.2c-0.2,0-0.4-0.1-0.5-0.1L382.6,486.9c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l123.8-71.7
   c0.3-0.2,0.8-0.2,1.1,0l177.3,102.2c0.3,0.2,0.5,0.6,0.5,1c0,0.4-0.2,0.8-0.6,0.9l-78.7,42.9c-14.4,7.8-28.3,16.6-41.5,26.2
   l-3.5,2.5C561,589.2,560.8,589.2,560.5,589.2 M385.4,485.9l175.1,100.9l2.9-2.1c13.4-9.6,27.3-18.4,41.8-26.3l77-42L507,415.5
   L385.4,485.9z" />
      <polygon class="stel1" points="436.4,659.8 560.4,588.2 383,485.9 259.2,556.1 259.2,557.7 	" />
      <path class="stel2" d="M436.4,660.9c-0.2,0-0.4-0.1-0.5-0.1L258.7,558.6c-0.3-0.2-0.5-0.6-0.5-1v-1.5c0-0.4,0.2-0.8,0.6-0.9
   L382.5,485c0.3-0.2,0.7-0.2,1.1,0l177.3,102.2c0.3,0.2,0.5,0.6,0.5,0.9c0,0.4-0.2,0.8-0.5,0.9l-124,71.6
   C436.8,660.9,436.6,660.9,436.4,660.9 M260.3,557l176.1,101.5l121.8-70.4L383,487.2l-122.7,69.5L260.3,557z" />
      <path class="stel1"
        d="M436.4,650.1l43.1-27.2c22.5-14.2,46.9-25.1,72.6-32.4l8.3-2.3L383,485.9l-124,61.9L436.4,650.1z" />
      <path class="stel2" d="M436.4,651.2c-0.2,0-0.4-0.1-0.5-0.1L258.5,548.8c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.3-0.3,0.5-0.4l124-61.9
   c0.3-0.2,0.7-0.1,1,0l177.3,102.2c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.4,0.4-0.6,0.5l-8.3,2.3c-25.5,7.2-49.8,18.1-72.3,32.2L437,651
   C436.8,651.2,436.6,651.2,436.4,651.2 M261.4,547.9l175,100.9l42.6-26.9c22.6-14.3,47.1-25.2,72.8-32.5l5.8-1.6L383,487.2
   L261.4,547.9z" />
      <path class="stel1" d="M439.6,643.1l45.9-34.7c18.2-14.3,41-21.6,64.1-20.7l10.7,0.4L383,485.9c-25.4-3.4-51.1,3.5-71.5,18.9
   l-49.2,36L439.6,643.1z" />
      <path class="stel2" d="M439.6,644.2c-0.2,0-0.4-0.1-0.5-0.1L261.7,541.8c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.1,0.2-0.2,0.3-0.3l49.2-36
   c20.7-15.6,46.6-22.4,72.3-19.2c0.1,0,0.3,0.1,0.4,0.1l177.3,102.2c0.4,0.3,0.6,0.8,0.5,1.3c-0.1,0.5-0.6,0.8-1.1,0.8l-10.7-0.4
   c-22.9-0.9-45.4,6.3-63.4,20.4L440.3,644C440.1,644.1,439.9,644.2,439.6,644.2 M264.3,540.8l175.3,101l45.3-34.3
   c18.5-14.4,41.4-21.8,64.8-20.9l6.2,0.3L382.7,487c-25.1-3.1-50.4,3.6-70.5,18.8L264.3,540.8z" />
      <path class="stel2"
        d="M289,544.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-21.5-2.3-43,3.6-60.3,16.7l-36.7,26.8C289.5,544.1,289.3,544.2,289,544.2" />
      <path class="stel2" d="M299.2,549.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c0,0,0,0,0,0c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8
   C299.6,549.9,299.4,549.9,299.2,549.9" />
      <path class="stel2"
        d="M309.3,555.7c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1l0,0c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8C309.7,555.6,309.5,555.7,309.3,555.7" />
      <path class="stel2"
        d="M319.4,561.5c-0.3,0-0.7-0.2-0.9-0.4c-0.4-0.5-0.2-1.2,0.2-1.5l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1l0,0c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8C319.9,561.4,319.6,561.5,319.4,561.5" />
      <path class="stel2"
        d="M329.5,567.3c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.7,0.9,1.3c-0.1,0.5-0.6,0.9-1.1,0.9c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8C330,567.2,329.8,567.3,329.5,567.3" />
      <path class="stel2"
        d="M339.7,573.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8C340.1,573,339.9,573.1,339.7,573.1" />
      <path class="stel2"
        d="M349.8,578.8c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8C350.3,578.8,350,578.8,349.8,578.8" />
      <path class="stel2"
        d="M359.9,584.6c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1l0,0c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8C360.4,584.5,360.2,584.6,359.9,584.6" />
      <path class="stel2" d="M370.1,590.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c0,0,0,0,0,0c-21.5-2.2-43,3.8-60.3,16.7l-36.7,26.8
   C370.5,590.3,370.3,590.4,370.1,590.4" />
      <path class="stel2" d="M380.2,596.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0,1.1,0.5,1,1.2c0,0.6-0.5,1.1-1.2,1c0,0-0.1,0-0.1,0c-21.5-2.2-43,3.7-60.3,16.7L380.8,596
   C380.7,596.1,380.4,596.2,380.2,596.2" />
      <path class="stel2" d="M390.3,602c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-21.5-2.2-43,3.7-60.3,16.7L391,601.7C390.8,601.9,390.6,602,390.3,602" />
      <path class="stel2"
        d="M400.5,607.7c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-21.5-2.2-43,3.7-60.3,16.7l-36.7,26.8C400.9,607.7,400.7,607.7,400.5,607.7" />
      <path class="stel2"
        d="M410.6,613.5c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-21.5-2.2-43,3.7-60.3,16.7l-36.7,26.8C411.1,613.4,410.8,613.5,410.6,613.5" />
      <path class="stel2"
        d="M420.7,619.3c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.2-0.7,0.5-0.9l36.7-26.8c17.7-13.3,39.8-19.4,61.8-17.1
   c0.6,0.1,1,0.6,1,1.2c-0.1,0.6-0.6,1-1.2,1c-21.5-2.3-43,3.7-60.3,16.7l-36.7,26.8C421.2,619.2,421,619.3,420.7,619.3" />
      <path class="stel1" d="M501.1,409.1l-16.4,4.6c-41.3,11.6-77.4,37.1-102.1,72.2L560,588l1.6-2.2c24.9-34.4,60.9-59.2,101.8-70.5
   l15-4.1L501.1,409.1z" />
      <path class="stel2" d="M560,589.1c-0.2,0-0.4,0-0.5-0.1L382.1,486.9c-0.5-0.3-0.7-1-0.4-1.5c0,0,0-0.1,0.1-0.1
   c24.9-35.3,61.1-60.9,102.7-72.6l16.4-4.6c0.3-0.1,0.6,0,0.8,0.1L679,510.2c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.3-0.4,0.4-0.7,0.5
   l-15,4.1c-40.7,11.2-76.4,35.9-101.2,70l-1.6,2.2C560.7,588.9,560.3,589.1,560,589.1 M384.2,485.6l175.4,101l1-1.4
   c25.1-34.6,61.2-59.5,102.4-70.9l12.5-3.4L500.9,410.3l-15.9,4.5C444.4,426.2,408.8,451.2,384.2,485.6" />
      <path class="stel2" d="M407,487.4c-0.2,0-0.5-0.1-0.7-0.2c-0.5-0.4-0.6-1.1-0.2-1.5c0,0,0,0,0,0c22.5-28.3,53.3-48.8,88.1-58.7
   l12.2-3.4c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.8-64.7,30-87,57.9
   C407.6,487.3,407.3,487.4,407,487.4" />
      <path class="stel2"
        d="M417,493.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C417.7,493.1,417.4,493.2,417,493.2" />
      <path class="stel2"
        d="M427.1,499c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C427.8,498.9,427.5,499,427.1,499" />
      <path class="stel2"
        d="M437.2,504.8c-0.6,0-1.1-0.5-1.1-1.1c0-0.2,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C437.9,504.7,437.5,504.8,437.2,504.8" />
      <path class="stel2"
        d="M447.3,510.6c-0.6,0-1.1-0.5-1.1-1.1c0-0.2,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C447.9,510.5,447.6,510.6,447.3,510.6" />
      <path class="stel2" d="M457.4,516.4c-0.2,0-0.5-0.1-0.7-0.2c-0.5-0.4-0.6-1.1-0.2-1.5c0,0,0,0,0,0c22.5-28.3,53.3-48.8,88.1-58.7
   l12.2-3.4c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9
   C458,516.2,457.7,516.4,457.4,516.4" />
      <path class="stel2"
        d="M467.4,522.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C468.1,522,467.8,522.2,467.4,522.2" />
      <path class="stel2"
        d="M477.5,528c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.8-64.7,30-87,57.9C478.2,527.8,477.8,528,477.5,528" />
      <path class="stel2"
        d="M487.6,533.8c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C488.2,533.6,487.9,533.8,487.6,533.8" />
      <path class="stel2"
        d="M497.7,539.6c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.2,0.6-0.2,1.2-0.8,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C498.3,539.4,498,539.6,497.7,539.6" />
      <path class="stel2"
        d="M507.7,545.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.1,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.1-0.7,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C508.4,545.2,508.1,545.4,507.7,545.4" />
      <path class="stel2" d="M517.8,551.1c-0.2,0-0.5-0.1-0.7-0.2c-0.5-0.4-0.6-1.1-0.2-1.5c0,0,0,0,0,0c22.5-28.3,53.3-48.8,88.1-58.7
   l12.2-3.4c0.6-0.2,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.1-0.7,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9
   C518.5,551,518.2,551.1,517.8,551.1" />
      <path class="stel2" d="M527.9,556.9c-0.2,0-0.5-0.1-0.7-0.2c-0.5-0.4-0.6-1.1-0.2-1.5c0,0,0,0,0,0c22.5-28.3,53.3-48.8,88.1-58.7
   l12.2-3.4c0.6-0.1,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.1-0.7,1.3l-12.2,3.4c-34.3,9.8-64.7,30-87,57.9
   C528.5,556.8,528.2,556.9,527.9,556.9" />
      <path class="stel2"
        d="M538,562.7c-0.6,0-1.1-0.5-1.1-1.1c0-0.2,0.1-0.5,0.2-0.7c22.5-28.3,53.3-48.8,88.1-58.7l12.2-3.4
   c0.6-0.2,1.2,0.2,1.3,0.8c0.1,0.6-0.2,1.1-0.7,1.3l-12.2,3.4c-34.3,9.7-64.7,30-87,57.9C538.6,562.6,538.3,562.7,538,562.7" />
      <polygon class="stel3" points="1048.5,361.9 869.8,254.3 713.8,321.8 911.4,441.4 914.6,439.7 	" />
      <path class="stel2" d="M911.4,442.5c-0.2,0-0.4-0.1-0.6-0.2L713.2,322.7c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.3-0.4,0.5-0.4l156-67.5
   c0.3-0.1,0.7-0.1,1,0.1L1049,360.9c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-133.8,77.8l-3.3,1.7
   C911.8,442.4,911.6,442.5,911.4,442.5 M716.2,322l195.3,118.2l2.7-1.4l132.2-76.9L869.7,255.5L716.2,322z" />
      <path class="stel1" d="M918.4,428.5l127.1-73.7L863.2,244.4c-1.8-1.1-4.1-1.1-5.9-0.1l-122.9,71.3L918.4,428.5z" />
      <path class="stel2"
        d="M918.4,429.5c-0.2,0-0.4-0.1-0.6-0.2l-184-112.8c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l122.9-71.3
   c2.2-1.2,4.9-1.2,7,0.1l182.3,110.3c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-127.1,73.7
   C918.8,429.5,918.6,429.5,918.4,429.5 M736.6,315.7l181.8,111.5l125-72.5L862.6,245.4c-1.5-0.9-3.3-0.9-4.8-0.1L736.6,315.7z" />
      <path class="stel1" d="M918.4,420.8l127.1-73.7L863.2,236.8c-1.8-1.1-4.1-1.1-5.9-0.1L734.4,308L918.4,420.8z" />
      <path class="stel2"
        d="M918.4,421.9c-0.2,0-0.4-0.1-0.6-0.2l-184-112.8c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l122.9-71.3
   c2.2-1.2,4.9-1.2,7,0.1l182.3,110.3c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-127.1,73.7
   C918.8,421.8,918.6,421.9,918.4,421.9 M736.6,308l181.8,111.5l125-72.5L862.6,237.7c-1.5-0.9-3.3-0.9-4.8-0.1L736.6,308z" />
      <path class="stel1" d="M918.4,412.7l127.1-73.7L863.2,228.7c-1.8-1.1-4.1-1.1-5.9-0.1l-122.9,71.3L918.4,412.7z" />
      <path class="stel2"
        d="M918.4,413.8c-0.2,0-0.4-0.1-0.6-0.2l-184-112.8c-0.3-0.2-0.5-0.6-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9l122.9-71.3
   c2.2-1.2,4.9-1.2,7,0.1l182.3,110.3c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-127.1,73.7
   C918.8,413.8,918.6,413.8,918.4,413.8 M736.6,299.9l181.8,111.5l125-72.5L862.6,229.6c-1.5-0.9-3.3-0.9-4.8-0.1L736.6,299.9z" />
      <path class="stel1" d="M1048.5,326.1L854.3,208.6c-1.9-1.2-4.3-1.2-6.3-0.1l-130.9,75.9c-5,2.9-8.1,8.2-8.1,14v18.4
   c0,2.5,1.5,4.8,3.8,5.8l196.9,118.9l-2.6-25.3l6.1-11.7L1048.5,326.1z M747.1,335c-5.1-3.3-8.3-8.9-8.7-15c0-5.5,3.9-7.7,8.7-4.9
   c5.1,3.3,8.3,8.9,8.7,15C755.8,335.5,751.9,337.8,747.1,335L747.1,335z" />
      <path class="stel2" d="M909.6,442.7c-0.2,0-0.4-0.1-0.6-0.2L712.2,323.6c-2.6-1.2-4.3-3.8-4.3-6.7v-18.4c0-6.2,3.3-11.9,8.7-15
   l130.9-75.9c2.3-1.3,5.1-1.3,7.4,0.1l194.2,117.5c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-135.2,78.4l-5.8,11.1l2.6,25
   c0.1,0.6-0.4,1.1-1,1.2C909.7,442.7,909.7,442.7,909.6,442.7L909.6,442.7z M851.1,208.8c-0.9,0-1.8,0.2-2.5,0.7l-130.9,75.9
   c-4.7,2.7-7.6,7.7-7.6,13.1v18.4c0,2.1,1.2,4,3.1,4.8l195.1,117.9l-2.4-23.2c0-0.2,0-0.4,0.1-0.6l6.1-11.7c0.1-0.2,0.2-0.3,0.4-0.4
   l133.8-77.6L853.7,209.5C852.9,209.1,852,208.8,851.1,208.8 M751,337.3c-1.6,0-3.1-0.5-4.4-1.3c-5.5-3.5-8.9-9.4-9.2-15.9
   c0-4.4,2.3-7.2,5.9-7.2c1.6,0,3.1,0.5,4.4,1.3c5.5,3.5,8.9,9.4,9.2,15.9C756.9,334.4,754.6,337.3,751,337.3 M743.2,315
   c-2.3,0-3.7,1.9-3.7,5c0.3,5.7,3.3,10.9,8.1,14c1,0.6,2.2,1,3.3,1c2.3,0,3.7-1.9,3.7-5c-0.3-5.7-3.3-10.9-8.1-14
   C745.6,315.4,744.4,315,743.2,315z" />
      <path class="stel0" d="M913.1,404.6l135.4-78.5L854.3,208.6c-1.9-1.2-4.3-1.2-6.3-0.1l-130.9,75.9L913.1,404.6z" />
      <path class="stel2"
        d="M913.1,405.7c-0.2,0-0.4-0.1-0.6-0.2l-196-120.2c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l130.9-75.9
   c2.3-1.3,5.1-1.3,7.4,0.1L1049,325.2c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-135.4,78.5
   C913.4,405.7,913.2,405.7,913.1,405.7 M719.2,284.5l193.9,118.9l133.3-77.3L853.7,209.5c-1.6-0.9-3.6-1-5.2-0.1L719.2,284.5z" />
      <path class="stel1" d="M914.6,439.7c-0.6,0.3-1.3,0.5-1.9,0.5c-2.2,0-3.9-1.8-3.9-3.9v-18.4c0-4.9,2.6-9.4,6.9-11.9l132.8-77.3v-2.7
   l-134.1,77.7c-5,2.9-8.1,8.2-8.1,14v18.4c0,3.6,2.9,6.4,6.4,6.4c1.1,0,2.2-0.3,3.2-0.8l132.6-76.9v-3.1L914.6,439.7z" />
      <path class="stel2" d="M912.7,443.8c-4.2,0-7.5-3.4-7.5-7.5v-18.4c0-6.2,3.3-11.9,8.7-15l134.1-77.7c0.3-0.2,0.8-0.2,1.1,0
   c0.3,0.2,0.5,0.6,0.5,0.9v2.7c0,0.4-0.2,0.7-0.5,0.9L916.2,407c-3.9,2.2-6.3,6.4-6.3,10.9v18.4c0,1.6,1.3,2.8,2.8,2.8
   c0.5,0,1-0.1,1.4-0.4l133.8-77.8c0.3-0.2,0.7-0.2,1.1,0c0.3,0.2,0.5,0.6,0.5,0.9v3.1c0,0.4-0.2,0.7-0.5,0.9l-132.6,76.9
   C915.3,443.5,914,443.8,912.7,443.8 M1047.4,328l-132.4,76.8c-4.7,2.7-7.6,7.7-7.6,13.1v18.4c0,3,2.4,5.3,5.4,5.4
   c0.9,0,1.8-0.3,2.6-0.7l132-76.6v-0.6l-132.2,76.9c-0.8,0.4-1.6,0.7-2.5,0.7c-2.8,0-5-2.2-5-5v-18.4c0-5.3,2.8-10.2,7.4-12.8
   l132.3-76.9L1047.4,328z" />
      <path class="stel2"
        d="M747.1,312.5c-6-3.5-10.9-0.7-10.9,6.2s4.9,15.4,10.9,18.9c6,3.5,10.9,0.7,10.9-6.2S753.2,316,747.1,312.5
    M747.1,335c-5.1-3.3-8.3-8.9-8.7-15c0-5.5,3.9-7.7,8.7-4.9c5.1,3.3,8.3,8.9,8.7,15C755.8,335.5,751.9,337.8,747.1,335L747.1,335z" />
      <polygon class="stel1" points="864,406.3 783.8,357.7 783.8,334.9 864,383.5 	" />
      <path class="stel2" d="M864,407.4c-0.2,0-0.4-0.1-0.6-0.2l-80.2-48.6c-0.3-0.2-0.5-0.6-0.5-0.9v-22.8c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0.1,0.6,0.2l80.2,48.6c0.3,0.2,0.5,0.6,0.5,0.9v22.8c0,0.4-0.2,0.8-0.6,0.9C864.4,407.4,864.2,407.4,864,407.4
    M784.9,357.1l78,47.3v-20.3l-78-47.3V357.1z" />
      <polygon class="stel3" points="844.2,394.3 864,406.3 864,383.5 844.2,371.5 	" />
      <path class="stel2" d="M864,407.4c-0.2,0-0.4-0.1-0.6-0.2l-19.9-12c-0.3-0.2-0.5-0.6-0.5-0.9v-22.8c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0.1,0.6,0.2l19.9,12c0.3,0.2,0.5,0.6,0.5,0.9v22.8c0,0.4-0.2,0.8-0.6,0.9C864.4,407.4,864.2,407.4,864,407.4
    M845.3,393.7l17.7,10.7v-20.3l-17.7-10.7V393.7z" />
      <polygon class="stel1" points="657.2,392.3 642.4,400.9 657.2,409.5 672.1,400.9 	" />
      <path class="stel2" d="M657.2,410.6c-0.2,0-0.4,0-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l14.8-8.6
   c0.3-0.2,0.8-0.2,1.1,0l14.8,8.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-14.8,8.6C657.6,410.5,657.4,410.6,657.2,410.6
    M644.6,400.9l12.6,7.3l12.6-7.3l-12.6-7.3L644.6,400.9z" />
      <polygon class="stel1" points="642.4,433 657.2,441.5 657.2,409.5 642.4,400.9 	" />
      <path class="stel2" d="M657.2,442.6c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.3-0.2-0.5-0.6-0.5-0.9v-32c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0.1,0.5,0.1l14.8,8.6c0.3,0.2,0.5,0.6,0.5,0.9v32C658.3,442.1,657.8,442.6,657.2,442.6L657.2,442.6z M643.5,432.3
   l12.6,7.3v-29.5l-12.6-7.3V432.3z" />
      <polygon class="stel0" points="657.2,441.5 672.1,433 672.1,400.9 657.2,409.5 	" />
      <path class="stel2" d="M657.2,442.6c-0.6,0-1.1-0.5-1.1-1.1v-32.1c0-0.4,0.2-0.8,0.5-0.9l14.8-8.6c0.5-0.3,1.2-0.1,1.5,0.4
   c0.1,0.2,0.1,0.4,0.1,0.5v32c0,0.4-0.2,0.8-0.5,0.9l-14.8,8.6C657.6,442.6,657.4,442.6,657.2,442.6 M658.3,410.1v29.5l12.6-7.3
   v-29.5L658.3,410.1z" />
      <polygon class="stel1" points="657.2,408.7 642.4,400.9 657.2,409.5 672.1,400.9 	" />
      <path class="stel2" d="M657.2,410.6c-0.2,0-0.4,0-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.3-0.5,1-0.7,1.5-0.4l14.3,7.5l14.3-7.5
   c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5l-14.8,8.6C657.6,410.5,657.4,410.6,657.2,410.6" />
      <polygon class="stel1" points="681.6,380.8 666.8,389.4 681.6,398 696.4,389.4 	" />
      <path class="stel2" d="M681.6,399.1c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l14.8-8.6
   c0.3-0.2,0.8-0.2,1.1,0l14.8,8.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-14.8,8.6C682,399,681.8,399.1,681.6,399.1
    M669,389.4l12.6,7.3l12.6-7.3l-12.6-7.3L669,389.4z" />
      <polygon class="stel1" points="666.8,445.9 681.6,454.5 681.6,398 666.8,389.4 	" />
      <path class="stel2" d="M681.6,455.6c-0.2,0-0.4,0-0.5-0.1l-14.8-8.6c-0.3-0.2-0.5-0.6-0.5-0.9v-56.6c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0.1,0.5,0.1l14.8,8.6c0.3,0.2,0.5,0.6,0.5,0.9v56.6C682.7,455.1,682.2,455.6,681.6,455.6L681.6,455.6z M667.9,445.3
   l12.6,7.3v-54l-12.6-7.3V445.3z" />
      <polygon class="stel0" points="681.6,454.5 696.4,446 696.4,389.4 681.6,398 	" />
      <path class="stel2" d="M681.6,455.6c-0.6,0-1.1-0.5-1.1-1.1V398c0-0.4,0.2-0.8,0.5-0.9l14.8-8.6c0.5-0.3,1.2-0.1,1.5,0.4
   c0.1,0.2,0.2,0.4,0.2,0.6v56.6c0,0.4-0.2,0.8-0.5,0.9l-14.8,8.6C682,455.5,681.8,455.6,681.6,455.6 M682.7,398.6v54l12.6-7.3v-54
   L682.7,398.6z" />
      <polygon class="stel1" points="706,371.9 691.2,380.5 706,389.1 720.8,380.5 	" />
      <path class="stel2" d="M706,390.1c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l14.8-8.6
   c0.3-0.2,0.8-0.2,1.1,0l14.8,8.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-14.8,8.6C706.4,390.1,706.2,390.1,706,390.1
    M693.4,380.5l12.6,7.3l12.6-7.3l-12.6-7.3L693.4,380.5z" />
      <polygon class="stel1" points="681.6,397.2 666.8,389.4 681.6,398 696.4,389.4 	" />
      <path class="stel2" d="M681.6,399.1c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.3-0.5,1-0.7,1.5-0.4l14.3,7.5
   l14.3-7.5c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5l-14.8,8.6C682,399,681.8,399.1,681.6,399.1" />
      <polygon class="stel1" points="706,388.2 691.2,380.5 706,389 720.8,380.5 	" />
      <path class="stel2" d="M706,390.1c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.3-0.5,1-0.7,1.5-0.4L706,387l14.3-7.5
   c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5l-14.8,8.6C706.4,390.1,706.2,390.1,706,390.1" />
      <polygon class="stel1" points="691.2,459.3 706,467.9 706,389 691.2,380.5 	" />
      <path class="stel2" d="M706,469c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.3-0.2-0.5-0.6-0.5-0.9v-78.8c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0,0.6,0.1l14.8,8.6c0.3,0.2,0.5,0.6,0.5,0.9v78.8c0,0.4-0.2,0.8-0.5,0.9C706.4,468.9,706.2,469,706,469 M692.2,458.7
   l12.6,7.3v-76.3l-12.6-7.3V458.7z" />
      <polygon class="stel0" points="706,467.9 720.8,459.3 720.8,380.5 706,389 	" />
      <path class="stel2" d="M706,469c-0.2,0-0.4,0-0.5-0.1c-0.3-0.2-0.5-0.6-0.5-0.9V389c0-0.4,0.2-0.8,0.5-0.9l14.8-8.6
   c0.5-0.3,1.2-0.1,1.5,0.4c0.1,0.2,0.2,0.4,0.1,0.6v78.8c0,0.4-0.2,0.8-0.5,0.9l-14.8,8.6C706.4,468.9,706.2,469,706,469
    M707.1,389.7V466l12.6-7.3v-76.3L707.1,389.7z" />
      <polygon class="stel1" points="1724.8,221.5 1710,230.1 1724.8,238.7 1739.6,230.1 	" />
      <path class="stel2" d="M1724.8,239.8c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l14.8-8.6
   c0.3-0.2,0.8-0.2,1.1,0l14.8,8.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-14.8,8.6C1725.1,239.7,1725,239.8,1724.8,239.8
    M1712.1,230.1l12.6,7.3l12.6-7.3l-12.6-7.3L1712.1,230.1z" />
      <polygon class="stel1" points="1709.9,262.1 1724.8,270.7 1724.8,238.7 1709.9,230.1 	" />
      <path class="stel2" d="M1724.8,271.8c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.3-0.2-0.5-0.6-0.5-0.9v-32c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0,0.6,0.1l14.8,8.6c0.3,0.2,0.5,0.6,0.5,0.9v32c0,0.4-0.2,0.7-0.5,0.9C1725.1,271.7,1725,271.8,1724.8,271.8
    M1711,261.5l12.6,7.3v-29.5L1711,232V261.5z" />
      <polygon class="stel0" points="1724.8,270.7 1739.6,262.1 1739.6,230.1 1724.8,238.7 	" />
      <path class="stel2" d="M1724.8,271.8c-0.6,0-1.1-0.5-1.1-1.1v-32.1c0-0.4,0.2-0.8,0.5-0.9l14.8-8.6c0.5-0.3,1.2-0.1,1.5,0.4
   c0.1,0.2,0.2,0.4,0.2,0.6v32c0,0.4-0.2,0.8-0.5,0.9l-14.8,8.6C1725.1,271.7,1725,271.8,1724.8,271.8 M1725.8,239.3v29.5l12.6-7.3
   V232L1725.8,239.3z" />
      <polygon class="stel1" points="1724.8,237.9 1710,230.1 1724.8,238.7 1739.6,230.1 	" />
      <path class="stel2" d="M1724.8,239.8c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.3-0.5,1-0.7,1.5-0.4l14.3,7.5
   l14.3-7.5c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5l-14.8,8.6C1725.2,239.7,1725,239.8,1724.8,239.8" />
      <polygon class="stel1" points="1749.2,210 1734.3,218.6 1749.2,227.2 1764,218.6 	" />
      <path class="stel2" d="M1749.2,228.2c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l14.8-8.6
   c0.3-0.2,0.8-0.2,1.1,0l14.8,8.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-14.8,8.6
   C1749.5,228.2,1749.4,228.2,1749.2,228.2 M1736.5,218.6l12.6,7.3l12.6-7.3l-12.6-7.3L1736.5,218.6z" />
      <polygon class="stel1" points="1734.3,275.1 1749.2,283.7 1749.2,227.1 1734.3,218.6 	" />
      <path class="stel2" d="M1749.2,284.8c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.3-0.2-0.5-0.6-0.5-0.9v-56.6c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0,0.6,0.1l14.8,8.6c0.3,0.2,0.5,0.6,0.5,0.9v56.6c0,0.4-0.2,0.8-0.5,0.9C1749.5,284.7,1749.3,284.8,1749.2,284.8
    M1735.4,274.5l12.6,7.3v-54l-12.6-7.3V274.5z" />
      <polygon class="stel0" points="1749.2,283.7 1764,275.1 1764,218.6 1749.2,227.1 	" />
      <path class="stel2" d="M1749.2,284.8c-0.2,0-0.4-0.1-0.5-0.1c-0.3-0.2-0.5-0.6-0.5-0.9v-56.6c0-0.4,0.2-0.8,0.5-0.9l14.8-8.6
   c0.5-0.3,1.2-0.1,1.5,0.4c0.1,0.2,0.2,0.4,0.1,0.6v56.6c0,0.4-0.2,0.8-0.5,0.9l-14.8,8.6C1749.5,284.7,1749.3,284.8,1749.2,284.8
    M1750.2,227.8v54l12.6-7.3v-54L1750.2,227.8z" />
      <polygon class="stel1" points="1773.5,201.1 1758.7,209.7 1773.5,218.2 1788.3,209.7 	" />
      <path class="stel2" d="M1773.5,219.3c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l14.8-8.6
   c0.3-0.2,0.8-0.2,1.1,0l14.8,8.6c0.5,0.3,0.7,1,0.4,1.5c-0.1,0.2-0.2,0.3-0.4,0.4l-14.8,8.6
   C1773.9,219.3,1773.7,219.3,1773.5,219.3 M1760.9,209.7l12.6,7.3l12.6-7.3l-12.6-7.3L1760.9,209.7z" />
      <polygon class="stel1" points="1749.2,226.3 1734.3,218.6 1749.2,227.2 1764,218.6 	" />
      <path class="stel2" d="M1749.2,228.2c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.3-0.5,1-0.7,1.5-0.4l14.3,7.5
   l14.3-7.5c0.5-0.3,1.2-0.1,1.5,0.5c0.3,0.5,0.1,1.2-0.4,1.4l-14.8,8.6C1749.5,228.2,1749.3,228.2,1749.2,228.2" />
      <polygon class="stel1" points="1773.5,217.4 1758.7,209.7 1773.5,218.2 1788.3,209.7 	" />
      <path class="stel2" d="M1773.5,219.3c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.5-0.3-0.7-1-0.4-1.5c0.3-0.5,1-0.7,1.5-0.4l14.3,7.5
   l14.3-7.5c0.5-0.3,1.2-0.1,1.5,0.4c0.3,0.5,0.1,1.2-0.4,1.5l-14.8,8.6C1773.9,219.3,1773.7,219.3,1773.5,219.3" />
      <polygon class="stel1" points="1758.7,288.5 1773.5,297.1 1773.5,218.2 1758.7,209.7 	" />
      <path class="stel2" d="M1773.5,298.2c-0.2,0-0.4-0.1-0.5-0.1l-14.8-8.6c-0.3-0.2-0.5-0.6-0.5-0.9v-78.8c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.4,0,0.6,0.1l14.8,8.6c0.3,0.2,0.5,0.6,0.5,0.9v78.8c0,0.4-0.2,0.8-0.5,0.9C1773.9,298.1,1773.7,298.2,1773.5,298.2
    M1759.8,287.9l12.6,7.3v-76.3l-12.6-7.3V287.9z" />
      <polygon class="stel0" points="1773.5,297.1 1788.3,288.5 1788.3,209.7 1773.5,218.2 	" />
      <path class="stel2" d="M1773.5,298.2c-0.2,0-0.4-0.1-0.5-0.1c-0.3-0.2-0.5-0.6-0.5-0.9v-78.8c0-0.4,0.2-0.8,0.5-0.9l14.8-8.6
   c0.5-0.3,1.2-0.1,1.5,0.4c0.1,0.2,0.2,0.4,0.1,0.6v78.8c0,0.4-0.2,0.7-0.5,0.9l-14.8,8.6C1773.9,298.1,1773.7,298.2,1773.5,298.2
    M1774.6,218.9v76.3l12.6-7.3v-76.3L1774.6,218.9z" />
      <path class="stel3" d="M1423,687.3v10.3l68.8-39.7c1.7-1,4-0.4,5,1.3c0.3,0.6,0.5,1.2,0.5,1.8v102.2L1366,839.5
   c-3.1,1.8-6.9,1.7-9.9-0.1l-2.8-1.7c-2.6-1.6-4.2-4.4-4.2-7.5V729.4c0-3.5,1.9-6.8,5-8.6l63.5-36.6c1.7-1,4-0.4,5,1.3
   C1422.8,686,1423,686.6,1423,687.3" />
      <path class="stel2" d="M1361.1,841.9c-2,0-4-0.6-5.6-1.6l-2.8-1.7c-2.9-1.8-4.7-5-4.7-8.4V729.4c0-3.9,2.1-7.6,5.5-9.5l63.5-36.6
   c2.3-1.3,5.2-0.5,6.5,1.7c0.4,0.7,0.6,1.6,0.6,2.4v8.4l67.2-38.8c2.3-1.3,5.2-0.5,6.5,1.7c0.4,0.7,0.6,1.5,0.6,2.4v102.2
   c0,0.4-0.2,0.7-0.5,0.9l-131.3,76.3C1364.9,841.4,1363,841.9,1361.1,841.9 M1419.3,684.7c-0.4,0-0.9,0.1-1.3,0.3l-63.5,36.6
   c-2.7,1.6-4.4,4.5-4.4,7.7v100.8c0,2.7,1.4,5.1,3.6,6.5l2.8,1.7c2.7,1.6,6.1,1.7,8.8,0.1l130.8-76V660.9c0-1.4-1.2-2.6-2.6-2.5
   c-0.4,0-0.9,0.1-1.2,0.3l-68.8,39.7c-0.5,0.3-1.2,0.1-1.5-0.4c-0.1-0.2-0.1-0.4-0.1-0.5v-10.3
   C1421.9,685.9,1420.7,684.7,1419.3,684.7" />
      <polygon class="stel1" points="1504,751.9 1355.8,837.4 1355.8,749.4 1504,663.8 	" />
      <path class="stel2" d="M1355.8,838.5c-0.2,0-0.4-0.1-0.5-0.1c-0.3-0.2-0.5-0.6-0.5-0.9v-88.1c0-0.4,0.2-0.8,0.5-0.9l148.2-85.6
   c0.5-0.3,1.2-0.1,1.5,0.4c0.1,0.2,0.2,0.4,0.2,0.6v88.1c0,0.4-0.2,0.8-0.5,0.9l-148.2,85.6C1356.2,838.5,1356,838.5,1355.8,838.5
    M1356.9,750v85.5l146-84.3v-85.5L1356.9,750z" />
      <polygon class="stel0" points="175.6,578.8 127.5,421.2 60.3,442.5 50.6,431 50.6,445.5 118.7,564.7 33.9,553.4 18.6,571.1 7.1,570
   7.1,584.5 182.5,601.5 182.5,587 	" />
      <path class="stel2" d="M182.5,602.6h-0.1L7,585.6c-0.6-0.1-1-0.5-1-1.1V570c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.3,0.8-0.3l11,1.1
   l14.9-17.3c0.2-0.3,0.6-0.4,1-0.4l82.6,11l-67-117.2c-0.1-0.2-0.1-0.4-0.1-0.5V431c0-0.6,0.5-1.1,1.1-1.1c0.3,0,0.7,0.1,0.9,0.4
   l9.2,10.9l66.6-21c0.3-0.1,0.6-0.1,0.8,0.1c0.3,0.1,0.4,0.4,0.5,0.6l47.9,157.4l6.8,8c0.2,0.2,0.3,0.4,0.3,0.7v14.5
   C183.6,602.1,183.1,602.6,182.5,602.6L182.5,602.6z M8.2,583.5l173.2,16.8v-12.9l-6.7-7.9c-0.1-0.1-0.2-0.2-0.2-0.4l-47.7-156.5
   l-66.2,20.9c-0.4,0.1-0.9,0-1.2-0.3l-7.7-9.2v11.2l67.9,118.9c0.2,0.4,0.2,0.8,0,1.1c-0.2,0.3-0.6,0.5-1,0.5l-84.2-11.2l-14.9,17.3
   c-0.2,0.3-0.6,0.4-0.9,0.4l-10.3-1L8.2,583.5z" />
      <polygon class="stel1" points="7.1,570 182.5,587 127.6,406.7 50.6,431 118.7,550.2 33.9,538.9 	" />
      <path class="stel2" d="M182.5,588.1h-0.1L7,571.1c-0.6-0.1-1-0.6-1-1.2c0-0.2,0.1-0.4,0.3-0.6L33,538.2c0.2-0.3,0.6-0.4,1-0.4
   l82.6,11l-67-117.2c-0.3-0.5-0.1-1.2,0.4-1.5c0.1,0,0.1-0.1,0.2-0.1l76.9-24.3c0.3-0.1,0.6-0.1,0.8,0.1c0.3,0.1,0.4,0.4,0.5,0.6
   l54.9,180.3c0.1,0.4,0,0.7-0.2,1C183.1,587.9,182.8,588.1,182.5,588.1 M9.3,569.1l171.7,16.6l-54.1-177.7l-74.6,23.6l67.4,117.9
   c0.2,0.4,0.2,0.8,0,1.2c-0.2,0.3-0.6,0.5-1,0.5l-84.2-11.2L9.3,569.1z" />
      <polygon class="stel1" points="259.9,16.7 65,108.3 83.5,167.9 	" />
      <path class="stel2" d="M83.5,168.9c-0.5,0-0.9-0.3-1-0.8L64,108.6c-0.2-0.5,0.1-1.1,0.6-1.3l194.9-91.6c0.5-0.3,1.2-0.1,1.5,0.5
   c0.2,0.5,0.1,1-0.3,1.4L84.2,168.7C84,168.9,83.8,168.9,83.5,168.9 M66.3,108.9L84,165.9l169-144.8L66.3,108.9z" />
      <polygon class="stel1" points="259.9,16.7 65,108.3 32.5,110.5 13.3,92.7 	" />
      <path class="stel2" d="M32.5,111.6c-0.3,0-0.5-0.1-0.7-0.3L12.6,93.5c-0.3-0.3-0.4-0.7-0.3-1c0.1-0.4,0.4-0.7,0.7-0.8l246.6-76
   c0.6-0.2,1.2,0.1,1.4,0.6c0.2,0.6-0.1,1.2-0.6,1.4L65.5,109.3c-0.1,0.1-0.3,0.1-0.4,0.1L32.5,111.6L32.5,111.6z M15.5,93.2
   l17.5,16.2l31.8-2.2l180.7-84.9L15.5,93.2z" />
      <polygon class="stel0" points="259.9,16.7 102,129.8 83.5,167.9 	" />
      <path class="stel2" d="M83.5,169c-0.6,0-1.1-0.5-1.1-1.1c0-0.2,0-0.3,0.1-0.5l18.5-38.1c0.1-0.2,0.2-0.3,0.3-0.4L259.3,15.8
   c0.5-0.4,1.2-0.3,1.5,0.2c0.3,0.5,0.3,1.1-0.2,1.5L84.2,168.7C84,168.9,83.8,168.9,83.5,169 M102.9,130.5l-16.1,33.1L240.2,32.2
   L102.9,130.5z" />
      <polygon class="stel1" points="259.9,16.7 102,129.8 126.6,156.9 156.9,167.9 	" />
      <path class="stel2" d="M156.9,169c-0.1,0-0.3,0-0.4-0.1l-30.2-11c-0.2-0.1-0.3-0.2-0.4-0.3l-24.6-27.1c-0.4-0.4-0.4-1.1,0.1-1.5
   c0,0,0.1-0.1,0.1-0.1L259.3,15.8c0.5-0.4,1.2-0.2,1.5,0.2c0.3,0.4,0.3,0.9,0,1.3L157.8,168.6C157.6,168.9,157.2,169,156.9,169
    M127.3,156l29.2,10.6l99.1-145.5L103.6,130L127.3,156z" />
      <polygon class="stel0" points="69.6,215.4 106.6,236.9 194.9,166.4 	" />
      <path class="stel0" d="M1571.9,561.3c55.5,0,100.4,18.7,100.4,41.8c0,23.1-45,41.8-100.4,41.8c-55.5,0-100.4-18.7-100.4-41.8
   C1471.5,580,1516.4,561.3,1571.9,561.3" />
      <path class="stel1" d="M1508.2,611.8c-5.1,2.3-7.9,2.6-12,0.3c-4.1-2.3-12.9-4.4-12.6-14.6c0.3-10.2-3.7-15.1-3.8-25.8
   c-0.2-11.4,7-18.5,9.4-22c2.3-3.5,6.7-20.5,14.4-25.2c7.6-4.7,19-9.1,21.1-12.3c2-3.2,22.8-14.1,34-17c11.1-2.9,50.1-4.1,57.4,3.2
   c7.3,7.3,14.4,1.5,16.4,10.8c2,9.4,7.4,39.8-43.2,65.6C1538.6,600.7,1514.6,608.9,1508.2,611.8" />
      <path class="stel2" d="M1501.4,614.8c-2.1,0-4.1-0.7-5.8-1.8c-0.7-0.4-1.4-0.7-2.3-1.1c-4.4-2.1-11.1-5.2-10.8-14.5
   c0.1-4.3-0.6-8.6-1.8-12.8c-1.2-4.2-1.9-8.6-2-13c-0.1-9.9,5-16.6,8.1-20.6c0.6-0.8,1.1-1.4,1.5-2c1-1.8,1.8-3.7,2.5-5.6
   c2.6-6.5,6.5-16.4,12.2-19.9c3-1.8,6.4-3.6,9.8-5.3c4.8-2.4,9.8-5,11-6.7c2.4-3.8,24.1-14.7,34.6-17.5c5.2-1.4,16-2.3,26.7-2.3
   c9.7,0,26.7,0.8,31.8,5.8c2.4,2.3,5.4,3.8,8.7,4.2c3.5,0.7,6.7,1.3,8,7.2c7.2,33-19.8,54.6-43.8,66.8c-40.5,20.7-63.8,30-75,34.5
   c-2.8,1.1-4.7,1.9-6,2.5C1506.4,614,1504,614.7,1501.4,614.8 M1585,494c-10.4,0-21.1,0.9-26.1,2.2c-11.2,3-31.6,13.8-33.3,16.5
   c-1.4,2.2-6.2,4.7-11.8,7.5c-3.3,1.7-6.7,3.4-9.6,5.2c-5.1,3.1-8.9,12.6-11.3,18.9c-0.7,2.1-1.6,4.1-2.7,6
   c-0.4,0.6-0.9,1.3-1.5,2.1c-2.9,3.8-7.8,10.1-7.6,19.2c0.1,4.2,0.8,8.4,1.9,12.5c1.3,4.3,1.9,8.8,1.9,13.4
   c-0.2,7.8,5.2,10.4,9.6,12.4c0.9,0.4,1.7,0.8,2.5,1.2c3.7,2.1,5.7,2.1,11-0.3c1.3-0.6,3.3-1.4,6.1-2.5
   c11.2-4.5,34.5-13.8,74.8-34.4c23.3-11.9,49.6-32.7,42.6-64.4c-1-4.5-3-4.9-6.3-5.5c-3.7-0.5-7.1-2.1-9.8-4.8
   C1612,496,1600.7,494,1585,494L1585,494z" />
      <path class="stel0" d="M1504.2,575c-9.1,9.1-3.7,18.2-3.7,24.6c0,6.4,5.9,17.6,17.6,19.2c11.8,1.6,16.6-3.2,25.1-0.5
   c8.6,2.7,17.1-4.3,17.1-4.3s12.3,3.2,17.6,2.1c5.3-1.1,4.3-3.2,8.6-3.2c4.3,0,17.1-3.2,26.2-12.3c5.5-5.5,10.2-11.8,13.9-18.7
   c5.3-0.2,10.3-2.1,14.4-5.3c6.4-5.3,8.4-22,8.4-30.1c0-8-4.7-13.2-4.7-18s-3.7-15.5-11.8-16c-8-0.5-5.3-6.9-18.2-8
   c-12.8-1.1-27.8-3.7-29.9,1.1c-2.1,4.8-11.8,2.7-17.6,6.9c-5.9,4.3-17.6,9.6-21.4,16c-3.7,6.4-11.2,6.4-17.1,9.1
   c-5.9,2.7-19.2,14.4-18.2,21.4C1511.7,566,1508.5,570.8,1504.2,575" />
      <path class="stel1" d="M1508.2,585.7c-9.9,9.9-4.1,19.9-4.1,27s6.4,19.3,19.3,21.1c12.9,1.8,18.2-3.5,27.5-0.6
   c9.4,2.9,18.7-4.7,18.7-4.7s13.5,3.5,19.3,2.3c5.9-1.2,4.7-3.5,9.4-3.5c4.7,0,18.7-3.5,28.7-13.5c6-6.1,11.2-13,15.2-20.5
   c5.8-0.2,11.3-2.3,15.8-5.9c7-5.9,9.2-24.2,9.2-33c0-8.8-5.1-14.5-5.1-19.8c0-5.3-4.1-17-12.9-17.6c-8.8-0.6-5.9-7.6-19.9-8.8
   c-14.1-1.2-30.5-4.1-32.8,1.2s-12.9,2.9-19.3,7.6c-6.4,4.7-19.3,10.5-23.4,17.6c-4.1,7-12.3,7-18.8,10
   c-6.4,2.9-21.1,15.8-19.9,23.4C1516.4,575.8,1512.9,581.1,1508.2,585.7" />
      <path class="stel2"
        d="M1528.7,635.3L1528.7,635.3c-1.8,0-3.6-0.1-5.4-0.4c-13.1-1.8-20.3-14.2-20.3-22.2c-0.1-1.9-0.3-3.8-0.7-5.6
   c-1.2-6.4-2.7-14.3,5.2-22.1c4-4,7.9-9.1,6.7-16.6c-1.3-8.2,13.8-21.5,20.5-24.6c2-0.9,4.1-1.6,6.2-2.1c4.9-1.4,9.4-2.8,12.1-7.4
   c3-5.2,10.4-9.6,16.8-13.5c2.6-1.6,5.1-3.1,6.9-4.4c3.2-2.3,7.2-3,10.8-3.6c3.8-0.7,7.2-1.2,8.2-3.5c1.1-2.6,4.5-3.7,10.8-3.7
   c4.4,0,9.7,0.5,15.3,1.1c2.6,0.3,5.3,0.5,7.9,0.8c8.2,0.7,11,3.4,13.3,5.6c1.6,2,4,3.1,6.6,3.2c9.9,0.7,13.9,13.4,13.9,18.7
   c0.2,2.4,0.9,4.8,2,7c1.5,3.6,3.1,7.6,3.1,12.8c0,7.2-1.7,27.2-9.6,33.8c-4.5,3.6-10.1,5.7-15.8,6.1c-4.1,7.4-9.2,14.2-15.1,20.2
   c-9.8,9.8-24,13.8-29.5,13.8c-1.3-0.1-2.7,0.3-3.7,1.2c-1.6,1.2-3.5,2-5.5,2.3c-1,0.2-2.1,0.3-3.1,0.3c-5.8,0-14-1.9-16.2-2.5
   c-1.7,1.2-7.6,5.3-14.5,5.3c-1.6,0-3.3-0.2-4.8-0.7c-4.1-1.3-8.1-0.8-12.3,0C1535.2,634.8,1531.9,635.2,1528.7,635.3 M1606.4,507.6
   c-5.1,0-8.1,0.8-8.8,2.4c-1.5,3.4-5.5,4.1-9.8,4.8c-3.5,0.6-7.1,1.2-9.9,3.2c-1.9,1.4-4.4,2.9-7.1,4.5c-6.2,3.8-13.3,8-16.1,12.7
   c-3.1,5.3-8.3,6.9-13.4,8.4c-2,0.5-4,1.2-5.9,2c-6.8,3.1-20.3,15.6-19.3,22.3c1.3,8.5-3,14.1-7.3,18.5c-7,7-5.7,14-4.6,20.2
   c0.4,2,0.7,4,0.8,6c0,5.9,5.6,18.3,18.4,20c1.7,0.2,3.4,0.4,5.1,0.4c3.1,0,6.2-0.4,9.2-1c4.5-0.8,8.9-1.3,13.4,0.1
   c1.3,0.4,2.8,0.6,4.2,0.6c7.2,0,13.5-5.1,13.6-5.1c0.3-0.2,0.6-0.3,1-0.2c0.1,0,9.8,2.5,16.1,2.5c0.9,0,1.8-0.1,2.7-0.2
   c1.7-0.3,3.3-0.9,4.6-2c1.4-1.1,3.2-1.7,5-1.6c3.9,0,17.9-3.1,27.9-13.2c5.9-6,11-12.8,15-20.2c0.2-0.4,0.6-0.6,1-0.6
   c5.5-0.2,10.8-2.2,15.1-5.6c6.4-5.4,8.8-22.6,8.8-32.1c-0.2-4.2-1.2-8.2-3-12c-1.2-2.4-1.9-5.1-2.2-7.8c0-5.3-4.2-16-11.9-16.5
   c-3.1-0.1-5.9-1.5-8-3.8c-2.2-2.1-4.5-4.4-12-5c-2.6-0.2-5.3-0.5-7.9-0.8C1616,508.2,1610.7,507.6,1606.4,507.6L1606.4,507.6z" />
      <path class="stel2" d="M1521.8,596c-0.1,0-0.2,0-0.2,0c-0.6-0.1-1-0.7-0.8-1.3c0,0,0,0,0,0c0,0,0.2-1,0.9-4.8
   c0.8-4.1,4.4-6.9,7.4-8.8c0.9-0.6,1.7-2.5,2.4-4.3c0.9-2.4,1.9-4.8,3.8-5.8c2.9-1.4,9.6-5.1,9.6-5.2c0.5-0.3,1.2-0.1,1.5,0.4
   c0.3,0.5,0.1,1.2-0.4,1.5c-0.3,0.2-6.8,3.8-9.7,5.2c-1.1,0.6-2,2.7-2.7,4.6c-0.9,2.2-1.7,4.3-3.2,5.3c-2.7,1.7-5.9,4.1-6.5,7.4
   c-0.7,3.9-0.9,4.8-0.9,4.8C1522.8,595.7,1522.3,596,1521.8,596" />
      <path class="stel2" d="M1536.4,611.5c-0.6,0-1.1-0.5-1.1-1.1c0-0.5,0.3-0.9,0.8-1.1c1.7-0.6,6.2-2.5,7.3-4.6
   c1.4-2.8,7.7-10.5,15.6-10.7c8.6-0.2,21.6-2.1,23.4-4.8c1.4-2,1.8-3.4,1.5-4.4c-0.4-1.1-1.7-2.1-4-2.9l-1.2-0.4
   c-6.8-2.5-13.2-4.9-12.7-8.6c0.5-3.4,4.5-6.9,4.9-7.3c0.5-0.4,1.1-0.3,1.5,0.1c0.4,0.5,0.3,1.1-0.1,1.5c-1.1,0.9-3.9,3.7-4.2,5.9
   c-0.3,2,6.7,4.6,11.3,6.3l1.2,0.4c3,1.1,4.8,2.5,5.3,4.3c0.7,2.2-0.5,4.4-1.7,6.3c-2.9,4.3-19.6,5.6-25.2,5.8
   c-6.8,0.1-12.5,7.1-13.7,9.5c-1.6,3.2-7.8,5.4-8.5,5.7C1536.6,611.5,1536.5,611.5,1536.4,611.5" />
      <path class="stel2" d="M1569.7,629.6c-0.5,0-0.9-0.3-1-0.8c-0.6-2.2-0.3-4.5,0.7-6.5c1.3-2.3,3.8-3.8,7.3-4.5
   c9.1-1.9,20.1-20.2,20.2-20.4c0.3-0.5,1-0.7,1.5-0.4c0.5,0.3,0.7,1,0.4,1.5c-0.5,0.8-11.6,19.3-21.6,21.4c-2.9,0.6-4.8,1.8-5.8,3.5
   c-0.8,1.5-1,3.1-0.6,4.7c0.2,0.6-0.1,1.2-0.7,1.4C1569.9,629.6,1569.8,629.6,1569.7,629.6L1569.7,629.6z" />
      <path class="stel2" d="M1582.1,617.2c-0.1,0-0.2,0-0.3,0c-0.1,0-3.3-1-5.5-5.4c-1.4-3.1-2.1-6.4-2.1-9.7c0-0.6,0.5-1.1,1.1-1.1
   c0.6,0,1.1,0.5,1.1,1.1c0.1,3,0.7,6,1.9,8.8c1.7,3.5,4.1,4.2,4.2,4.3c0.6,0.1,0.9,0.7,0.8,1.3
   C1583.1,616.9,1582.6,617.2,1582.1,617.2L1582.1,617.2z" />
      <path class="stel2"
        d="M1543.2,633.7c-6.3,0-16.1-8.8-16.8-13c-0.1-1.7-1.1-3.2-2.6-4.1c-3-1.6-7.9,0.1-9.6,0.9
   c-0.5,0.3-1.2,0-1.4-0.5c-0.3-0.5,0-1.2,0.5-1.4c0.3-0.1,7-3.2,11.5-0.8c2.1,1.2,3.5,3.3,3.7,5.7c0.5,3.2,9.6,11.2,14.6,11.2
   c0.1,0,0.2,0,0.3,0c0.6-0.1,1.1,0.4,1.2,1c0,0,0,0,0,0c0,0.6-0.4,1.1-1,1.2c0,0,0,0,0,0C1543.5,633.7,1543.4,633.7,1543.2,633.7" />
      <path class="stel2"
        d="M1557.2,546.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c2.6,0,5.7-1.2,9.1-2.4c6.4-2.4,13.6-5.1,18.3,0.8
   c0.4,0.5,0.3,1.2-0.2,1.5c-0.5,0.4-1.2,0.3-1.5-0.2c-3.7-4.6-9.6-2.4-15.8-0.1C1563.6,544.8,1560.2,546.1,1557.2,546.1" />
      <path class="stel2"
        d="M1606.7,569.4c-0.5,0-1-0.4-1.1-0.9c-0.9-7.5,0-15.1,2.5-22.2c3.4-8.8-2.7-12.8-8.6-16.6l-1.4-0.9
   c-6.7-4.5-7.3-9.6-2.3-19.5c0.3-0.5,0.9-0.8,1.5-0.5c0.5,0.3,0.8,0.9,0.5,1.5c-5,9.9-3.4,13.4,1.6,16.7l1.4,0.9
   c5.7,3.7,13.5,8.8,9.5,19.3c-3.9,10.1-2.4,21-2.3,21.2c0.1,0.6-0.3,1.1-0.9,1.2C1606.8,569.4,1606.7,569.4,1606.7,569.4" />
      <path class="stel2" d="M1595,553.1c-1.2,0-2.5-0.1-3.7-0.2c-0.6-0.1-1-0.6-1-1.2c0.1-0.6,0.6-1,1.2-1c0.1,0,13.7,1.4,16.6-4.6
   c0.3-0.5,0.9-0.8,1.5-0.5c0.5,0.3,0.8,0.9,0.5,1.5C1607.6,552.2,1599.7,553.1,1595,553.1" />
      <path class="stel2" d="M1634,556.1c-0.3,0-0.6-0.1-0.8-0.3c-3.6-3.6-2.6-8.4,3.1-15.5c2.3-2.7,3.1-6.3,2.3-9.8
   c-1.1-4.3-4-7.9-7.9-9.8c-0.5-0.3-0.8-0.9-0.5-1.5c0.3-0.5,0.9-0.8,1.5-0.5c4.6,2.2,7.9,6.4,9.1,11.3c1,4.1,0,8.4-2.7,11.6
   c-6.2,7.8-5.2,10.7-3.3,12.6c0.4,0.4,0.4,1.1,0,1.5C1634.5,556,1634.3,556.1,1634,556.1" />
      <path class="stel2"
        d="M1642.4,594.5c-0.1,0-0.2,0-0.2,0c-4-0.8-6.5-2.8-7.6-5.8c-2.2-6.2,2.6-14.6,5.5-18.2c1.4-1.8,3.1-3.3,4.9-4.7
   c2.4-1.9,3.8-3,4.5-7.7c0.1-0.6,0.6-1,1.2-0.9c0.6,0.1,1,0.6,0.9,1.2c-0.8,5.6-2.8,7.1-5.4,9.1c-1.7,1.2-3.2,2.7-4.5,4.3
   c-3,3.8-6.9,11.2-5.1,16.1c0.8,2.3,2.8,3.8,6,4.4c0.6,0.1,1,0.6,0.9,1.2C1643.4,594.1,1642.9,594.5,1642.4,594.5L1642.4,594.5z" />
      <path class="stel2" d="M1600.2,623.2c-0.1,0-0.1,0-0.2,0c-0.6-0.1-1-0.7-0.9-1.3c0,0,0,0,0,0c0.7-3.7,4.4-6.6,8.3-9.8
   c3.6-2.9,7.4-5.9,9.5-9.8c4.1-7.9-1.2-19.4-1.2-19.5c-0.3-0.5,0-1.2,0.5-1.5s1.2,0,1.5,0.5c0.2,0.5,5.8,12.6,1.2,21.5
   c-2.3,4.3-6.4,7.6-10,10.5c-3.6,2.9-7,5.6-7.5,8.5C1601.2,622.8,1600.8,623.2,1600.2,623.2" />
      <path class="stel2" d="M1498.2,573.8c-0.6,0-1.1-0.5-1.1-1.1c0-0.2,0.1-0.5,0.2-0.7c0-0.1,4.9-6.6,4.2-13.7c-0.2-3.6,0-7.3,0.7-10.8
   c-2.3,3.6-5.5,7-9.3,7c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c5.3,0,10-10.2,11.4-14.1c0.2-0.6,0.8-0.9,1.4-0.7c0,0,0,0,0,0
   c0.6,0.2,0.9,0.8,0.7,1.4c0,0.1-3.5,11.9-2.8,19.3c0.8,8-4.4,15-4.6,15.2C1498.8,573.6,1498.5,573.8,1498.2,573.8" />
      <path class="stel2" d="M1533.9,534.4c-0.1,0-0.2,0-0.4-0.1c-4.1-1.5-5.1-5.7-5.9-9.5c-0.8-3.5-1.4-5.7-3.2-6.1
   c-1.9-0.4-3.1-1.2-3.3-2.5c-0.5-2.2,2.2-4.5,2.8-4.9c0.5-0.4,1.2-0.3,1.5,0.2c0.4,0.5,0.3,1.2-0.2,1.5c-1,0.8-2.1,2.1-2,2.7
   c0,0.2,0.5,0.6,1.7,0.8c3.4,0.7,4.2,4.3,4.9,7.8c0.7,3.4,1.5,6.9,4.5,7.9c0.6,0.2,0.9,0.8,0.7,1.4
   C1534.8,534.1,1534.4,534.4,1533.9,534.4L1533.9,534.4z" />
      <path class="stel2" d="M1556,521.1c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-0.6-1-0.3-1.5l0,0c3.1-4.4,2-7.3,1-9.9c-0.8-2-1.7-4.2,0.4-6.3
   c3.5-3.5,6.8-3.9,9.7-4.2l1-0.1c0.6-0.1,1.1,0.3,1.2,0.9c0,0,0,0,0,0c0.1,0.6-0.3,1.1-0.9,1.2l-1,0.1c-2.8,0.3-5.4,0.6-8.4,3.6
   c-0.9,0.9-0.7,1.8,0.1,3.9c1.1,2.8,2.6,6.6-1.2,12C1556.7,521,1556.4,521.1,1556,521.1" />
      <path class="stel3" d="M1196.3,493l-11.9,13.1c-0.4,0.4-1.1,0.5-1.6,0.1c-0.2-0.2-0.3-0.4-0.3-0.6l-14.3-77l59.9,50.9
   c0.5,0.4,0.5,1.1,0.1,1.6c-0.2,0.2-0.4,0.3-0.6,0.4l-17.1,3.5l29.4,50.7l-14.2,8.1L1196.3,493z" />
      <path class="stel2" d="M1225.7,544.8c-0.4,0-0.7-0.2-0.9-0.5l-28.7-49.4l-10.9,12c-0.9,0.9-2.3,0.8-3.2-0.1c-0.3-0.3-0.5-0.6-0.6-1
   l-14.3-77c-0.1-0.6,0.3-1.2,0.9-1.3c0.3-0.1,0.7,0,0.9,0.2l59.9,50.9c0.9,0.8,1,2.2,0.2,3.1c-0.3,0.4-0.8,0.6-1.2,0.7l-15.6,3.2
   l28.6,49.4c0.1,0.3,0.2,0.6,0.1,0.8c-0.1,0.3-0.3,0.5-0.5,0.7l-14.2,8.1C1226,544.7,1225.8,544.8,1225.7,544.8 M1196.3,491.9h0.1
   c0.3,0,0.7,0.2,0.8,0.5l28.9,49.8l12.3-7l-28.9-49.8c-0.2-0.3-0.2-0.7-0.1-1c0.1-0.3,0.4-0.6,0.8-0.6l17.1-3.5l-57.5-49l13.7,74
   l11.9-13.1C1195.7,492,1196,491.9,1196.3,491.9L1196.3,491.9z" />
      <path class="stel1" d="M780.5,100l-125.1-73c-11.8-6.8-21.4,0.4-21.4,16.2v39.7c0,15.8,9.6,34.1,21.4,40.9l62.7,35.8l16.4,48.2
   l16.4-29.3l29.7,18.3c11.8,6.8,21.4-0.4,21.4-16.2v-39.8C801.9,125.1,792.4,106.8,780.5,100" />
      <path class="stel2" d="M734.5,208.9h-0.1c-0.4,0-0.8-0.3-0.9-0.7l-16.3-47.9l-62.3-35.6c-12.1-7-22-25.8-22-41.8V43.1
   c0-12.1,5.5-19.8,14-19.8c3.2,0.1,6.3,1,9,2.7l125.1,73c12.1,7,21.9,25.8,21.9,41.8v39.7c0,5.9-1.3,10.9-3.8,14.4
   c-2.3,3.4-6.1,5.5-10.2,5.4c-3.2-0.1-6.3-1-9-2.7L751.3,180l-15.8,28.3C735.3,208.7,734.9,208.9,734.5,208.9 M647,25.5
   c-7.2,0-11.8,6.9-11.8,17.7v39.7c0,15.4,9.4,33.3,20.9,39.9l62.7,35.8c0.2,0.1,0.4,0.3,0.5,0.6l15.6,45.9l15.2-27.2
   c0.3-0.5,1-0.7,1.5-0.4c0,0,0,0,0,0l29.7,18.3c2.4,1.5,5.1,2.3,7.9,2.4c3.4,0,6.6-1.7,8.4-4.5c2.2-3.1,3.4-7.7,3.4-13.1v-39.7
   c0-15.4-9.4-33.3-20.9-39.9l-125.1-73C652.5,26.4,649.8,25.6,647,25.5" />
      <polygon class="stel0" points="757.6,182.4 750.9,178.5 734.5,207.8 747.7,200.1 	" />
      <path class="stel2" d="M734.5,208.9c-0.6,0-1.1-0.5-1.1-1.1c0-0.2,0-0.4,0.1-0.5l16.4-29.3c0.3-0.5,1-0.7,1.5-0.4c0,0,0,0,0,0
   l6.7,3.9c0.5,0.3,0.7,1,0.4,1.5l-9.9,17.7c-0.1,0.2-0.2,0.3-0.4,0.4l-13.1,7.7C734.9,208.9,734.7,208.9,734.5,208.9 M751.3,180
   l-13.9,24.8l9.4-5.6l9.2-16.5L751.3,180z" />
      <path class="stel0" d="M791.6,93.5l-125.1-73c-5.1-2.9-9.7-3.2-13.4-1.4l-0.1-0.1l-11.7,6.9l0,0.1c3.8-2.2,8.7-2.1,14.1,1l125.1,73
   c11.8,6.8,21.4,25.1,21.4,40.9v39.7c0,7.7-2.3,13.3-6,16.3l6.5-4.3c5.5-3.1,10.5-8,10.5-18.5v-39.7
   C813,118.6,803.4,100.3,791.6,93.5" />
      <path class="stel2" d="M796,198c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.6,0.4-0.9c3.6-2.9,5.6-8.4,5.6-15.4v-39.7
   c0-15.4-9.4-33.3-20.9-39.9l-125.1-73c-4.8-2.8-9.4-3.2-13-1c-0.5,0.3-1.2,0.1-1.5-0.4c0-0.1-0.1-0.2-0.1-0.2
   c-0.2-0.5,0-1.1,0.4-1.4l11.7-6.9c0.2-0.1,0.4-0.2,0.7-0.1c4-1.8,8.9-1.2,13.9,1.7l125.1,73c12.1,7,21.9,25.8,21.9,41.8v39.7
   c0,11.7-6.3,16.7-11,19.4l-6.5,4.3C796.4,197.9,796.2,198,796,198 M647.6,23.3c3,0.2,5.8,1.1,8.4,2.7l125.1,73
   c12.1,7,21.9,25.8,21.9,41.8v39.7c0.1,4.1-0.7,8.1-2.4,11.9l1.2-0.8c4.7-2.7,10-7.1,10-17.5v-39.7c0-15.4-9.4-33.3-20.9-39.9
   l-125.1-73c-4.5-2.6-8.9-3.1-12.3-1.4c-0.2,0.1-0.3,0.1-0.5,0.1L647.6,23.3z" />
      <path class="stel3" d="M677.3,80c4.1-2.4,10.7,1.4,14.8,8.4c4.1,7,4.2,14.7,0.1,17c-4.1,2.4-10.7-1.4-14.8-8.4
   C673.3,90,673.2,82.4,677.3,80" />
      <path class="stel2" d="M689.5,107.3c-1.9-0.1-3.7-0.6-5.2-1.6c-6.1-3.5-11-12-11-19c0-5.2,2.7-8.5,6.9-8.5c1.9,0.1,3.7,0.6,5.2,1.6
   c6.1,3.5,11,12,11,19C696.3,104,693.6,107.3,689.5,107.3 M680.1,80.4c-2.9,0-4.7,2.4-4.7,6.3c0,6.3,4.4,13.9,9.9,17.1
   c1.2,0.8,2.7,1.2,4.2,1.3c2.9,0,4.7-2.4,4.7-6.3c0-6.3-4.4-13.9-9.9-17.1C683,80.9,681.5,80.5,680.1,80.4" />
      <path class="stel3" d="M728,117.8c0,6.6-4.7,9.3-10.5,6c-5.8-3.3-10.5-11.4-10.5-18c0-6.6,4.7-9.3,10.5-6
   C723.3,103.1,728,111.2,728,117.8" />
      <path class="stel2" d="M722.2,126.3c-1.9-0.1-3.7-0.6-5.2-1.6c-6.1-3.5-11-12-11-19c0-5.2,2.7-8.5,6.9-8.5c1.9,0.1,3.7,0.6,5.2,1.6
   c6.1,3.5,11,12,11,19C729.1,122.9,726.4,126.3,722.2,126.3 M712.8,99.4c-2.9,0-4.7,2.4-4.7,6.3c0,6.3,4.4,14,9.9,17.1
   c1.2,0.8,2.7,1.2,4.2,1.3c2.9,0,4.7-2.4,4.7-6.3c0-6.3-4.4-13.9-9.9-17.1C715.7,99.9,714.3,99.5,712.8,99.4" />
      <path class="stel3" d="M760,136c0,6.6-4.7,9.3-10.5,6c-5.8-3.3-10.5-11.4-10.5-18.1c0-6.6,4.7-9.3,10.5-6
   C755.3,121.3,760,129.3,760,136" />
      <path class="stel2"
        d="M754.2,144.5c-1.9-0.1-3.7-0.6-5.2-1.6c-6.1-3.5-11-12-11-19c0-5.2,2.7-8.5,6.9-8.5c1.9,0.1,3.7,0.6,5.2,1.6
   c6.1,3.5,11,12,11,19C761.1,141.1,758.4,144.5,754.2,144.5 M744.8,117.6c-2.9,0-4.7,2.4-4.7,6.3c0,6.3,4.4,13.9,9.9,17.1
   c1.2,0.8,2.7,1.2,4.2,1.3c2.9,0,4.7-2.4,4.7-6.3c0-6.3-4.4-13.9-9.9-17.1C747.7,118.1,746.3,117.6,744.8,117.6L744.8,117.6z" />
      <path class="stel0" d="M817,430.2c12.5,7.2,23.5,21,29.7,35.9c0.1,0.2,7.1-2.2,9.9-3.3c6.2-2.5,14.1-1.5,22.9,3.5
   c19.5,11.2,35.3,38.5,35.3,60.8c0,40.9-69.9,55-69.9,55s-66.5-88.9-66.5-129.8c0-11.2-4.7-14.6,1.6-18.2c0.5-0.3,13.2-7,13.8-7.3
   C799.9,424.1,808.1,425,817,430.2" />
      <path class="stel2"
        d="M844.8,583.2c-0.3,0-0.7-0.2-0.9-0.4c-2.7-3.6-66.7-89.7-66.7-130.4c0-3.1-0.4-6.2-1.1-9.2
   c-0.9-4.2-1.5-7.2,3.3-10c0.1-0.1,13.1-7,13.9-7.4c6.7-2.9,15.4-1.7,24.3,3.4c12.1,7,23.2,20.3,29.9,35.6c1.7-0.5,6.4-2.2,8.8-3.1
   c6.7-2.7,15.1-1.4,23.8,3.6c19.8,11.4,35.8,39.1,35.8,61.8c0,41.3-67.9,55.5-70.8,56.1C845,583.2,844.9,583.2,844.8,583.2
    M801.2,426.5c-2.4,0-4.8,0.4-7,1.4c-0.6,0.3-13.1,6.9-13.7,7.2c-3.4,1.9-3,3.5-2.2,7.6c0.7,3.2,1.1,6.4,1.1,9.7
   c0,38.4,60.2,120.9,65.9,128.6c7.2-1.6,68.4-16.4,68.4-53.8c0-22-15.6-48.8-34.7-59.9c-8.1-4.7-15.9-5.9-21.9-3.5
   c-1.8,0.7-9.1,3.4-10.2,3.4c-0.5,0-0.9-0.3-1.1-0.7c-6.4-15.3-17.4-28.5-29.3-35.4C811,428,805.9,426.4,801.2,426.5L801.2,426.5z" />
      <path class="stel1" d="M805.2,436.6c13.6,7.8,25.3,23.5,31.2,39.8c5.9-9.5,17.7-11.5,31.2-3.7c19.5,11.2,35.3,38.5,35.3,60.8
   c0,40.9-66.5,53-66.5,53s-66.5-88.9-66.5-129.8C769.9,434.3,785.7,425.3,805.2,436.6" />
      <path class="stel2" d="M836.5,587.5c-0.3,0-0.7-0.2-0.9-0.4c-2.7-3.6-66.7-89.7-66.7-130.4c0-15.8,8.1-26.1,20.5-26.1
   c5.1,0,10.6,1.7,16.4,5c12.9,7.4,24.6,22,31,38.4c3.6-4.7,9.2-7.4,15.2-7.3c5.1,0,10.6,1.7,16.4,5c19.8,11.4,35.8,39.1,35.8,61.8
   c0,41.3-64.7,53.5-67.4,54C836.6,587.5,836.5,587.5,836.5,587.5 M789.4,432.8c-11.3,0-18.4,9.1-18.4,23.9
   c0,38.4,60.3,121,65.9,128.6c6.9-1.4,65-14.4,65-51.8c0-22-15.6-48.8-34.7-59.9c-5.4-3.1-10.5-4.7-15.3-4.7
   c-5.9-0.2-11.5,2.9-14.5,8c-0.2,0.4-0.6,0.6-1,0.5c-0.4,0-0.8-0.3-0.9-0.7c-6-16.7-17.8-31.7-30.8-39.2
   C799.3,434.4,794.1,432.8,789.4,432.8" />
      <path class="stel0" d="M1917.2,239c0,12.7-4.4,21.7-11.5,26c-0.2,0.1-12.2,7.5-12.4,7.7c-7.4,4.1-2.7-5.3-13.9-11.8
   c-22.6-13.1-41-44.7-41-70.7c0-12.8-11.9-13-4.6-17.2c0.2-0.1,13.6-7.8,13.8-7.9c7.4-4,17.5-3.3,28.6,3.2
   C1898.8,181.3,1917.2,213,1917.2,239" />
      <path class="stel2" d="M1890.4,274.8c-2.1,0-2.9-1.8-3.7-3.7c-1.4-3.9-4.2-7.2-7.9-9.2c-22.9-13.2-41.5-45.4-41.5-71.7
   c0.1-4.3-1.7-8.3-4.8-11.2c-1.2-1.3-2.3-2.4-2.1-3.8c0.2-1.1,1-2.1,2.9-3.1c0.1-0.1,13.6-7.8,13.8-7.9c8-4.3,18.6-3.2,29.7,3.2
   c22.9,13.2,41.5,45.4,41.5,71.7c0,12.6-4.3,22.2-12,27l-2.1,1.3c-7.5,4.6-10.2,6.3-10.3,6.4
   C1892.8,274.3,1891.6,274.7,1890.4,274.8 M1857.8,163.7c-3.4,0-6.7,0.8-9.7,2.3c-0.3,0.2-13.5,7.7-13.8,7.9
   c-1.6,0.9-1.8,1.4-1.8,1.6c-0.1,0.4,0.8,1.3,1.5,2c3.6,3.3,5.5,7.9,5.4,12.7c0,25.6,18.1,56.9,40.4,69.8c4.1,2.2,7.2,5.9,8.8,10.2
   c0.8,1.9,1.1,2.4,1.7,2.4c0.8-0.1,1.6-0.4,2.3-0.9c0.3-0.2,6.8-4.2,10.2-6.3l2.1-1.3c7.1-4.3,11-13.2,11-25.1
   c0-25.6-18.1-56.9-40.4-69.8C1869.3,165.6,1863.3,163.7,1857.8,163.7" />
      <path class="stel1" d="M1833.6,173.4c15.9-9.3,41.9,5.5,58,33.1c16.1,27.6,16.3,57.4,0.4,66.7c-15.9,9.3-41.9-5.5-58-33.1
   C1817.9,212.6,1817.7,182.7,1833.6,173.4" />
      <path class="stel2" d="M1881.2,277c-5.9,0-12.3-1.9-18.9-5.8c-22.9-13.2-41.5-45.4-41.5-71.6c0-18.3,9.3-30.1,23.7-30.1
   c5.9,0,12.3,1.9,18.9,5.8c22.9,13.2,41.5,45.4,41.5,71.6C1904.9,265.2,1895.6,277,1881.2,277 M1844.4,171.7
   c-13.3,0-21.5,10.7-21.5,27.9c0,25.6,18.1,56.9,40.4,69.8c6.3,3.6,12.3,5.5,17.9,5.5c13.3,0,21.5-10.7,21.5-27.9
   c0-25.6-18.1-56.9-40.4-69.8C1855.9,173.6,1849.9,171.7,1844.4,171.7" />
      <path class="stel2" d="M1855.4,206.4c0,3.6-2.6,5.1-5.8,3.3c-3.4-2.2-5.5-5.9-5.8-9.9c0-3.7,2.6-5.1,5.8-3.3
   C1853.1,198.7,1855.2,202.4,1855.4,206.4" />
      <path class="stel2" d="M1878.7,219.9c0,3.7-2.6,5.1-5.8,3.3c-3.4-2.2-5.5-5.9-5.8-9.9c0-3.7,2.6-5.1,5.8-3.3
   C1876.4,212.2,1878.5,215.8,1878.7,219.9" />
      <path class="stel2" d="M1839.6,221.5l5.4,0.9c2.5,9.4,9.1,18.6,16.5,22.9c7.2,4.1,13.7,2.8,16.3-3.4l5.3,5.4
   c-3.4,8.2-12.1,10-21.6,4.5C1851.6,246.1,1842.8,234,1839.6,221.5" />
      <path class="stel0" d="M309.6,754.1V735l-14.8,5.9l-133.3-77c-8.7-5-22.8-5-31.4-0.1l-78.5,45.3l-11-8.2v19.3c-0.3,3.5,1.8,7,6.5,9.7
   l88.6,51.1l0.3,51l44-25.4l8.9,5.1c8.7,5,22.7,5,31.4,0.1l83.1-48C307.9,761.1,309.9,757.6,309.6,754.1" />
      <path class="stel2" d="M135.9,833.1c-0.6,0-1.1-0.5-1.1-1.1l-0.3-50.3l-88-50.8c-4.8-2.8-7.3-6.7-7-10.7v-19.2c0-0.6,0.5-1.1,1.1-1.1
   c0.2,0,0.5,0.1,0.7,0.2l10.4,7.8l77.8-44.9c5-2.7,10.5-4,16.1-3.9c5.7-0.1,11.3,1.2,16.3,3.9l132.9,76.7l14.3-5.7
   c0.6-0.2,1.2,0,1.4,0.6c0.1,0.1,0.1,0.3,0.1,0.4v19.1c0.4,4-2.2,7.9-6.9,10.6l-83.1,48c-5,2.7-10.5,4-16.1,3.9
   c-5.7,0.1-11.3-1.2-16.3-3.9l-8.3-4.8L136.5,833C136.3,833,136.1,833.1,135.9,833.1 M41.7,703.1v17.1c-0.3,3.3,1.8,6.4,5.9,8.8
   l88.6,51.1c0.3,0.2,0.5,0.6,0.5,0.9l0.3,49.1l42.4-24.5c0.3-0.2,0.8-0.2,1.1,0l8.9,5.1c4.7,2.5,9.9,3.8,15.2,3.6
   c5.2,0.1,10.4-1.1,15.1-3.6l83.1-48c4-2.3,6.1-5.4,5.8-8.6v-17.6l-13.3,5.3c-0.3,0.1-0.7,0.1-0.9-0.1l-133.4-77
   c-4.7-2.5-9.9-3.8-15.2-3.6c-5.2-0.1-10.4,1.1-15.1,3.6L52.2,710c-0.4,0.2-0.8,0.2-1.2-0.1L41.7,703.1z" />
      <path class="stel1" d="M303.2,725.2l-141.7-81.8c-8.7-5-22.8-5-31.4-0.1l-83.1,48c-8.6,5-8.6,13.1,0.1,18.1l88.6,51.1l0.3,51l44-25.4
   l8.9,5.1c8.7,5,22.7,5,31.4,0.1l83.1-48C311.9,738.3,311.9,730.2,303.2,725.2" />
      <path class="stel2" d="M135.9,812.6c-0.2,0-0.4,0-0.5-0.1c-0.3-0.2-0.5-0.6-0.6-0.9l-0.3-50.3l-88-50.8c-4.6-2.6-7.1-6.2-7.1-10.1
   c0-3.8,2.5-7.3,7-10l83.1-48c5-2.7,10.5-4,16.2-3.9c5.7-0.1,11.3,1.2,16.3,3.9l141.7,81.8c4.6,2.6,7.1,6.2,7.1,10.1
   c0,3.8-2.5,7.3-7,10l-83.1,48c-5,2.7-10.5,4-16.1,3.9c-5.7,0.1-11.3-1.2-16.3-3.9l-8.3-4.8l-43.5,25.1
   C136.3,812.6,136.1,812.6,135.9,812.6 M145.7,640.7c-5.2-0.1-10.4,1.1-15.1,3.6l-83.1,48c-3.8,2.2-5.9,5.1-5.9,8.1
   c0,3,2.1,5.9,6,8.2l88.6,51.1c0.3,0.2,0.5,0.6,0.5,0.9l0.3,49.1l42.4-24.5c0.3-0.2,0.7-0.2,1.1,0l8.9,5.1c4.7,2.5,9.9,3.8,15.2,3.6
   c5.2,0.1,10.4-1.1,15.1-3.6l83.1-48c3.8-2.2,5.9-5.1,5.9-8.1c0-3-2.1-5.9-6-8.2l-141.7-81.8C156.3,641.8,151,640.6,145.7,640.7
   L145.7,640.7z" />
      <path class="stel0" d="M318.9,193.5l32.9,6c5.5,1,6.7,5.2,2,7.1L325.7,218c-1.9,0.8-3,2-2.9,3.3l0.5,19.9c0.1,3.3-6.5,5.2-11.1,3.2
   l-27.7-11.9c-2-0.8-4.3-1-6.4-0.6l-32.6,6.3c-5.5,1.1-10.7-2-8.8-5.1l11-18.8c0.7-1.3,0.3-2.9-1-3.7l-20.7-16
   c-3.5-2.7-0.1-6.5,5.6-6.4l34.4,0.3c2.1,0.1,4.1-0.5,5.8-1.7l19.8-16.2c3.3-2.7,10.6-2,12.3,1.1l10.3,19
   C315.4,192.3,317,193.3,318.9,193.5" />
      <path class="stel0" d="M350.5,201.2v-9.5l-16.9,4.1l-18.4-3.3c-1.7-0.2-3.3-1.1-4.3-2.5l-9.7-17.8c-1.6-3-8.4-3.6-11.5-1.1
   l-18.5,15.1c-1.6,1.1-3.5,1.7-5.4,1.6l-32.2-0.3h-0.2l-6.5-6.5v9.9l0.1,0.1c-0.1,1,0.4,2,1.3,2.5l19.3,14.9c1.2,0.7,1.6,2.2,1,3.5
   l-5.8,9.9l-4.8-0.8v9.2c-0.1,0.3-0.1,0.6,0,0.9v0.2l0,0c0.6,2.1,4.5,3.7,8.5,2.9l30.5-5.9c2-0.4,4.1-0.2,6,0.6l25.9,11.2
   c4.3,1.9,10.5,0.1,10.4-3l-0.5-18.6c0-1.2,1-2.4,2.7-3.1l26.2-10.7C349.9,203.8,350.7,202.5,350.5,201.2" />
      <path class="stel2" d="M312.8,241.9L312.8,241.9c-1.5,0-3-0.3-4.3-0.8l-25.9-11.2c-1.7-0.7-3.6-0.8-5.3-0.5l-30.5,5.9
   c-4.2,0.8-8.6-0.8-9.7-3.4c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.4-0.1-0.9,0-1.3l0-9c0-0.6,0.5-1.1,1.1-1.1c0.1,0,0.1,0,0.2,0l4,0.7
   l5.4-9.3c0.5-0.9-0.1-1.6-0.7-2l-19.4-14.9c-1-0.7-1.6-1.8-1.7-3c-0.1-0.1-0.1-0.3-0.1-0.4V181c0-0.6,0.5-1.1,1.1-1.1
   c0.3,0,0.6,0.1,0.8,0.3l6.2,6.2l31.9,0.3c1.7,0.1,3.3-0.4,4.7-1.3l18.5-15.1c1.7-1.3,3.8-1.9,5.9-1.8c3.3,0,6.2,1.3,7.2,3.2
   l9.7,17.8c0.9,1.1,2.1,1.8,3.5,1.9l18.2,3.3l16.7-4c0.6-0.1,1.2,0.2,1.3,0.8c0,0.1,0,0.2,0,0.3v9.5c0.3,1.7-0.9,3.5-3.4,4.5
   L322,216.4c-1.3,0.5-2.1,1.3-2.1,2.1l0.5,18.6c0,1-0.4,2-1.1,2.7C317.5,241.3,315.2,242.1,312.8,241.9 M279.2,227.1
   c1.5,0,3,0.3,4.3,0.8l25.9,11.2c1.1,0.5,2.3,0.7,3.5,0.7c1.8,0.1,3.5-0.5,4.9-1.6c0.3-0.3,0.5-0.7,0.5-1.1l-0.5-18.6
   c0-1.7,1.2-3.3,3.4-4.2l26.2-10.7c1.4-0.6,2.2-1.4,2-2.2l0-8.3l-15.6,3.7c-0.1,0-0.3,0-0.5,0l-18.4-3.3c-2.1-0.2-3.9-1.3-5.1-3
   l-9.7-17.8c-0.7-1.2-2.9-2.1-5.3-2.1c-1.6-0.1-3.2,0.4-4.6,1.3l-18.5,15.2c-1.7,1.3-3.8,1.9-6,1.8l-32.3-0.3
   c-0.3,0-0.7-0.1-0.9-0.3l-4.7-4.7v6.9c0.1,0.2,0.1,0.4,0.1,0.6c0,0.6,0.4,1.2,0.9,1.5l19.3,14.9c1.7,1,2.2,3.2,1.2,4.9l-5.8,9.9
   c-0.2,0.4-0.7,0.6-1.1,0.5l-3.5-0.6v8c0,0.2-0.1,0.4,0,0.7l0,0.1c0.3,1.1,2.5,2.4,5.4,2.4c0.6,0,1.3-0.1,1.9-0.2l30.5-5.9
   C277.6,227.2,278.4,227.1,279.2,227.1L279.2,227.1z" />
      <path class="stel1" d="M315.1,182.6l30.8,5.6c5.2,0.9,6.3,4.9,1.9,6.7l-26.2,10.7c-1.7,0.7-2.8,1.9-2.7,3.1l0.5,18.6
   c0.1,3.1-6,4.9-10.4,3l-25.9-11.2c-1.9-0.8-4-1-6-0.6l-30.5,5.9c-5.1,1-10-1.8-8.3-4.8l10.2-17.6c0.7-1.2,0.2-2.8-1-3.5l-19.3-14.9
   c-3.2-2.5-0.1-6,5.3-6l32.2,0.3c1.9,0.1,3.8-0.5,5.4-1.6l18.5-15.2c3.1-2.5,9.9-1.9,11.5,1.1l9.7,17.8
   C311.8,181.5,313.4,182.4,315.1,182.6" />
      <path class="stel2" d="M312.8,232.1c-1.5,0-3-0.3-4.3-0.8l-25.9-11.2c-1.7-0.7-3.6-0.8-5.3-0.5l-30.5,5.9c-3.9,0.8-8.1-0.6-9.5-2.9
   c-0.6-1.1-0.6-2.4,0-3.5l10.2-17.6c0.5-0.9-0.1-1.6-0.7-2l-19.3-14.9c-1.4-0.9-2-2.6-1.6-4.2c0.8-2.3,3.7-3.8,7.4-3.8l32.3,0.3
   c1.7,0.1,3.3-0.4,4.7-1.3l18.5-15.2c1.7-1.3,3.8-1.9,5.9-1.8c3.3,0,6.2,1.3,7.2,3.2l9.7,17.8c0.9,1.1,2.1,1.8,3.5,1.9l30.8,5.6
   c3.2,0.6,5.3,2.2,5.5,4.4c0.2,1.8-1.1,3.4-3.4,4.4L322,206.5c-1.3,0.5-2.1,1.3-2.1,2.1l0.5,18.6c0,1-0.4,2-1.1,2.7
   C317.5,231.4,315.2,232.2,312.8,232.1 M279.2,217.3c1.5,0,3,0.3,4.3,0.8l25.9,11.2c2.7,1.2,6.9,0.6,8.4-0.9
   c0.3-0.3,0.5-0.7,0.5-1.1l-0.5-18.6c0-1.7,1.2-3.3,3.4-4.2l26.2-10.7c1.4-0.6,2.1-1.4,2.1-2.1c-0.1-0.9-1.3-2-3.7-2.4l-30.8-5.6
   c-2.1-0.2-3.9-1.3-5.1-3l-9.7-17.8c-0.7-1.2-2.9-2.1-5.3-2.1c-1.6-0.1-3.2,0.4-4.6,1.3l-18.5,15.2c-1.7,1.3-3.8,1.9-6,1.8
   l-32.3-0.3c-3,0-5,1.2-5.4,2.3c-0.2,0.6,0.1,1.2,0.8,1.7l19.3,14.9c1.6,1,2.2,3.2,1.2,4.9l-10.3,17.6c-0.3,0.4-0.3,0.9,0,1.3
   c0.6,1,2.6,2.1,5.3,2.1c0.6,0,1.3-0.1,1.9-0.2l30.5-5.9C277.6,217.3,278.4,217.3,279.2,217.3L279.2,217.3z" />
      <path class="stel0" d="M294.9,439.7l78-45c8.8-5.1,7.7-13.9-2.5-19.8L187.1,269c-10.2-5.9-25.5-6.5-34.3-1.4l-78,45
   c-8.8,5.1-7.7,13.9,2.5,19.8l183.4,105.9C270.8,444.2,286.1,444.8,294.9,439.7" />
      <path class="stel0" d="M291,433.4l74.4-43c3.8-2.2,5.6-5.1,5.7-8.1c0-0.3,0-9,0-9.3c-0.3-3.5-3-1.6-8-4.5l-175-101
   c-9.7-5.6-24.4-6.2-32.7-1.4l-74.4,43c-3.7,2.2-5.6-1.6-5.7,1.4c0,0.3,0,10.1,0,10.4c0.3,3.5,3,7.2,8,10l175,101
   C267.9,437.6,282.6,438.2,291,433.4" />
      <path class="stel2"
        d="M276.7,437.7L276.7,437.7c-6.7,0.1-13.2-1.6-19.1-4.8l-175-101c-5.2-3-8.2-6.8-8.5-10.9c0-0.4,0-10.4,0-10.5
   c0-1.9,0.9-2.3,1.7-2.3c0.4,0,0.8,0.1,1.1,0.2c1.1,0.4,2.4,0.3,3.4-0.2l74.4-43c4.6-2.4,9.6-3.6,14.8-3.4
   c6.7-0.1,13.2,1.6,19.1,4.8l175,101c1.5,0.8,3.1,1.4,4.7,1.7c1.8,0.4,3.5,0.7,3.8,3.6c0,0.4,0,9.3,0,9.4c-0.1,3.5-2.3,6.7-6.2,9
   l-74.4,43C287,436.7,281.9,437.9,276.7,437.7 M76.3,310.6L76.3,310.6c0,0.5,0,9.9,0,10.3c0.3,3.2,3,6.6,7.4,9.2l175,101
   c5.5,3,11.7,4.5,18,4.5c4.8,0.1,9.5-0.9,13.7-3.1l74.4-43c3.3-1.9,5.1-4.4,5.1-7.2c0-0.5,0-8.8,0-9.2c-0.1-1.2-0.3-1.2-2.1-1.6
   c-1.9-0.3-3.7-1-5.4-2l-175-101c-5.5-3-11.7-4.5-18-4.5c-4.8-0.1-9.5,0.9-13.7,3.1l-74.4,43C79.9,311,78,311.1,76.3,310.6" />
      <path class="stel1" d="M291,424.1l74.4-43c8.4-4.8,7.3-13.3-2.4-18.9l-175-101c-9.7-5.6-24.4-6.2-32.7-1.4l-74.4,43
   c-8.4,4.8-7.3,13.3,2.4,18.9l175,101C267.9,428.3,282.6,428.9,291,424.1" />
      <path class="stel2" d="M276.7,428.4L276.7,428.4c-6.7,0.1-13.2-1.6-19.1-4.8l-175-101c-5.4-3.1-8.6-7.4-8.6-11.6
   c0-3.6,2.2-6.8,6.2-9.2l74.4-43c4.5-2.4,9.6-3.6,14.8-3.4c6.7-0.1,13.2,1.6,19.1,4.8l175,101c5.4,3.1,8.6,7.4,8.6,11.6
   c0,3.6-2.2,6.8-6.2,9.2l-74.4,43C287,427.4,281.9,428.6,276.7,428.4 M169.5,257.7c-4.8-0.1-9.5,0.9-13.7,3.1l-74.4,43
   c-3.3,1.9-5.1,4.5-5.1,7.3c0,3.5,2.7,7,7.5,9.8l175,101c5.5,3,11.7,4.5,18,4.5c4.8,0.1,9.5-0.9,13.7-3.1l74.4-43
   c3.3-1.9,5.1-4.5,5.1-7.3c0-3.5-2.7-7-7.5-9.8l-175-101C182,259.1,175.8,257.6,169.5,257.7" />
      <path class="stel1" d="M160,264.6c5.8-3.4,15.2-3.4,21,0l176.2,101.8c5.8,3.3,5.7,8.7-0.1,12.1l-70.8,40.9c-5.8,3.4-15.2,3.4-21,0
   L89.1,317.6c-5.8-3.3-5.7-8.7,0.1-12.1L160,264.6z" />
      <path class="stel2" d="M275.8,422.9c-3.8,0.1-7.6-0.8-11-2.6L88.6,318.5c-3.1-1.8-4.9-4.3-4.9-7c0-2.7,1.7-5.2,4.9-7l70.8-40.9
   c3.4-1.8,7.2-2.7,11.1-2.7c3.8-0.1,7.6,0.8,11,2.6l176.2,101.8c3.1,1.8,4.9,4.3,4.9,7c0,2.7-1.7,5.2-4.9,7l-70.8,40.9
   C283.5,422.1,279.6,423,275.8,422.9 M170.5,263.2c-3.5-0.1-6.9,0.7-10,2.4l-70.8,40.9c-2.5,1.4-3.8,3.2-3.8,5.1
   c0,1.9,1.3,3.7,3.8,5.1l176.2,101.8c3,1.6,6.4,2.4,9.9,2.3c3.5,0.1,6.9-0.7,10-2.4l70.8-40.9c2.5-1.4,3.8-3.2,3.8-5.1
   c0-1.9-1.3-3.7-3.8-5.1L180.4,265.5C177.3,263.9,173.9,263.1,170.5,263.2" />
      <path class="stel3" d="M180.4,339.7c4.3,2.5,4.3,6.5,0,9c-4.9,2.5-10.7,2.5-15.5,0c-4.3-2.5-4.3-6.5-0.1-9
   C169.7,337.2,175.5,337.2,180.4,339.7" />
      <path class="stel2" d="M172.7,351.6c-2.9,0.1-5.8-0.6-8.4-2c-2.4-1.4-3.8-3.3-3.8-5.5c0-2.1,1.3-4,3.7-5.4c4.5-2.6,12.1-2.6,16.6,0
   c2.4,1.4,3.8,3.3,3.8,5.5c0,2.1-1.3,4-3.7,5.4C178.4,350.9,175.6,351.6,172.7,351.6 M172.5,338.9c-2.5-0.1-5,0.5-7.2,1.7
   c-1.7,1-2.7,2.2-2.7,3.5c0,1.3,1,2.6,2.7,3.6c4.6,2.2,9.9,2.2,14.4,0c1.7-1,2.6-2.2,2.6-3.5c0-1.3-1-2.6-2.7-3.6
   C177.6,339.4,175.1,338.8,172.5,338.9L172.5,338.9z" />
      <path class="stel3" d="M106.4,310.4c-0.4,0.2-0.8,0.5-1.2,0.8l7.8,4.5c0.4-0.2,0.8-0.5,1.2-0.8c32-18.5,84.3-18.4,116.5,0.2
   c0.1,0.1,0.2,0.1,0.2,0.2l7.7-4.5c-0.1,0-0.2-0.1-0.2-0.2C201.9,289.5,142.7,289.4,106.4,310.4" />
      <path class="stel2" d="M113,316.7c-0.2,0-0.4-0.1-0.5-0.1l-7.8-4.5c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4
   c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.2,0.5-0.3,0.7-0.4c17.6-10.2,41.1-15.8,66.2-15.8c25.3,0,49,5.7,67,16c0.4,0.2,0.6,0.6,0.7,1
   c0,0.4-0.1,0.8-0.4,1l-7.7,4.5c-0.3,0.2-0.8,0.2-1.1,0l-0.3-0.2c-15.5-8.9-36.1-13.9-58.1-13.9c-21.7,0-42.1,4.9-57.4,13.7
   c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.6,0.4C113.4,316.7,113.2,316.7,113,316.7 M107.3,311.1l5.6,3.3
   c0.2-0.1,0.4-0.3,0.7-0.4c15.6-9,36.3-14,58.5-14c22.2,0,43.1,5,58.9,14l5.6-3.2c-17.4-9.6-40.2-15-64.5-15
   C147.6,295.8,124.6,301.2,107.3,311.1L107.3,311.1z" />
      <path class="stel3" d="M137.6,328.4c-0.4,0.2-0.7,0.6-1.1,0.8l7.8,4.5c0.4-0.3,0.7-0.6,1.1-0.8c14.9-8.6,39.3-8.6,54.4,0.1
   c0.1,0.1,0.1,0.1,0.2,0.2l7.7-4.5c-0.1-0.1-0.1-0.1-0.2-0.2C188.2,317.3,156.8,317.3,137.6,328.4" />
      <path class="stel2" d="M144.2,334.8c-0.2,0-0.4-0.1-0.5-0.1l-7.8-4.5c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4
   c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.5-0.4,0.7-0.5c9.4-5.4,21.9-8.4,35.3-8.4c13.5,0,26.1,3,35.7,8.6c0.1,0.1,0.2,0.1,0.2,0.2
   c0.3,0.2,0.5,0.6,0.5,0.9c0,0.4-0.2,0.8-0.6,0.9l-7.7,4.5c-0.3,0.2-0.8,0.2-1.1,0l0.4-1l-0.7,0.8c-7.1-4.1-16.6-6.4-26.8-6.4
   c-10,0-19.5,2.2-26.5,6.3c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.2-0.4,0.3-0.6,0.4C144.6,334.7,144.4,334.8,144.2,334.8 M138.5,329.1
   l5.7,3.3c0.2-0.2,0.4-0.3,0.7-0.5c7.4-4.2,17.2-6.6,27.6-6.6c10.4,0,20.2,2.3,27.6,6.5l5.5-3.2c-9.1-4.8-20.8-7.5-33.2-7.5
   C159.5,321.2,147.5,324,138.5,329.1L138.5,329.1z" />
      <path class="stel3" d="M122,319.4c-0.4,0.2-0.8,0.5-1.2,0.8l7.8,4.5c0.4-0.3,0.8-0.5,1.2-0.8c23.5-13.6,61.8-13.5,85.4,0.2
   c0.1,0.1,0.2,0.1,0.2,0.2l7.7-4.5c-0.1,0-0.2-0.1-0.2-0.2C195.1,303.4,149.7,303.3,122,319.4" />
      <path class="stel2" d="M128.6,325.7c-0.2,0-0.4-0.1-0.5-0.1l-7.8-4.5c-0.5-0.3-0.7-1-0.4-1.5c0.1-0.2,0.2-0.3,0.4-0.4l0.5-0.3
   c0.2-0.2,0.5-0.3,0.7-0.5c13.5-7.8,31.5-12.1,50.7-12.1c19.4,0,37.6,4.4,51.3,12.3c0.4,0.2,0.6,0.6,0.7,1c0,0.4-0.1,0.8-0.4,1
   l-7.7,4.5c-0.3,0.2-0.8,0.2-1.1,0l0.3-1.1l-0.5,0.9c-11.3-6.5-26.4-10.1-42.4-10.1c-15.9,0-30.8,3.5-41.9,10
   c-0.2,0.1-0.4,0.3-0.6,0.4l-0.5,0.3C129,325.7,128.8,325.7,128.6,325.7 M122.9,320.1l5.6,3.3c0.2-0.2,0.4-0.3,0.7-0.4
   c11.5-6.6,26.7-10.3,43-10.3s31.6,3.6,43.2,10.3l5.5-3.2c-13.2-7.2-30.5-11.2-48.8-11.2C153.6,308.5,136.1,312.6,122.9,320.1
   L122.9,320.1z" />
      <path class="stel0" d="M1684.6,542.6l14.3,8.2l25.8-14.9l-14.3-8.2L1684.6,542.6z M1710.5,530.5l9.2,5.3l-20.8,12l-9.2-5.3
   L1710.5,530.5z M1703.2,553.3l14.3,8.2l45.3-26.1l-14.3-8.2L1703.2,553.3z M1748.5,530l9.2,5.3l-40.2,23.2l-9.2-5.3L1748.5,530z
    M1786.5,526.6l-64.7,37.4l14.3,8.2l64.7-37.4L1786.5,526.6z M1736,569.3l-9.2-5.3l59.7-34.5l9.2,5.3L1736,569.3z" />
      <path class="stel0" d="M180.4,484.3l14.2,8.2l25.8-14.9l-14.3-8.2L180.4,484.3z M206.2,472.3l9.2,5.3l-20.8,12l-9.2-5.3L206.2,472.3z
    M199,495l14.3,8.2l45.3-26.1l-14.3-8.2L199,495z M244.3,471.8l9.2,5.3l-40.2,23.2l-9.2-5.3L244.3,471.8z M282.3,468.4l-64.7,37.4
   l14.3,8.2l64.7-37.4L282.3,468.4z M231.8,511.1l-9.2-5.3l59.7-34.5l9.2,5.3L231.8,511.1z" />
      <path class="stel0" d="M1455.6,506.5l-50.5,29.2l-6.2,15.7l27-3.7l50.5-29.2L1455.6,506.5z M1410.6,535.8l32.8-18.9l15,8.6
   l-32.8,18.9L1410.6,535.8z M1407.4,537.7l0.4-0.2l13.7,7.9l-11.1,1.5l-5.3-3.1L1407.4,537.7z" />
      <path class="stel0" d="M270.7,653.9l50.5,29.2l27,3.7L342,671l-50.5-29.2L270.7,653.9z M321.4,679.8l-32.8-18.9l15-8.6l32.8,18.9
   L321.4,679.8z M342.1,679.2l-5.3,3.1l-11.1-1.5l13.6-7.9l0.4,0.2L342.1,679.2z" />
      <path class="stel0" d="M1065.2,468.5l11.3-14.2c0-0.1,0.1-0.1,0.1-0.2l15.6-9l-25-14.4l-14.5,8.4c-0.1,0-0.2,0-0.3,0l-24.6,6.5
   c-0.4,0.1-0.7,0.4-0.9,0.8l-13.3,26.9c-0.4,0.7-0.1,1.6,0.7,1.9c0.4,0.2,0.9,0.2,1.3,0l0.6-0.3c-0.1,0.3-0.1,0.7,0,1
   c0.2,0.6,0.8,0.9,1.4,0.9c0.1,0,0.2,0,0.2,0l46.5-7.7C1064.7,468.9,1065,468.7,1065.2,468.5 M1024.3,472.6l15.3-9.8
   c2.3,0.6,4.7,0.3,6.8-0.7c2.4-1.4,2.4-3.6,0-5c-2.7-1.4-6-1.4-8.7,0c-1.8,1-2.2,2.6-1.3,3.9l-18.1,9.4l11-22.3l23-6.1l20.7,12
   l-9.7,12.2L1024.3,472.6z" />
      <path class="stel0" d="M1869.2,116.4l46.5,7.7c0.1,0,0.2,0,0.2,0c0.6,0,1.1-0.4,1.4-0.9c0.1-0.3,0.1-0.7,0-1l0.6,0.3
   c0.7,0.4,1.6,0.1,2-0.6c0.2-0.4,0.2-0.9,0-1.3l-13.3-26.9c-0.2-0.4-0.5-0.7-0.9-0.8l-24.6-6.5c-0.1,0-0.2,0-0.3,0l-14.5-8.4
   l-25,14.4l15.6,9c0,0.1,0.1,0.1,0.1,0.2l11.3,14.2C1868.5,116.2,1868.8,116.3,1869.2,116.4 M1870.2,113.6l-9.7-12.2l20.7-12l23,6.1
   l11,22.3l-18.1-9.4c0.9-1.3,0.5-2.8-1.3-3.9c-2.7-1.4-6-1.4-8.7,0c-2.4,1.4-2.4,3.6,0,5c2.1,1.1,4.5,1.3,6.8,0.7l15.3,9.8
   L1870.2,113.6z" />
      <path class="stel0" d="M1320.3,145c1.6,0.9,3.3,1.5,5.1,1.8c4.4,0.7,8.6,1.9,12.7,3.5l-8.7,5l8.4,4.9l3.9-2.2l24.5,14.1
   c3.2,1.7,6.7,2.7,10.3,2.8l7.6,0.4c0.4,0,0.8,0.1,1.1,0.2l4.8,1.7c0.4,0.2,0.9,0.2,1.4,0.2c1.9,0,3.4-1.2,2.3-2.3l-3.2-3.1
   c-0.2-0.2-0.3-0.4-0.4-0.6l-0.6-4c-0.3-2.3-2.1-4.5-4.9-6.1l-24.4-14.1l3.9-2.2l-8.4-4.8l-8.6,4.9c-2.5-2.1-4.6-4.6-6.2-7.4
   c-1.3-1.9-3.2-3.3-5.4-3.9c-4.2-1.4-8.8-1.4-13,0C1315.5,136.2,1314.7,141.8,1320.3,145 M1345,156.3l12.7-7.3l25.6,14.8
   c2,1.2,3.3,2.6,3.5,4l0.6,4c0.1,0.5,0.3,1.1,0.6,1.5l-1.6-0.6c-0.6-0.2-1.3-0.3-1.9-0.4l-7.6-0.4c-3.2-0.1-6.3-1-9.1-2.4
   L1345,156.3z" />
    </g>
  </svg>

  <div class="row">

    <div *ngIf="alertTitle == 1 " class="col-md-12 bg-danger" style="text-align: right;">
      <h4>
        {{alertMessage}}
      </h4>
    </div>
    <div *ngIf="alertTitle == 2 " class="col-md-12 bg-success" style="text-align: right;">
      <h4>
        {{alertMessage}}
      </h4>
    </div>

  </div>
  <div class="row" style="margin: auto;position: absolute;width: 100%;height: 100%;top: 30%;">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <!-- <img src="../assets/mastery.png" style="width: 30%; margin-left: 35%;" alt=""> -->
      <img src="../assets/images/maknooncertificate.png" style="width: 30%; margin-left: 35%;" alt="">
      <div class="account-wall">
        <h2 style="text-align:center;"> نافذة إعادة تعيين كلمة المرور الخاصة بحسابي </h2>
        <br />
        <form name="form" (ngSubmit)="f.form.valid && reset()" #f="ngForm" novalidate>
          <div class="append-icon m-b-20">
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
              <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
                placeholder="كلمة المرور" #password="ngModel" style="border-radius: 50px;" required />
              <div *ngIf="f.submitted && !password.valid" class="help-block">كلمة المرور مطلوبة</div>
              <!-- <i class="icon-lock"></i> -->
            </div>
          </div>
          <div class="append-icon m-b-20">
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !repassword.valid }">
              <input type="password" class="form-control" name="repassword" [(ngModel)]="model.repassword"
                placeholder="إعادة كلمة المرور" #repassword="ngModel" style="border-radius: 50px;" required />
              <div *ngIf="f.submitted && !repassword.valid || f.submitted && password != repassword" class="help-block">
                يجب مطابقة كلمة المرور </div>
              <!-- <i class="icon-lock"></i> -->
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-embossed btn-primary btn-block" style="border-radius: 50px;">
              إعادة تعيين كلمة المرور </button>
            <img *ngIf="loading"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

          </div>
        </form>


      </div>
    </div>
    <div class="col-md-3"></div>

  </div>


</div>



<div class="modal bounceInUp animated" id="alert" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h4 class="modal-title">{{alertTitle}}</h4>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label> {{alertMessage}} </label><br />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="pull-right">
          <a class="btn btn-primary btn-embossed btn-block" href="#" data-rel="tooltip" data-placement="top"
            data-dismiss="modal" data-original-title="موافق">

            موافق
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
