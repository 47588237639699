<div class="alertmessage">
  <h2 class="alert-header {{ alertTypeClass }}" mat-dialog-title>
    {{ alertTitle }}
  </h2>
  <p class="alert-body" [innerHtml]="alertBody"></p>

  <div class="alert-buttons">
    <button mat-button mat-dialog-close style="text-align: center">Ok</button>
  </div>
</div>
