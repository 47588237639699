import { mergeMap, catchError, map, tap, exhaustMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoursesService } from '../../services';
import { EMPTY, of } from 'rxjs';
import * as courseActions from './courses.actions';
import { Action } from '@ngrx/store';






@Injectable()
export class CoursesEffect {
  constructor(
    private actions$: Actions,
    private _service: CoursesService,
  ) {
  }



  getSelectedCourse$ = createEffect(() => this.actions$.pipe(
    ofType('[Courses] Select And Update Course'),
    exhaustMap((action: any) => this._service.getCourseCard(action.courseId).pipe(
      map(course => ({ type: '[Courses] Load Current Course', course: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );


  getSelectPackage$ = createEffect(() => this.actions$.pipe(
    ofType('[Packages] Select And Update Package'),
    exhaustMap((action: any) => this._service.getPackageCard(action.packageId).pipe(
      map(course => ({ type: '[Packages] Load Current Package', packages: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );

  getSelectedCourseById$ = createEffect(() => this.actions$.pipe(
    ofType('[Courses] Select And Update Course By Id'),
    exhaustMap((action: any) => this._service.getCourseCardById(action.courseId).pipe(
      map(course => ({ type: '[Courses] Load Current Course', course: course }),
        catchError((error) => of({ type: '[Courses] Failed To Get Selected Course', payload: { error } })
        )
      ))
    ))
  );



  loadAllCategories$ = createEffect(() => this.actions$.pipe(

    ofType('[Categories] Load All Categories'),
    mergeMap(() => this._service.AllCategories()
      .pipe(
        map(courses => ({ type: '[Categories] Load All Categories Success', categories: courses }),
          catchError((error) => of({ type: courseActions.LoadCorusesFail, payload: { error } }))
        ))
    )));


  loadLatestCourses$ = createEffect(() => this.actions$.pipe(

    ofType('[Courses] Load Available Courses'),
    mergeMap(() => this._service.getCourses()
      .pipe(
        map(courses => ({ type: '[Courses] Load Available Courses Success', courses: courses }),
          catchError((error) => of({ type: courseActions.LoadCorusesFail, payload: { error } }))
        ))
    )));


    getMyCoursesEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Courses] Load Available Courses Success'),
      map(() => ({ type: '[Profile] Stop Loading' }))
    ),
    { dispatch: false },
  );



  loadLatestPackages$ = createEffect(() => this.actions$.pipe(

    ofType('[Packages] Load Available Packages'),
    mergeMap(() => this._service.getPacakges()
      .pipe(
        map(courses => ({ type: '[Packages] Load Available Packages Success', packages: courses }),
          catchError((error) => of({ type: courseActions.LoadCorusesFail, payload: { error } }))
        ))
    )));


    getPackagesend$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Packages] Load Available Packages Success'),
      map(() => ({ type: '[Profile] Stop Loading' }))
    ),
    { dispatch: false },
  );


  apiError$ = createEffect(
    () => this.actions$.pipe(
      ofType(courseActions.LoadCorusesFail),
      tap(({ error }) => {
        console.log({ error })
      }
      )
    ), { dispatch: false }
  )


}
