import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import { SystemService } from 'src/app/core/services';
import { AccountService } from 'src/app/core/services/account.service';
declare var $: any;



@Component({
  selector: 'sm-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {


  template
  model: any = {};
  loading = false;
  returnUrl: string;
  public alertMessage = '';
  public alertTitle = 0;
  email = '';
  notactivated


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _acount: AccountService,
    private _store: Store<any>,
    private cd: ChangeDetectorRef,
    private _system: SystemService,
    private authenticationService: AuthenticationService) {

    this.template = JSON.parse(localStorage.getItem('__template'));
    
    
  }

  ngOnInit() {

  }

  reset() {
    this.loading = true;

    this.email = this.model.email;
    this.authenticationService.reset(this.model).subscribe((data) => {
      console.log(data);
      if (data == 'not found') {
        this.alertTitle = 1;
        this.alertMessage = "هذا البريد غير موجود سابقاً ";
        this.loading = false;
        this.cd.markForCheck();
        setTimeout(() => {
          this.alertTitle = 0;
          this.cd.markForCheck();
        }, 3500);
      }
      else if (data == 'not activated') {
        this.alertTitle = 1;
        this.alertMessage = "لم يتم تفعيل هذا الحساب من قبل إذا لم تتلق بريد تفعيل الحساب يمكنك الضغط هنا لإعادة الإرسال";
        this.loading = false;
        this.notactivated = true;
        this.cd.markForCheck();
        setTimeout(() => {
          this.alertTitle = 0;
          this.cd.markForCheck();
        }, 3500);
      }
      else {
        this.alertTitle = 2;
        this.alertMessage = "Junk Mail تم إرسال رسالة لإعادة تعيين كلمة المرور الخاصة بكم إلى بريدكم  يمكن ان تصل الرسالة إلى بريد "
        this.cd.markForCheck();
        setTimeout(() => {
          this.alertTitle = 0;
          this.cd.markForCheck();
          this.router.navigate(['/']);
        }, 3500);
      }
    })

  }


  resendActivation() {
    this._acount.reactivate(this.email).subscribe((data) => {
      if (data == 'did') {
        this.alertTitle = 2;
        this.alertMessage = "Junk Mail تم إرسال رسالة تفعيل إلى بريدكم  يمكن ان تصل الرسالة إلى بريد "
        this.cd.markForCheck();
        setTimeout(() => {
          this.alertTitle = 0;
          this.cd.markForCheck();
          this.router.navigate(['/']);
        }, 3500);
      }
    })
  }

}
