<!-- <div class='sm-loader-container'>

  <div class="sm-loader-progressbar">
    <div class="slider">
      <div class="line"></div>
      <div class="subline inc"></div>
      <div class="subline dec"></div>
    </div>
  </div>

</div> -->

<div class="containersx">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" style="z-index: 999999;"
    [fullScreen]="true">
    <p style="color: white"> </p>
  </ngx-spinner>
</div>