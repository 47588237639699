import { ResetformComponent } from './views/components/resetform/resetform.component';
import { ConfirmedComponent } from './views/components/confirmed/confirmed.component';
import { RegisterComponent } from './views/components/register/register.component';
import { ResetComponent } from './views/components/reset/reset.component';
// import { ProfileComponent } from './views/profile/profile/profile.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './core/auth/auth.guard';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './views/base/base.component';
// import { PreviewComponent } from './views/courses/preview/preview.component';
import { CallbackComponent } from './callback/callback.component';
import { LoginComponent } from './views/components/login/login.component';
import { BusinessLoginComponent } from './views/components/businesslogin/businesslogin.component';
import { BusinessResetComponent } from './views/components/blreset/blreset.component';
import { AuthGuard2 } from './core/auth/authb2b.guard';
import { BasesupervisionComponent } from './views/supervision/basesupervision/basesupervision.component';
import { LoginbComponent } from './views/components/loginb/loginb.component';
import { TwofactorComponent } from './views/components/twofactor/twofactor.component';
import { LoginResolver } from './core/services/pagesresolver';
import { EmbedVideoComponent } from './views/course/embed-video/embed-video.component';

const routes: Routes = [

  { path: 'viebm', component: EmbedVideoComponent },
  {
    path: 'affiliates',
    loadChildren: () => import('./views/affilate/affilate.module').then(m => m.AffilateModule),
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent, data: { preload: true }, resolve: { loginData: LoginResolver }, },
  { path: '2facheck', component: TwofactorComponent },
  { path: 'business-login', component: LoginbComponent, data: { preload: true }, resolve: { loginData: LoginResolver }, },
  { path: 'bl/:id', component: BusinessLoginComponent },
  { path: 'logout', component: LoginComponent },
  { path: 'blreset', component: BusinessResetComponent },
  { path: 'reset', component: ResetComponent },
  { path: 'confirmed/token/:id/userid/:id2', component: ConfirmedComponent },
  { path: 'resetpassword/token/:id/userid/:id2', component: ResetformComponent },
  { path: 'register', component: RegisterComponent },

  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),

    canActivate: [AuthGuard]
  },

  {
    path: 'course',
    loadChildren: () => import('./views/course/course.module').then(m => m.CourseModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'quizz',
    loadChildren: () => import('./views/quizz/quizz.module').then(m => m.QuizzModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'assignments',
    loadChildren: () => import('./views/assignments/assignments.module').then(m => m.AssignmentsModule),
    canActivate: [AuthGuard]
  },


  {
    path: 'clientId/:id',
    component: BasesupervisionComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard2]
      },
      {
        path: 'supervision',
        loadChildren: () => import('./views/supervision/supervision.module').then(m => m.SupervisionModule),
        canActivate: [AuthGuard2]
      },
      {
        path: 'study',
        loadChildren: () => import('./views/study/study.module').then(m => m.StudyModule),
        canActivate: [AuthGuard2]
      },
      {
        path: 'quizz',
        loadChildren: () => import('./views/quizz/quizz.module').then(m => m.QuizzModule),
        canActivate: [AuthGuard2]
      },

      {
        path: 'assignments',
        loadChildren: () => import('./views/assignments/assignments.module').then(m => m.AssignmentsModule),
        canActivate: [AuthGuard2]
      },
    ]

  },



  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'callback', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
