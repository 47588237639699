<h4 style="
text-align: center;
top: 50%;
position: absolute;
left: 0;
right: 0 ;
font-family: 'JF-Flat';
color: #203060;
font-size: 3em; ">
  {{'SYSTEM.YOUWILLBETRANSFERED' | translate}}
</h4>
