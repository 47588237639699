import { courseCard } from './../../_models/cards';
import { createReducer, on, Action, createSelector, createFeatureSelector } from '@ngrx/store';
import { COUCategories, courseSlide } from './../../_models';
import * as courseActions from './courses.actions';
import { appState } from '../app.state';
import { getMergedRoute } from '../_router/router-state.selectors';

export interface courseState {
  isLoaded: boolean;
  isLoading: boolean;
  courses: courseSlide[];
  selectedCourse: courseCard;
  categories: COUCategories[];
  isCourseSelected: boolean;
  error: any;
}

const defaultState: courseState = {
  isLoaded: false,
  isLoading: false,
  courses: undefined,
  selectedCourse: undefined,
  categories: undefined,
  isCourseSelected: false,
  error: undefined
}


const _reducer = createReducer(
  defaultState,
  on(courseActions.AvailableCourses, state => ({ ...state, isLoaded: false, isLoading: true })),
  on(courseActions.SelectCourse, (state, { courseId: string }) => ({ ...state, isCourseSelected: false })),
  on(courseActions.SelectCourseById, (state, { courseId: number }) => ({ ...state, isCourseSelected: false })),
  on(courseActions.GetSelectedCourse, (state: courseState, { course }) => ({ ...state, selectedCourse: course, isCourseSelected: true })),
  on(courseActions.LoadCorusesFail, (state: courseState) => ({ ...state, isLoading: false, isLoaded: false, error: state.error })),
  on(courseActions.LoadCorusesSuccess, (state: courseState, { courses }) => ({ ...state, isLoaded: true, isLoading: false, courses: courses })),
  on(courseActions.SelectedCourseError, (state: courseState, { error }) => ({ ...state, isCourseSelected: false, error: error })),
  on(courseActions.LoadCategoriesSuccess, (state: courseState, { categories }) => ({ ...state, categories: categories }))
)


export function courseReducer(state: courseState | undefined, action: Action) {
  return _reducer(state, action);
}


export const courseFeatureKey = 'courses';

export const selectCoursesFeature = createFeatureSelector<appState, courseState>(courseFeatureKey);

export const loadingStatus = createSelector(
  selectCoursesFeature,
  (state: courseState) => state.isLoading
)


export const categories = createSelector(
  selectCoursesFeature,
  (state: courseState) => state.categories
)

export const availableCourses = createSelector(
  selectCoursesFeature,
  (state: courseState) => state.courses
)


export const selectedCourse = createSelector(
  selectCoursesFeature,
  (state: courseState) => state.selectedCourse
)



// export const selectedCourse = createSelector(availableCourses, getMergedRoute, (courseId, mergedRoute) =>
//   mergedRoute.queryParams.id
// );



export const isSelectedCourse = createSelector(
  selectCoursesFeature,
  (state: courseState) => state.isCourseSelected
)
