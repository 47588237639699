<div class="row" style="margin: 0;padding: 3em;">
  <div class="col-md-12" style="text-align : right ; font-family: 'JF-Flat';">
    <h3 style="margin-top: 50px;margin-bottom: 50px;  font-family: 'JF-Flat';">
      {{'SYSTEM.CHECKYOURCERTIFICATE' | translate}}
    </h3>
    <p style="text-align : right ; font-family: 'JF-Flat';">
      {{'SYSTEM.ENTERYOURCODE'| translate}}
    </p>
    <p style="text-align : right ; font-family: 'JF-Flat';">
      {{'SYSTEM.IFYOUDONTHAVECODE'| translate}}
    </p>

  </div>
  <div class="col-md-6 " style="text-align : right ; font-family: 'JF-Flat';margin-top: 50px;margin-bottom: 50px; ">
    <div class="form-group" style="text-align : right ;  font-family: 'JF-Flat';">
      <label for="code">{{'SYSTEM.ENTERCODEHERE' | translate}}</label>
      <input type="text" [placeholder]="'SYSTEM.ENTERCODEHERE' | translate" class="form-control" [(ngModel)]="code">
    </div>

    <button class="btn btn-primary btn-embossed" (click)="checkCode()">
      {{'SYSTEM.CHECK' | translate}}
    </button>
  </div>

  <div *ngIf="result" class="col-md-6 offset-md-6"
    style="text-align : right ; font-family: 'JF-Flat';margin-top: 15px;margin-bottom: 50px; ">
    <h4>
      {{'SYSTEM.RESULT' | translate}}
    </h4>
    <ul style="
    list-style: none;
    padding: 1em;
    font-size: 17px;
">
      <li *ngFor="let item of result; let i = index ;">
        <span *ngIf="result.length == 1">
          {{'SYSTEM.NoCertificate' | translate}}
        </span>
        <span *ngIf="result.length > 1 && i == 0">
          {{'SYSTEM.NAME' | translate}} <strong> {{item}} </strong>
        </span>
        <!-- <span *ngIf="i == 1"></span> -->
        <span *ngIf="i == 1">
          {{'SYSTEM.CERTIFICATENAME' | translate}} <strong> {{item}} </strong>
        </span>
        <span *ngIf="i == 2">
          {{'SYSTEM.CERTIFICATEDATE' | translate}} <strong> {{item | date: 'dd-MMMM-yyyy'}} </strong>
        </span>
      </li>
    </ul>
  </div>
</div>
