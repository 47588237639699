<div class="sm-id-container">
  <svg version="1.1" id="moon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 511.989 511.989" style="enable-background:new 0 0 511.989 511.989;" xml:space="preserve">
    <path style="fill:#FCD57E;"
      d="M391.37,292.817c-48.362,48.362-126.774,48.362-175.136,0s-48.362-126.774,0-175.136
	c10.084-10.084,21.476-18.057,33.627-23.935c-39.798,1.061-79.279,16.774-109.653,47.147c-62.946,62.946-62.946,165.003,0,227.949
	s165.003,62.946,227.949,0c30.374-30.374,46.086-69.854,47.147-109.653C409.427,271.341,401.454,282.733,391.37,292.817z" />
    <g>
      <polygon style="fill:#FFFFFF;" points="355.804,153.568 361.513,205.217 367.224,153.568 418.873,147.859 367.224,142.148
		361.513,90.499 355.804,142.148 304.154,147.859 	" />
      <polygon style="fill:#FFFFFF;" points="112.365,410.626 116.455,447.623 120.545,410.626 157.542,406.536 120.545,402.446
		116.455,365.449 112.365,402.446 75.368,406.536 	" />

      <rect x="243.188" y="248.208" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -106.4278 249.8396)"
        style="fill:#FFFFFF;" width="10.362" height="10.362" />

      <rect x="418.93" y="193.377" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -16.1825 358.0481)"
        style="fill:#FFFFFF;" width="10.362" height="10.362" />

      <rect x="450.017" y="284.419" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -71.4537 406.696)"
        style="fill:#FFFFFF;" width="10.362" height="10.362" />

      <rect x="76.067" y="124.535" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -67.9259 95.4443)"
        style="fill:#FFFFFF;" width="10.362" height="10.362" />

      <rect x="346.322" y="421.433" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -198.7088 373.5025)"
        style="fill:#FFFFFF;" width="10.362" height="10.362" />

      <rect x="117.587" y="97.883" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -36.9191 116.9972)"
        style="fill:#FFFFFF;" width="10.362" height="10.362" />
    </g>
  </svg>


  <h4 id="greeting1" >السلام عليكم</h4>

  <svg version="1.1" id="roboty" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 317.3 343.3" style="enable-background:new 0 0 317.3 343.3;" xml:space="preserve">
    <style type="text/css">
      .st0 {
        fill: url(#SVGID_1_);
        stroke: #680F19;
        stroke-miterlimit: 10;
      }

      .st1 {
        fill: url(#SVGID_2_);
      }

      .st2 {
        fill: url(#SVGID_3_);
      }

      .st3 {
        fill: url(#SVGID_4_);
      }

      .st4 {
        fill: #EFF8FB;
      }

      .st5 {
        fill: url(#SVGID_5_);
      }

      .st6 {
        fill: url(#SVGID_6_);
      }

      .st7 {
        fill: url(#SVGID_7_);
      }

      .st8 {
        fill: #FFFFFF;
      }

      .st9 {
        fill: url(#SVGID_8_);
      }

      .st10 {
        fill: url(#SVGID_9_);
      }

      .st11 {
        fill: url(#SVGID_10_);
      }

      .st12 {
        fill: url(#SVGID_11_);
        stroke: #680F19;
        stroke-miterlimit: 10;
      }

      .st13 {
        fill: url(#SVGID_12_);
      }

      .st14 {
        fill: url(#SVGID_13_);
      }
    </style>
    <g id="whole">
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-49.2823" y1="271.6165" x2="77.7071"
        y2="141.3375" gradientTransform="matrix(0.1117 0.9937 -0.9937 0.1117 356.9439 168.3327)">
        <stop offset="5.434346e-07" style="stop-color:#691018" />
        <stop offset="1" style="stop-color:#BE2428" />
      </linearGradient>
      <path id="thehand" class="st0" d="M60.4,126.4c37,10.1,45.8,7.3,52.4,17.2c0.8,1.1,4.6,7,3.9,14.4c-0.6,6-3.9,10.6-6,12.6
    c-12.7,12.3-47.6-2.7-67.6-19.2c-4.4-3.6-16.1-13.2-13.7-20c1-2.8,4-4.2,6.1-5.2C45,122,54.9,124.8,60.4,126.4z" />
      <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="164.7855" y1="39.6998" x2="175.2248"
        y2="38.1774">
        <stop offset="5.434346e-07" style="stop-color:#691018" />
        <stop offset="1" style="stop-color:#BE2428" />
      </linearGradient>
      <rect x="170.1" y="27.2" class="st1" width="3.5" height="22.8" />
      <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="162.2831" y1="66.0265" x2="180.5047"
        y2="45.4538">
        <stop offset="5.434346e-07" style="stop-color:#691018" />
        <stop offset="1" style="stop-color:#BE2428" />
      </linearGradient>
      <path class="st2" d="M183.3,62.7h-22.9c-2.1,0-3.8-1.7-3.8-3.8v-7.4c0-2.1,1.7-3.8,3.8-3.8h22.9c2.1,0,3.8,1.7,3.8,3.8v7.4
    C187.1,61,185.4,62.7,183.3,62.7z" />
      <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="95.5445" y1="91.1075" x2="247.7692" y2="91.1075">
        <stop offset="5.434346e-07" style="stop-color:#691018" />
        <stop offset="1" style="stop-color:#BE2428" />
      </linearGradient>
      <path class="st3" d="M233.6,126.6H109.8c-7.8,0-14.2-6.4-14.2-14.2V77.2c0-11.9,9.7-21.6,21.6-21.6h109c11.9,0,21.6,9.7,21.6,21.6
    v35.2C247.8,120.3,241.4,126.6,233.6,126.6z" />
      <g>
        <g>
          <circle class="st4" cx="144.6" cy="88.7" r="17.4" />
        </g>
        <g>
          <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="130.0284" y1="88.6862" x2="159.1853"
            y2="88.6862">
            <stop offset="0" style="stop-color:#0F1418" />
            <stop offset="1" style="stop-color:#0A2F44" />
          </linearGradient>
          <circle class="st5" cx="144.6" cy="88.7" r="14.6" />
        </g>
        <g>
          <path class="st4"
            d="M153.9,84.9c0,3-2.4,5.4-5.4,5.4c-3,0-5.4-2.4-5.4-5.4s2.4-5.4,5.4-5.4C151.5,79.5,153.9,81.9,153.9,84.9z" />
        </g>
        <g>
          <circle class="st4" cx="142.5" cy="99.9" r="2.2" />
        </g>
        <g>
          <path class="st4" d="M137.1,96.6c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
        C136.6,95.6,137.1,96,137.1,96.6z" />
        </g>
      </g>
      <g>
        <g>
          <circle class="st4" cx="197" cy="88.7" r="17.4" />
        </g>
        <g>
          <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="182.4344" y1="88.6862" x2="211.5913"
            y2="88.6862">
            <stop offset="0" style="stop-color:#0F1418" />
            <stop offset="1" style="stop-color:#0A2F44" />
          </linearGradient>
          <circle class="st6" cx="197" cy="88.7" r="14.6" />
        </g>
        <g>
          <path class="st4"
            d="M206.4,84.9c0,3-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4s2.4-5.4,5.4-5.4S206.4,81.9,206.4,84.9z" />
        </g>
        <g>
          <circle class="st4" cx="194.9" cy="99.9" r="2.2" />
        </g>
        <g>
          <path class="st4"
            d="M189.5,96.6c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1S189.5,96,189.5,96.6z" />
        </g>
      </g>
      <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="162.492" y1="27.094" x2="176.3074" y2="25.0792">
        <stop offset="5.434346e-07" style="stop-color:#691018" />
        <stop offset="1" style="stop-color:#BE2428" />
      </linearGradient>
      <path class="st7"
        d="M176.3,25.7c0,2.5-2,4.5-4.5,4.5c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5C174.3,21.3,176.3,23.3,176.3,25.7z" />
      <path class="st8" d="M222.6,63.1c-0.1,0.9-2.3,2.2-4.6,2.5c-2.1,0.3-3.6-0.4-5.1-1.1c-1.5-0.7-4.7-2.6-3.1-3.4
    C211.9,60.2,222.9,60.9,222.6,63.1z" />
      <path class="st8" d="M239.8,98.6c-0.8-0.1-0.2-8.6-6.5-21.2c-2.2-4.4-4.8-8.4-4.3-8.6c0.5-0.2,3.9,3.6,6.2,7.1
    C242.6,87.1,240.7,98.8,239.8,98.6z" />
      <path class="st8" d="M185.7,53.6c-0.1,0-0.2-0.8-0.7-2c0,0-0.2-0.6-0.5-1c-0.9-1.5-4-1.5-4.3-1.5c-4.8,0.1-7.9-0.3-7.9-0.3
    c0,0,2.3,0,3.9-0.1c4.7-0.1,7.5-0.4,8.7,1.1c0.5,0.6,0.7,1.5,0.7,1.5C185.9,52.5,185.8,53.6,185.7,53.6z" />
      <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="124.3916" y1="239.5573" x2="251.9987"
        y2="90.5452">
        <stop offset="5.434346e-07" style="stop-color:#691018" />
        <stop offset="1" style="stop-color:#BE2428" />
      </linearGradient>
      <path class="st9" d="M259.4,162.9c0,0.3,0,0.7,0,1c-0.1,3.1-0.8,5.4-1.2,6.5c-10.2,31.1-16.3,47.5-24.9,60.8c-1.5,2.3-3,4.5-4.7,6.7
    c-2.2,2.9-4.6,5.7-7.2,8.6c-5.9,6.4-14.9,9.3-17.7,10.3c-10.5,3.7-19.9,3-26,3.1h-1.1c-6.2-0.1-15.5,0.6-26-3.1
    c-2.9-1-11.8-3.9-17.7-10.3c-16.3-17.7-22.5-32.4-36.8-76c-0.3-1-1-3.2-1.2-6.1c0-0.8-0.1-1.7,0-2.5c0.3-5.2,2.4-10.3,6.2-15
    c6.4-8,16.4-12.4,26.6-12.4l49.5-0.2l48.6-0.2c10.5,0,20.7,4.4,27.3,12.5c3.2,3.9,5.3,8.2,6,12.6c0.1,0.6,0.2,1.3,0.3,1.9
    C259.3,161.5,259.4,162.2,259.4,162.9z" />
      <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="154.8705" y1="167.381" x2="193.7906"
        y2="121.9323">
        <stop offset="0" style="stop-color:#0F1418" />
        <stop offset="1" style="stop-color:#0A2F44" />
      </linearGradient>
      <path class="st10" d="M206.3,149.4C206.3,149.5,206.3,149.5,206.3,149.4c-0.1,0.3-0.4,0.5-0.5,0.6c-4.5,2.5-7.1,3.8-10.9,4.9
    c-0.6,0.2-1.3,0.4-2.1,0.5c-1,0.2-2,0.5-3.2,0.7c-2.6,0.5-6.5,0.8-7.8,0.8c-4.6,0.3-8.7,0.2-11.4,0.2H170c-2.7,0-6.8,0-11.4-0.2
    c-1.3-0.1-5.2-0.3-7.8-0.8c-7.1-1.4-9.9-2.6-16.1-6.1c-0.1-0.1-0.4-0.3-0.5-0.5c0-0.1,0-0.1,0-0.2c0.1-0.4,1.1-0.8,2.7-1.2
    c2.8-0.6,7.2-1,11.7-1l21.7,0l21.3,0c4.6,0,9.1,0.4,12,1c1.4,0.3,2.3,0.7,2.6,1c0,0,0.1,0.1,0.1,0.2
    C206.3,149.3,206.3,149.4,206.3,149.4z" />
      <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="117.632" y1="165.2684" x2="194.37" y2="75.6581">
        <stop offset="0" style="stop-color:#0F1418" />
        <stop offset="1" style="stop-color:#0A2F44" />
      </linearGradient>
      <path class="st11" d="M177,134.1l-49.5,0.2c-10.3,0-20.2,4.4-26.6,12.4c-3.8,4.7-5.9,9.8-6.2,15c0.3-5.4,2.5-10.6,6.4-15.4
    c6.7-8.1,16.8-12.5,27.3-12.5L177,134.1z" />
      <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="239.5388" y1="227.7901" x2="344.5106"
        y2="173.0865" gradientTransform="matrix(-0.9886 -0.1505 -0.1505 0.9886 553.605 29.2301)">
        <stop offset="5.434346e-07" style="stop-color:#691018" />
        <stop offset="1" style="stop-color:#BE2428" />
      </linearGradient>
      <path class="st12"
        d="M226.3,208.1c-0.7-38.4-5.9-46,1.7-55.1c0.9-1,5.4-6.4,12.8-7.8c5.9-1.1,11.2,0.8,13.8,2.2
    c15.4,8.7,10.8,46.5,0.7,70.3c-2.2,5.2-8.1,19.1-15.3,18.8c-3-0.1-5.1-2.6-6.7-4.4C226.4,224.2,226.4,213.9,226.3,208.1z" />
      <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="217.8366" y1="194.9175" x2="233.3163"
        y2="194.9175">
        <stop offset="0" style="stop-color:#0F1418" />
        <stop offset="1" style="stop-color:#0A2F44" />
      </linearGradient>
      <path class="st13" d="M233.3,231.2c-1.5,2.3-3,4.5-4.7,6.7c-0.5-0.5-1-1.1-1.4-1.6c-6.8-7.9-6.8-18.2-6.9-24
    c-0.7-38.4-5.9-46,1.7-55.1c0.6-0.7,2.6-3.1,6-5.1c-7.6,9.1-2.4,16.8-1.7,55.1c0.1,5.8,0.2,16.1,6.9,24
    C233.2,231.1,233.3,231.1,233.3,231.2z" />
      <path class="st8" d="M255,175.1c-0.2-4.1-1-7.2-2.5-13.2c-2-7.9-4.6-11.8-2.9-13.3c1.1-1,3.2-0.3,3.7-0.2c7.3,2.3,9.5,15.9,9.2,24.5
    c-0.2,5.2-1.5,11.1-3.4,11.3c-0.3,0-1.1,0-1.6-0.5C255.3,181.7,255.2,178.2,255,175.1z" />
      <radialGradient id="SVGID_13_" cx="173.9903" cy="144.8212" r="78.0443"
        gradientTransform="matrix(1.012 0 0 0.4143 -1.2454 236.9243)" gradientUnits="userSpaceOnUse">
        <stop offset="0" style="stop-color:#231F20" />
        <stop offset="0.9838" style="stop-color:#231F20;stop-opacity:0" />
      </radialGradient>
    </g>
    <ellipse id="shadow" class="st14" cx="174.8" cy="296.9" rx="79" ry="32.3" />
  </svg>


</div>
