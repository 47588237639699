import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider/slider.component';
// import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';



@NgModule({
  declarations: [SliderComponent],
  exports: [SliderComponent],
  imports: [
    CommonModule, RouterModule,
    // LazyLoadImageModule.forRoot({
    //   preset: scrollPreset
    // })
  ]
})
export class SliderModule { }
