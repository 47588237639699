export class PriceList {

    id?: number;
    Id?: number;
    courseId?: number;
    price: number;
    name: string;
    startingFrom?: Date;
    endingAt?: Date;
    active: boolean;
    isMainPrice: boolean;
}