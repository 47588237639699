<div style="margin-top: 50px">
  <div class="logo-box px-4" style="margin: 50px 0">
    <a (click)="goHome()" class="logo">
      <img
        [src]="template.logo"
        [alt]="template.companyName"
        style="width: 100px; height: 100px"
      />
    </a>
  </div>

  <ul
    class="cs-new-generic-list-item w-100 generic-list-item off-canvas-menu-list off--canvas-menu-list"
  >
    <!-- <li routerLinkActive="page-active"><a [routerLink]="['reports']" routerLinkActive="page-active">
        <img src="/assets/images/stats.png" class="mr-2" width="20">
        {{'DASHBOARD.REPORTS' | translate}} </a></li> -->
    <li routerLinkActive="page-active">
      <a [routerLink]="['supervision']" routerLinkActive="page-active">
        <img src="/assets/images/stats.png" class="mr-2" width="20" />
        {{ "DASHBOARD.HOME" | translate }}
      </a>
    </li>
    <!-- <li routerLinkActive="page-active">
      <a
        [routerLink]="['supervision/departments']"
        routerLinkActive="page-active"
      >
        <img src="/assets/images/categories.png" class="mr-2" width="20" />
        {{ "DASHBOARD.DEPARTMENTS" | translate }}
      </a>
    </li> -->
    <li routerLinkActive="page-active">
      <a
        [routerLink]="['supervision/users/users']"
        routerLinkActive="page-active"
      >
        <img src="/assets/images/consultation.png" class="mr-2" width="20" />
        {{ "DASHBOARD.USERS" | translate }}</a
      >
    </li>
    <li routerLinkActive="page-active">
      <a
        [routerLink]="['supervision/consultancies']"
        routerLinkActive="page-active"
      >
        <img src="/assets/images/consultation.png" class="mr-2" width="20" />
        {{ "DASHBOARD.CONSULTANCIES" | translate }}</a
      >
    </li>
    <!-- <li routerLinkActive="page-active"><a [routerLink]="['learning-path']" routerLinkActive="page-active">
        <img src="/assets/images/consultation.png" class="mr-2" width="20">
        {{'DASHBOARD.LEARNINGPATH' | translate}}</a></li> -->

    <li routerLinkActive="page-active">
      <a
        [routerLink]="['supervision/learning-paths']"
        routerLinkActive="page-active"
      >
        <img src="/assets/images/consultation.png" class="mr-2" width="20" />
        {{ "DASHBOARD.LEARNINGPATH" | translate }}</a
      >
    </li>
    <li routerLinkActive="page-active">
      <a
        [routerLink]="['supervision/learning-assignments']"
        routerLinkActive="page-active"
      >
        <img src="/assets/images/consultation.png" class="mr-2" width="20" />
        {{ "DASHBOARD.B2BASSIGNMENTS" | translate }}</a
      >
    </li>

    <li routerLinkActive="page-active">
      <a [routerLink]="['/dashboard']" routerLinkActive="page-active">
        <i class="la la-home font20 mr-2"></i>
        {{ "DASHBOARD.DASHBOARD" | translate }}
      </a>
    </li>
  </ul>
</div>
