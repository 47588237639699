import { LoadmoreService } from './loadmore.service';
import { LoadmoreDirective } from './loadmore.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadmoreComponent } from './loadmore/loadmore.component';



@NgModule({
  declarations: [LoadmoreDirective, LoadmoreComponent],
  imports: [
    CommonModule
  ], exports: [LoadmoreDirective, LoadmoreComponent],
  providers: [LoadmoreService]
})
export class LoadmoreModule { }
