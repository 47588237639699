<sm-loader *ngIf="loading"></sm-loader>


<div *ngIf="!loading" class="id-page-container">


  <div class="page-header">
    <sm-header></sm-header>
  </div>
  <div class="page-content">

    <router-outlet></router-outlet>

  </div>
  <div class="page-footer">

    <sm-footer></sm-footer>
  </div>


</div>
