import { Store } from '@ngrx/store';

import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, HostListener, ViewEncapsulation, AfterViewInit, Input } from '@angular/core';



@Component({
  selector: 'sm-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnInit {


  @Input() loading = false;
  constructor(private _store: Store<any>, private cd:  ChangeDetectorRef) {

  }


  ngOnInit(): void {
    this.cd.markForCheck();
    // this._store.subscribe(state => (this.loading = state.Courses.isLoading));
  }




}
