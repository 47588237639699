<div class="row">
  <div
    *ngIf="alertTitle == 1"
    class="col-md-12 bg-danger"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
    <a *ngIf="notactivated" (click)="resendActivation()">
      إضغط هنا لإعادة ارسال الرابط</a
    >
  </div>
  <div
    *ngIf="alertTitle == 2"
    class="col-md-12 bg-success"
    style="text-align: right"
  >
    <h4 style="padding: 20px; color: #fff">
      {{ alertMessage }}
    </h4>
  </div>
</div>

<div
  class="login-background"
  [ngStyle]="{
    'background-image': 'url(' + template.image + ')'
  }"
></div>
<div class="login-container">
  <div class="box">
    <span class="borderLine"></span>

    <form
      name="form"
      (ngSubmit)="f.form.valid && reset()"
      #f="ngForm"
      novalidate
    >
      <img
        style="object-fit: contain"
        [style.height]="!template.companyId ? '200px' : '200px'"
        [style.width]="!template.companyId ? '200px' : '200px'"
        [src]="template.logo"
        [alt]="template.companyName"
      />

      <h4 style="text-align: center">إستعادة كلمة المرور</h4>
      <div class="input-box">
        <label class="label-text">البريد الإلكتروني</label>
        <div
          class="form-group"
          [ngClass]="{ 'has-error': f.submitted && !email.valid }"
        >
          <input
            class="form-control form--control"
            type="text"
            name="email"
            [(ngModel)]="model.email"
            #email="ngModel"
            required
          />
          <span class="la la-envelope input-icon"></span>
          <div *ngIf="f.submitted && !email.valid" class="help-block">
            بريد المستخدم مطلوب
          </div>
        </div>
      </div>

      <!-- end input-box -->
      <div class="btn-box" style="width: 100%">
        <button class="btn theme-btn2 btn-block">
          إرسال رابط كلمة المرور <i class="la la-arrow-right icon ml-1"></i>
        </button>
        <!-- <button class="btn btn-success btn-block" routerLink="/business-login">
           تسجيل دخول الشركات
         </button> -->
        <p class="fs-14 pt-3 text-center">
          الذهاب إلى تسجيل الدخول ؟
          <a routerLink="/login" class="text-color-8 hover-underline"
            >اضغط هنا</a
          >
        </p>
      </div>
    </form>
  </div>
</div>
