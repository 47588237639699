import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as profileReducer from './../core/state/_profile/profile.reducer';

@Component({
  selector: 'sm-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CallbackComponent implements OnInit, OnDestroy, AfterViewInit {

  sub$ = new Subscription()
  currentRoute$: Observable<string>;

  constructor(private _route: ActivatedRoute, private _store: Store<any>, private _router: Router) { }
  ngAfterViewInit(): void {
    var rout: any = localStorage.getItem('place');
    console.log(rout);

    // this._router.navigateByUrl('/');
  }


  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  ngOnInit(): void {
    // this.currentRoute$ = this._store.pipe(select(profileReducer.CurrentRoute))
    // this.sub$ = this.currentRoute$.subscribe((data) => {
    //   console.log(data);

    // })


  }

}
