<div class="mas-b2b-container">


  <h4>{{'HOME.APPLICATIONFORB2BSUBSCRIPTION' | translate }}</h4>
  <div *ngIf="!isapplied"  class="row" style="direction: ltr;">
    <!-- col-md-push-8 -->
    <div class="col-md-6">
      <div class="">

        <div class="panel-content bg-default" style="
        background: #fff;
        border: 1px solid;
        border-radius: 5px;
        color: #000;
    ">

          <form [formGroup]="newCourse" (ngSubmit)="onSubmit()" style="padding: 1em;">

            <div class="row">
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.NAME' | translate
                  }}</label>
                <input type="text" formControlName="contactName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.contactName.errors }" />
                <div *ngIf="submitted && xf.contactName.errors" class="invalid-feedback">
                  <div *ngIf="xf.contactName.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.EMAIL' | translate
                  }}</label>
                <input type="text" formControlName="companyEmail" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.companyEmail.errors }" />
                <div *ngIf="submitted && xf.companyEmail.errors" class="invalid-feedback">
                  <div *ngIf="xf.companyEmail.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.PHONE' | translate
                  }}</label>
                <br>
                <ngx-intl-tel-input [preferredCountries]="[CountryISO.SaudiArabia]" [enableAutoCountrySelect]="false"
                  [searchCountryFlag]="true" [customPlaceholder]="'SYSTEM.ENTERPHONE' | translate "
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.SaudiArabia" [maxLength]="15" [phoneValidation]="true" name="phone"
                  [(ngModel)]="phonex" [ngModelOptions]="{standalone: true}">
                </ngx-intl-tel-input>
                <!-- <input type="text" formControlName="contactPhone" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.contactPhone.errors }" /> -->
                <div *ngIf="submitted && xf.contactPhone.errors" class="invalid-feedback">
                  <div *ngIf="xf.contactPhone.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.COMPANYNAME' | translate
                  }}</label>
                <input type="text" formControlName="companyName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.companyName.errors }" />
                <div *ngIf="submitted && xf.companyName.errors" class="invalid-feedback">
                  <div *ngIf="xf.companyName.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.JOBTITLE' | translate
                  }}</label>
                <input type="text" formControlName="jobTitle" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.jobTitle.errors }" />
                <div *ngIf="submitted && xf.jobTitle.errors" class="invalid-feedback">
                  <div *ngIf="xf.jobTitle.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.COMPANYSIZE' | translate
                  }}</label>
                <ng-select [items]="companySize" bindLabel="name" formControlName="type" bindValue="name">
                </ng-select>
                <div *ngIf="submitted && xf.contactName.errors" class="invalid-feedback">
                  <div *ngIf="xf.contactName.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.COUNTRY' | translate
                  }}</label>
                <input type="text" formControlName="country" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.country.errors }" />
                <div *ngIf="submitted && xf.country.errors" class="invalid-feedback">
                  <div *ngIf="xf.country.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
              <div class="col-md-12 form-group">
                <label for="register-form-name"
                  style="text-align: right; font-family: 'Droid Arabic Kufi', sans-serif;">{{'B2B.CITY' | translate
                  }}</label>
                <input type="text" formControlName="city" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && xf.city.errors }" />
                <div *ngIf="submitted && xf.city.errors" class="invalid-feedback">
                  <div *ngIf="xf.city.errors.required">{{'MATERIALS.REQUIRED' | translate }}</div>
                </div>
  
              </div>
  
            </div>
  

            <div class="col_full nobottommargin">
              <button class="button button-3d button-green nomargin btn btn-success btn-embossed"
                id="register-form-submit" style="text-align: center; font-family: 'Droid Arabic Kufi', sans-serif;"
                name="register-form-submit" value="register"> {{button}} </button>



            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <ng-container *ngFor="let item of policies">
            <h3 style="text-align: right; font-family: 'JF-Flat';">
              {{item.header}}
            </h3>
            <p style="text-align: right;" [innerHTML]='item.body'>

            </p>
            <br> <br>
          </ng-container>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="isapplied" class="row">
    <div class="col-md-12">
      <h3 style="text-align: center;">
        {{'TRAINER.YOURAPPLICATIONHASBEENSENT' | translate }}
      </h3>
    </div>
  </div>
</div>