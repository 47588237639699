import { Products } from "./coupons";

export class Affiliate {
    id?: number;
    Id?: number;
    userId?: number;
    groupId?: number;
    name: string;
    email: string;
    phone: string;
    address: string;
    code: string;
}

export class updateStatusAttrs {
    affiliateId?: number;
    status: Status;
}
export enum Status {
    Pending = 1,
    Active = 2,
    Susbended = 3,
    Deleted = 4
}

export class AFFBankInfo {
    Id?: number;
    id?: number;
    affiliateId: number;
    bankName: string;
    accountName: string;
    accountId: number;
    accountIBAN: string;
    bankAddress: string;
    accountAddress: string;
    bankSwift: string;
}

export class AFFComissionsDTO {
    Id?: number;
    id?: number;
    productType: Products;
    commission: number;
    courseId?: number;
    consultantId?: number;
    coachingId?: number;
    bookId?: number;
    packageId?: number;
}

export class confirmedCommissionAttrs {
    affiliateId?: number;
    fromDate?: Date;
    toDate?: Date;
}