import { ActiveCampaign } from './../_models/settings';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { throwError, Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SettingsService {

  private activeCampaign = new BehaviorSubject<ActiveCampaign>(null);

  getActiveCampaign() {
    if (this.activeCampaign.value != null) return this.activeCampaign;


    this.getActiveCampaginSettings().subscribe((data) => {
      this.activeCampaign.next(data);
      return this.activeCampaign;
    })
  }

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }



  verifyEmail(email): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/verifyemail/' + email, this.jwt());
  }


  getSigntureForZoom(email): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/zoomsignature/' + email, this.jwt());
  }


  sendEmail(email): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/send/' + email, this.jwt());
  }


  getSignCookieForMe() {
    return this.http.get(this.apiUrl + '/v1/signcookie', this.jwt());
  }


  getSignCookie(fileName: string) {
    return this.http.get(this.apiUrl + '/api/settings/signcookie/' + fileName, this.jwt());
  }
  getKey(email): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/settings/key/' + email, this.jwt());
  }

  getUrl() {
    return this.http.get<any>(this.apiUrl + '/api/settings/file', this.Withoujwt());
  }


  getSocialMedia() {
    return this.http.get<any>(this.apiUrl + '/v1/social', this.Withoujwt());
  }

  updateActiveCampaign(attrs): Observable<ActiveCampaign> {
    return this.http.post<ActiveCampaign>(this.apiUrl + '/api/settings/activecampaign', attrs, this.jwt());
  }

  private getActiveCampaginSettings(): Observable<ActiveCampaign> {
    return this.http.get<ActiveCampaign>(this.apiUrl + '/api/settings/activecampaign/', this.jwt());
  }

  protected handleError(error: any) {

    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key]) modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }


  private Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }
  private jwt2() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


  private jwt() {
    if (typeof window !== 'undefined') {
      let currentUser = JSON.parse(localStorage.getItem('profile'));
      if (currentUser && currentUser.token) {
        let headers = new HttpHeaders({
          'Authorization': 'Bearer ' + currentUser.token,
          'Content-Type': 'application/json'
        });
        const httpOptions = {
          headers: headers
        };
        return httpOptions;
      }
    }
  }


}
