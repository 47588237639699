import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sm-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BookmarkComponent implements OnInit {


  @Input() currentTime = 0;
  @Output() addBookmark = new EventEmitter<any>();
  @Output() closed = new EventEmitter<boolean>();
  @Input() hidden: boolean = false;
  bookmark: string;


  constructor() { }

  ngOnInit(): void {
  }


  submitBookmark() {
    this.addBookmark.emit(this.bookmark);
  }


  closeBookmark() {
    this.hidden = true;
    this.bookmark = null
    this.closed.emit(this.hidden);
  }


}
