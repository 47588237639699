import { Injectable } from '@angular/core';
declare var $: any;



@Injectable()
export class SystemService {
  loadAPI: Promise<any>;



  loadData() {
    this.loadAPI = new Promise((resolve) => {

      this.loadStripe();
      // this.loadScript();
      // this.loadScriptHead();
      // this.loadStylesHead();
      // resolve(true);
    });
  }


  // loadStripeData() {
  //   this.loadAPI = new Promise((resolve) => {
  //     this.loadStripe();
  //   })
  // }





  public ActivateRadioAndCheckBox() {
    if (!$().iCheck) return;
    $(':checkbox:not(.js-switch, .switch-input, .switch-iphone, .onoffswitch-checkbox, .ios-checkbox, .md-checkbox), :radio:not(.md-radio)').each(function () {

      var checkboxClass = $(this).attr('data-checkbox') ? $(this).attr('data-checkbox') : 'icheckbox_minimal-grey';
      var radioClass = $(this).attr('data-radio') ? $(this).attr('data-radio') : 'iradio_minimal-grey';

      if (checkboxClass.indexOf('_line') > -1 || radioClass.indexOf('_line') > -1) {
        $(this).iCheck({
          checkboxClass: checkboxClass,
          radioClass: radioClass,
          insert: '<div class="icheck_line-icon"></div>' + $(this).attr("data-label")
        });
      } else {
        $(this).iCheck({
          checkboxClass: checkboxClass,
          radioClass: radioClass
        });
      }
    });
  }



  public loadDashScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [

        "assets/js/videojs-dash.js",
        "assets/js/dash.all.min.js",
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    }
  }
  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [

        "assets/admin/layout2/js/layout.js",
        "assets/global/plugins/gsap/main-gsap.min.js",
        "assets/global/plugins/tether/js/tether.min.js",
        "assets/global/plugins/bootstrap/js/bootstrap.min.js",
        "assets/global/plugins/appear/jquery.appear.js",
        "assets/global/plugins/jquery-cookies/jquery.cookies.min.js",
        "assets/global/plugins/jquery-block-ui/jquery.blockUI.min.js",
        "assets/global/plugins/bootbox/bootbox.min.js",
        "assets/global/plugins/mcustom-scrollbar/jquery.mCustomScrollbar.concat.min.js",
        "assets/global/plugins/bootstrap-dropdown/bootstrap-hover-dropdown.min.js",
        "assets/global/plugins/charts-sparkline/sparkline.min.js",
        "assets/global/plugins/bootstrap-tags-input/bootstrap-tagsinput.min.js",
        "assets/global/plugins/retina/retina.min.js",
        // "assets/js/select2.full.js",
        // "assets/js/select2.min.js",
        // "assets/global/plugins/icheck/icheck.min.js",
        "assets/global/plugins/backstretch/backstretch.min.js",
        "assets/global/plugins/bootstrap-progressbar/bootstrap-progressbar.min.js",
        "assets/global/js/builder.js",
        "assets/global/js/sidebar_hover.js",
        "assets/global/js/application.js",
        "assets/global/js/plugins.js",

        "assets/global/js/widgets/widget_weather.js",
        "assets/global/plugins/bootstrap-editable/js/bootstrap-editable.min.js",
        "assets/global/plugins/bootstrap-context-menu/bootstrap-contextmenu.min.js",
        "assets/global/plugins/multidatepicker/multidatespicker.min.js",
        "assets/global/js/widgets/todo_list.js",
        "assets/global/plugins/metrojs/metrojs.min.js",
        "assets/global/js/pages/dashboard.js",

      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }

    }
  }



  public loadScriptHead() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        "assets/global/plugins/jquery/jquery-3.1.0.min.js",
        "assets/global/plugins/jquery/jquery-migrate-3.0.0.min.js",
        "assets/global/plugins/jquery-ui/jquery-ui.min.js"
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }



  // <link href="../assets/global/css/style.css" rel="stylesheet">
  // <link href="../assets/global/css/rtl.css" rel="stylesheet">
  // <link href="../assets/global/css/theme.css" rel="stylesheet">

  // <link href="../assets/global/css/ui.css" rel="stylesheet">
  // <link href="../assets/admin/layout2/css/layout.css" rel="stylesheet">
  // <!-- BEGIN PAGE STYLE -->
  // <!-- END PAGE STYLE -->
  // <link href="https://cdn.jsdelivr.net/npm/select2@4.0.12/dist/css/select2.min.css" rel="stylesheet" />

  public loadStylesHead() {
    var isFound = false;
    var scripts = document.getElementsByTagName("link")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        "assets/global/css/style.css",
        "assets/global/css/rtl.css",
        "assets/global/css/ui.css",
        "assets/admin/layout2/css/layout.css",
        "https://cdn.jsdelivr.net/npm/select2@4.0.12/dist/css/select2.min.css",
        "assets/global/css/theme.css"
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('link');
        node.href = dynamicScripts[i];
        node.rel = 'stylesheet';
        // node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }




  public loadStripe() {
    var dynamicScripts = [
      "https://js.stripe.com/v3/",
    ];

    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts[i];
      // node.type = 'text/javascript';
      node.async = true;
      // node.charset = 'utf-8';
      document.getElementsByTagName('body')[0].appendChild(node);
    }
  }
}


export function removeMainRTL(){
  if(document.getElementById('main-rtl'))
  document.getElementById('main-rtl').remove();
}


export function addMainRTL() {

  var dynamicScripts = [
    "assets/thestyle/js/main-rtl.js",
  ];

  for (var i = 0; i < dynamicScripts.length; i++) {
    let node = document.createElement('script');
    node.src = dynamicScripts[i];
    // node.type = 'text/javascript';
    node.id = 'main-rtl'
    node.async = true;
    // node.charset = 'utf-8';
    document.getElementsByTagName('body')[0].appendChild(node);
  }
}


export function loadHeaderStyle(lang) {
  // document
  //   .querySelectorAll('style,link[rel="stylesheet"]')
  //   .forEach(item => item.remove())
  var isFound = false;
  var body = document.body;
  var scripts = document.getElementsByTagName("link")
  for (var i = 0; i < scripts.length; ++i) {
    if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
      isFound = true;
    }
  }

  if (!isFound) {
    var dynamicScripts = [];

    if (lang === 'ar') {
      dynamicScripts =
        [
          "styles.css",
          "assets/thestyle/css/bootstrap.min.css",
          "assets/thestyle/css/bootstrap-rtl.min.css",
          "assets/thestyle/css/owl.carousel.min.css",
          "assets/thestyle/css/owl.theme.default.min.css",
          "assets/thestyle/css/bootstrap-select.min.css",
          "assets/thestyle/css/fancybox.css",
          "assets/thestyle/css/style.css",
          "assets/thestyle/css/style-rtl.css",
        ];
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');
    } else {
      dynamicScripts = [
        "assets/thestyle/main.css",
        "assets/thestyle/style-en.css",
      ];
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');
    }


    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('link');
      node.href = dynamicScripts[i];
      node.rel = 'stylesheet';
      // node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }
}

export function loadOriginalHeaderStyle(lang) {
  document
    .querySelectorAll('style,link[rel="stylesheet"]')
    .forEach(item => item.remove())
  var isFound = false;
  var body = document.body;
  var scripts = document.getElementsByTagName("link")
  for (var i = 0; i < scripts.length; ++i) {
    if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
      isFound = true;
    }
  }

  if (!isFound) {
    var dynamicScripts = [];

    if (lang === 'ar') {
      dynamicScripts =
        [
          "assets/global/css/style.css",
          "assets/global/css/rtl.css",
          "assets/global/css/theme.css",
          "assets/icon_fonts_assets/typicons/typicons.css",
          "assets/icon_fonts_assets/font-awesome/css/font-awesome.min.css",
          "assets/icon_fonts_assets/feather/style.css",
          "assets/icon_fonts_assets/simple-line-icons/css/simple-line-icons.css",
          "assets/icofont.min.css",
          "styles.css",
          "node_modules/video.js/dist/video-js.min.css",
          "node_modules/intl-tel-input/build/css/intlTelInput.css",
          "node_modules/noty/lib/noty.css",
          "node_modules/noty/lib/themes/metroui.css",
          "node_modules/cookieconsent/build/cookieconsent.min.css",
          "node_modules/noty/lib/themes/semanticui.css",
          "node_modules/noty/lib/themes/nest.css",
          "node_modules/videojs-markers/dist/videojs.markers.css",
          "node_modules/videojs-seek-buttons/dist/videojs-seek-buttons.css",
          "node_modules/videojs-max-quality-selector/dist/videojs-max-quality-selector.css",
          "node_modules/videojs-selector-quality-hls/dist/videojs-selector-quality-hls.css"
        ];
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');
    } else {
      dynamicScripts = [
        "assets/thestyle/main.css",
        "assets/thestyle/style-en.css",
      ];
      body.classList.toggle('menu-side-right');
      body.classList.toggle('menu-side-left');
    }


    for (var i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('link');
      node.href = dynamicScripts[i];
      node.rel = 'stylesheet';
      // node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }
}