import { cartItem } from './../../../core/_models/cart';
import { consultancyCard } from './../../../core/_models/consultancies';
import { courseState } from './../../../core/state/_courses/course.reducer';
import { courseSlide } from './../../../core/_models';
import { Component, OnInit, ChangeDetectorRef, HostListener, ChangeDetectionStrategy, ViewEncapsulation, Inject, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICSliderOptions } from '../../components/courseslider/icslider';
import { Router, NavigationExtras } from '@angular/router';
import { BaseService } from '../../services';
import { B2BService, CoursesService, loadOriginalHeaderStyle, removeMainRTL } from './../../../core/services';
import { SliderModel } from './../../../slider/slider-model';
import { TweenMax } from 'gsap';
import { Store, select } from '@ngrx/store';
import * as _reducer from './../../../core/state/_courses/course.reducer';
import * as _packagesReducer from './../../../core/state/_courses/packages.reducer';
import * as _consultancyReducer from './../../../core/state/_consultancies/consultancy.reducers';
import * as _cartReducer from './../../../core/state/_cart/cart.reducer';
import 'hammerjs';


@Component({
  selector: 'sm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {

  items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  progress = new BehaviorSubject<number>(0);
  loading: Observable<boolean>;
  options: ICSliderOptions = {
    itemHeight: 0,
    scale: '6:5',
    transition: 'bounce',
    rtl: true,
    cardType: 'category'
  }

  constructor(
    @Inject('API_URL') private apiUrl: string,
    private _router: Router, private _base: BaseService,
    private _store: Store, private _course: CoursesService,
    private cd: ChangeDetectorRef, private _b2b: B2BService) {
    // _b2b.TESTAPI().subscribe(data => console.log(data));
    this._store.dispatch({ type: '[Profile] Stop Loading' });
  }
  ngAfterViewInit(): void {
    this._store.dispatch({ type: '[Profile] Stop Loading' });
    this.cd.markForCheck();

  }

  header1 = 'الدورات التدريبية'
  header2 = 'باقات الدورات'
  header3 = 'الدبلومات التدريبية والباقات'
  header4 = 'الاستشارات الشخصية'
  header5 = 'الفيديوهات'

  data: SliderModel[] = [];



  grow(i) {
    var item = document.getElementById('xx' + i);
    if (i == 0) {
      TweenMax.to(item, .5, { scale: 2, x: 113 });
    } else if (i == this.items.length - 1) {

    } else {
      TweenMax.to(item, .5, { scale: 2 });
    }

    for (let w = i; w < this.items.length; w++) {
      var x = w + 1;
      TweenMax.to(document.getElementById('xx' + x), .5, { x: 113 });
    }
    var before = this.items.filter(x => { return this.items.indexOf(x) < i });
    // console.log(before);

    for (let w = i; w > -1; w--) {
      var y = w - 1;
      TweenMax.to(document.getElementById('xx' + y), .5, { y: -113 });
    }
  }

  leave(i) {
    var item = document.getElementById('xx' + i);
    if (i == 0) {
      TweenMax.to(item, .5, { scale: 1, x: 0 });
    } else if (i == this.items.length - 1) {

    } else {
      TweenMax.to(item, .5, { scale: 1 });
    }

    for (let i = 0; i < this.items.length; i++) {
      var x = i + 1;
      TweenMax.to(document.getElementById('xx' + x), .5, { scale: 1, x: 0 });

    }
  }



  getItem(event, id) {
    if (id == 3) {

      this._store.dispatch({ type: '[Counsultancies] Select And Update Consultancy', consultancyId: event.consultancyId });
      this._router.navigate(['/consultancy/consultancy/', event.consultancyId])
    }
    if (id == 1) {
      var query: any = {};
      var arrayOfValues = {
        coursetitle: event.courseLink, id: event.id, addid: null
      };
      query.session = JSON.stringify(arrayOfValues);
      const navigationExtras: NavigationExtras = { queryParams: arrayOfValues };
      this._store.dispatch({ type: '[Courses] Select And Update Course By Id', courseId: event.id });
      this._router.navigate(['/courses/course'], navigationExtras)
    }


    if (id == 4) {
      // console.log(event);

      var query: any = {};
      var arrayOfValues = {
        coursetitle: event.courseLink, id: event.id, addid: null
      };
      query.session = JSON.stringify(arrayOfValues);
      this._store.dispatch({ type: '[Courses] Select And Update Course By Id', courseId: event.id });
      const navigationExtras: NavigationExtras = { queryParams: arrayOfValues };
      if (event.isCourse) {
        this._router.navigate(['/courses/course'], navigationExtras)
      } else {

        this._router.navigate(['/courses/package'], navigationExtras)
      }

    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.init();
  }


  data$: Observable<SliderModel[]>
  consultancies$: Observable<consultancyCard[]>
  packages$: Observable<SliderModel[]>;
  cart$: Observable<cartItem[]>
  error: any;

  ngOnInit(): void {
    // console.log('WE ARE HERE');
    removeMainRTL();
    // loadOriginalHeaderStyle('ar');


    this.data$ = this._store.pipe(select(_reducer.availableCourses));
    // console.log('courses');
    this.packages$ = this._store.pipe(select(_packagesReducer.availablePackages));
    // console.log('packagses');
    // this.cart$ = this._store.pipe(select(_cartReducer.selectAllUsers));
    this.consultancies$ = this._store.pipe(select(_consultancyReducer.availableConsultancies));
    // console.log('consultancies');
    this.loading = this._store.pipe(select(_reducer.loadingStatus))
    // console.log('loading');
  }

  goToAll(e) {
    // console.log(e);¿
    if (e.cardType == 'course') {
      this._router.navigate(['courses'])
    } else if (e.cardType == 'category') {
      this._router.navigate(['courses/packages'])
    } else {
      this._router.navigate(['consultancy'])
    }
  }





}
