import { BehaviorSubject } from 'rxjs';
import { SliderModel } from './../slider-model';
import { ISlider } from './../islider';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
// import { // TweenMax, Ease, Power4 } from 'gsap';

@Component({
  selector: 'sm-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SliderComponent implements OnInit, ISlider, OnChanges, AfterViewInit {

  private _numberOfSliders = 0;
  private inialCollection = 1;
  slected: boolean = false;

  get numberOfScollers() {
    return this._numberOfSliders;
  }
  set numberOfScollers(any) {
    this._numberOfSliders = this.data.length / this.numberOfSlides.value;
  }
  private numberOfSlides = new BehaviorSubject<number>(5);
  @Input() data: SliderModel[];
  private _header: string = 'Slider Header';
  get header() {
    return this._header;
  }
  @Input() set header(any) {
    this._header = any;
  }

  private _rtl: boolean = true;
  get RTL() {
    return this._rtl;
  }

  set RTL(any) {
    this._rtl = any;
  }

  @Input() id: number = 0;

  pager: any;

  private _sliders: SliderModel[] = [];
  get sliders() {
    return this._sliders;
  }

  set sliders(page) {
    this._sliders = page;
  }
  innerWidth = 0;
  defaultImage: string = "/mastery.jpg";

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.init();

  }




  @Output() changeScroll = new EventEmitter<any>(true);
  loaded = false;

  constructor(private cd: ChangeDetectorRef, ) { }
  ngAfterViewInit(): void {
    // console.log(this.id + " SD SDS D  " + this.data + document.getElementById('sm-slider-container-' + this.id));

    this.init();
  }


  onMouseEnter(i): void {
    var item: any = document.getElementsByClassName('sm-slider-item-' + i + '-' + this.id);
    var title: any = document.getElementById('sm-item-content-body-' + i + '-' + this.id);
    var active = document.querySelectorAll('.sm-slider-item-active');
    var arrayOfActive = Array.from(active);
    arrayOfActive = arrayOfActive.filter((x: any) => { return x.style.display != '' })
    // console.log(arrayOfActive);

    item[0].classList.add('hover');
    var padding = window.innerWidth * 4 / 100;
    var wBigElement = item[0].offsetWidth * 2;
    var translation = (wBigElement - item[0].offsetWidth) / 2;
    var scaleFactor = 2;
    if (arrayOfActive.length != 0) {

    } else {
      if (this.numberOfSlides.value == 6 || this.numberOfSlides.value == 5) {
        if (i == 0) {
          // TweenMax.to(item, .25, { scale: scaleFactor, x: - (padding + ((item[0].offsetWidth / 4) + 2)) });
          for (let w = i; w < this.sliders.length; w++) {
            var x = w + 1;
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + x + '-' + this.id), .25, { x: -(padding + ((item[0].offsetWidth / 1.25) - 18)) });
          }

        } else if (i == this.sliders.length - 1) {
          // TweenMax.to(item, .25, { scale: scaleFactor, x: (padding + ((item[0].offsetWidth / 4) - 2)) });
          // // TweenMax.to(item, .25, { scale: scaleFactor, x: ((item[0].offsetWidth * scaleFactor) - item[0].offsetWidth)  - 8  });
          for (let ii = i - 1; ii >= 0; ii--) {
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + ii + '-' + this.id), .25, { x: (padding + ((item[0].offsetWidth / 1.25) - 20)) });
            // // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + ii + '-' + this.id), .25, { x: ((item[0].offsetWidth * scaleFactor) - item[0].offsetWidth) / 2 });
          }
        }
        //not the first nor the last
        else {
          // TweenMax.to(item, .25, { scale: scaleFactor });
          for (let ii = i - 1; ii >= 0; ii--) {
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + ii + '-' + this.id), .25, { x: ((item[0].offsetWidth * scaleFactor) - item[0].offsetWidth) / 2 });
            // // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + ii + '-' + this.id), .25, { x: (padding + ((item[0].offsetWidth / 4))) });
          }
          for (let w = i; w < this.sliders.length; w++) {
            var x = w + 1;
            // // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + x + '-' + this.id), .25, { x: - (padding + ((item[0].offsetWidth / 4))) });
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + x + '-' + this.id), .25, { x: - ((item[0].offsetWidth * scaleFactor) - item[0].offsetWidth) / 2 });
          }
        }

      }
      // IF THE NUMBER OF SLIDES IS 3
      else {
        if (i == 0) {
          // TweenMax.to(item, .25, { scale: 1.75, x: -(padding + ((item[0].offsetWidth / 3.25) - 9)) });
          for (let w = i; w < this.sliders.length; w++) {
            var x = w + 1;
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + x + '-' + this.id), .25, { x: - (padding + ((item[0].offsetWidth / 1.7))) });
          }

        } else if (i == this.sliders.length - 1) {
          // TweenMax.to(item, .25, { scale: 1.75, x: (padding + ((item[0].offsetWidth / 4) - 8)) });
          for (let ii = i - 1; ii >= 0; ii--) {
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + ii + '-' + this.id), .25, { x: (padding + ((item[0].offsetWidth / 1.7))) });
          }
        } else {
          // TweenMax.to(item, .25, { scale: 1.75 });
          for (let ii = i - 1; ii >= 0; ii--) {
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + ii + '-' + this.id), .25, { x: (padding + ((item[0].offsetWidth / 3.25))) });
          }
          for (let w = i; w < this.sliders.length; w++) {
            var x = w + 1;
            // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + x + '-' + this.id), .25, { x: -(padding + ((item[0].offsetWidth / 3.25))) });
          }
        }

      }
    }


    if (this.numberOfSlides.value == 6) {
      // TweenMax.to(title, .6, { y: -50 })
      // TweenMax.set(document.getElementsByClassName('sm-item-content-' + i + '-' + this.id), { autoAlpha: 1 })
      // TweenMax.from(document.getElementsByClassName('sm-item-content-' + i + '-' + this.id), .25, { opacity: 0, scale: 0, transformOirigin: '50% 50%', ease: Power4.easeOut, delay: .3 });
      // TweenMax.set(document.getElementsByClassName('sm-original-price-' + i + '-' + this.id), { autoAlpha: 1 })
      // TweenMax.to(document.getElementsByClassName('sm-original-price-' + i + '-' + this.id), .25, { scale: .8, autoAlpha: .9, delay: .25 })
      // TweenMax.from('.sm-original-price-line-' + i + '-' + this.id, .25, { width: '0', transformOrigin: "-100% -50%", delay: .25 });
      // TweenMax.set(document.getElementsByClassName('sm-new-price-' + i + '-' + this.id), { autoAlpha: 1 })
      // TweenMax.from(document.getElementsByClassName('sm-new-price-' + i + '-' + this.id), .2, { autoAlpha: 0, x: '10', delay: .25 })
      // TweenMax.to(document.getElementById('moreinfo-' + i + '-' + this.id), 1, { y: 50, autoAlpha: 1 })
    } else {

      // TweenMax.to(title, .6, { y: -40 })
      // TweenMax.set(document.getElementsByClassName('sm-item-content-' + i + '-' + this.id), { autoAlpha: 1 })
      // TweenMax.set(document.getElementsByClassName('sm-item-content-' + i + '-' + this.id), { y: -25, css: { background: 'black', top: '20px' } });
      // TweenMax.from(document.getElementsByClassName('sm-item-content-' + i + '-' + this.id), .25, { opacity: 0, scale: 0, transformOirigin: '50% 50%', ease: Power4.easeOut, delay: .3 });
      // TweenMax.set(document.getElementsByClassName('sm-original-price-' + i + '-' + this.id), { autoAlpha: 1 })
      // TweenMax.to(document.getElementsByClassName('sm-original-price-' + i + '-' + this.id), .25, { scale: .6, autoAlpha: .9, delay: .25 })
      // TweenMax.from('.sm-original-price-line-' + i + '-' + this.id, .25, { width: '0', transformOrigin: "-100% -50%", delay: .25 });
      // TweenMax.set(document.getElementsByClassName('sm-new-price-' + i + '-' + this.id), { autoAlpha: 1 })
      // TweenMax.from(document.getElementsByClassName('sm-new-price-' + i + '-' + this.id), .2, { autoAlpha: 0, x: '10', delay: .25 })
      // TweenMax.to(document.getElementById('moreinfo-' + i + '-' + this.id), 1, { y: 30, autoAlpha: 1 })

    }
    // for (let w = i; w > -1; w--) {
    //   var y = w - 1;
    //   // TweenMax.to(document.getElementsByClassName('slider-item-' + y), .25, { y: -113 });
    // }
  }


  onMouseLeave(i): void {
    var item = document.getElementsByClassName('sm-slider-item-' + i + '-' + this.id);
    var title: any = document.getElementById('sm-item-content-body-' + i + '-' + this.id);
    item[0].classList.remove('hover');
    if (i == 0) {
      // TweenMax.to(item, .25, { scale: 1, x: 0 });
    } else if (i == this.sliders.length - 1) {

    } else {
      // TweenMax.to(item, .25, { scale: 1 });
    }

    for (let i = 0; i < this.sliders.length; i++) {
      var x = i + 1;
      // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + x + '-' + this.id), .25, { scale: 1, x: 0 });
    }

    for (let ii = i - 1; ii >= 0; ii--) {
      // TweenMax.to(document.getElementsByClassName('sm-slider-item-' + ii + '-' + this.id), .25, { x: 0 });
    }

    // TweenMax.to(title, .6, { y: 0 })
    // TweenMax.to(document.getElementsByClassName('sm-item-content-' + i + '-' + this.id), .25, { autoAlpha: 0 })
    // TweenMax.to(document.getElementsByClassName('sm-new-price-' + i + '-' + this.id), .25, { autoAlpha: 0 })
    // TweenMax.set(document.getElementsByClassName('sm-original-price-' + i + '-' + this.id), { autoAlpha: 0, scale: 1 })
    // TweenMax.to(document.getElementById('moreinfo-' + i + '-' + this.id), 1, { y: 0, autoAlpha: 0 })

  }


  hoverNext() {
    // // TweenMax.set('#nextMen-' + this.iZd, { autoAlpha: 1 });
    // // TweenMax.staggerFrom('#nextMen-' + this.id + ' path', .25, { x: 2, autoAlpha: 0 }, .08);
    // TweenMax.to('#next-' + this.id, .25, { x: -12, });
  }

  hoverPrev() {
    // // TweenMax.set('#prevMen-' + this.id, { autoAlpha: 1 });
    // // TweenMax.staggerFrom('#prevMen-' + this.id + ' path', .25, { x: 2, autoAlpha: 0 }, .08);
    // TweenMax.to('#prev-' + this.id, .25, { x: 12, });
  }
  releaseNext() {
    // TweenMax.to('#next-' + this.id, .25, { x: 0, });
    // // TweenMax.set('#nextMen-' + this.id, { autoAlpha: 0 });
  }
  releasePrev() {
    // TweenMax.to('#prev-' + this.id, .25, { x: 0, });
    // // TweenMax.set('#prevMen-' + this.id, { autoAlpha: 0 });
  }


  @Output() onclick = new EventEmitter();
  onClick(i, item): void {
    this.onMouseLeave(i);
    var active = document.querySelectorAll('.sm-slider-item-active');
    var allitems = document.querySelectorAll('.sm-slider-item');
    var arrayAllItems = Array.from(allitems);
    arrayAllItems.forEach((element: any) => {
      element.style.opacity = '0.7';
    });
    var currentItem: any = document.getElementById('sm-slider-item-' + i + '-' + this.id);
    currentItem.style.opacity = 1;
    var arrayOfActive = Array.from(active);
    arrayOfActive = arrayOfActive.filter((x: any) => { return x.style.display != '' })
    // console.log(arrayOfActive);
    if (arrayOfActive.length != 0) {
      arrayOfActive.forEach((element: any) => {
        element.style.display = ''
      });
    }
    var element: any = document.getElementById('sm-slider-item-active-' + i + '-' + this.id);
    element.style.display = 'block'
    this.onclick.emit(item);
    this.slected = true;
  }

  swipeHandler($event, pageNumber) {
    // console.log($event);


  }


  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth >= 0 && this.innerWidth <= 414) {
      this.numberOfSlides.next(2);
    }
    if (this.innerWidth >= 415 && this.innerWidth <= 768) {
      this.numberOfSlides.next(3);
    }
    if (this.innerWidth >= 769 && this.innerWidth <= 1024) this.numberOfSlides.next(4);
    if (this.innerWidth >= 1025 && this.innerWidth <= 1399) this.numberOfSlides.next(5);
    if (this.innerWidth >= 1400) this.numberOfSlides.next(6);

    this.pager = this.paginate(this.data.length, this.inialCollection, this.numberOfSlides.value, 6);
    if (this.data && this.data.length) {
      this.scrollTo(this.inialCollection);
      this.loaded = true;
    }
  }

  public scaling = 2;

  init() {


    var videoWidth = ((this.innerWidth - 40 * 2) / this.numberOfSlides.value);
    var videoHeight = Math.round(videoWidth / (16 / 9));

    // var container = $('.sm-slider-container');
    var container = document.getElementById('sm-slider-container-' + this.id);
    container.style.height = videoHeight * this.scaling + "px";
    container.style.overflow = 'hidden';


    var sliderBody = document.getElementById('sm-slider-body-' + this.id);
    var videoDefHeight = (videoHeight * this.scaling) - videoHeight;
    sliderBody.style.top = videoDefHeight / 3.5 + 'px';


    // TweenMax.set('.sm-slider-container', { css: { 'height': videoHeight * this.scaling } });
    // console.log(videoHeight * this.scaling);


  }
  ngOnChanges(changes: SimpleChanges) {
    // // reset page if items array has changed
    // if (changes.items.currentValue !== changes.items.previousValue) {
    //   this.scrollTo(this.inialCollection);
    // }
  }

  scrollTo(nextSlider) {

    if (nextSlider > 1) {
      for (let i = 0; i < this.sliders.length; i++) {
        const element = this.sliders[i];
        // // TweenMax.staggerTo(document.getElementsByClassName('sm-slider-item-' + this.id), .4, { autoAlpha: 0, scale: 0, transformOrigin: "50% 50%" }, .06);
        // get new pager object for specified page
        this.pager = this.paginate(this.data.length, nextSlider, this.numberOfSlides.value, 50);

        // get new page of items from items array
        this.sliders = this.data.slice(this.pager.startIndex, this.pager.endIndex + 1);


        // // TweenMax.from(document.getElementsByClassName('slider-item'), 1, { autoAlpha: 0, scale: 0, transformOrigin: "50% 50%", delay: 1 });
        // call change page function in parent component
        this.changeScroll.emit(this.sliders);

        //  // TweenMax.staggerTo(document.getElementsByClassName('sm-slider-item-' + this.id), .8, { autoAlpha: 1, scale: 1, transformOrigin: "50% 50%" }, .02);
        this.cd.markForCheck()
      }
    } else {
      // get new pager object for specified page
      this.pager = this.paginate(this.data.length, nextSlider, this.numberOfSlides.value, 50);

      // get new page of items from items array
      this.sliders = this.data.slice(this.pager.startIndex, this.pager.endIndex + 1);


      // // TweenMax.from(document.getElementsByClassName('slider-item'), 1, { autoAlpha: 0, scale: 0, transformOrigin: "50% 50%", delay: 1 });
      // call change page function in parent component
      this.changeScroll.emit(this.sliders);

      for (let i = 0; i < this.sliders.length; i++) {
        const element = this.sliders[i];
        // // TweenMax.from(document.getElementsByClassName('sm-slider-item-' + i + '-' + this.id), 10, { autoAlpha: 0, scale: 0, transformOrigin: "50% 50%" });
        this.cd.markForCheck();
      }
    }
    this.cd.markForCheck()
  }


  paginate(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 10,
    maxPages: number = 10
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      numberOfPages: Array(totalPages),
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }


}
