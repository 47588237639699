import { TweenMax } from 'gsap';
import { SHA1CryptoService } from './../../core/services/sha1encrypt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import * as CryptoJS from 'crypto-js';
import { switchMap } from 'rxjs/operators';
import { bindNodeCallback, BehaviorSubject } from 'rxjs';
import xml2js from 'xml2js';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sm-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss']
})
export class MeetingComponent implements OnInit {


  private apiUrl = 'https://center2.smartstep.com.tr/bigbluebutton/';
  private apiSecret = 'H1CsluILQxFYWMy1e1ejYmzZeY9bdsCPsNs6XRMVsM';
  private params = 'allowStartStopRecording=true&autoStartRecording=false&duration=40&moderatorPW=12&logoutURL=https%3A%2F%2Fcenter2.smartstep.com.tr&maxParticipants=12&meetingID=s90&record=true&voiceBridge=75011';
  joining: any;


  constructor(private http: HttpClient, private _sha: SHA1CryptoService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    var query = "create" + this.params + "" + this.apiSecret;
    // var checked = CryptoJS.SHA1(query);
    var checked = this._sha.hex_sha1(query);



    var queryRecording = 'getRecordingsmeetingID=s90&recordID=s90' + this.apiSecret;
    var chereq = this._sha.hex_sha1(queryRecording);
    var welcome = "<br>Welcome to <b>%%CONFNAME%%</b>!<br><br>To understand how BigBlueButton works see our <a href=\"event:http://www.bigbluebutton.org/content/videos\"><u>tutorial videos</u></a>.<br><br>To join the audio bridge click the headset icon (upper-left hand corner). <b>You can mute yourself in the Listeners window.</b>";
    var xml = `<?xml version='1.0' encoding='UTF-8' ?> <modules>
    <module name="presentation">
       <document url="http://www.pdf995.com/samples/pdf.pdf" filename="report.pdf"/>
    </module>
 </modules>`;
    var welcomeMsg = "The presentation will appear in moment.  To download click <a href='https://www.smartstep.com.tr/assets/contact.jpg'> <u>YES YES YES </u></a >.< br > " + welcome;
    // this.http.post(this.apiUrl + "presentation/PresUploadToken-n3vcv242DEFAULT_PRESENTATION_POD-w_a6nhjnifbmwh/upload", null, this.Withoujwt()).subscribe((data) => {
    //   console.log(data);

    // })

    this.getUrl(true);
    // this.makeXMLRequest('POST', this.apiUrl + 'api/create?' + this.params + '&checksum=' + checked, xml);

  }



  getUrl(data) {
    this.trux = true;
    var login = 'join' + 'fullName=NourYasein&meetingID=s90&password=12&avatarURL=https%3A%2F%2Fsmartstep.com.tr%2Fsmartstep.png' + this.apiSecret;
    var checkedLog = this._sha.hex_sha1(login);
    // console.log(checked);
    var joinurl: string = this.apiUrl + 'api/join?fullName=NourYasein&meetingID=s90&password=12&avatarURL=https%3A%2F%2Fsmartstep.com.tr%2Fsmartstep.png&checksum=' + checkedLog;
    var xml = `<?xml version='1.0' encoding='UTF-8' ?> <modules>
    <module name="presentation">
       <document url="http://www.pdf995.com/samples/pdf.pdf" filename="report.pdf"/>
    </module>
 </modules>`;
    // joinurl = joinurl.toLowerCase();
    // console.log(this.sanitizer.bypassSecurityTrustUrl(joinurl));
    var join: any = document.getElementById('join');
    // this.http.get(joinurl, this.Withoujwt()).subscribe((data: any) => {
    //   console.log("SSS " + data);
    // });

    this.makeXMLRequest('POST', joinurl, xml)
    join.src = joinurl
    var chat: any = document.getElementsByClassName('chat--Z1w8gP7')[0];
    TweenMax.set('.chat--Z1w8gP7', { autoAlpha: 0 });
    // return this.sanitizer.bypassSecurityTrustUrl(joinurl);

  }

  trux = false;

  makeXMLRequest(method, url, xml) {
    let self = this;
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        console.log(this.response);
        if (!self.trux) {
          // self.getUrl(true);
        }
      }
    };
    xhr.open(method, url, true);
    // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(xml);
  }

  Withoujwt() {
    // create authorization header with jwt token
    let headers = new HttpHeaders({
      'Response-Type': 'text',
      'Accept': 'xml'
    });
    const httpOptions = {
      headers: headers
    };
    return httpOptions;
  }




}





